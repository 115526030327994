import React, { Component } from "react";
import "./style.scss";
import { translate } from "react-i18next";
import { handleFetchContent } from "./fetchUtils";
import { getLanguageId } from "../../utils/helpers";
import { PhyzziBrandLoader } from "phyzzi.com-frontend-utils";
import "phyzzi.com-frontend-utils/build/static/css/index.css";

export class AboutComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoadingIcon: true,
      content: []
    };
  }

  componentDidMount() {
    this.fetchContent();
  }

  fetchContent = () => {
    const { i18n } = this.props;

    handleFetchContent({
      contentId: "about",
      languageId: getLanguageId(i18n.language),
      token: this.props.token,
      invokeInfo: this.props.invokeInfo,
      injectContent: this.injectContent
    });
  };

  injectContent = json => {
    this.setState({
      showLoadingIcon: false,
      content: json
    });
  };

  render() {
    return (
      <section className="About">
        {this.state.showLoadingIcon ? (
          <PhyzziBrandLoader />
        ) : (
          <div
            className="About__wrapper"
            // for tracking purposes
            id="About"
          >
            {this.state.content.map((block, idx) => (
              <div key={idx}>
                <div className="About__chapter-block">
                  {!!block.title &&
                    block.title.map((title, idx) => (
                      <p key={idx} className="About__chapter">
                        {title}
                      </p>
                    ))}
                </div>
                <div className="About__content-block">
                  {!!block.content &&
                    block.content.map((content, idx) => (
                      <p key={idx} className="About__content">
                        {content}
                      </p>
                    ))}
                </div>
              </div>
            ))}
          </div>
        )}
      </section>
    );
  }
}

export default translate("frontend")(AboutComponent);
