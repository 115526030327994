import { connect } from "react-redux";

import ShareButton from "../../components/ShareButton";

const mapStateToProps = state => ({
  theme: state.theme
});

const mapDispatchToProps = {};

const ShareButtonWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShareButton);

export default ShareButtonWrapper;
