/*
 * Action types
 */
export const TOGGLE_SECTION_COLLAPSE = "TOGGLE_SECTION_COLLAPSE";
export const TOGGLE_UNIT_TYPE_SELECTION = "TOGGLE_UNIT_TYPE_SELECTION";
export const CLEAR_ALL_UNIT_TYPE_FILTERS = "CLEAR_ALL_UNIT_TYPE_FILTERS";

/*
 * Action creators
 */
export function toggleSectionCollapse(id) {
  return {
    type: TOGGLE_SECTION_COLLAPSE,
    id
  };
}

export function toggleUnitTypeSelection(id) {
  return {
    type: TOGGLE_UNIT_TYPE_SELECTION,
    id
  };
}

export function clearAllUnitTypeFilters() {
  return {
    type: CLEAR_ALL_UNIT_TYPE_FILTERS
  };
}
