import React from "react";

import "./style.scss";
import { DescribedButton } from "react-bootstrap-tools";
import { translate } from "react-i18next";

export function UnitTypeTreeButtonsComponent(props) {
  const { t } = props;
  const description = t("UnitTypeTreeButtons.descriptionUncheck");

  return (
    <section className="UnitTypeTreeButtons">
      <DescribedButton
        description={description}
        icon="layers_clear"
        // for tracking purposes
        buttonClassName="UnitTypeTreeButtons__uncheck"
        onClick={props.clearAllUnitTypeFilters}
      />
    </section>
  );
}

export default translate("frontend")(UnitTypeTreeButtonsComponent);
