import React, { PureComponent } from "react";
import "./style.scss";
import { Collapse } from "react-bootstrap";
import { DescribedButton } from "react-bootstrap-tools";
import { translate } from "react-i18next";
import { transformString } from "./functions";

export class SearchResultsComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.t = props.t;
  }

  render() {
    const queryArray = this.props.query.toLowerCase().split(" ");
    const units = this.props.results
      .filter(result => result.type === "unit")
      .sort((a, b) => (a.name > b.name ? 1 : -1));
    const users = this.props.results
      .filter(result => result.type === "user")
      .sort((a, b) => (a.surname > b.surname ? 1 : -1));

    return (
      <Collapse in={this.props.isResultWindowOpen}>
        <section
          className="SearchResults__collapse-container"
          // for tracking purposes
          id="SearchResults"
          trackinglabel={this.props.query}
        >
          <section className="SearchResults__wrapper">
            {units.length > 0 && (
              <div className="SearchResults__header">
                {this.t("SearchResults.headerUnits")}
              </div>
            )}
            {units.map(result => {
              return (
                <DescribedButton
                  key={result.id}
                  text={transformString(result.result, queryArray)}
                  buttonClassName="SearchResults__button"
                  onClickArg={{
                    id: result.id,
                    lat: result.lat / 1000000,
                    lon: result.lon / 1000000,
                    name: result.name,
                    icon: result.icon
                  }}
                  onClick={this.props.handleOpenInfoSection}
                />
              );
            })}
            {users.length > 0 && (
              <div className="SearchResults__header">
                {this.t("SearchResults.headerUsers")}
              </div>
            )}
            {users.map(result => {
              return (
                <DescribedButton
                  key={result.uuid}
                  text={transformString(result.result, queryArray)}
                  buttonClassName="SearchResults__button"
                  onClickArg={result.uuid}
                  onClick={this.props.handleOpenProfile}
                />
              );
            })}
          </section>
        </section>
      </Collapse>
    );
  }
}

export default translate("frontend")(SearchResultsComponent);
