/*
 * Action types
 */
export const SHOW_MODAL_CONTENT = "SHOW_MODAL_CONTENT";
export const SHOW_MODAL_PREV_CONTENT = "SHOW_MODAL_PREV_CONTENT";
export const SHOW_MODAL_NEXT_CONTENT = "SHOW_MODAL_NEXT_CONTENT";
export const CLOSE_MODAL_SECTION = "CLOSE_MODAL_SECTION";
export const TOGGLE_RELOAD_MODAL_CONTENT = "TOGGLE_RELOAD_MODAL_CONTENT";
export const SET_MODAL_CONF = "SET_MODAL_CONF";
export const SET_MODAL_TYPE = "SET_MODAL_TYPE";

/*
 * Action creators
 */

export function showModalContent(conf) {
  return {
    type: SHOW_MODAL_CONTENT,
    conf
  };
}

export function showModalPreviousContent(id) {
  return {
    type: SHOW_MODAL_PREV_CONTENT,
    id
  };
}

export function showModalNextContent(id) {
  return {
    type: SHOW_MODAL_NEXT_CONTENT,
    id
  };
}

export function closeModalSection(id) {
  return {
    type: CLOSE_MODAL_SECTION,
    id
  };
}

export function toggleReloadModalPosition(id) {
  return {
    type: TOGGLE_RELOAD_MODAL_CONTENT,
    id
  };
}

export function setModalConf(id, conf) {
  return {
    type: SET_MODAL_CONF,
    id,
    conf
  };
}

export function setModalType(id, modalType) {
  return {
    type: SET_MODAL_TYPE,
    id,
    modalType
  };
}
