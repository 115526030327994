import { connect } from "react-redux";

import FooterMenu from "../../components/FooterMenu";
import { showModalContent } from "../ModalGroupWrapper/actions";

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = {
  showModalContent
};

const FooterMenuWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(FooterMenu);

export default FooterMenuWrapper;
