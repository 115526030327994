import { connect } from "react-redux";

import SearchIcon from "../../components/SearchIcon";

const mapStateToProps = state => ({
  theme: state.theme
});

const mapDispatchToProps = {};

const SearchIconWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(SearchIcon);

export default SearchIconWrapper;
