export const Config = {
  defaultMenu: null,
  defaultContent: null,
  defaultSizes: {
    pointMargin: 10,
    otherSideMargin: 30,
    topPosition: 90
  },
  excludingModalInstances: {
    /*
    Those two below is an example for the future. Now it's defunct
    mapMenu: ["unitInfo"],
    unitInfo: ["mapMenu"]
    */
  },
  // @ToDo: freeChanges should be 5 at release
  captcha: {
    freeChanges: 50,
    timeBuffer: 12
  }
};
