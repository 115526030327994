import React from "react";
import { translate } from "react-i18next";
import "./style.scss";
import { AlertBoxGroup } from "react-bootstrap-tools";
import { getClassName } from "./functions";

export const AlertBoxComponent = props => {
  const dismissInfo = id => {
    props.dismissInfo(id);
  };

  if (props.alertBox.length === 0) return null;

  const { t } = props;

  const alerts = props.alertBox.map(alert => {
    return { ...alert, message: t(alert.message), onExited: dismissInfo };
  });

  return (
    <section className={getClassName(props.isLeftSectionCollapsed)}>
      <AlertBoxGroup alerts={alerts} />
    </section>
  );
};

export default translate("frontend")(AlertBoxComponent);
