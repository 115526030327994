export const generateTriangleGlyph = isPanelCollapsed => {
  return isPanelCollapsed ? "arrow_right" : "arrow_left";
};

export const generateButtonClassName = isPanelCollapsed => {
  return "LeftSection__sectionToggler" + (isPanelCollapsed ? "--hidden" : "");
};

export const generateGlyphDescription = isPanelCollapsed => {
  return isPanelCollapsed ? "LeftSection.toggleShow" : "LeftSection.toggleHide";
};
