import { connect } from "react-redux";

import Messenger from "../../components/Messenger";
import { invokeInfo } from "../AlertBoxWrapper/actions";
import { showModalContent } from "../ModalGroupWrapper/actions";
import { setMessagesAmount } from "../App/actions";

const mapStateToProps = state => ({
  user: state.user,
  token: state.initData.token
});

const mapDispatchToProps = {
  invokeInfo,
  showModalContent,
  setMessagesAmount
};

const MessengerWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(Messenger);

export default MessengerWrapper;
