import { connect } from "react-redux";

import UserProfile from "../../components/UserProfile";
import { invokeInfo } from "../AlertBoxWrapper/actions";
import { showModalContent } from "../ModalGroupWrapper/actions";

const mapStateToProps = state => ({
  token: state.initData.token,
  user: state.user,
  theme: state.theme
});

const mapDispatchToProps = {
  invokeInfo,
  showModalContent
};

const UserProfileWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfile);

export default UserProfileWrapper;
