import { connect } from "react-redux";

import HowToThumbnail from "../../components/HowToThumbnail";
import { showModalContent } from "../ModalGroupWrapper/actions";

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  showModalContent
};

const HowToThumbnailWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(HowToThumbnail);

export default HowToThumbnailWrapper;
