import { Config } from "../../components/ModalSection/config";

export const refreshHistory = (currentState, direction) => {
  let history = currentState.history;

  if (
    direction === "next" &&
    currentState.historyPointer + 2 === history.length
  )
    history.pop();

  if (direction === "prev" && currentState.historyPointer + 1 > history.length)
    history.push({
      type: currentState.type,
      conf: currentState.conf
    });

  if (!direction && currentState.shown) {
    while (currentState.historyPointer < history.length) {
      history.pop();
    }

    history.push({
      type: currentState.type,
      conf: currentState.conf
    });
  }

  return history;
};

export const getModalId = (modalType, modalGroup) => {
  let modalId = -1;
  const expectedModalGroup = getModalInstance(modalType);

  modalGroup.forEach((modal, idx) => {
    if (getModalInstance(modal.type) === expectedModalGroup) {
      modalId = idx;
      return true;
    }

    return false;
  });

  return modalId;
};

export const getModalToDeleteId = (modalType, modalGroup) => {
  let modalId = -1;
  const expectedModalGroup = getModalInstance(modalType);

  if (!!Config.excludingModalInstances[expectedModalGroup]) {
    modalGroup.forEach((modal, idx) => {
      if (
        Config.excludingModalInstances[expectedModalGroup].indexOf(
          getModalInstance(modal.type)
        ) > -1
      ) {
        modalId = idx;
        return true;
      }

      return false;
    });
  }

  return modalId;
};

export const getModalInstance = modalType => {
  switch (modalType) {
    case "mySettings":
    case "myProfile":
      return "myAccount";
    case "userProfile":
      return "userProfile";
    case "about":
    case "advertising":
    case "privacyPolicy":
    case "tou":
    case "howTo":
    case "faq":
    case "contact":
      return "phyzziInfo";
    case "unitWikipedia":
    case "unitYoutube":
      return "unitInternet";
    case "login":
    case "loginError":
    case "register":
    case "registrationSuccess":
    case "registrationError":
    case "registrationVerificationSuccess":
    case "resetPasswordRequest":
    case "resetPasswordRequestSuccess":
    case "resetPassword":
    case "resetPasswordSuccess":
    case "resetPasswordError":
    case "resetPasswordFormError":
      return "loginRegister";
    case "messenger":
      return "messenger";
    case "externalContent":
      return "externalContent";
    case "unitTypes":
    case "unitInfo":
    case "unitUsers":
    case "unitBoard":
      return "unitInfo";
    default:
      return "";
  }
};

export const getPosition = modalType => {
  const invokeTime = new Date();

  // Unit info, Unit users, Unit board and Unit types (the instance) should always be below other modals
  return getModalInstance(modalType) !== "unitInfo" ? invokeTime.getTime() : 0;
};

/**
 * Returns if given modal type is allowed to show when user is logged in. Helpful with modals such login/register.
 * @param string modalType
 * @returns boolean
 */
export const isModalTypeAllowedWithLoggedUser = modalType => {
  return ["login", "register", "resetPasswordRequest", "resetPassword"].every(
    forbiddenType => modalType !== forbiddenType
  );
};
