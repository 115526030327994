export const getGlyphProps = props => {
  let glyphProps = {
    glyph: props.glyph
  };

  if (!!props.glyphClassName) glyphProps.className = props.glyphClassName;

  return glyphProps;
};

export const getButtonProps = (props, onClickHandler) => {
  let buttonProps = {};

  if (!!props.buttonProps) buttonProps = props.buttonProps;

  if (!!props.buttonClassName) buttonProps.className = props.buttonClassName;

  if (!!props.buttonType) buttonProps.type = props.buttonType;

  if (!!props.buttonName) buttonProps.name = props.buttonName;

  if (!!props.buttonValue) buttonProps.value = props.buttonValue;

  if (!!props.size) buttonProps.size = props.size;

  if (props.disabled === true) buttonProps.disabled = props.disabled;

  if (props.active === false || props.active === true)
    buttonProps.active = props.active;

  if (!!props.onClick) buttonProps.onClick = onClickHandler;

  if (!!props.id) buttonProps.id = props.id;

  return buttonProps;
};

export const getComponentProps = props => {
  let componentProps = {};

  if (!!props.description) {
    componentProps.description = props.description;

    if (!!props.descriptionTheme)
      componentProps.descriptionTheme = props.descriptionTheme;

    if (!!props.placement) componentProps.placement = props.placement;

    if (!!props.delay) componentProps.delay = props.delay;

    if (!!props.useTranslation || props.useTranslation === false)
      componentProps.useTranslation = props.useTranslation;
  }

  return componentProps;
};
