import React from "react";

import MapIcon from "../MapIcon/index";
import L from "leaflet";
import config from "../../containers/App/config";
import { getClassName } from "./functions";
import "./style.scss";
import { getMapIconProps } from "../../utils/helpers";

export const SelectedPoint = props => {
  const handlePointClick = () => {};

  if (!!props.selectedUnit)
    return (
      <MapIcon
        handlePointClick={handlePointClick}
        icon={L.icon(
          getMapIconProps(
            props.selectedUnit.icon,
            {
              ...config.icons,
              className: getClassName(props.selectedUnit.icon, props.mapType)
            },
            props.mapType
          )
        )}
        lat={props.selectedUnit.lat}
        lon={props.selectedUnit.lon}
        id={props.selectedUnit.id}
        name={props.selectedUnit.name}
        pointIcon={props.selectedUnit.icon}
      />
    );

  return null;
};

export default SelectedPoint;
