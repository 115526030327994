import { connect } from "react-redux";

import InfoSectionInfoInternet from "../../components/InfoSectionInfoInternet";
import { showModalContent } from "../ModalGroupWrapper/actions";
import { activateCaptcha } from "../CaptchaWrapper/actions";

const mapStateToProps = (state, props) => ({
  isAddition: state.modalSection[props.modalId].conf.isAddition,
  userUuid: state.user.uuid,
  user: state.user,
  theme: state.theme
});

const mapDispatchToProps = {
  showModalContent,
  activateCaptcha
};

const InfoSectionInfoInternetWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoSectionInfoInternet);

export default InfoSectionInfoInternetWrapper;
