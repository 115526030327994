import React, { Component } from "react";

import "./style.scss";
import UnitTypeTreeWrapper from "../../containers/UnitTypeTreeWrapper/index";
import UnitTypeTreeButtonsWrapper from "../../containers/UnitTypeTreeButtonsWrapper/index";

class UnitTypeTreeContainer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showClearAll: false
    };
  }

  componentDidMount() {
    // This is hack for Edge. It seems, that Clear all filters button appears somehow in the middle of LeftSection
    // even if tree is fully presented. Ater simple mouse press it moves to the bottom. Couldn't find any other solution.
    setTimeout(this.toggleShowClearAll, 1000);
  }

  toggleShowClearAll = () => {
    this.setState({
      showClearAll: !this.state.showClearAll
    });
  };

  render() {
    return (
      <div className="UnitTypeTreeContainer">
        <UnitTypeTreeWrapper parentId={null} level={0} />
        {this.state.showClearAll && <UnitTypeTreeButtonsWrapper />}
      </div>
    );
  }
}

export default UnitTypeTreeContainer;
