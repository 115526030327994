import { START_EMITTING_CALLBACK, STOP_EMITTING_CALLBACK } from "./actions";

const initialState = {
  emitCallback: false,
  callback: null,
  screenX: 0,
  screenY: 0
};

function movableReducers(state = initialState, action) {
  switch (action.type) {
    case START_EMITTING_CALLBACK:
      return Object.assign({}, state, {
        emitCallback: true,
        callback: action.callback,
        screenX: action.screenX,
        screenY: action.screenY
      });
    case STOP_EMITTING_CALLBACK:
      return Object.assign({}, state, initialState);
    default:
      return state;
  }
}

export default movableReducers;
