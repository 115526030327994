export const isAddButtonDisabled = (userUuid, canUserAddMessage) => {
  return !userUuid || !canUserAddMessage;
};

export const getAddButtonDescription = (userUuid, canUserAddMessage) => {
  if (!userUuid) {
    return "InfoSectionBoardList.buttonAddDescriptionUnsigned";
  } else if (!canUserAddMessage) {
    return "InfoSectionBoardList.buttonAddDescriptionLowAccountType";
  } else {
    return "InfoSectionBoardList.buttonAddDescription";
  }
};
