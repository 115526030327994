import { depracated_handleFetch } from "phyzzi.com-frontend-utils";
import Config from "../../containers/App/config";

export const handleFetchContent = conf => {
  depracated_handleFetch(
    {
      url:
        Config.url.api.staticContentResource +
        "/" +
        conf.contentId +
        "/" +
        conf.languageId,
      token: conf.token
    },
    {
      success: json => conf.injectContent(json),
      error: () => handleFetchContentFail(conf.invokeInfo)
    }
  );
};

const handleFetchContentFail = invokeInfo => {
  invokeInfo({
    variant: "danger",
    message: "TermsOfUse.fetchContentFailAlert"
  });
};
