import React  from "react";
import "./style.scss";
import { translate } from "react-i18next";
import { getClassName } from "../AgentButton/functions";

export const AgentButtonComponent = ({isLeftSectionCollapsed, bottomSectionSizeMode, showModalContent, t}) => {

  const handleClick = () => {
    showModalContent({type: 'contact'})
  }

    return (
      <section className={getClassName(
        isLeftSectionCollapsed,
        bottomSectionSizeMode
      )}>
        <button className={'AgentButton__button'} onClick={handleClick}>{t("AgentButton.text")}</button>
      </section>
    );
}

export default translate("frontend")(AgentButtonComponent);
