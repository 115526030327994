import React from "react";

import "./style.scss";
import MessengerInterlocutor from "../MessengerInterlocutor/index";
import MessengerMessages from "../MessengerMessages/index";
import { getInterlocutor } from "../Messenger/functions";

export default function MessengerTopic(props) {
  if (!!props.topic)
    return (
      <div className="MessengerTopic">
        <MessengerInterlocutor
          interlocutor={getInterlocutor(props.topic, props.author.uuid)}
          handleInterlocutorClick={props.handleInterlocutorClick}
        />
        <MessengerMessages
          key={props.topic.id}
          topic={props.topic}
          author={props.author}
          handleLoadMessages={props.handleLoadMessages}
          oldestMessageId={props.oldestMessageId}
          scrollToBottom={props.scrollToBottom}
          switchOnScrollToBottom={props.switchOnScrollToBottom}
          switchOffScrollToBottom={props.switchOffScrollToBottom}
          fetchOlderMessagesTimestamp={props.fetchOlderMessagesTimestamp}
        />
      </div>
    );

  return null;
}
