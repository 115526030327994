import { depracated_handleFetch } from "phyzzi.com-frontend-utils";

export const handleFetchSearch = conf => {
  depracated_handleFetch(
    {
      url: conf.url,
      token: conf.token
    },
    {
      success: json => conf.injectData(json),
      error: () => conf.fetchFailCallback()
    }
  );
};
