import React from "react";

import "./style.scss";
import { DescribedButton } from "react-bootstrap-tools";
import { translate } from "react-i18next";
import { Avatar } from "react-bootstrap-tools";
import InfoSectionUsersAddSection from "../InfoSectionUsersAddSection/index";
import { getButtonDescription, isButtonDisabled } from "./functions";
import { transformDate } from "../../utils/helpers";

export const InfoSectionUsersListComponent = props => {
  const { t } = props;

  const deleteButtonDescription = t(
    "InfoSectionUsersList.removeButtonDescription"
  );

  const handleUserNameClick = uuid => {
    props.showModalContent({
      type: "userProfile",
      conf: {
        userId: uuid
      }
    });
  };

  const handleMessageClick = uuid => {
    props.showModalContent({
      type: "messenger",
      conf: {
        userUuid: uuid
      }
    });
  };

  return (
    <tbody className="InfoSectionUsersList">
      {!props.isUserSubscribed && (
        <InfoSectionUsersAddSection
          theme={props.theme}
          handleAddClick={props.handleAddClick}
          isButtonDisabled={isButtonDisabled(
            props.userUuid,
            props.canUserSubscribe
          )}
          description={t(
            getButtonDescription(props.userUuid, props.canUserSubscribe)
          )}
        />
      )}
      {props.content.length === 0 && (
        <tr>
          <td colSpan={4}>
            <div className="InfoSectionUsersList__no-users">
              {t("InfoSectionUsersList.empty")}
            </div>
          </td>
        </tr>
      )}
      {props.content.map(user => {
        const positionLabel = user.company ? t('InfoSectionUsersList.userPositionWithCompany', {position: user.position, company: user.company}) : user.position;

        return (
          <tr
            className={
              "InfoSectionUsersList__user" +
              (user.uuid === props.userUuid ? "--highlighted" : "")
            }
            key={user.uuid}
          >
            <td className="InfoSectionUsersList__cell--thumbnail">
              <Avatar
                src={user.picture}
                className="InfoSectionUsersList__image-wrapper"
                iconClassName="InfoSectionUsersList__icon"
              />
            </td>
            <td className="InfoSectionUsersList__cell--info">
              <div>
                <DescribedButton
                  buttonClassName="InfoSectionUsersList__name"
                  onClick={handleUserNameClick}
                  onClickArg={user.uuid}
                  text={user.name + " " + user.surname}
                />
              </div>
              <div className="InfoSectionUsersList__position">
                {positionLabel}
              </div>
              <div className="InfoSectionUsersList__location">
                {user.location}
              </div>
            </td>
            <td className="InfoSectionUsersList__cell--date">
              {transformDate(user.created_at)}
            </td>
            <td className="InfoSectionUsersList__cell">
              <div>
                {user.uuid !== props.userUuid ? (
                  <DescribedButton
                    onClick={handleMessageClick}
                    onClickArg={user.uuid}
                    buttonClassName="InfoSectionUsersList__message-button"
                    icon="email"
                    disabled={!props.userUuid}
                  />
                ) : (
                  <DescribedButton
                    buttonClassName="InfoSectionUsersList__remove-button"
                    icon="delete"
                    placement="left"
                    description={deleteButtonDescription}
                    onClick={props.handleDeleteClick}
                  />
                )}
              </div>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};

export default translate("frontend")(InfoSectionUsersListComponent);
