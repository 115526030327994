import { connect } from "react-redux";

import MapContainer from "../../components/MapContainer";
import { changeViewport } from "./actions";

const mapStateToProps = state => ({
  isEditPositionMarkerShown: state.map.editPositionMarker.shown,
  mapType: state.map.background,
  modalSection: state.modalSection,
  viewport: state.map.viewport,
  bottomSectionSizeMode: state.bottomSection.sizeMode
});

const mapDispatchToProps = {
  changeViewport
};

const MapContainerWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(MapContainer);

export default MapContainerWrapper;
