import React from "react";

import "./style.scss";
import { getLogoClassName, getLogoUrl } from "./functions";
import config from "./config";

export const PhyzziBrandLogo = props => {
  return (
    <section className="PhyzziBrandLogo">
      <img
        src={getLogoUrl(props.theme, config.url)}
        alt="Logo of Phyzzi.com - easy physical commodities"
        className={getLogoClassName(props.isLeftSectionShown)}
      />
    </section>
  );
};

export default PhyzziBrandLogo;
