import React from "react";
import { getClassName } from "./functions";

export const GlyphiconComponent = props => {
  const { glyph, className, ...others } = props;
  const componentClassName = getClassName(className);

  return (
    <i className={componentClassName} {...others}>
      {glyph}
    </i>
  );
};

export default GlyphiconComponent;
