import React, { Component } from "react";

import "./style.scss";
import FormData from "../FormData/index";
import {
  calculateOwnershipFormValidation,
  calculateValidationState,
  sortOwnerships
} from "./functions";
import { shouldCaptchaAppear } from "../InfoSectionContent/functions";
import { convertTimestampToDateTime } from "../PhyzziFrontend/functions";
import { DescribedButton, DescribedComponent } from "react-bootstrap-tools";
import { Config } from "../ModalSection/config";
import { translate } from "react-i18next";
import { ButtonGroup } from "react-bootstrap";

class InfoSectionInfoOwnership extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validations: {},
      canAdd: false,
      canSave: false,
      isEdit: props.isAddition,
      ownerships: [
        ...this.props.ownerships,
        {
          id: "",
          name: "",
          ownership: "",
          timestamp: convertTimestampToDateTime(new Date())
        }
      ]
    };

    this.t = props.t;
    this.addButtonDescription = this.t(
      "InfoSectionInfoMaintenance.buttonAddDescription"
    );
    this.deleteButtonDescription = this.t(
      "InfoSectionInfoMaintenance.buttonDeleteDescription"
    );
    this.editButtonDescription = this.t(
      "InfoSectionInfoMaintenance.buttonEditDescription"
    );
    this.saveButtonDescription = this.t(
      "InfoSectionInfoMaintenance.buttonSaveDescription"
    );
    this.closeButtonDescription = this.t(
      "InfoSectionInfoMaintenance.buttonCancelDescription"
    );
  }

  handleValidation = (ownershipIdx, fieldId, content) => {
    let canSave = true,
      canAdd = true;

    let validationState = null;
    if (ownershipIdx !== this.state.ownerships.length - 1 || content !== "")
      validationState = calculateValidationState(fieldId, content);

    let ownershipSum = 0;

    this.state.ownerships.forEach((ownership, idx) => {
      ownershipSum +=
        idx === ownershipIdx && fieldId === "ownership"
          ? parseFloat(content, 10) || 0
          : parseFloat(ownership.ownership, 10) || 0;
    });

    let validations = this.state.validations;

    // Putting validation status to given ownership
    let validation = validations["_" + ownershipIdx] || {};
    validation.ownershipExceeded = ownershipSum > 100 ? false : true;
    validation[fieldId] = validationState;
    validations["_" + ownershipIdx] = validation;

    // Changing validation status for all ownerships (in case of Exceeded changed)
    const validationKeys = Object.keys(validations);
    validationKeys.forEach((validationKey, idx) => {
      let validation = validations[validationKey];
      validation.ownershipExceeded = ownershipSum > 100 ? false : true;

      Object.keys(validation).forEach(validationKey => {
        if (validation[validationKey] === false) {
          canSave = false;

          if (idx + 1 === validationKeys.length) canAdd = false;
        }
      });

      validations[validationKey] = validation;
    });

    this.setState({
      validations: validations,
      canSave: canSave,
      canAdd: canAdd
    });

    return validationState;
  };

  handleChange = (ownershipIdx, fieldId, content) => {
    const ownerships = this.state.ownerships.map((ownership, idx) => {
      let newOwnership = {
        id: ownership.id,
        name: ownership.name,
        ownership: ownership.ownership,
        timestamp: ownership.timestamp
      };

      if (ownershipIdx === idx) {
        if (fieldId === "ownership") {
          newOwnership.ownership = parseFloat(content, 10);
        } else {
          newOwnership[fieldId] = content;
        }
      }

      return newOwnership;
    });

    this.setState({
      ownerships: ownerships
    });
  };

  handleAdd = () => {
    let ownerships = this.state.ownerships.map(ownership => {
      if (ownership.id === "") {
        return {
          id: 0,
          name: ownership.name,
          ownership: ownership.ownership,
          timestamp: ownership.timestamp
        };
      } else {
        return ownership;
      }
    });

    ownerships.push({
      id: "",
      name: "",
      ownership: "",
      timestamp: convertTimestampToDateTime(new Date())
    });

    this.setState({
      ownerships: ownerships
    });
  };

  handleDelete = id => {
    let ownerships = this.state.ownerships.filter((ownership, idx) => {
      return id !== idx;
    });

    this.setState({
      ownerships: ownerships
    });

    ownerships.forEach((ownership, idx) => {
      this.handleValidation(idx, "name", ownership.name);
      this.handleValidation(idx, "ownership", ownership.ownership);
    });
  };

  handleSaveClick = () => {
    this.props.isAddition
      ? this.handleReload()
      : shouldCaptchaAppear(
          "ownerships",
          this.props.user.lastChanges,
          this.props.user.isAdmin,
          Config.captcha
        )
        ? this.handleShowCaptcha(this.handleSave)
        : this.handleSave();

    this.toggleEdit();
  };

  handleReload = () => {
    let ownerships = [];

    this.state.ownerships.forEach(ownership => {
      if (ownership.id !== "") {
        const { timestamp, ...rest } = ownership;
        ownerships.push(rest);
      }
    });

    this.props.handleReloadData({ ownerships });
  };

  handleSave = () => {
    let ownerships = [];

    this.state.ownerships.forEach(ownership => {
      if (ownership.id !== "") {
        const { timestamp, ...rest } = ownership;
        ownerships.push(rest);
      }
    });

    this.props.handleSaveData({ ownerships });

    this.props.handleReloadData({ ownerships });
  };

  toggleEdit = () => {
    this.setState({
      isEdit: !this.state.isEdit
    });
  };

  handleShowCaptcha = action => {
    this.props.activateCaptcha(action, "Unit info - ownership");
  };

  render() {
    return (
      <tr className={!!this.props.rowClassName ? this.props.rowClassName : ""}>
        <td
          className={
            !!this.props.leftColumnClassName
              ? this.props.leftColumnClassName
              : ""
          }
        >
          {this.t("InfoSectionInfoOwnership.ownership")}:
        </td>
        <td
          className={
            !!this.props.rightColumnClassName
              ? this.props.rightColumnClassName
              : ""
          }
        >
          {!this.state.isEdit && (
            <DescribedComponent
              description={
                !this.props.userUuid ? null : this.editButtonDescription
              }
            >
              <div
                className={
                  "InfoSectionInfoOwnership__edit-list" +
                  (!!this.props.userUuid ? "--active" : "")
                }
                onClick={() => {
                  return !!this.props.userUuid ? this.toggleEdit() : null;
                }}
              >
                <ul className="InfoSectionInfoOwnership__list">
                  {sortOwnerships(this.props.ownerships).map(ownership => {
                    return (
                      <li
                        key={
                          !!ownership.timestamp
                            ? ownership.timestamp
                            : ownership.id
                        }
                      >
                        {ownership.name}: {parseFloat(ownership.ownership)}%
                      </li>
                    );
                  })}
                </ul>
              </div>
            </DescribedComponent>
          )}
          {this.state.isEdit && (
            <ul className="InfoSectionInfoOwnership__list">
              {this.state.ownerships.map((ownership, idx) => {
                let addButton, deleteButton;

                if (ownership.id !== "") {
                  deleteButton = (
                    <DescribedButton
                      key={idx + "_del"}
                      description={this.deleteButtonDescription}
                      size="sm"
                      onClick={this.handleDelete}
                      onClickArg={idx}
                      buttonClassName="FormData__cancel"
                      iconClassName="FormData__cancel-icon"
                      icon="close"
                    />
                  );
                }

                if (ownership.id === "") {
                  addButton = (
                    <DescribedButton
                      key={idx + "_add"}
                      description={this.addButtonDescription}
                      size="sm"
                      onClick={this.handleAdd}
                      onClickArg={ownership.id}
                      disabled={!this.state.canAdd}
                      buttonClassName="FormData__save"
                      iconClassName="FormData__save-icon"
                      icon="add"
                    />
                  );
                }

                return (
                  <li
                    key={
                      !!ownership.timestamp ? ownership.timestamp : ownership.id
                    }
                  >
                    <div>
                      <FormData
                        key="form_name"
                        className="InfoSectionInfoOwnership__form--name"
                        inputClassName="InfoSectionInfo__input"
                        value={ownership.name}
                        nonSavable={true}
                        nonCancelable={true}
                        validation={formValue =>
                          this.handleValidation(idx, "name", formValue)
                        }
                        validationState={calculateOwnershipFormValidation(
                          idx,
                          this.state.validations,
                          "name"
                        )}
                        change={formValue =>
                          this.handleChange(idx, "name", formValue)
                        }
                        isEditing={true}
                        size="sm"
                        maxLength={255}
                      />
                      :{" "}
                      <FormData
                        key="form_ownership"
                        className="InfoSectionInfoOwnership__form--ownership"
                        inputClassName="InfoSectionInfo__input"
                        value={ownership.ownership}
                        nonSavable={true}
                        nonCancelable={true}
                        validation={formValue =>
                          this.handleValidation(idx, "ownership", formValue)
                        }
                        validationState={calculateOwnershipFormValidation(
                          idx,
                          this.state.validations,
                          "ownership"
                        )}
                        change={formValue =>
                          this.handleChange(idx, "ownership", formValue)
                        }
                        isEditing={true}
                        size="sm"
                        addonsAfter={["%"]}
                        useTranslation={false}
                        additionalButtons={[
                          ownership.id !== "" ? deleteButton : addButton
                        ]}
                        maxLength={5}
                      />
                    </div>
                  </li>
                );
              })}
            </ul>
          )}
          {!this.state.isEdit &&
            this.props.ownerships.length === 0 &&
            this.props.userUuid && (
              <DescribedComponent
                description={this.editButtonDescription}
                useTranslation={true}
              >
                <div
                  onClick={this.toggleEdit}
                  className="FormData__value--logged"
                >
                  {this.t("common:emptyInBrackets")}
                </div>
              </DescribedComponent>
            )}
          {this.state.isEdit && (
            <ButtonGroup>
              <DescribedButton
                description={this.saveButtonDescription}
                size="sm"
                onClick={this.handleSaveClick}
                disabled={!this.state.canSave}
                buttonClassName="InfoSectionInfo__button--positive"
                iconClassName="InfoSectionInfo__button-icon"
                icon="done"
              />
              <DescribedButton
                description={this.closeButtonDescription}
                size="sm"
                onClick={this.toggleEdit}
                buttonClassName="InfoSectionInfo__button--negative"
                iconClassName="InfoSectionInfo__button-icon"
                icon="close"
              />
            </ButtonGroup>
          )}
        </td>
      </tr>
    );
  }
}

export default translate("frontend")(InfoSectionInfoOwnership);
