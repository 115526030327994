import React from "react";
import FormData from "../FormData/index";
import { validateInput } from "./functions";
import Config from "../../containers/App/config";

export const UserProfileDataComponent = props => {
  const handleValidation = formValue => {
    return validateInput(props.fieldId, formValue, Config.regex);
  };

  const handleSave = formValue => props.save(formValue);

  if (props.nonEditable) return props.content;

  return (
    <FormData
      value={props.content}
      validation={handleValidation}
      save={handleSave}
      as={props.as}
      maxLength={!!props.maxLength ? props.maxLength : "255"}
      tooltipClassName={props.theme}
      placeholder={!!props.placeholder ? props.placeholder : ""}
    />
  );
};

export default UserProfileDataComponent;
