import React from "react";

import "./style.scss";
import FilterSorter from "phyzzi.com-frontend-filter-sorter";
import MessengerListSorter from "../MessengerListSorter/index";
import MessengerListTopics from "../MessengerListTopics/index";
import { translate } from "react-i18next";
import { PhyzziBrandLoader } from "phyzzi.com-frontend-utils";
import "phyzzi.com-frontend-utils/build/static/css/index.css";

const MessengerList = props => {
  const { t } = props;

  return (
    <div className="MessengerList">
      {props.isFetchingTopics ? (
        <PhyzziBrandLoader />
      ) : props.topics.length === 0 ? (
        <span>{t("MessengerList.empty")}</span>
      ) : (
        <FilterSorter
          content={props.topics}
          filterValue=""
          sorters={[
            {
              field: "updated_at",
              label: t("MessengerList.columnDate"),
              selected: "desc"
            }
          ]}
          filters={["interlocutor_name", "interlocutor_surname"]}
        >
          <MessengerListSorter componentRole="filterSorter" />
          <MessengerListTopics
            componentRole="content"
            onTopicListClick={props.onTopicListClick}
            selectedTopicId={props.selectedTopicId}
            author={props.author}
          />
        </FilterSorter>
      )}
    </div>
  );
};

export default translate("frontend")(MessengerList);
