import { toUserTimezone } from "../../utils/helpers";
import { unifyDate } from "phyzzi.com-frontend-utils";
import moment from "moment-timezone";

export const getInterlocutor = (topic, userUuid) => {
  return topic.messengerParticipants.find(participant => {
    return !participant.user || participant.user.uuid !== userUuid;
  });
};

/**
 * If given date is on the same day (of user's timezone) it returns hours:minutes, otherwise year-months-days
 * ToDo: this function is identical to utils/helpers.js one.
 * Nevertheless messenger will be moved to separate project so it's acceptable
 *
 * @param dateString
 */
export const transformDate = dateString => {
  const topicDate = toUserTimezone(unifyDate(dateString));
  const topicDateString = topicDate.format("YYYY-MM-DD");
  const actualDate = moment(new Date());

  if (topicDateString === actualDate.format("YYYY-MM-DD"))
    return topicDate.format("HH:mm");

  return topicDateString;
};

export const getEmptyTopicStructure = (
  user,
  interlocutorUuid,
  interlocutorJson
) => {
  return {
    id: null,
    messengerParticipants: [
      {
        id: null,
        user: {
          uuid: user.uuid,
          name: user.name,
          surname: user.surname,
          picture: user.picture
        }
      },
      {
        id: null,
        user: {
          uuid: interlocutorUuid,
          name: interlocutorJson.name,
          surname: interlocutorJson.surname,
          picture: interlocutorJson.picture
        }
      }
    ],
    messengerMessages: {
      data: [],
      more_messages: false
    },
    interlocutor_uuid: interlocutorUuid,
    interlocutor_name: interlocutorJson.name,
    interlocutor_surname: interlocutorJson.surname
  };
};

/**
 * Returns value for trackinglabel attribute. Generated for tracking purposes
 *
 * @param uuid
 * @param topicId
 * @returns {string}
 */
export const getTrackingLabel = (uuid, topicId) => {
  if (!!uuid) return "uuid";

  if (!!topicId) return "topic";

  return "";
};

export const calculateShouldCallUnread = (topic, uuid) => {
  const myParticipant = topic.messengerParticipants.filter(
    participant => participant.is_unread && !!participant.user && participant.user.uuid === uuid
  )[0];
  if (!!myParticipant) {
    return { shouldCallUnread: true, participantId: myParticipant.id };
  }
  return { shouldCallUnread: false };
};
