import { connect } from "react-redux";

import PhyzziFrontend from "../../components/PhyzziFrontend";
import { invokeInfo } from "../AlertBoxWrapper/actions";
import { receivePublicGroupData } from "../App/actions";
import { toggleUnitTypeSelection } from "../UnitTypeTreeWrapper/actions";
import { receivePoints } from "../MapContainerWrapper/actions";
import { stopEmittingCallback } from "../MovableWrapper/actions";

const mapStateToProps = state => ({
  theme: state.theme,
  token: state.initData.token,
  accessesLastUpdate: state.accesses.lastUpdate,
  productsLastUpdate: state.products.lastUpdate,
  unitTypesLastUpdate: state.unitTypes.lastUpdate,
  unitTypeProductsLastUpdate: state.unitTypeProducts.lastUpdate,
  boardGroupsLastUpdate: state.boardGroups.lastUpdate,
  unitTypes: state.unitTypes,
  mapPoints: state.map.points,
  unitTypeTree: state.unitTypeTree,
  movable: state.movable
});

const mapDispatchToProps = {
  invokeInfo,
  receivePublicGroupData,
  toggleUnitTypeSelection,
  receivePoints,
  stopEmittingCallback
};

const PhyzziFrontendWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhyzziFrontend);

export default PhyzziFrontendWrapper;
