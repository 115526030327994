/*
 * Action types
 */
export const CHANGE_MAP_BACKGROUND = "CHANGE_MAP_BACKGROUND";
export const RECEIVE_POINTS = "RECEIVE_POINTS";
export const OPEN_POSITION_MARKER = "OPEN_POSITION_MARKER";
export const CLOSE_POSITION_MARKER = "CLOSE_POSITION_MARKER";
export const CHANGE_VIEWPORT = "CHANGE_VIEWPORT";
export const SELECT_UNIT = "SELECT_UNIT";
export const UNSELECT_UNIT = "UNSELECT_UNIT";

/*
 * Action creators
 */
export function changeBackground(background) {
  return {
    type: CHANGE_MAP_BACKGROUND,
    background
  };
}

export function receivePoints(json, stringId) {
  return {
    type: RECEIVE_POINTS,
    json,
    stringId
  };
}

export function openPositionMarker(point) {
  return {
    type: OPEN_POSITION_MARKER,
    ...point
  };
}

export function closePositionMarker() {
  return {
    type: CLOSE_POSITION_MARKER
  };
}

export function changeViewport(viewport) {
  return {
    type: CHANGE_VIEWPORT,
    ...viewport
  };
}

export function selectUnit(unit) {
  return {
    type: SELECT_UNIT,
    unit
  };
}

export function unselectUnit() {
  return {
    type: UNSELECT_UNIT
  };
}
