export const getUnitTypeParent = (unitTypes, id) => {
  const unitTypeArray = unitTypes.filter(unitType => unitType.id === id);

  if (unitTypeArray.length > 0) {
    const parentObjects = unitTypes.filter(
      unitType => unitType.id === unitTypeArray[0].parentId
    );
    if (parentObjects.length > 0) return parentObjects[0];
    return null;
  }

  return null;
};

export const getUnitTypeStringId = (unitTypes, id) => {
  const unitType = unitTypes.filter(unitType => unitType.id === id);

  return unitType.length > 0 ? unitType[0].stringId : "";
};

export const getUnitTypeIcon = (unitTypes, id) => {
  const filteredUnitTypes = unitTypes.filter(unitType => unitType.id === id);

  return filteredUnitTypes.length > 0 ? filteredUnitTypes[0].icon : "";
};

export const getProductNameLabel = (products, id) => {
  return "common:productName." + getGroupDataStringId(products, id);
};

export const getAccessNameLabel = (accesses, id) => {
  return "common:accessName." + getGroupDataStringId(accesses, id);
};

export const getUnitTypeNameLabel = (unitTypes, id) => {
  return "common:unitTypeName." + getUnitTypeStringId(unitTypes, id);
};

export const getGroupDataStringId = (groupData, id) => {
  const element = groupData.filter(item => item.id === id);

  return element.length > 0 ? element[0].string_id : "";
};

export const getGroupDataExclude = (elementId, groupData) => {
  return groupData.filter((element, idx) => {
    return idx !== elementId;
  });
};

export const filterSuggestions = (inputValue, suggestions) => {
  const lcValue = inputValue.toLowerCase();

  return suggestions.filter(suggestion =>
    suggestion.text.toLowerCase().includes(lcValue)
  );
};
