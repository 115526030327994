/*
 * Action types
 */
export const RECEIVE_PUBLIC_DATA = "RECEIVE_PUBLIC_DATA";
export const RECEIVE_PUBLIC_ACCESSES_DATA = "RECEIVE_PUBLIC_ACCESSES_DATA";
export const RECEIVE_PUBLIC_PRODUCTS_DATA = "RECEIVE_PUBLIC_PRODUCTS_DATA";
export const RECEIVE_PUBLIC_UNIT_TYPES_DATA = "RECEIVE_PUBLIC_UNIT_TYPES_DATA";
export const RECEIVE_PUBLIC_UNIT_TYPE_PRODUCTS_DATA =
  "RECEIVE_PUBLIC_UNIT_TYPE_PRODUCTS_DATA";
export const RECEIVE_PUBLIC_BOARD_GROUPS_DATA =
  "RECEIVE_PUBLIC_BOARD_GROUPS_DATA";
export const RECEIVE_PRIVATE_DATA = "RECEIVE_PRIVATE_DATA";
export const INJECT_DATA = "INJECT_DATA";
export const STACK_USER_LAST_CHANGE = "STACK_USER_LAST_CHANGE";
export const SET_MESSAGES_AMOUNT = "SET_MESSAGES_AMOUNT";

/*
 * Action creators
 */
export function receivePublicData(json) {
  return {
    type: RECEIVE_PUBLIC_DATA,
    json: json,
    receivedAt: Date.now()
  };
}

export function receivePrivateData(json) {
  return {
    type: RECEIVE_PRIVATE_DATA,
    json: json,
    receivedAt: Date.now()
  };
}

export function injectData(dataset) {
  return {
    type: INJECT_DATA,
    injectedData: dataset
  };
}

export function receivePublicGroupData(groupId, json) {
  let type;

  switch (groupId) {
    case "accesses":
      type = RECEIVE_PUBLIC_ACCESSES_DATA;
      break;
    case "products":
      type = RECEIVE_PUBLIC_PRODUCTS_DATA;
      break;
    case "unitTypes":
      type = RECEIVE_PUBLIC_UNIT_TYPES_DATA;
      break;
    case "unitTypeProducts":
      type = RECEIVE_PUBLIC_UNIT_TYPE_PRODUCTS_DATA;
      break;
    case "boardGroups":
      type = RECEIVE_PUBLIC_BOARD_GROUPS_DATA;
      break;
    default:
      break;
  }

  return {
    type: type,
    json: json,
    receivedAt: Date.now()
  };
}

export function stackUserLastChange() {
  return {
    type: STACK_USER_LAST_CHANGE
  };
}

export function setMessagesAmount(amount) {
  return {
    type: SET_MESSAGES_AMOUNT,
    amount
  };
}
