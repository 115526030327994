import { connect } from "react-redux";

import About from "../../components/About";
import { invokeInfo } from "../AlertBoxWrapper/actions";

const mapStateToProps = state => ({
  token: state.initData.token
});

const mapDispatchToProps = {
  invokeInfo
};

const AboutWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(About);

export default AboutWrapper;
