import React, { Component } from "react";

import "./style.scss";
import FilterSorter from "phyzzi.com-frontend-filter-sorter";
import FaqFilter from "../FaqFilter/index";
import FaqContent from "../FaqContent/index";
import { handleFetchContent } from "./fetchUtils";
import { translate } from "react-i18next";
import { getLanguageId } from "../../utils/helpers";
import { PhyzziBrandLoader } from "phyzzi.com-frontend-utils";
import "phyzzi.com-frontend-utils/build/static/css/index.css";

export class FaqComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoadingIcon: true,
      content: []
    };
  }

  componentDidMount() {
    this.fetchContent();
  }

  fetchContent = () => {
    const { i18n } = this.props;

    handleFetchContent({
      contentId: "faq",
      languageId: getLanguageId(i18n.language),
      token: this.props.token,
      invokeInfo: this.props.invokeInfo,
      injectContent: this.injectContent
    });
  };

  injectContent = json => {
    this.setState({
      showLoadingIcon: false,
      content: json.map(block => {
        return {
          ...block,
          mergedTitle: !!block.title ? block.title.join(" ") : "",
          mergedContent: !!block.content ? block.content.join(" ") : ""
        };
      })
    });
  };

  render() {
    return (
      <section className="Faq">
        {this.state.showLoadingIcon ? (
          <PhyzziBrandLoader />
        ) : (
          <div
            className="Faq__wrapper"
            // for tracking purposes
            id="Faq"
          >
            <FilterSorter
              content={this.state.content}
              filterValue=""
              sorters={[]}
              filters={["mergedTitle", "mergedContent"]}
            >
              <FaqFilter componentRole="filterSorter" />
              <FaqContent componentRole="content" />
            </FilterSorter>
          </div>
        )}
      </section>
    );
  }
}

export default translate("frontend")(FaqComponent);
