import React, { Component } from "react";

import "./style.scss";
import { Interpolate, translate } from "react-i18next";
import { DescribedButton } from "react-bootstrap-tools";
import { config } from "./config";
import { shouldCookieInfoAppear } from "../../utils/helpers";
import { getClassName } from "./functions";

export class CookieInfoComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shouldAppear: shouldCookieInfoAppear(document.cookie, config)
    };

    this.t = props.t;
    this.dismissTextualButtonText = this.t("CookieInfo.dismissButtonText");
    this.dismissButtonText = this.t("CookieInfo.dismissButton");
    this.privacyPolicyText = this.t("CookieInfo.privacyPolicyButton");
    this.touButtonText = this.t("CookieInfo.termsOfUseButton");

    this.dismissButtonText = (
      <DescribedButton
        text={this.t("CookieInfo.dismissButtonText")}
        buttonClassName="CookieInfo__text-button"
        onClick={this.dismissInfo}
      />
    );

    this.dismissButton = (
      <div className="CookieInfo__dismiss-button-wrapper">
        <DescribedButton
          text={this.t("CookieInfo.dismissButton")}
          buttonClassName="CookieInfo__dismiss-button"
          onClick={this.dismissInfo}
        />
      </div>
    );

    this.privacyPolicyButton = (
      <DescribedButton
        text={this.t("CookieInfo.privacyPolicyButton")}
        buttonClassName="CookieInfo__text-button"
        onClick={this.openPrivacyPolicy}
      />
    );

    this.termsOfUseButton = (
      <DescribedButton
        text={this.t("CookieInfo.termsOfUseButton")}
        buttonClassName="CookieInfo__text-button"
        onClick={this.openTermsOfUse}
      />
    );
  }

  dismissInfo = () => {
    const d = new Date();
    d.setTime(d.getTime() + config.dismissalDays * 24 * 60 * 60 * 1000);
    const expires = d.toUTCString();
    document.cookie =
      config.cookieName +
      "=" +
      config.cookieDismissedValue +
      ";expires=" +
      expires +
      ";";

    this.setState({
      shouldAppear: false
    });
  };

  openPrivacyPolicy = () => {
    this.props.showModalContent({
      type: "privacyPolicy",
      conf: {}
    });
  };

  openTermsOfUse = () => {
    this.props.showModalContent({
      type: "tou",
      conf: {}
    });
  };

  render() {
    if (this.state.shouldAppear) {
      return (
        <section
          className={getClassName(
            this.props.isLeftSectionCollapsed,
            this.props.bottomSectionSizeMode
          )}
        >
          <Interpolate
            i18nKey="CookieInfo.info"
            useDangerouslySetInnerHTML
            dismissButtonText={this.dismissButtonText}
            dismissButton={this.dismissButton}
            privacyPolicyButton={this.privacyPolicyButton}
            termsOfUseButton={this.termsOfUseButton}
            className={"CookieInfo__info-wrapper"}
          />
        </section>
      );
    }

    return null;
  }
}

export default translate("frontend")(CookieInfoComponent);
