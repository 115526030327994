import { connect } from "react-redux";

import InfoSectionBoardFirstRow from "../../components/InfoSectionBoardFirstRow";

const mapStateToProps = state => ({
  user: state.user,
  theme: state.theme
});

const mapDispatchToProps = {};

const InfoSectionBoardFirstRowWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoSectionBoardFirstRow);

export default InfoSectionBoardFirstRowWrapper;
