import React, { Component } from "react";

import "./style.scss";
import { calculateHasFocus, getClassName, getFinalQuery } from "./functions";
import { translate } from "react-i18next";
import { config } from "./config";
import { handleFetchSearch } from "./fetchUtils";
import Config from "../../containers/App/config";
import SearchIconWrapper from "../../containers/SearchIconWrapper";
import SearchResults from "../SearchResults";
import { getMapRangedZoomLevel } from "../../utils/helpers";
import { Form } from "react-bootstrap";

export class SearchComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // If component has focus
      focused: false,

      // If there is mouse over a search box
      isMouseOver: false,

      // If there is a cursor inside a box
      isFocus: false,

      // Query inside search box
      query: "",

      // If triggering of new fetch should be blocked
      isSearchBlocker: false,

      // Query of last fetch call
      lastFetchQuery: "",

      // If fetch is currently undergoing
      isSending: false,

      results: [],

      // If result window should be open
      isResultWindowOpen: false
    };

    this.t = props.t;
  }

  componentDidMount() {
    if (this.props.initQuery)
      this.handleChange({ target: { value: this.props.initQuery } });
  }

  setIsMouseOver = () => {
    this.setState({
      focused: true,
      isMouseOver: true
    });
  };

  setIsMouseOut = () => {
    this.setState({
      focused: calculateHasFocus(false, this.state.query, this.state.isFocus),
      isMouseOver: false
    });
  };

  setIsFocus = () => {
    this.setState({
      focused: true,
      isFocus: true
    });
  };

  setIsBlur = () => {
    this.setState({
      focused: calculateHasFocus(
        this.state.isMouseOver,
        this.state.query,
        false
      ),
      isFocus: false
    });
  };

  toggleResultOpen = () => {
    if (this.state.results.length > 0)
      this.setState({
        isResultWindowOpen: true
      });
  };

  toggleResultClose = () => {
    this.setState({
      isResultWindowOpen: false
    });
  };

  handleChange = ev => {
    const query = ev.target.value.trim();
    let newState = {
      query: ev.target.value,
      focused: true
    };

    const finalQuery = getFinalQuery(query);

    if (finalQuery.length < config.minChar) newState.isResultWindowOpen = false;

    if (
      !this.state.isSearchBlocker &&
      finalQuery.length >= config.minChar &&
      query !== this.state.lastFetchQuery
    ) {
      newState.isSearchBlocker = true;
      newState.lastFetchQuery = query;
      this.handleFetch(query);
    }

    this.setState(newState);
  };

  handleFetch = query => {
    const finalQuery = getFinalQuery(query);

    if (finalQuery.length >= config.minChar) {
      handleFetchSearch({
        url: Config.url.api.search + "/" + finalQuery,
        token: this.props.token,
        injectData: this.injectData,
        fetchFailCallback: this.handleFetchFail
      });
      this.setState({ isSending: true });
      setTimeout(this.handleRefetch, config.fetchPeriods);
    }
  };

  handleRefetch = () => {
    const query = this.state.query.trim();
    if (this.state.lastFetchQuery !== query && query.length > config.minChar) {
      this.handleFetch(query);
      this.setState({ lastFetchQuery: query });
    } else {
      this.setState({ isSearchBlocker: false });
    }
  };

  injectData = json => {
    this.setState({
      isSending: false,
      results: json,
      isResultWindowOpen: json.length > 0
    });
  };

  handleFetchFail = () => {
    this.props.invokeInfo({
      variant: "danger",
      message: "Search.fetchFailAlert"
    });

    this.setState({
      isSending: false
    });
  };

  handleOpenProfile = uuid => {
    this.props.showModalContent({
      type: "userProfile",
      conf: {
        userId: uuid
      }
    });
    this.toggleResultClose();
  };

  handleOpenInfoSection = unit => {
    this.props.changeViewport({
      lat: unit.lat,
      lon: unit.lon,
      zoom: getMapRangedZoomLevel(config.unitZoomLevel)
    });
    this.props.selectUnit({
      id: unit.id,
      lat: unit.lat,
      lon: unit.lon,
      name: unit.name,
      icon: unit.icon
    });
    this.props.showModalContent({
      type: "unitInfo",
      conf: {
        useRelativePosition: {
          x: window.innerWidth / 2,
          y: window.innerHeight / 2
        },
        reloadContent: true,
        id: unit.id,
        isAddition: false,
        isAdditionPath: false
      }
    });
    this.toggleResultClose();
  };

  render() {
    return (
      <div
        className="Search"
        onMouseEnter={this.setIsMouseOver}
        onMouseLeave={this.setIsMouseOut}
      >
        <section className="Search__bar-container">
          <section className="Search__bar">
            <Form.Control
              type="text"
              maxLength={config.maxChar}
              className={getClassName(
                this.state.isMouseOver,
                this.state.focused
              )}
              onChange={this.handleChange}
              onBlur={this.setIsBlur}
              onFocus={this.setIsFocus}
              value={this.state.query}
              placeholder={this.t("Search.queryPlaceholder")}
            />
            <SearchIconWrapper
              isSending={this.state.isSending}
              isResultWindowOpen={this.state.isResultWindowOpen}
              resultsAmount={this.state.results.length}
              toggleResultOpen={this.toggleResultOpen}
              toggleResultClose={this.toggleResultClose}
            />
          </section>
        </section>
        <SearchResults
          isResultWindowOpen={this.state.isResultWindowOpen}
          results={this.state.results}
          query={this.state.lastFetchQuery}
          handleOpenProfile={this.handleOpenProfile}
          handleOpenInfoSection={this.handleOpenInfoSection}
        />
      </div>
    );
  }
}

export default translate("frontend")(SearchComponent);
