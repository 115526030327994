export const getPopupShiftedPosition = (position, mapReference) => {
  const markerTranslated = mapReference.leafletElement.latLngToLayerPoint(
    position
  );
  const popupPosition = {
    x: markerTranslated.x,
    y: markerTranslated.y - 40
  };

  return mapReference.leafletElement.layerPointToLatLng(popupPosition);
};

export const isDraggingOutsideBounds = (draggingPosition, maxBounds) => {
  return (
    isLatOutside(draggingPosition, maxBounds) ||
    isLngOutside(draggingPosition, maxBounds)
  );
};

const isLatOutside = (draggingPosition, maxBounds) => {
  return (
    draggingPosition.lat < maxBounds[0][0] ||
    draggingPosition.lat > maxBounds[1][0]
  );
};

const isLngOutside = (draggingPosition, maxBounds) => {
  return (
    draggingPosition.lng < maxBounds[0][1] ||
    draggingPosition.lng > maxBounds[1][1]
  );
};

export const getMarkerPositionInsideBounds = (draggingPosition, maxBounds) => {
  const lat = getLatInside(draggingPosition, maxBounds);
  const lng = getLngInside(draggingPosition, maxBounds);

  return {
    lat,
    lng
  };
};

const getLatInside = (draggingPosition, maxBounds) => {
  if (draggingPosition.lat < maxBounds[0][0]) return maxBounds[0][0];
  if (draggingPosition.lat > maxBounds[1][0]) return maxBounds[1][0];
  return draggingPosition.lat;
};

const getLngInside = (draggingPosition, maxBounds) => {
  if (draggingPosition.lng < maxBounds[0][1]) return maxBounds[0][1];
  if (draggingPosition.lng > maxBounds[1][1]) return maxBounds[1][1];
  return draggingPosition.lng;
};
