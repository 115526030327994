import config from "../../containers/App/config";

export const calculateValidationState = (fieldId, content) => {
  // Url
  if (
    ["website", "youtube"].indexOf(fieldId) > -1 &&
    content.length > 0 &&
    !config.regex.url.test(content)
  )
    return false;

  switch (fieldId) {
    case "wikipedia":
      if (content.length > 0 && content.indexOf("wikipedia.org/wiki") === -1)
        return false;
      break;
    case "youtube":
      if (content.length > 0 && content.indexOf("youtube.com") === -1)
        return false;
      if (content.length > 0 && content.indexOf("watch?v=") === -1)
        return false;
      break;
    default:
      return true;
  }

  return true;
};

export const getAddonsBefore = fieldId => {
  switch (fieldId) {
    case "wikipedia":
      return "InfoSectionInfoInternet.addonFieldWikipedia";
    case "youtube":
      return "InfoSectionInfoInternet.addonFieldYoutube";
    case "website":
      return "InfoSectionInfoInternet.addonFieldWeb";
    default:
      return "";
  }
};

export const getPlaceholder = fieldId => {
  switch (fieldId) {
    case "wikipedia":
      return "https://";
    case "youtube":
      return "https://";
    case "website":
      return "http://";
    default:
      return "";
  }
};
