import { connect } from "react-redux";

import Contact from "../../components/Contact";
import { invokeInfo } from "../AlertBoxWrapper/actions";
import { activateCaptcha } from "../CaptchaWrapper/actions";
import { showModalContent } from "../ModalGroupWrapper/actions";

const mapStateToProps = state => ({
  token: state.initData.token,
  user: state.user
});

const mapDispatchToProps = {
  invokeInfo,
  activateCaptcha,
  showModalContent
};

const ContactWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(Contact);

export default ContactWrapper;
