/**
 * Returns index of expected size mode
 * @param array sizeModes - available size modes of section
 * @param string sizeMode - actually selected (taken from store) size mode
 * @param enum expected - up|down|first|last or the one inside sizeModes (i.e. partial30)
 * @returns {number} - index number of expected size mode
 */
export const getExpectedSizeMode = (
  sizeModes = [],
  sizeMode = "",
  expected = ""
) => {
  if (expected === "first") return sizeModes[0];

  if (expected === "last") return sizeModes[sizeModes.length - 1];

  // precisely pointed sizeMode to go to
  if (sizeModes.indexOf(expected) > -1) return expected;

  // up or down option
  const actualSizeModeIndex = sizeModes.indexOf(sizeMode);

  // in case of any error
  if (actualSizeModeIndex < 0) return sizeModes[0];

  const direction = expected === "up" ? 1 : expected === "down" ? -1 : 0;
  const sizeModeIndex = actualSizeModeIndex + direction;

  // in case of 'down' the first mode in queue
  if (sizeModeIndex < 0) return sizeModes[0];

  // in case of 'up' the last mode in queue
  if (sizeModeIndex >= sizeModes.length) return sizeModes[sizeModes.length - 1];

  return sizeModes[sizeModeIndex];
};
