import React, { Component } from "react";

import "./style.scss";
import {
  getClassName,
  getIsInfoSectionShown,
  getMapInitialPosition,
  getMapInitialZoom,
  getZoomsliderAdditionalClassName,
  getZoomsliderClassName
} from "./functions";
import EditPositionMarkerWrapper from "../../containers/EditPositionMarkerWrapper/index";
import Config from "./config";
import PointClusterWrapper from "../../containers/PointClusterWrapper/index";
import { Map, TileLayer } from "react-leaflet";
import * as zoomslide from "leaflet.zoomslider";
import L from "leaflet";
import SelectedPointWrapper from "../../containers/SelectedPointWrapper";
import { translate } from "react-i18next";

export class MapContainerComponent extends Component {
  constructor(props) {
    super(props);

    const applicationDataset = !!document.getElementById("App")
      ? document.getElementById("App").dataset
      : {};

    this.state = {
      mapReference: null,
      mapPosition: getMapInitialPosition(
        applicationDataset.mapPosition,
        Config.map.initial.position
      ),
      mapZoom: getMapInitialZoom(
        applicationDataset.mapZoom,
        Config.map.initial
      ),
      mapClassName: getClassName(
        getIsInfoSectionShown(props.modalSection),
        props.isEditPositionMarkerShown
      )
    };

    this.mapToken = applicationDataset.mapToken || "";
    this.zoomslider = null;
  }

  componentDidMount() {
    this.setState({
      mapReference: this.refs.map
    });
    this.handleLeafletAddons();
  }

  componentWillReceiveProps(nextProps) {
    const newPositionState =
      !!nextProps.viewport.lat &&
      !!nextProps.viewport.lat &&
      !!nextProps.viewport.zoom
        ? {
            mapPosition: [nextProps.viewport.lat, nextProps.viewport.lon],
            mapZoom: nextProps.viewport.zoom
          }
        : {};

    this.setState({
      ...newPositionState,
      mapClassName: getClassName(
        getIsInfoSectionShown(nextProps.modalSection),
        nextProps.isEditPositionMarkerShown
      )
    });

    if (nextProps.lng !== this.props.lng)
      this.hackZoomsliderLabels(this.zoomslider);

    if (nextProps.bottomSectionSizeMode !== this.props.bottomSectionSizeMode)
      this.hackZoomsliderClassName(
        this.zoomslider,
        nextProps.bottomSectionSizeMode
      );
  }

  handleLeafletAddons = () => {
    const leafletElement = this.getLeafletElement();

    if (!!leafletElement) {
      this.addZoomslider(leafletElement);
    }
  };

  getLeafletElement = () => {
    return !!this.refs.map && !!this.refs.map.leafletElement
      ? this.refs.map.leafletElement
      : null;
  };

  addZoomslider = leafletElement => {
    var zoomsliderControl = new L.Control.Zoomslider({
      position: "bottomright"
    });
    leafletElement.addControl(zoomsliderControl);

    this.hackZoomsliderLabels(zoomsliderControl);
    this.hackZoomsliderClassName(
      zoomsliderControl,
      this.props.bottomSectionSizeMode
    );

    this.zoomslider = zoomsliderControl;
  };

  hackZoomsliderClassName = (zoomsliderControl, bottomSectionSizeMode) => {
    if (!!zoomsliderControl && !!zoomsliderControl._container) {
      const className = getZoomsliderAdditionalClassName(bottomSectionSizeMode);
      zoomsliderControl._container.className = getZoomsliderClassName(
        zoomsliderControl._container.className,
        className
      );
    }
  };

  hackZoomsliderLabels = zoomsliderControl => {
    const zoomInTitleLabel = "MapContainer.zoomInTitle";
    const zoomOutTitleLabel = "MapContainer.zoomOutTitle";

    const { t } = this.props;

    const zoomInTitle = t(zoomInTitleLabel);
    const zoomOutTitle = t(zoomOutTitleLabel);

    if (zoomInTitle !== zoomInTitleLabel)
      zoomsliderControl._ui.zoomIn.title = zoomInTitle;
    if (zoomOutTitle !== zoomOutTitleLabel)
      zoomsliderControl._ui.zoomOut.title = zoomOutTitle;
  };

  handleViewportChange = viewport => {
    this.props.changeViewport({
      lat: viewport.center[0],
      lon: viewport.center[1],
      zoom: viewport.zoom
    });
  };

  render() {
    return (
      <Map
        center={this.state.mapPosition}
        zoom={this.state.mapZoom}
        zoomControl={false}
        minZoom={Config.map.initial.minZoom}
        maxZoom={Config.map.initial.maxZoom}
        className={this.state.mapClassName}
        ref="map"
        onViewportChanged={this.handleViewportChange}
        maxBounds={Config.map.initial.maxBounds}
      >
        <TileLayer
          key={"tileLayer_" + this.props.mapType}
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution="&copy; <a href=&quot;http://www.openstreetmap.org/copyright&quot;>OpenStreetMap</a>"
        />

        {this.props.isEditPositionMarkerShown && (
          <EditPositionMarkerWrapper mapReference={this.state.mapReference} />
        )}

        <PointClusterWrapper />
        <SelectedPointWrapper />
      </Map>
    );
  }
}

export default translate("frontend")(MapContainerComponent);
