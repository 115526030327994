import { connect } from "react-redux";

import { Avatar } from "react-bootstrap-tools";

const mapStateToProps = state => ({
  src: state.user.picture || null
});

const mapDispatchToProps = {};

const MyPictureWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(Avatar);

export default MyPictureWrapper;
