import { connect } from "react-redux";

import UserBar from "../../components/UserBar";
import { invokeInfo } from "../AlertBoxWrapper/actions";
import { showModalContent } from "../ModalGroupWrapper/actions";

const mapStateToProps = state => ({
  user: state.user
});

const mapDispatchToProps = {
  invokeInfo,
  showModalContent
};

const UserBarWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserBar);

export default UserBarWrapper;
