import React, { Component } from "react";

import "./style.scss";
import config from "../../containers/App/config";
import MessengerMoreMessages from "../MessengerMoreMessages/index";
import MessengerMessage from "../MessengerMessage/index";
import MessengerListBottom from "../MessengerListBottom/index";

class MessengerMessages extends Component {
  constructor(props) {
    super(props);

    this.listWrapper = "MessengerMessages";
    this.loadingTrigger = "MessengerMessages__loading-trigger";
    this.listBottom = "MessengerMessages__list-bottom";
  }

  componentDidMount() {
    this.fetchNewMessages = setInterval(
      this.handleLoadNewMessages,
      config.messenger.fetchNewMessagesTimeout
    );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.fetchOlderMessagesTimestamp !==
      this.props.fetchOlderMessagesTimestamp
    ) {
      // Scrolls to the old top of the list
      document.querySelector(
        "." + this.listWrapper
      ).scrollTop = document.querySelector(
        "#message_" + this.props.oldestMessageId
      ).offsetTop;
    }

    if (this.props.scrollToBottom) {
      // Scrolls to the bottom of message list
      document.querySelector(
        "." + this.listWrapper
      ).scrollTop = document.querySelector("#" + this.listBottom).offsetTop;
    }
  }

  componentWillUnmount() {
    clearInterval(this.fetchNewMessages);
  }

  handleLoadMoreMessages = () => {
    this.props.handleLoadMessages(this.props.topic.id, true);
  };

  handleLoadNewMessages = () => {
    this.props.handleLoadMessages(this.props.topic.id, false);
  };

  render() {
    return (
      <div className="MessengerMessages">
        {this.props.topic.messengerMessages.more_messages && (
          <MessengerMoreMessages
            loadingTrigger={this.loadingTrigger}
            listWrapper={this.listWrapper}
            handleLoadMoreMessages={this.handleLoadMoreMessages}
            fetchOlderMessagesTimestamp={this.props.fetchOlderMessagesTimestamp}
          />
        )}

        {this.props.topic.messengerMessages.data.map(message => {
          return (
            <MessengerMessage
              key={message.id}
              message={message}
              participants={this.props.topic.messengerParticipants}
              author={this.props.author}
            />
          );
        })}
        <MessengerListBottom
          listWrapper={this.listWrapper}
          listBottom={this.listBottom}
          switchOnScrollToBottom={this.props.switchOnScrollToBottom}
          switchOffScrollToBottom={this.props.switchOffScrollToBottom}
        />
      </div>
    );
  }
}

export default MessengerMessages;
