import { connect } from "react-redux";

import InfoSectionInfoTag from "../../components/InfoSectionInfoTag";
import { activateCaptcha } from "../CaptchaWrapper/actions";
import Immutable from "seamless-immutable";

const mapStateToProps = (state, props) => ({
  accesses: Immutable.asMutable(state.accesses.data),
  products: Immutable.asMutable(state.products.data),
  unitTypeProducts: Immutable.asMutable(state.unitTypeProducts.data),
  isAddition: state.modalSection[props.modalId].conf.isAddition,
  userUuid: state.user.uuid,
  user: state.user,
  theme: state.theme
});

const mapDispatchToProps = {
  activateCaptcha
};

const InfoSectionInfoTagWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoSectionInfoTag);

export default InfoSectionInfoTagWrapper;
