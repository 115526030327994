import { connect } from "react-redux";

import UnitAgentButton from "../../components/UnitAgentButton";
import { showModalContent } from "../ModalGroupWrapper/actions";

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  showModalContent
};

const UnitAgentButtonWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnitAgentButton);

export default UnitAgentButtonWrapper;
