import React, { Component } from "react";

import "./style.scss";
import { DescribedButton } from "react-bootstrap-tools";
import { getAllowSend, validateInput } from "./functions";
import config from "../../containers/App/config";
import { Interpolate, translate } from "react-i18next";
import { Form, InputGroup } from "react-bootstrap";
import { handleFetchSendContact } from "./fetchUtils";

export class ContactComponent extends Component {
  defaultState = {
    isSending: false,
    content: {
      email: "",
      title: "",
      content: ""
    },
    isValid: {
      email: null,
      title: null,
      content: null
    },
    allowSend: false
  };

  config = {
    textMaxLength: 7000
  };

  constructor(props) {
    super(props);

    this.t = props.t;

    this.state = this.defaultState;

    if(props.unitName) {
      this.state.content.title = this.t("Contact.agentContactTitle", {unitName: props.unitName});
      this.state.isValid.title = true;
      this.state.content.content = this.t("Contact.agentContactContent", {unitName: props.unitName});
      this.state.isValid.content = true;

      if(!!this.props.user.uuid) {
        this.state.allowSend = true;
      }
    }

    this.touButtonText = this.t("Contact.touLinkText");
    this.privacyPolicyButtonText = this.t("Contact.privacyPolicyLinkText");
    this.sendButtonText = this.t("common:send");
  }

  handleInputChange = ev => {
    const fieldId = ev.target.name;
    const fieldValue = ev.target.value;

    const content = {
      ...this.state.content,
      [fieldId]: fieldValue
    };

    const isValid = {
      ...this.state.isValid,
      [fieldId]: validateInput(fieldId, fieldValue, config.regex)
    };

    this.setState({
      content,
      isValid,
      allowSend: getAllowSend(
        isValid,
        content,
        !!this.props.user.uuid,
        this.state.isSending
      )
    });
  };

  handleSendClick = () => {
    this.handleShowCaptcha(this.handleSendContact);
  };

  handleSendContact = () => {
    this.setState({
      isSending: true,
      allowSend: false
    });

    const sendContent = this.state.content;
    if (!!this.props.user.uuid) sendContent.uuid = this.props.user.uuid;

    handleFetchSendContact({
      token: this.props.token,
      body: sendContent,
      invokeInfo: this.props.invokeInfo,
      handleFetchSendContactSuccessCallback: this.handleSendSuccess,
      handleFetchSendContactFailCallback: this.handleSendFail
    });
  };

  handleSendSuccess = () => {
    this.setState(this.defaultState);
  };

  handleSendFail = () => {
    this.setState({
      isSending: false,
      allowSend: true
    });
  };

  handleShowCaptcha = action => {
    this.props.activateCaptcha(action, "Contact");
  };

  handleTouLinkClick = () => {
    this.props.showModalContent({ type: "tou" });
  };

  handlePrivacyPolicyLinkClick = () => {
    this.props.showModalContent({ type: "privacyPolicy" });
  };

  render() {
    const touLink = (
      <DescribedButton
        buttonClassName="Contact__disclaimer-link"
        text={this.touButtonText}
        onClick={this.handleTouLinkClick}
      />
    );

    const privacyPolicyLink = (
      <DescribedButton
        buttonClassName="Contact__disclaimer-link"
        text={this.privacyPolicyButtonText}
        onClick={this.handlePrivacyPolicyLinkClick}
      />
    );

    return (
      <section
        className="Contact"
        // for tracking purposes
        id="Contact"
      >
        <Form>
          {!this.props.user.uuid && (
            <Form.Group controlId="name">
              <InputGroup>
                {/*<InputGroup.Addon>@</InputGroup.Addon>*/}
                <Form.Control
                  readOnly={this.state.isSending}
                  type="text"
                  maxLength={255}
                  placeholder={this.t("Contact.emailFormPlaceholder")}
                  name="email"
                  onChange={this.handleInputChange}
                  value={this.state.content.email}
                  isInvalid={this.state.isValid.email === false}
                  className={'Contact__email'}
                />
              </InputGroup>
            </Form.Group>
          )}
          <Form.Group controlId="title">
            <Form.Control
              readOnly={this.state.isSending}
              type="text"
              maxLength={255}
              placeholder={this.t("Contact.titleFormPlaceholder")}
              name="title"
              onChange={this.handleInputChange}
              value={this.state.content.title}
              isInvalid={this.state.isValid.title === false}
              className={'Contact__title'}
            />
          </Form.Group>
          <Form.Group controlId="content">
            <Form.Control
              readOnly={this.state.isSending}
              className="Contact__textarea"
              as="textarea"
              maxLength={this.config.textMaxLength}
              placeholder={this.t("Contact.contentFormPlaceholder")}
              name="content"
              onChange={this.handleInputChange}
              value={this.state.content.content}
              isInvalid={this.state.isValid.content === false}
            />
          </Form.Group>
          <div className="Contact__counter-wrapper">
            <p className="Contact__counter">
              {this.state.content.content.length} / {this.config.textMaxLength}
            </p>
          </div>
          <Form.Group className="Contact__button-row">
            <DescribedButton
              text={this.sendButtonText}
              buttonClassName="Contact__button"
              disabled={!this.state.allowSend}
              onClick={this.handleSendClick}
            />
          </Form.Group>
          <div className="Contact__disclaimer">
            <Interpolate
              i18nKey="Contact.disclaimerSet"
              useDangerouslySetInnerHTML
              touLink={touLink}
              privacyPolicyLink={privacyPolicyLink}
            />
          </div>
        </Form>
      </section>
    );
  }
}

export default translate("frontend")(ContactComponent);
