import { TOGGLE_LEFT_SECTION_VISIBILITY } from "./actions";

const initialState = {
  collapsed: false
};

function leftSectionReducers(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_LEFT_SECTION_VISIBILITY:
      return Object.assign({}, state, { collapsed: action.collapsed });
    default:
      return state;
  }
}

export default leftSectionReducers;
