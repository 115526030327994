import { depracated_handleFetch } from "phyzzi.com-frontend-utils";
import Config from "../../containers/App/config";

export const handleFetchUserData = conf => {
  depracated_handleFetch(
    {
      url: Config.url.api.userResource + "/" + conf.uuid || "",
      token: conf.token
    },
    {
      success: json => conf.injectUserData(json),
      error: () => handleFetchUserDataFail(conf.invokeInfo)
    }
  );
};

const handleFetchUserDataFail = invokeInfo => {
  invokeInfo({
    variant: "danger",
    message: "UserProfile.fetchDataFailAlert"
  });
};

export const handleRemoveUser = conf => {
  depracated_handleFetch(
    {
      url:
        Config.url.api.pointUserResource.replace(
          "_POINT_ID_",
          encodeURIComponent(conf.unitId)
        ) +
          "/" +
          conf.uuid || "",
      method: "DELETE",
      token: conf.token
    },
    {
      success: () => handleRemoveUserSuccess(conf),
      error: () => handleRemoveUserFail(conf.invokeInfo)
    }
  );
};

const handleRemoveUserSuccess = conf => {
  conf.invokeInfo({
    variant: "success",
    message: "UserProfile.removeUserSuccessAlert"
  });

  conf.fetchUserData();
};

const handleRemoveUserFail = invokeInfo => {
  invokeInfo({
    variant: "danger",
    message: "UserProfile.removeUserFailAlert"
  });
};

export const handleRemoveBoard = conf => {
  depracated_handleFetch(
    {
      url: Config.url.api.postResource + "/" + conf.boardId || "",
      method: "DELETE",
      token: conf.token
    },
    {
      success: () => handleRemoveBoardSuccess(conf),
      error: () => handleRemoveBoardFail(conf.invokeInfo)
    }
  );
};

const handleRemoveBoardSuccess = conf => {
  conf.invokeInfo({
    variant: "success",
    message: "UserProfile.removeBoardSuccessAlert"
  });

  conf.fetchUserData();
};

const handleRemoveBoardFail = invokeInfo => {
  invokeInfo({
    variant: "danger",
    message: "UserProfile.removeBoardFailAlert"
  });
};

export const handleSaveUserValue = conf => {
  depracated_handleFetch(
    {
      url: Config.url.api.userResource + "/" + conf.uuid || "",
      method: "PUT",
      token: conf.token,
      body: {
        [conf.fieldId]: conf.content
      }
    },
    {
      success: () => handleSaveUserValueSuccess(conf),
      error: () => handleSaveUserValueFail(conf.invokeInfo)
    }
  );
};

const handleSaveUserValueSuccess = conf => {
  conf.invokeInfo({
    variant: "success",
    message: "UserProfile.changeDataSuccessAlert"
  });

  conf.changeUserData(conf.fieldId, conf.content);
};

const handleSaveUserValueFail = invokeInfo => {
  invokeInfo({
    variant: "danger",
    message: "UserProfile.changeDataFailAlert"
  });
};
