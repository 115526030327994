export const generateClassName = theme => {
  return "PhyzziFrontend " + theme;
};

export const shouldFetchUpdate = (localDate, serverDate) => {
  return localDate.isBefore(serverDate);
};

export const convertTimestampToDateTime = unixTimestamp => {
  const timestamp = new Date(unixTimestamp);

  let year = timestamp.getFullYear();
  let month = timestamp.getMonth() + 1;
  if (month < 10) month = "0" + month;
  let day = timestamp.getDate();
  if (day < 10) day = "0" + day;

  let hour = timestamp.getHours();
  if (hour < 10) hour = "0" + hour;
  let minute = timestamp.getMinutes();
  if (minute < 10) minute = "0" + minute;
  let second = timestamp.getSeconds();
  if (second < 10) second = "0" + second;

  return (
    year + "-" + month + "-" + day + " " + hour + ":" + minute + ":" + second
  );
};

// @ToDo: depracated?
export const convertTimestampToDate = unixTimestamp => {
  const timestamp = new Date(unixTimestamp);

  let year = timestamp.getFullYear();
  let month = timestamp.getMonth() + 1;
  if (month < 10) month = "0" + month;
  let day = timestamp.getDate();
  if (day < 10) day = "0" + day;

  return year + "-" + month + "-" + day;
};
