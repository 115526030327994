import React, { Component } from "react";

import "./style.scss";
import { DescribedButton } from "react-bootstrap-tools";
import { extractCookies } from "../../utils/helpers";
import { config } from "./config";

export class HowToThumbnailComponent extends Component {
  constructor(props) {
    super(props);

    const extractedCookies = extractCookies(document.cookie);

    this.state = {
      shouldAppear:
        !extractedCookies[config.cookieName] ||
        extractedCookies[config.cookieName] !== config.cookieDismissedValue
    };
  }

  handleDismiss = () => {
    const d = new Date();
    d.setTime(d.getTime() + config.dismissalDays * 24 * 60 * 60 * 1000);
    const expires = d.toUTCString();
    document.cookie =
      config.cookieName +
      "=" +
      config.cookieDismissedValue +
      ";expires=" +
      expires +
      ";";

    this.setState({
      shouldAppear: false
    });
  };

  handleThumbnailClick = () => {
    this.props.showModalContent({
      type: "howTo",
      conf: {}
    });
  };

  render() {
    if (this.state.shouldAppear) {
      return (
        <div className="HowToThumbnail">
          <DescribedButton
            onClick={this.handleDismiss}
            buttonClassName="HowToThumbnail__button"
            icon="close"
          />
          <DescribedButton
            onClick={this.handleThumbnailClick}
            buttonClassName="HowToThumbnail__thumbnail"
          />
        </div>
      );
    }

    return null;
  }
}

export default HowToThumbnailComponent;
