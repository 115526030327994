export const config = {
  maxLengths: {
    keyword: 25,
    message: 255
  },
  filter: {
    sorters: [
      {
        field: "namesurname",
        label: "InfoSectionBoard.columnUser"
      },
      {
        field: "updated_at_timestamp",
        label: "InfoSectionBoard.columnDate",
        selected: "desc"
      },
      {
        field: "type",
        label: "InfoSectionBoard.columnType"
      }
    ],
    filters: [
      "name",
      "surname",
      "position",
      "location",
      "board_group_name",
      "message"
    ],
    filterMaxLength: 20
  },
  message: {
    empty: {
      data: {
        board_group_id: "",
        type: "",
        keyword: "",
        message: ""
      },
      validation: {
        board_group_id: false,
        type: false,
        keyword: true,
        message: false
      }
    },
    edit: {
      validation: {
        board_group_id: true,
        type: true,
        keyword: true,
        message: true
      }
    }
  }
};
