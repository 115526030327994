import React from "react";

import "./style.scss";
import { DescribedButton } from "react-bootstrap-tools";
import { translate } from "react-i18next";
import { getAddButtonDescription, isAddButtonDisabled } from "./functions";

export const InfoSectionBoardFirstRowComponent = props => {
  const { t } = props;
  const addButtonDescription = t(
    getAddButtonDescription(props.user.uuid || null, props.canUserAddMessage)
  );
  const addButtonDisabled = isAddButtonDisabled(
    props.user.uuid || null,
    props.canUserAddMessage
  );

  return (
    <tr className="InfoSectionBoardFirstRow">
      <td />
      <td colSpan={6}>
        <p className="InfoSectionBoardFirstRow__add-yourself">
          {t("InfoSectionBoardFirstRow.addYourself")}
        </p>
        <p className="InfoSectionBoardFirstRow__add-yourself-info">
          {t("InfoSectionBoardFirstRow.addYourselfInfo")}
        </p>
      </td>
      <td>
        <DescribedButton
          buttonClassName="InfoSectionBoardFirstRow__icon-button"
          icon="add"
          placement="left"
          onClick={props.toggleShowAddForm}
          disabled={addButtonDisabled}
          description={addButtonDescription}
        />
      </td>
    </tr>
  );
};

export default translate("frontend")(InfoSectionBoardFirstRowComponent);
