import { connect } from "react-redux";

import Logo from "../../components/Logo";

const mapStateToProps = state => ({
  theme: state.theme
});

const mapDispatchToProps = {};

const LogoWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(Logo);

export default LogoWrapper;
