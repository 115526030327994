import React from "react";

import "./style.scss";
import { DescribedButton } from "react-bootstrap-tools";

export class ReactTagsRemove extends React.Component {
  render() {
    return (
      <DescribedButton
        {...this.props}
        size="sm"
        buttonClassName="ReactTagsRemove"
        iconClassName="ReactTagsRemove__icon"
        icon="close"
      />
    );
  }
}

export default ReactTagsRemove;
