import React, { Component } from "react";

import "./style.scss";
import { DescribedButton } from "react-bootstrap-tools";
import { translate } from "react-i18next";
import { Collapse } from "react-bootstrap";
import { getLanguageId } from "../../utils/helpers";
import { getClassName } from "./functions";
import { config } from "./config";

export class LanguageButtonComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLanguageMenuShown: false,
      flagButtonClassName: getClassName(getLanguageId(props.i18n.language))
    };

    this.mouseClickInside = false;
    this.t = this.props.t;
    this.i18n = this.props.i18n;
  }

  componentDidMount() {
    window.addEventListener("mousedown", this.handlePageClick, false);
  }

  componentWillReceiveProps() {
    document.title = this.t("common:appMetaTitle");
  }

  componentWillUnmount() {
    window.removeEventListener("mousedown", this.handlePageClick, false);
  }

  handlePageClick = e => {
    if (this.mouseClickInside) {
      return;
    }

    if (this.state.isLanguageMenuShown) this.toggleMenu();
  };

  toggleMenu = () => {
    this.setState(prevState => {
      return {
        ...prevState,
        isLanguageMenuShown: !this.state.isLanguageMenuShown
      };
    });
  };

  handleClickInside = () => {
    this.handleClick(true);
  };

  handleClickOutside = () => {
    this.handleClick(false);
  };

  handleClick = mouseClickInside => {
    this.mouseClickInside = mouseClickInside;
  };

  changeLanguage = language => {
    const languageId = getLanguageId(language);

    this.i18n.changeLanguage(language);
    this.toggleMenu();
    this.setState({
      flagButtonClassName: getClassName(languageId)
    });

    // Replaces url for social media
    window.history.replaceState(
      "",
      this.t("common:appMetaTitle"),
      "/" + languageId
    );

    // Sets cookie
    const d = new Date();
    d.setTime(d.getTime() + config.dismissalDays * 24 * 60 * 60 * 1000);
    const expires = d.toUTCString();
    document.cookie =
      config.cookieName + "=" + language + ";expires=" + expires + ";";
  };

  changeLanguageToEn = () => {
    this.changeLanguage("en");
  };

  changeLanguageToRu = () => {
    this.changeLanguage("ru");
  };

  render() {
    return (
      <div className="LanguageButton">
        <DescribedButton
          description={this.t("LanguageButton.description")}
          placement="bottom"
          buttonClassName={this.state.flagButtonClassName}
          onClick={this.toggleMenu}
        />

        <Collapse in={this.state.isLanguageMenuShown}>
          <div
            className="LanguageButton__language-menu-wrapper"
            onMouseDown={this.handleClickInside}
            onMouseUp={this.handleClickOutside}
          >
            <DescribedButton
              buttonClassName="LanguageButton__language-menu--en"
              onClick={this.changeLanguageToEn}
              text={this.t("common:language.en")}
            />
            <DescribedButton
              buttonClassName="LanguageButton__language-menu--ru"
              onClick={this.changeLanguageToRu}
              text={this.t("common:language.ru")}
            />
            <div className="LanguageButton__language-menu--cn">
              {this.t("common:language.cn")}{" "}
              <span className="LanguageButton__coming-soon">
                ({this.t("LanguageButton.comingSoonCn")})
              </span>
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}

export default translate("frontend")(LanguageButtonComponent);
