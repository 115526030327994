import React from "react";

import config from "./config";
import { getOverlayProps, getTooltipProps } from "./functions";
import { translate } from "react-i18next";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export const DescribedComponentComponent = props => {
  const { t } = props;

  const tooltipComponent = (
    <Tooltip {...getTooltipProps(props, config)}>
      {props.useTranslation !== false
        ? t(props.description)
        : props.description}
    </Tooltip>
  );

  if (!!props.description) {
    return (
      <OverlayTrigger {...getOverlayProps(props, config, tooltipComponent)}>
        {props.children}
      </OverlayTrigger>
    );
  } else {
    return <span>{props.children}</span>;
  }
};

export default translate("frontend")(DescribedComponentComponent);
