import React, { Component } from "react";

import "./style.scss";
import InfoSectionInfoWrapper from "../../containers/InfoSectionInfoWrapper/index";
import InfoSectionUnitTypesWrapper from "../../containers/InfoSectionUnitTypesWrapper/index";
import InfoSectionBoardWrapper from "../../containers/InfoSectionBoardWrapper/index";
import InfoSectionUsersWrapper from "../../containers/InfoSectionUsersWrapper/index";

class InfoSectionContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentType: props.type
    };
  }

  componentWillReceiveProps(props) {
    const contentType = props.type;

    this.setState({
      contentType
    });
  }

  render() {
    return (
      <section className="InfoSectionContent">
        {this.state.contentType === "unitInfo" && (
          <InfoSectionInfoWrapper modalId={this.props.modalId} />
        )}
        {this.state.contentType === "unitTypes" && (
          <InfoSectionUnitTypesWrapper modalId={this.props.modalId} />
        )}
        {this.state.contentType === "unitUsers" && (
          <InfoSectionUsersWrapper modalId={this.props.modalId} />
        )}
        {this.state.contentType === "unitBoard" && (
          <InfoSectionBoardWrapper modalId={this.props.modalId} />
        )}
      </section>
    );
  }
}

export default InfoSectionContent;
