import { getModalId } from "../../containers/ModalGroupWrapper/functions";

const possibleZoomsliderClassNames = [
  "Zoomslider--partial30",
  "Zoomslider--full"
];

export const getClassName = (isInfoSectionShown, isEditPositionMarkerShown) => {
  return (
    "MapContainer" +
    (!isInfoSectionShown && !isEditPositionMarkerShown
      ? "--clickable-markers"
      : "")
  );
};

export const getMapInitialPosition = (datasetPosition, configPosition) => {
  if (!!datasetPosition) {
    const splittedDataset = datasetPosition
      .split(",")
      .map(coord => parseFloat(coord, 10));
    if (isLat(splittedDataset[0]) && isLng(splittedDataset[1]))
      return splittedDataset;
  }

  return configPosition;
};

const isLat = position => {
  return position > -90 && position < 90;
};

const isLng = position => {
  return position > -180 && position < 180;
};

export const getMapInitialZoom = (datasetZoom, config) => {
  if (!!datasetZoom) {
    const zoomLevel = parseInt(datasetZoom, 10);
    if (zoomLevel <= config.maxZoom && zoomLevel >= config.minZoom)
      return zoomLevel;
  }

  return config.zoomLevel;
};

export const getIsInfoSectionShown = modalSection => {
  return (
    getModalId("unitInfo", modalSection) >= 0 ||
    getModalId("unitTypes", modalSection) >= 0
  );
};

/**
 * Returns additional className of a zoomslider component for horizontal behaviour
 * @param string sizeMode - sizeMode of bottom component (from store)
 * @returns {string}
 */
export const getZoomsliderAdditionalClassName = sizeMode => {
  const basicClassName = "Zoomslider";

  if (["partial30", "full"].indexOf(sizeMode) > -1) {
    const className = basicClassName + "--" + sizeMode;

    if (possibleZoomsliderClassNames.indexOf(className) > -1) return className;
  }

  return "";
};

/**
 * Returns final className of a zoomslider component for horizontal behaviour
 * @param string sizeMode - sizeMode of bottom component (from store)
 * @returns {string}
 */
export const getZoomsliderClassName = (
  actualClassName,
  additionalClassName
) => {
  return actualClassName
    .split(" ")
    .filter(className => possibleZoomsliderClassNames.indexOf(className) < 0)
    .concat(additionalClassName)
    .join(" ");
};
