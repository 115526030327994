/**
 * Returns className of a component for horizontal behaviour
 * @param string sizeMode - sizeMode of bottom component (from store)
 * @returns {string}
 */
export const getClassName = sizeMode => {
  const basicClassName = "AddButton";

  if (["partial30", "full"].indexOf(sizeMode) > -1)
    return basicClassName + "--" + sizeMode;

  return basicClassName;
};
