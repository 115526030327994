import { connect } from "react-redux";

import MessageButton from "../../components/MessageButton";
import { showModalContent } from "../ModalGroupWrapper/actions";
import { invokeInfo } from "../AlertBoxWrapper/actions";
import { setMessagesAmount } from "../App/actions";

const mapStateToProps = state => ({
  userUuid: state.user.uuid,
  theme: state.theme,
  token: state.initData.token,
  messagesAmount: state.messagesAmount.amount,
  modalSection: state.modalSection
});

const mapDispatchToProps = {
  showModalContent,
  invokeInfo,
  setMessagesAmount
};

const MessageButtonWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(MessageButton);

export default MessageButtonWrapper;
