import { connect } from "react-redux";

import InfoSectionInfoButtons from "../../components/InfoSectionInfoButtons";
import { openPositionMarker } from "../MapContainerWrapper/actions";
import { setModalConf, setModalType } from "../ModalGroupWrapper/actions";
import { activateCaptcha } from "../CaptchaWrapper/actions";

const mapStateToProps = state => ({
  user: state.user,
  theme: state.theme
});

const mapDispatchToProps = {
  setModalConf,
  openPositionMarker,
  setModalType,
  activateCaptcha
};

const InfoSectionInfoButtonsWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoSectionInfoButtons);

export default InfoSectionInfoButtonsWrapper;
