import React, { PureComponent } from "react";

import MapIcon from "../MapIcon/index";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { getMapPointCollection } from "./functions";
import L from "leaflet";
import config from "../../containers/App/config";
import { getMapIconProps } from "../../utils/helpers";

class PointCluster extends PureComponent {
  handlePointClick = (event, unit) => {
    this.props.selectUnit(unit);
    this.props.showModalContent({
      type: "unitInfo",
      conf: {
        useRelativePosition: {
          x: event.originalEvent.clientX,
          y: event.originalEvent.clientY
        },
        reloadContent: true,
        id: unit.id,
        isAddition: false,
        isAdditionPath: false
      }
    });
  };

  render() {
    const showPoints = getMapPointCollection(
      this.props.unitTypeTree,
      this.props.points
    );

    if (showPoints.length === 0) return null;

    return (
      <MarkerClusterGroup
        key={new Date().getTime()}
        removeOutsideVisibleBounds={true}
        disableClusteringAtZoom={7}
        spiderfyOnMaxZoom={false}
      >
        {showPoints.map(point => {
          return (
            <MapIcon
              key={point.id + "_"}
              handlePointClick={this.handlePointClick}
              icon={L.icon(
                getMapIconProps(point.icon, config.icons, this.props.mapType)
              )}
              lat={point.lat}
              lon={point.lon}
              id={point.id}
              name={point.name}
              pointIcon={point.icon}
            />
          );
        })}
      </MarkerClusterGroup>
    );
  }
}

export default PointCluster;
