import { connect } from "react-redux";

import InfoSectionInfoMaintenance from "../../components/InfoSectionInfoMaintenance";
import { activateCaptcha } from "../CaptchaWrapper/actions";

const mapStateToProps = (state, props) => ({
  isAddition: state.modalSection[props.modalId].conf.isAddition,
  userUuid: state.user.uuid,
  user: state.user,
  theme: state.theme
});

const mapDispatchToProps = {
  activateCaptcha
};

const InfoSectionInfoMaintenanceWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoSectionInfoMaintenance);

export default InfoSectionInfoMaintenanceWrapper;
