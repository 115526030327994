import React, { Component } from "react";
import "./style.scss";
import { DescribedButton, DescribedComponent } from "react-bootstrap-tools";
import { translate } from "react-i18next";
import Config from "../../containers/App/config";
import {
  getProviderButtonText,
  getProviderIconClassName,
  getProviderIconDescription
} from "./functions";
import { PhyzziBrandLoader } from "phyzzi.com-frontend-utils";
import "phyzzi.com-frontend-utils/build/static/css/index.css";
import { Button, Collapse } from "react-bootstrap";
import {
  handleFetchAccountTypes,
  handleFetchChangeContactVisibility,
  handleFetchUserSettings,
  handleFetchUserDelete,
  handleFetchDisconnectProvider
} from "./fetchUtils";

export class UserSettingsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showDeleteDescription: false,
      showDisconnectDescription: false,
      userData: {},
      accountTypes: []
    };

    this.t = props.t;
    this.deleteAccountButtonDescription = this.t(
      "UserSettings.buttonDeleteAccountDescription"
    );
    this.deleteAccountButtonText = this.t(
      "UserSettings.buttonDeleteAccountText"
    );
    this.deleteAccountConfirmationButtonDescription = this.t(
      "UserSettings.buttonDeleteAccountConfirmDescription"
    );
    this.deleteAccountConfirmationButtonText = this.t(
      "UserSettings.buttonDeleteAccountConfirmText"
    );
    this.newPasswordButtonDescription = this.t(
      "UserSettings.buttonRequestNewPasswordDescription"
    );
    this.newPasswordButtonCreateText = this.t(
      "UserSettings.buttonRequestNewPasswordCreateText"
    );
    this.newPasswordButtonChangeText = this.t(
      "UserSettings.buttonRequestNewPasswordChangeText"
    );
    this.contactVisibilityButtonHideDescription = this.t(
      "UserSettings.buttonContactDataHideDescription"
    );
    this.contactVisibilityButtonShowDescription = this.t(
      "UserSettings.buttonContactDataShowDescription"
    );
    this.contactVisibilityButtonHideText = this.t(
      "UserSettings.buttonContactDataShowText"
    );
    this.contactVisibilityButtonShowText = this.t(
      "UserSettings.buttonContactDataHideText"
    );
  }

  componentDidMount() {
    Object.keys(this.state.userData).length === 0 && this.getUserSettings();

    this.state.accountTypes.length === 0 && this.getAccountTypes();
  }

  getUserSettings = () => {
    handleFetchUserSettings({
      token: this.props.token,
      injectUserSettings: this.injectUserSettings,
      invokeInfo: this.props.invokeInfo
    });
  };

  injectUserSettings = json => {
    if (Object.keys(json).length > 0) {
      this.setState({
        userData: json.user
      });
    }
  };

  getAccountTypes = () => {
    handleFetchAccountTypes({
      token: this.props.token,
      injectAccountTypes: this.injectAccountTypes,
      invokeInfo: this.props.invokeInfo
    });
  };

  injectAccountTypes = json => {
    if (Object.keys(json).length > 0) {
      this.setState({
        accountTypes: json
      });
    }
  };

  handleContactVisibilityClick = () => {
    this.handleShowCaptcha(this.changeContactVisibility);
  };

  changeContactVisibility = () => {
    handleFetchChangeContactVisibility({
      uuid: this.state.userData.uuid,
      token: this.props.token,
      body: { contact_visibility: !this.state.userData.contactVisibility },
      handleReload: this.handleReload,
      invokeInfo: this.props.invokeInfo
    });
  };

  handleFirstDeleteClick = () => {
    this.setState({
      showDeleteDescription: !this.state.showDeleteDescription
    });
  };

  handleFinalDeleteClick = () => {
    this.handleShowCaptcha(this.deleteUser);
  };

  handleShowCaptcha = action => {
    this.props.activateCaptcha(action, "User settings");
  };

  deleteUser = () => {
    handleFetchUserDelete({
      uuid: this.state.userData.uuid,
      token: this.props.token,
      invokeInfo: this.props.invokeInfo
    });
  };

  handleReload = () => {
    this.getUserSettings();
  };

  handleProviderIconClick = (providerId, isConnected) => {
    isConnected
      ? this.handleDisconnectProviderClick(this.state.userData.uuid, providerId)
      : window.location.assign(
          Config.url.joinProvider
            .replace("_UUID_", this.state.userData.uuid)
            .replace("_PROVIDER_", providerId)
        );
  };

  handleDisconnectProviderClick = (uuid, providerId) => {
    let providersNumber = 0;
    if (!!this.state.userData.userLinkedin) providersNumber++;
    if (!!this.state.userData.userGoogle) providersNumber++;

    if (this.state.userData.onlyProvided && providersNumber === 1) {
      this.setState({ showDisconnectDescription: true });
    } else {
      this.handleDisconnectProvider(uuid, providerId);
    }
  };

  handleDisconnectProvider = (uuid, providerId) => {
    handleFetchDisconnectProvider({
      uuid,
      providerId,
      token: this.props.token,
      getUserSettings: this.getUserSettings,
      invokeInfo: this.props.invokeInfo
    });
  };

  render() {
    const { t } = this.props;

    return (
      <section className="UserSettings">
        {Object.keys(this.state.userData).length === 0 ? (
          <PhyzziBrandLoader />
        ) : (
          <div>
            <section
              className="UserSettings__section"
              // for tracking purposes
              id="UserSettings__section"
            >
              <div className="UserSettings__section-table">
                <div className="UserSettings__section-cell">
                  {t("UserSettings.user")}
                </div>
                <div className="UserSettings__section-cell">
                  {this.state.userData.name} {this.state.userData.surname}
                </div>
              </div>
              <div className="UserSettings__section-table">
                <div className="UserSettings__section-cell">
                  {t("UserSettings.email")}
                </div>
                <div className="UserSettings__section-cell">
                  {this.state.userData.email}
                </div>
              </div>
              <div className="UserSettings__section-table">
                <div className="UserSettings__section-cell">
                  {t("UserSettings.password")}
                </div>
                <div className="UserSettings__section-cell">
                  <form
                    action={
                      Config.url.resetPasswordRequest +
                      "/" +
                      this.state.userData.uuid
                    }
                    method="GET"
                  >
                    <DescribedButton
                      type="submit"
                      description={this.newPasswordButtonDescription}
                      buttonClassName="UserSettings__button"
                      text={
                        this.state.userData.onlyProvided
                          ? this.newPasswordButtonCreateText
                          : this.newPasswordButtonChangeText
                      }
                    />
                  </form>
                </div>
              </div>
              <div className="UserSettings__section-table">
                <div className="UserSettings__section-cell">
                  {t("UserSettings.connectedAccounts")}
                </div>
                <div className="UserSettings__section-cell">
                  {Config.providers.map(provider => {
                    return (
                      <DescribedComponent
                        key={provider.id}
                        description={t(
                          getProviderIconDescription(
                            provider.id,
                            !!this.state.userData[provider.accountId]
                          )
                        )}
                      >
                        <Button
                          onClick={() => {
                            this.handleProviderIconClick(
                              provider.id,
                              !!this.state.userData[provider.accountId]
                            );
                          }}
                          className={getProviderIconClassName(
                            provider.id,
                            !!this.state.userData[provider.accountId]
                          )}
                        >
                          {t(
                            getProviderButtonText(
                              provider.id,
                              !!this.state.userData[provider.accountId]
                            )
                          )}
                        </Button>
                      </DescribedComponent>
                    );
                  })}
                </div>
              </div>
              <Collapse in={this.state.showDisconnectDescription}>
                <div className="UserSettings__section-description-wrapper">
                  {t("UserSettings.disconnectOnlyProviderInfo")}
                </div>
              </Collapse>
              <div className="UserSettings__section-table">
                <div className="UserSettings__section-cell">
                  {t("UserSettings.contactData")}
                </div>
                <div className="UserSettings__section-cell">
                  <DescribedButton
                    description={
                      this.state.userData.contactVisibility
                        ? this.contactVisibilityButtonHideDescription
                        : this.contactVisibilityButtonShowDescription
                    }
                    onClick={this.handleContactVisibilityClick}
                    buttonClassName="UserSettings__button"
                    id="UserSettings__contact-visibility-button"
                    text={
                      this.state.userData.contactVisibility
                        ? this.contactVisibilityButtonHideText
                        : this.contactVisibilityButtonShowText
                    }
                  />
                </div>
              </div>
              <div className="UserSettings__section-table">
                <div className="UserSettings__section-cell" />
                <div className="UserSettings__section-cell">
                  <DescribedButton
                    description={this.deleteAccountButtonDescription}
                    buttonClassName="UserSettings__button"
                    text={this.deleteAccountButtonText}
                    onClick={this.handleFirstDeleteClick}
                  />
                </div>
              </div>
              <Collapse in={this.state.showDeleteDescription}>
                <div className="UserSettings__section-description-wrapper">
                  {t("UserSettings.deleteAccountInfo")}
                  <br />
                  <DescribedButton
                    description={
                      this.deleteAccountConfirmationButtonDescription
                    }
                    buttonClassName="UserSettings__button"
                    text={this.deleteAccountConfirmationButtonText}
                    onClick={this.handleFinalDeleteClick}
                  />
                </div>
              </Collapse>
            </section>
          </div>
        )}
      </section>
    );
  }
}

export default translate("frontend")(UserSettingsComponent);
