import React from "react";

import "./style.scss";
import { Filter } from "phyzzi.com-frontend-filter-sorter";
import { config } from "../InfoSectionBoard/config";

export default function MessengerListSorter(props) {
  return (
    <div className="MessengerListSorter">
      <Filter
        onChange={props.handleFilterChange}
        maxLength={config.filter.filterMaxLength}
        value={props.filterValue}
        transformedItemsNumber={props.transformedItemsNumber}
        originalItemsNumber={props.originalItemsNumber}
        placeholder={!!props.placeholder && props.placeholder}
      />
    </div>
  );
}
