import { connect } from "react-redux";

import UserProfileUnits from "../../components/UserProfileUnits";
import { changeViewport, selectUnit } from "../MapContainerWrapper/actions";
import {
  closeModalSection,
  showModalContent
} from "../ModalGroupWrapper/actions";

const mapStateToProps = state => ({
  unitTypes: state.unitTypes.data,
  boardGroups: state.boardGroups.data,
  theme: state.theme
});

const mapDispatchToProps = {
  showModalContent,
  selectUnit,
  changeViewport,
  closeModalSection
};

const UserProfileUnitsWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserProfileUnits);

export default UserProfileUnitsWrapper;
