export const Config = {
  url: {
    mainPage: "https://phyzzi.com/",
    lowIePage: "/not-supported-browser",
    smallScreenPage: "/small-screen",
    deletedAccountPage: "/deleted-account",
    userThumbnail: "/img/userThumb/",
    api: {
      publicDataResource: "/api/public_data",
      privateDataResource: "/api/private_data",
      fetchPoints: "/api/point/_POINT_TYPE_ID_/_LAST_UPDATE_",
      pingUpdates: "/api/update",
      login: "/api/auth/linkedin",
      contact: "/api/contact",

      pointResource: "/api/point",
      pointUserResource: "/api/point/_POINT_ID_/user",
      userResource: "/api/user",
      accountTypeResource: "/api/account_type",
      topicResource: "/api/topic",
      participantResource: "/api/participant",
      staticContentResource: "/api/staticContent",
      postResource: "/api/post",

      checkboxes: "/api/checkbox/_SITE_",
      search: "/api/search"
    },
    register: "/auth/register",
    registerUsingProvider: "/auth/registerUsingProvider",
    resetPasswordRequest: "/auth/refreshPassword",
    login: "/auth/login",
    loginUsingProvider: "/auth/loginUsingProvider",
    logout: "/auth/logout",
    joinProvider: "/auth/joinProvider/_UUID_/_PROVIDER_",
    howToVideo: "https://www.youtube.com/embed/mUpFRvKrDzg"
  },
  regex: {
    email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    password: /^.*(?=.{8,255})(?!.*\s)(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/,
    digits: /^\d+$/,
    floats: /^[\d.]+$/,
    hex: /^[\dabcdef]+$/,
    allowedSigns: /^[A-Z0-9 &()+':/.,-]*$/i,
    url: /^https?:\/\/(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)$/,
    searchBots: /bot|google|baidu|bing|msn|duckduckbot|teoma|slurp|yandex/i
  },
  validation: {
    minYear: 1800,
    maxYearInterval: 15
  },
  // @ToDo: to change 15-30 sec or fetch from backend
  pingTimeout: 5000,
  loginRegisterTimeout: 500,
  // @ToDo: probable to fetch from backend
  dbTimezone: "UTC",
  // @ToDo: to fetch from backend
  providers: [
    {
      id: "linkedin",
      accountId: "userLinkedin"
    },
    {
      id: "google",
      accountId: "userGoogle"
    }
  ],
  messenger: {
    fetchNewMessagesTimeout: 5000,
    fetchNewMessagesButtonTimeout: 20000,
    fetchMessagesListTimeout: 5000
  },
  minScreenWidth: 800,
  icons: {
    iconUrl: "/storage/mapIcons/_ICON_NAME_.svg",
    map: {
      iconSize: {
        circle: [10, 10],
        square: [10, 10],
        diamond: [14, 14],
        triangle: [14, 14]
      },
      iconAnchor: {
        circle: [4, 4],
        square: [5, 5],
        diamond: [7, 7],
        triangle: [7, 7]
      }
    },
    unitTypeTree: {
      iconSize: {
        circle: [10, 10],
        square: [10, 10],
        diamond: [14, 14],
        triangle: [14, 14]
      }
    }
  }
};

export default Config;
