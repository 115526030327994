import React, { Component } from "react";

import "./style.scss";
import { shouldCaptchaAppear } from "../InfoSectionContent/functions";
import { DescribedButton } from "react-bootstrap-tools";
import FormData from "../FormData/index";
import {
  calculateValidationState,
  getAddonsBefore,
  getPlaceholder
} from "./functions";
import { Config as modalSectionConfig } from "../ModalSection/config";
import { translate } from "react-i18next";
import { ButtonGroup } from "react-bootstrap";

class InfoSectionInfoInternet extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEdit: props.isAddition,
      website: props.content.website || "",
      wikipedia: props.content.wikipedia || "",
      youtube: props.content.youtube || "",
      canSave: false,
      validations: {}
    };

    this.t = props.t;
    this.editButtonDescription = this.t(
      "InfoSectionInfoInternet.buttonEditDescription"
    );
    this.saveButtonDescription = this.t(
      "InfoSectionInfoInternet.buttonSaveDescription"
    );
    this.cancelButtonDescription = this.t(
      "InfoSectionInfoInternet.buttonCancelDescription"
    );
  }

  handleChange = (fieldId, value) => {
    this.setState({
      [fieldId]: value
    });
  };

  handleValidation = (fieldId, value) => {
    const validationStatus = calculateValidationState(fieldId, value);
    const validations = {
      ...this.state.validations,
      [fieldId]: validationStatus
    };

    const canSave = Object.keys(validations).every(
      validationKey => validations[validationKey]
    );

    this.setState({
      validations,
      canSave
    });

    return validationStatus;
  };

  handleSaveClick = () => {
    this.props.isAddition
      ? this.handleReload()
      : shouldCaptchaAppear(
          "internet",
          this.props.user.lastChanges,
          this.props.user.isAdmin,
          modalSectionConfig.captcha
        )
        ? this.handleShowCaptcha(this.handleSave)
        : this.handleSave();

    this.toggleEdit();
  };

  handleReload = () => {
    this.props.handleReloadData({
      website: this.state.website,
      wikipedia: this.state.wikipedia,
      youtube: this.state.youtube
    });
  };

  handleSave = () => {
    const data = {
      website: this.state.website,
      wikipedia: this.state.wikipedia,
      youtube: this.state.youtube
    };

    this.props.handleSaveData(data);
    this.props.handleReloadData(data);
  };

  getFormProps = fieldId => {
    return {
      value: this.props.content[fieldId],
      validation: formValue => this.handleValidation(fieldId, formValue),
      bsSize: "small",
      tooltipClassName: this.props.theme,
      isEditing: true,
      addonsBefore: [getAddonsBefore(fieldId)],
      placeholder: [getPlaceholder(fieldId)],
      nonSavable: true,
      nonCancelable: true,
      change: formValue => this.handleChange(fieldId, formValue)
    };
  };

  toggleEdit = () => {
    this.setState({
      isEdit: !this.state.isEdit
    });
  };

  handleShowCaptcha = action => {
    this.props.activateCaptcha(action, "Unit info - internet");
  };

  handleWebsiteClick = () => window.open(this.props.content.website);

  handleWikiClick = () => window.open(this.props.content.wikipedia);

  handleEditClick = () => {
    !!this.props.userUuid && this.toggleEdit();
  };

  render() {
    return (
      <tr className={!!this.props.rowClassName ? this.props.rowClassName : ""}>
        <td
          className={
            !!this.props.leftColumnClassName
              ? this.props.leftColumnClassName
              : ""
          }
        >
          {this.t("InfoSectionInfoInternet.internet")}:
        </td>
        <td
          className={
            !!this.props.rightColumnClassName
              ? this.props.rightColumnClassName
              : ""
          }
        >
          {this.state.isEdit ? (
            <div>
              <FormData {...this.getFormProps("wikipedia")} />
              <FormData {...this.getFormProps("website")} />
              <FormData {...this.getFormProps("youtube")} />
            </div>
          ) : (
            <div className="InfoSectionInfoInternet">
              {!!this.props.content.wikipedia &&
                this.props.content.wikipedia !== "" && (
                  <DescribedButton
                    onClick={this.handleWikiClick}
                    buttonClassName="InfoSectionInfoInternet__button--wiki"
                  />
                )}
              {!!this.props.content.youtube &&
                this.props.content.youtube !== "" && (
                  <DescribedButton
                    onClick={this.props.showModalContent}
                    onClickArg={{
                      type: "unitYoutube",
                      conf: {
                        youtubeUrl: this.props.content.youtube,
                        wikiUrl: this.props.content.wikipedia,
                        url: this.props.content.youtube,
                        className: "ModalSectionExternalContent--youtube",
                        unitName: this.props.content.name
                      }
                    }}
                    buttonClassName="InfoSectionInfoInternet__button--youtube"
                  />
                )}
              {!!this.props.content.website &&
                this.props.content.website !== "" && (
                  <DescribedButton
                    icon="public"
                    size="sm"
                    description={this.props.content.website}
                    onClick={this.handleWebsiteClick}
                    buttonClassName="InfoSectionInfoInternet__button--web"
                    iconClassName="InfoSectionInfo__button-icon"
                  />
                )}
            </div>
          )}
          {!this.state.isEdit &&
            !!this.props.userUuid && (
              <DescribedButton
                description={this.editButtonDescription}
                size="sm"
                onClick={this.handleEditClick}
                disabled={!this.props.userUuid}
                buttonClassName="InfoSectionInfo__button"
                iconClassName="InfoSectionInfo__button-icon"
                icon="edit"
              />
            )}
          {this.state.isEdit && (
            <ButtonGroup>
              <DescribedButton
                description={this.saveButtonDescription}
                size="sm"
                onClick={this.handleSaveClick}
                disabled={!this.state.canSave}
                buttonClassName="InfoSectionInfo__button--positive"
                iconClassName="InfoSectionInfo__button-icon"
                icon="done"
              />
              <DescribedButton
                description={this.cancelButtonDescription}
                size="sm"
                onClick={this.toggleEdit}
                buttonClassName="InfoSectionInfo__button--negative"
                iconClassName="InfoSectionInfo__button-icon"
                icon="close"
              />
            </ButtonGroup>
          )}
        </td>
      </tr>
    );
  }
}

export default translate("frontend")(InfoSectionInfoInternet);
