export const canShowDeleteButton = unitId => {
  return unitId > 0;
};

export const canShowAddButton = unitId => {
  return unitId === 0;
};

export const canShowEditUnitTypeButton = unitId => {
  return unitId === 0;
};
