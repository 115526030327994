import { ACTIVATE_CAPTCHA, INACTIVATE_CAPTCHA } from "./actions";

const initialState = {
  active: false,
  afterCaptchaAction: null,
  invoker: null
};

function captchaReducers(state = initialState, action) {
  switch (action.type) {
    case ACTIVATE_CAPTCHA:
      return Object.assign({}, state, {
        active: true,
        afterCaptchaAction: action.afterCaptchaAction,
        invoker: !!action.invoker ? action.invoker : null
      });
    case INACTIVATE_CAPTCHA:
      return Object.assign({}, state, initialState);
    default:
      return state;
  }
}

export default captchaReducers;
