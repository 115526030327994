import React from "react";
import "./style.scss";
import { PhyzziBrandLoader } from "phyzzi.com-frontend-utils";
import "phyzzi.com-frontend-utils/build/static/css/index.css";
import { DescribedButton, Glyphicon } from "react-bootstrap-tools";

export const SearchIcon = props => {
  if (props.isSending)
    return (
      <div className="SearchIcon">
        <PhyzziBrandLoader />
      </div>
    );

  if (props.resultsAmount > 0) {
    return props.isResultWindowOpen ? (
      <DescribedButton
        icon="keyboard_arrow_up"
        onClick={props.toggleResultClose}
        // for tracking purposes
        buttonClassName="SearchIcon--menu"
        id="SearchIcon__menuUp"
      />
    ) : (
      <DescribedButton
        icon="keyboard_arrow_down"
        onClick={props.toggleResultOpen}
        // for tracking purposes
        buttonClassName="SearchIcon--menu"
        id="SearchIcon__menuDown"
      />
    );
  }

  return <Glyphicon icon="search" className="SearchIcon" />;
};

export default SearchIcon;
