import { connect } from "react-redux";

import Search from "../../components/Search";
import { invokeInfo } from "../AlertBoxWrapper/actions";
import { showModalContent } from "../ModalGroupWrapper/actions";
import { changeViewport, selectUnit } from "../MapContainerWrapper/actions";

const mapStateToProps = state => ({
  initQuery: state.initData.searchQuery
});

const mapDispatchToProps = {
  invokeInfo,
  showModalContent,
  changeViewport,
  selectUnit
};

const SearchWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(Search);

export default SearchWrapper;
