import React, { Component } from "react";
import "./style.scss";

class Movable extends Component {
  handleMouseDown = event => {
    const { screenX, screenY } = event;
    this.props.startEmittingCallback(
      this.props.onMove,
      screenX,
      screenY,
      this.props.onMouseUp || null
    );
  };

  render() {
    return (
      <div
        className="Movable"
        onMouseDown={event => this.handleMouseDown(event)}
      >
        {this.props.children}
      </div>
    );
  }
}

export default Movable;
