export const getStatusLabel = (status, selectedStatus) => {
  const statuses = {
    selected: {
      inactive: "InfoSectionInfoStatus.NameSelectedInactive",
      active: "InfoSectionInfoStatus.NameSelectedActive",
      notSelected: "InfoSectionInfoStatus.NameSelectedNotSelected"
    },
    notSelected: {
      inactive: "InfoSectionInfoStatus.NameOptionInactive",
      active: "InfoSectionInfoStatus.NameOptionActive",
      notSelected: "InfoSectionInfoStatus.NameOptionNotSelected"
    }
  };

  const translatedStatus =
    status === true ? "active" : status === false ? "inactive" : "notSelected";

  return status === selectedStatus
    ? statuses.selected[translatedStatus]
    : statuses.notSelected[translatedStatus];
};
