import React from "react";

import "./style.scss";
import { translate } from "react-i18next";

export const InfoSectionBoardNoResultsComponent = props => {
  const { t } = props;

  return (
    <tr>
      <td colSpan={8}>
        <div className="InfoSectionBoardNoResults">
          {t("InfoSectionBoardList.empty")}
        </div>
      </td>
    </tr>
  );
};

export default translate("frontend")(InfoSectionBoardNoResultsComponent);
