import React, { Component } from "react";

import "./style.scss";
import {
  getCanUserAddMessage,
  getCanUserEditMessage,
  getPostedMessagesNumber
} from "./functions";
import { calculateIsUserSubscribed } from "../InfoSectionUsers/functions";
import InfoSectionBoardNewMessageWrapper from "../../containers/InfoSectionBoardNewMessageWrapper/index";
import FilterSorter from "phyzzi.com-frontend-filter-sorter";
import { getBoardGroupNameLabel, unifyDate } from "phyzzi.com-frontend-utils";
import {
  getUnitTypeIcon,
  getUnitTypeStringId
} from "../../containers/App/functions";
import InfoSectionBoardFilterSorter from "../InfoSectionBoardFilterSorter/index";
import { translate } from "react-i18next";
import { getMapRangedZoomLevel } from "../../utils/helpers";
import { PhyzziBrandLoader } from "phyzzi.com-frontend-utils";
import "phyzzi.com-frontend-utils/build/static/css/index.css";
import {
  handleFetchAdd,
  handleFetchDelete,
  handleFetchEdit,
  handleFetchPrivateData,
  handleFetchRefresh,
  handleFetchUnitBoardData,
  handleFetchUnitData,
  handleFetchUnitUsers
} from "./fetchUtils";
import appConfig from "../../containers/App/config";
import { config } from "./config";
import InfoSectionBoardList from "../InfoSectionBoardList";

export class InfoSectionBoardComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoadingIcon: true,
      isUserSubscribed: false,
      postedUsersMessages: 0,
      canUserAddMessage: false,
      canUserEditMessage: false,
      showAddForm: false,
      content: {},
      editFormMode: "add",
      editFormValues: {}
    };

    this.fetchUsersApiUrl = appConfig.url.api.pointUserResource.replace(
      "_POINT_ID_",
      encodeURIComponent(props.id)
    );

    this.trackingLabel = !!props.unitTypeId
      ? getUnitTypeStringId(props.unitTypes, props.unitTypeId)
      : "";

    this.filterAlwaysTopRule = {
      key: "uuid",
      value: props.user.uuid || null
    };
  }

  componentDidMount() {
    this.handleReload();
    if (!this.props.isSelectedUnit) this.handleFetchUnitData();
  }

  handleGetUnitBoard = () => {
    handleFetchUnitUsers({
      url: this.fetchUsersApiUrl,
      token: this.props.token,
      injectUserSubscribed: this.injectUserSubscribed,
      getUnitBoardData: this.handleGetUnitBoardData,
      invokeInfo: this.props.invokeInfo
    });
  };

  handleGetUnitBoardData = () => {
    handleFetchUnitBoardData({
      unitId: this.props.id,
      token: this.props.token,
      injectUnitBoardData: this.injectUnitBoardData,
      invokeInfo: this.props.invokeInfo
    });
  };

  injectUnitBoardData = json => {
    const { t } = this.props;
    const postedUsersMessages = !!this.props.user.uuid
      ? getPostedMessagesNumber(json, this.props.user.uuid)
      : 0;

    this.setState({
      showLoadingIcon: false,
      content: json.map(message => {
        return {
          ...message,
          updated_at_timestamp: unifyDate(message.updated_at).unix(),
          namesurname: message.surname + " " + message.name,
          board_group_name: t(
            getBoardGroupNameLabel(
              this.props.boardGroups,
              message.board_group_id
            )
          )
        };
      }),
      postedUsersMessages,
      canUserAddMessage: getCanUserAddMessage(
        this.props.user,
        postedUsersMessages,
        this.state.isUserSubscribed
      ),
      canUserEditMessage: getCanUserEditMessage(
        this.props.user,
        postedUsersMessages,
        this.state.isUserSubscribed
      )
    });
  };

  injectUserSubscribed = json => {
    this.setState({
      isUserSubscribed: !!this.props.user.uuid
        ? calculateIsUserSubscribed(json, this.props.user.uuid)
        : false
    });
  };

  handleFetchUnitData = () => {
    handleFetchUnitData({
      id: this.props.id,
      token: this.props.token,
      invokeInfo: this.props.invokeInfo,
      handleFetchUnitDataSuccess: this.handleFetchUnitDataSuccess
    });
  };

  handleFetchUnitDataSuccess = json => {
    if (json && typeof json === "object" && !Array.isArray(json)) {
      this.props.selectUnit({
        icon: getUnitTypeIcon(this.props.unitTypes, json.unit_type_id),
        lat: json.lat / 1000000,
        lon: json.lon / 1000000,
        id: this.props.id,
        name: json.name
      });

      this.props.changeViewport({
        lat: json.lat / 1000000,
        lon: json.lon / 1000000,
        zoom: getMapRangedZoomLevel(11)
      });
    }
  };

  toggleShowAddForm = () => {
    this.setState({
      showAddForm: !this.state.showAddForm,
      editFormMode: "add",
      editFormValues: {}
    });
  };

  toggleEditForm = id => {
    const message = this.state.content.filter(board => board.id === id)[0];

    this.setState({
      showAddForm: !this.state.showAddForm,
      editFormMode: "edit",
      editFormValues: message
    });
  };

  handleReload = () => {
    this.handleGetUnitBoard();
    if (this.props.user.uuid) this.handleGetPrivateData();
  };

  handleGetPrivateData = () => {
    handleFetchPrivateData({
      url: appConfig.url.api.privateDataResource,
      token: this.props.token,
      receivePrivateData: this.props.receivePrivateData,
      invokeInfo: this.props.invokeInfo
    });
  };

  onRefreshButtonClick = id => {
    handleFetchRefresh({
      id,
      token: this.props.token,
      handleReload: this.handleReload,
      invokeInfo: this.props.invokeInfo
    });
  };

  onDeleteButtonClick = id => {
    handleFetchDelete({
      id,
      token: this.props.token,
      handleReload: this.handleReload,
      invokeInfo: this.props.invokeInfo
    });
  };

  onAddButtonClick = (formData, removeSendingStateCallback) => {
    const body = {
      ...formData,
      uuid: this.props.user.uuid,
      unit_id: this.props.id
    };

    handleFetchAdd({
      token: this.props.token,
      body,
      handleReload: this.handleReload,
      handleFetchAddSuccess: this.onFetchAddSuccess,
      invokeInfo: this.props.invokeInfo,
      failCallback: removeSendingStateCallback
    });
  };

  onFetchAddSuccess = () => {
    this.setState({
      showAddForm: false,
      editFormMode: "add",
      editFormValues: {}
    });
  };

  onEditButtonClick = (data, failCallback) => {
    const { id, type, board_group_id, keyword, message } = data;

    const body = {
      type,
      board_group_id,
      keyword,
      message,
      uuid: this.props.user.uuid,
      unit_id: this.props.id
    };

    handleFetchEdit({
      id,
      token: this.props.token,
      body,
      handleReload: this.handleReload,
      handleFetchEditSuccess: this.handleFetchEditSuccess,
      invokeInfo: this.props.invokeInfo,
      failCallback
    });
  };

  handleFetchEditSuccess = () => {
    this.setState({
      showAddForm: false,
      editFormMode: "add",
      editFormValues: {}
    });
  };

  render() {
    const { t } = this.props;

    const translatedSorters = config.filter.sorters.map(sorter => {
      return { ...sorter, label: t(sorter.label) };
    });

    return (
      <section className="InfoSectionBoard">
        {this.state.showLoadingIcon ? (
          <PhyzziBrandLoader />
        ) : (
          <div
            className="InfoSectionBoard__wrapper"
            // for tracking purposes
            id="UnitInfoBoard"
            trackinglabel={this.trackingLabel}
          >
            {!this.state.showAddForm && (
              <div className="InfoSectionBoard__table">
                <FilterSorter
                  content={this.state.content}
                  component={<table />}
                  filterValue=""
                  sorters={translatedSorters}
                  filters={config.filter.filters}
                  allwaysTopRule={this.filterAlwaysTopRule}
                >
                  <InfoSectionBoardFilterSorter
                    componentRole="filterSorter"
                    placeholder={t("InfoSectionBoard.formFilterPlaceholder")}
                  />
                  <InfoSectionBoardList
                    componentRole="content"
                    handleRefresh={this.onRefreshButtonClick}
                    handleDelete={this.onDeleteButtonClick}
                    canUserAddMessage={this.state.canUserAddMessage}
                    canUserEditMessage={this.state.canUserEditMessage}
                    toggleShowAddForm={this.toggleShowAddForm}
                    toggleEditForm={this.toggleEditForm}
                  />
                </FilterSorter>
              </div>
            )}
            {this.state.showAddForm && (
              <InfoSectionBoardNewMessageWrapper
                handleAdd={this.onAddButtonClick}
                handleEdit={this.onEditButtonClick}
                handleCancel={this.toggleShowAddForm}
                values={this.state.editFormValues}
                mode={this.state.editFormMode}
              />
            )}
          </div>
        )}
      </section>
    );
  }
}

export default translate("frontend")(InfoSectionBoardComponent);
