import React, { Component } from "react";

import "./style.scss";
import {
  getFormGroupProps,
  getInputProps,
  presentationValue
} from "./functions";
import DescribedButton from "../DescribedButton/index";
import DescribedComponent from "../DescribedComponent/index";
import { translate } from "react-i18next";
import { Form, InputGroup } from "react-bootstrap";

class FormData extends Component {
  constructor(props) {
    super(props);

    this.t = props.t;

    const isValid = this.validate(this.props.value);

    this.state = {
      value: this.props.value,
      isEditing: props.isEditing === true ? true : false,
      maxLength: !!props.maxLength ? props.maxLength : 255,
      empty: !!props.empty
        ? props.empty === "null"
          ? "null"
          : "empty"
        : "empty",
      isSaveProvided: !!props.save,
      isChangeProvided: !!props.change,
      isCancelProvided: !!props.cancel,
      canEdit: !props.nonEditable,
      showSaveButton: !props.nonSavable,
      showCancelButton: !props.nonCancelable,
      canSave: this.calculateCanSave(isValid),
      isValid,
      areAddonsBefore: !!props.addonsBefore && props.addonsBefore.length > 0,
      areAddonsAfter: !!props.addonsAfter && props.addonsAfter.length > 0,
      areAdditionalButtons:
        !!props.additionalButtons && props.additionalButtons.length > 0,
      presentation: !!props.presentation ? props.presentation : null,
      useTranslation: props.useTranslation !== false
    };
  }

  componentWillReceiveProps(props) {
    let objectToUpdate = {};

    if (props.hasOwnProperty("isValid")) objectToUpdate.isValid = props.isValid;

    if (props.hasOwnProperty("canSave")) objectToUpdate.canSave = props.canSave;

    this.setState(objectToUpdate);
  }

  toggleEdit = () => {
    this.setState({
      isEditing: !this.state.isEditing
    });
  };

  handleChange = event => {
    const isValid = this.validate(event.target.value);

    this.setState({
      value: event.target.value,
      isValid,
      canSave: this.calculateCanSave(isValid)
    });

    if (this.state.isChangeProvided) this.props.change(event.target.value);
  };

  validate = value => {
    if (!!this.props.validation) return this.props.validation(value);

    return true;
  };

  calculateCanSave = isValid => {
    return isValid === true;
  };

  handleCancel = () => {
    if (this.state.isCancelProvided) this.props.cancel();

    this.setState({
      value: this.props.value,
      isEditing: false
    });
  };

  handleSave = () => {
    if (this.state.canSave) {
      if (this.state.isSaveProvided) this.props.save(this.state.value);

      this.setState({
        isEditing: false
      });
    }
  };

  render() {
    const inputGroupButton = (
      <InputGroup.Append>
        {this.state.showSaveButton &&
          this.state.isSaveProvided && (
            <DescribedButton
              description={
                this.state.canSave
                  ? "FormData.saveButtonDescriptionCanSave"
                  : "FormData.saveButtonDescriptionCantSave"
              }
              descriptionTheme={
                !!this.props.tooltipClassName ? this.props.tooltipClassName : ""
              }
              onClick={this.handleSave}
              disabled={!this.state.canSave}
              buttonClassName="FormData__save"
              glyphClassName="FormData__save-icon"
              glyph="done"
              size="sm"
              placement={this.props.placement}
            />
          )}
        {this.state.showCancelButton && (
          <DescribedButton
            description="FormData.cancelButtonDescription"
            descriptionTheme={
              !!this.props.tooltipClassName ? this.props.tooltipClassName : ""
            }
            onClick={this.handleCancel}
            buttonClassName="FormData__cancel"
            glyphClassName="FormData__cancel-icon"
            glyph="close"
            size="sm"
            placement={this.props.placement}
          />
        )}
        {this.state.areAdditionalButtons &&
          this.props.additionalButtons.map(button => {
            return button;
          })}
      </InputGroup.Append>
    );

    return this.state.isEditing ? (
      <Form.Group {...getFormGroupProps(this.props, this.state)}>
        {this.props.as === "textarea" ? (
          <div>
            <Form.Control
              {...getInputProps(this.props, this.state)}
              onChange={this.handleChange}
            />
            <div className="FormData__textarea-buttons-container">
              {!!this.props.maxLength && (
                <section className="FormData__textarea-counter">
                  {this.state.value.length} / {this.props.maxLength}
                </section>
              )}
              <section className="FormData__textarea-buttons">
                {inputGroupButton}
              </section>
            </div>
          </div>
        ) : (
          <InputGroup>
            {this.state.areAddonsBefore && (
              <InputGroup.Prepend className="FormData__addon">
                {this.props.addonsBefore.map(addon => {
                  return this.state.useTranslation ? this.t(addon) : addon;
                })}
              </InputGroup.Prepend>
            )}
            <Form.Control
              {...getInputProps(this.props, this.state)}
              onChange={this.handleChange}
            />
            {this.state.areAddonsAfter && (
              <InputGroup.Append className="FormData__addon">
                {this.props.addonsAfter.map(addon => {
                  return this.state.useTranslation ? this.t(addon) : addon;
                })}
              </InputGroup.Append>
            )}
            {inputGroupButton}
          </InputGroup>
        )}
      </Form.Group>
    ) : this.state.canEdit ? (
      <DescribedComponent
        descriptionTheme={
          !!this.props.tooltipClassName ? this.props.tooltipClassName : ""
        }
        description="FormData.uneditedValueDescription"
        useTranslation={true}
        placement={this.props.placement}
      >
        <div onClick={this.toggleEdit} className="FormData__value--logged">
          {this.state.value === ""
            ? this.state.empty === "null"
              ? this.t("common:null")
              : this.t("common:emptyInBrackets")
            : presentationValue(this.state.value, this.state.presentation)}
        </div>
      </DescribedComponent>
    ) : (
      <div className="FormData__value">
        {this.state.value === ""
          ? this.state.empty === "null"
            ? this.t("common:null")
            : this.t("common:emptyInBrackets")
          : presentationValue(this.state.value, this.state.presentation)}
      </div>
    );
  }
}

export default translate("frontend")(FormData);
