import React, { Component } from "react";
import InfoSectionUnitTypeTreeWrapper from "../../containers/InfoSectionUnitTypeTreeWrapper/index";
import { getGlyphName } from "./functions";
import { DescribedButton } from "react-bootstrap-tools";
import { translate } from "react-i18next";
import "./style.scss";
import { getUnitTypeNameLabel } from "../../containers/App/functions";

class InfoSectionUnitTypeTreeItemComponent extends Component {
  constructor(props) {
    super(props);

    this.t = props.t;

    this.state = {
      childrenHiding: false,
      collapsed: true,
      selected: props.selectedId === props.unitType.id
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      selected: props.selectedId === props.unitType.id
    });
  }

  toggleUnitTypeSelection = id => {
    this.props.handleUnitTypeSelect(id);
  };

  toggleChildrenCollapse = () => {
    if (this.state.collapsed) {
      this.setState({
        childrenHiding: false,
        collapsed: !this.state.collapsed
      });
    } else {
      this.setState({
        childrenHiding: true
      });
    }
  };

  handleChildrenCollapsed = () => {
    this.setState({
      collapsed: true,
      childrenHiding: false
    });
  };

  getUnitTypeNameLabel = (unitTypes, id) => {
    return this.t(getUnitTypeNameLabel(unitTypes, id));
  };

  handleIconClick = () => {
    this.props.hasChildren
      ? this.toggleChildrenCollapse()
      : this.toggleUnitTypeSelection(this.props.unitType.id);
  };

  render() {
    let unitTypeComponents = [];

    unitTypeComponents.push(
      <div className="InfoSectionUnitTypeTreeItem">
        <DescribedButton
          icon={getGlyphName(
            this.props.hasChildren,
            this.state.collapsed,
            this.state.selected
          )}
          iconClassName="InfoSectionUnitTypeTreeItem__name-icon"
          buttonClassName="InfoSectionUnitTypeTreeItem__name"
          text={this.getUnitTypeNameLabel(
            this.props.unitTypes,
            this.props.unitType.id
          )}
          onClick={this.handleIconClick}
        />
      </div>
    );

    if (!this.state.collapsed)
      unitTypeComponents.push(
        <InfoSectionUnitTypeTreeWrapper
          key={this.props.unitType.id + "wrapper"}
          parentId={this.props.unitType.id}
          level={this.props.level + 1}
          hide={this.state.childrenHiding}
          toggleSectionCollapse={this.handleChildrenCollapsed}
          handleUnitTypeSelect={this.props.handleUnitTypeSelect}
          selectedId={this.props.selectedId}
        />
      );

    return <div>{unitTypeComponents}</div>;
  }
}

export default translate("frontend")(InfoSectionUnitTypeTreeItemComponent);
