import React from "react";

import "./style.scss";
import { getModalId } from "../../containers/ModalGroupWrapper/functions";
import { DescribedButton } from "react-bootstrap-tools";
import { translate } from "react-i18next";
import { getClassName } from "./functions";

export const AddButtonComponent = props => {
  const { t } = props;
  const description = t("AddButton.buttonDescription");

  const handleButtonClick = () => {
    if (props.userUuid) {
      if (
        getModalId("unitInfo", props.modalSection) < 0 &&
        getModalId("unitTypes", props.modalSection) < 0
      )
        invokeAddPoint();
    } else {
      props.invokeInfo({
        variant: "warning",
        message: "AddButton.clickAlert"
      });
    }
  };

  const invokeAddPoint = () => {
    props.showModalContent({
      type: "unitTypes",
      conf: {
        hide: true,
        useRelativePosition: {
          x: window.innerWidth / 2,
          y: window.innerHeight / 2
        },
        reloadContent: true,
        isAddition: true,
        isAdditionPath: true,
        id: 0,
        lat: 0,
        lon: 0,
        unitTypeId: null
      }
    });
    props.openPositionMarker({
      autoCenter: true
    });
  };

  if (props.bottomSectionSizeMode !== "full")
    return (
      <section className={getClassName(props.bottomSectionSizeMode)}>
        <DescribedButton
          description={description}
          icon="add_location"
          // for tracking purposes
          buttonClassName="AddButton__button"
          iconClassName="AddButton__button-icon"
          onClick={handleButtonClick}
        />
      </section>
    );

  return null;
};

export default translate("frontend")(AddButtonComponent);
