import React, { Component } from "react";

import "./style.scss";
import { calculateIsUserSubscribed } from "./functions";
import Config from "../../containers/App/config";
import FilterSorter from "phyzzi.com-frontend-filter-sorter";
import InfoSectionUsersListWrapper from "../../containers/InfoSectionUsersListWrapper/index";
import {
  getUnitTypeIcon,
  getUnitTypeStringId
} from "../../containers/App/functions";
import InfoSectionUsersFilterSorter from "../InfoSectionUsersFilterSorter/index";
import { translate } from "react-i18next";
import { getMapRangedZoomLevel } from "../../utils/helpers";
import { unifyDate } from "phyzzi.com-frontend-utils";
import { PhyzziBrandLoader } from "phyzzi.com-frontend-utils";
import "phyzzi.com-frontend-utils/build/static/css/index.css";
import { handleFetchUnitData } from "../InfoSectionInfo/fetchUtils";
import {
  handleFetchPrivateData,
  handleFetchUnitUsers,
  handleFetchUserAdd,
  handleFetchUserDelete
} from "./fetchUtils";
import UnitAgentButtonWrapper from "../../containers/UnitAgentButtonWrapper";

export class InfoSectionUsersComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showLoadingIcon: true,
      isUserSubscribed: false,
      content: {}
    };

    this.fetchApiUrl = Config.url.api.pointUserResource.replace(
      "_POINT_ID_",
      encodeURIComponent(props.id)
    );
  }

  componentDidMount() {
    this.handleFetchUnitUsers();
    if (!this.props.isSelectedUnit)
      handleFetchUnitData({
        id: this.props.id,
        token: this.props.token,
        invokeInfo: this.props.invokeInfo,
        handleFetchUnitDataSuccess: this.handleFetchUnitDataSuccess
      });
    if (this.props.user.uuid) this.handleFetchPrivateData();
  }

  handleFetchUnitUsers = () => {
    handleFetchUnitUsers({
      id: this.props.id,
      token: this.props.token,
      injectUnitUsersData: this.injectUnitUsersData,
      invokeInfo: this.props.invokeInfo
    });
  };

  injectUnitUsersData = json => {
    this.setState({
      showLoadingIcon: false,
      isUserSubscribed: calculateIsUserSubscribed(json, this.props.user.uuid),
      content: json.map(user => {
        return {
          ...user,
          namesurname: user.surname + " " + user.name,
          created_at_timestamp: unifyDate(user.created_at).unix()
        };
      })
    });
  };

  handleFetchPrivateData = () => {
    handleFetchPrivateData({
      token: this.props.token,
      handleFetchPrivateDataSuccess: this.handleFetchPrivateDataSuccess,
      invokeInfo: this.props.invokeInfo
    });
  };

  handleFetchUnitDataSuccess = json => {
    if (json && typeof json === "object" && !Array.isArray(json)) {
      this.props.selectUnit({
        icon: getUnitTypeIcon(this.props.unitTypes, json.unit_type_id),
        lat: json.lat / 1000000,
        lon: json.lon / 1000000,
        id: this.props.id,
        name: json.name
      });

      this.props.changeViewport({
        lat: json.lat / 1000000,
        lon: json.lon / 1000000,
        zoom: getMapRangedZoomLevel(11)
      });
    }
  };

  handleFetchPrivateDataSuccess = json => {
    this.props.receivePrivateData(json);
  };

  handleAddClick = () => {
    if (this.props.user.uuid) {
      if (this.canUserSubscribe()) {
        this.handleUserAdd();
      } else {
        this.props.invokeInfo({
          variant: "warning",
          message: "InfoSectionUsers.addFailAlertLowAccountType"
        });
      }
    } else {
      this.props.invokeInfo({
        variant: "warning",
        message: "InfoSectionUsers.addFailAlertSignIn"
      });
    }
  };

  handleUserAdd = () => {
    handleFetchUserAdd({
      id: this.props.id,
      token: this.props.token,
      body: { uuid: this.props.user.uuid },
      handleReload: this.handleReload,
      invokeInfo: this.props.invokeInfo
    });
  };

  handleDeleteClick = () => {
    this.handleUserDelete();
  };

  handleUserDelete = () => {
    handleFetchUserDelete({
      id: this.props.id,
      userUuid: this.props.user.uuid,
      token: this.props.token,
      handleReload: this.handleReload,
      invokeInfo: this.props.invokeInfo
    });
  };

  handleReload = () => {
    this.handleFetchUnitUsers();
    if (this.props.user.uuid) this.handleFetchPrivateData();
  };

  canUserSubscribe = () => {
    return (
      !!this.props.user.uuid &&
      this.props.user.unitSubscriptionsNumber <
        this.props.user.accountType.max_subscriptions
    );
  };

  render() {
    const { t } = this.props;

    return (
      <section className="InfoSectionUsers">
        <UnitAgentButtonWrapper unitName={this.props.unitName} />
        {this.state.showLoadingIcon ? (
          <PhyzziBrandLoader />
        ) : (
          <div
            className="InfoSectionUsers__wrapper"
            // for tracking purposes
            id="UnitInfoUsers"
            // for tracking purposes
            trackinglabel={
              !!this.props.unitTypeId
                ? getUnitTypeStringId(
                    this.props.unitTypes,
                    this.props.unitTypeId
                  )
                : ""
            }
          >
            <div className="InfoSectionUsers__table">
              <FilterSorter
                content={this.state.content}
                component={<table />}
                filterValue=""
                sorters={[
                  {
                    field: "namesurname",
                    label: t("InfoSectionUsers.columnUser")
                  },
                  {
                    field: "created_at_timestamp",
                    label: t("InfoSectionUsers.columnDate"),
                    selected: "desc"
                  }
                ]}
                filters={["name", "surname", "position", "location"]}
                allwaysTopRule={{
                  key: "uuid",
                  value: this.props.user.uuid || null
                }}
              >
                <InfoSectionUsersFilterSorter
                  componentRole="filterSorter"
                  placeholder={t("InfoSectionUsers.formFilterPlaceholder")}
                />
                <InfoSectionUsersListWrapper
                  componentRole="content"
                  isUserSubscribed={this.state.isUserSubscribed}
                  canUserSubscribe={this.canUserSubscribe()}
                  handleAddClick={this.handleAddClick}
                  handleDeleteClick={this.handleDeleteClick}
                />
              </FilterSorter>
            </div>
          </div>
        )}
      </section>
    );
  }
}

export default translate("frontend")(InfoSectionUsersComponent);
