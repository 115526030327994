import { connect } from "react-redux";

import UserSettings from "../../components/UserSettings";
import { invokeInfo } from "../AlertBoxWrapper/actions";
import { activateCaptcha } from "../CaptchaWrapper/actions";

const mapStateToProps = state => ({
  token: state.initData.token,
  theme: state.theme
});

const mapDispatchToProps = {
  invokeInfo,
  activateCaptcha
};

const UserSettingsWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserSettings);

export default UserSettingsWrapper;
