import React, { Component } from "react";

import "./style.scss";
import InfoSectionInfoButtonsWrapper from "../../containers/InfoSectionInfoButtonsWrapper/index";
import InfoSectionInfoDataWrapper from "../../containers/InfoSectionInfoDataWrapper/index";
import {
  getUnitTypeIcon,
  getUnitTypeStringId
} from "../../containers/App/functions";
import {
  handleAddUnit,
  handleDeleteUnit,
  handleFetchUnitData,
  handleSaveUnitData
} from "./fetchUtils";
import { PhyzziBrandLoader } from "phyzzi.com-frontend-utils";
import "phyzzi.com-frontend-utils/build/static/css/index.css";
import { getMapRangedZoomLevel } from "../../utils/helpers";
import UnitAgentButtonWrapper from "../../containers/UnitAgentButtonWrapper";

class InfoSectionInfo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      content: {},
      canAdd: false
    };
  }

  componentDidMount() {
    (Object.keys(this.state.content).length === 0 ||
      this.props.isAdditionPath ||
      this.props.reloadContent) &&
      this.fetchUnitData(this.props.id);

    this.props.isAdditionPath &&
      this.props.setModalConf(this.props.modalId, { isAdditionPath: false });
  }

  componentWillReceiveProps(props) {
    props.reloadContent && this.fetchUnitData(props.id);
  }

  fetchUnitData = id => {
    this.setState({
      content: {}
    });

    handleFetchUnitData({
      id,
      token: this.props.token,
      invokeInfo: this.props.invokeInfo,
      handleFetchUnitDataSuccess: this.handleFetchUnitDataSuccess
    });
  };

  handleFetchUnitDataSuccess = json => {
    if (json && typeof json === "object" && !Array.isArray(json)) {
      let content = {
        ...json,
        lat: json.lat / 1000000,
        lon: json.lon / 1000000
      };

      if (this.props.id === 0) {
        content.unit_type_id = this.props.unitTypeId;
        content.lat = this.props.lat;
        content.lon = this.props.lon;
      }
      this.setState({
        content
      });

      if (!this.props.isSelectedUnit) {
        this.props.selectUnit({
          icon: getUnitTypeIcon(this.props.unitTypes, content.unit_type_id),
          lat: content.lat,
          lon: content.lon,
          id: this.props.id,
          name: json.name
        });

        this.props.changeViewport({
          lat: content.lat,
          lon: content.lon,
          zoom: getMapRangedZoomLevel(11)
        });
      }
    }
  };

  handleReloadData = content => {
    this.setState({
      content: {
        ...this.state.content,
        ...content
      }
    });
  };

  handleAdd = () => {
    handleAddUnit({
      data: this.state.content,
      modalId: this.props.modalId,
      token: this.props.token,
      invokeInfo: this.props.invokeInfo,
      stackUserLastChange: this.props.stackUserLastChange,
      closeModalSection: this.props.closeModalSection
    });
  };

  handleSaveData = data => {
    handleSaveUnitData({
      id: this.props.id,
      data,
      token: this.props.token,
      invokeInfo: this.props.invokeInfo,
      stackUserLastChange: this.props.stackUserLastChange,
      handleFetchUnitData: this.fetchUnitData
    });
  };

  handleDelete = () => {
    handleDeleteUnit({
      id: this.props.id,
      modalId: this.props.modalId,
      token: this.props.token,
      invokeInfo: this.props.invokeInfo,
      stackUserLastChange: this.props.stackUserLastChange,
      closeModalSection: this.props.closeModalSection
    });
  };

  handleCanAdd = canAdd => {
    this.setState({
      canAdd: canAdd
    });
  };

  render() {
    return (
      <section className="InfoSectionInfo">
        <UnitAgentButtonWrapper unitName={this.props.unitName} />
        {Object.keys(this.state.content).length === 0 ? (
          <PhyzziBrandLoader />
        ) : (
          <table
            className="InfoSectionInfo__table"
            // for tracking purposes
            id="UnitInfo"
            // for tracking purposes
            trackinglabel={getUnitTypeStringId(
              this.props.unitTypes,
              this.state.content.unit_type_id
            )}
          >
            <InfoSectionInfoDataWrapper
              modalId={this.props.modalId}
              content={this.state.content}
              handleCanAdd={this.handleCanAdd}
              handleReloadData={this.handleReloadData}
              handleSaveData={this.handleSaveData}
            />
            {!!this.props.user.uuid && (
              <InfoSectionInfoButtonsWrapper
                modalId={this.props.modalId}
                unitId={this.props.id}
                content={this.state.content}
                canAdd={this.state.canAdd}
                handleAddUnit={this.handleAdd}
                handleDeleteUnit={this.handleDelete}
              />
            )}
          </table>
        )}
      </section>
    );
  }
}

export default InfoSectionInfo;
