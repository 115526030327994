import React, { Component } from "react";

import "./style.scss";
import MessengerList from "../MessengerList/index";
import MessengerTopic from "../MessengerTopic/index";
import { depracated_handleFetch } from "phyzzi.com-frontend-utils";
import config from "../../containers/App/config";
import {
  calculateShouldCallUnread,
  getEmptyTopicStructure,
  getInterlocutor,
  getTrackingLabel
} from "./functions";
import MessengerNewMessage from "../MessengerNewMessage/index";
import { unifyDate } from "phyzzi.com-frontend-utils";
import { calculateUnreadMessagesAmount } from "../MessageButton/functions";

class Messenger extends Component {
  constructor(props) {
    super(props);

    this.componentTrackingLabel = getTrackingLabel(
      this.props.userUuid || null,
      this.props.topicId || null
    );

    this.state = {
      topics: [],
      selectedTopicId: null,

      // After older messages appeared, the content's top should be at previously oldest message.
      // This property helps to identify this message.
      oldestMessageId: null,

      // Flag indicating if topic's content needs to be scrolled to the bottom (latest message)
      // Need when user revisits the topic with many messages
      scrollToBottom: false,

      // Timestamp of last sent new message. Helps to reset new message component
      newMessageSentTimestamp: 0,

      // Timestamp of last fetch of older messages. Helps to reinitialise triggering of next fetching.
      fetchOlderMessagesTimestamp: 0,

      // Flag indicating fetching list of topics (then scan icon should appear)
      isFetchingTopics: true
    };
  }

  componentDidMount() {
    this.handleFetchList();

    this.fetchListInterval = setInterval(
      this.handleFetchList,
      config.messenger.fetchMessagesListTimeout
    );
  }

  componentWillUnmount() {
    clearInterval(this.fetchListInterval);
  }

  handleFetchList = () => {
    depracated_handleFetch(
      {
        url: config.url.api.topicResource + "/list/" + this.props.user.uuid,
        token: this.props.token
      },
      {
        success: json =>
          this.handleFetchListSuccess(json, this.props.user.uuid),
        error: () => this.handleFetchListFail()
      }
    );
  };

  handleFetchListSuccess = json => {
    const givenTopicId =
      (!!this.state.selectedTopicId &&
        this.state.topics[this.state.selectedTopicId].id) ||
      this.props.topicId;
    let isSelectedTopicStarted = false;
    let selectedTopicId = null;

    // Merged state and json topics
    const topics = [
      ...this.state.topics.map(stateTopic => {
        const topicFromJson = json.find(
          jsonTopic => jsonTopic.id === stateTopic.id
        );
        if (!topicFromJson) {
          return stateTopic;
        }
        return {
          ...stateTopic,
          messengerParticipants: topicFromJson.messengerParticipants,
        };
      }),
      ...json.filter(jsonTopic => {
        return (
          this.state.topics.find(
            stateTopic => stateTopic.id === jsonTopic.id
          ) === undefined
        );
      })
    ]
      .sort((a, b) => {
        const aLastMessage = a.messengerMessages.data[a.messengerMessages.data.length - 1];
        const bLastMessage = b.messengerMessages.data[b.messengerMessages.data.length - 1];
        return unifyDate(aLastMessage.updated_at).isBefore(unifyDate(bLastMessage.updated_at))
          ? 1
          : -1;
      })
      .map((topic, idx) => {
        if (
          givenTopicId === topic.id ||
          topic.messengerParticipants.some(participant => {
            return (
              !!participant.user &&
              participant.user.uuid === this.props.userUuid
            );
          })
        ) {
          isSelectedTopicStarted = true;
          selectedTopicId = idx;
        }

        const interlocutor = getInterlocutor(topic, this.props.user.uuid);
        if (!!interlocutor.user) {
          topic.interlocutor_uuid = interlocutor.user.uuid;
          topic.interlocutor_name = interlocutor.user.name;
          topic.interlocutor_surname = interlocutor.user.surname;
        } else {
          topic.interlocutor_uuid = "";
          topic.interlocutor_name = "";
          topic.interlocutor_surname = "";
        }

        return topic;
      });

    if (!isSelectedTopicStarted && !!this.props.userUuid)
      this.handleFetchUser(this.props.userUuid);

    // If messenger window is with no selected user, then autoselect first topic
    if (!this.props.userUuid && !givenTopicId) selectedTopicId = 0;

    const messagesAmount = calculateUnreadMessagesAmount(
      topics,
      this.props.user.uuid
    );
    this.props.setMessagesAmount(messagesAmount);

    const { shouldCallUnread, participantId } = calculateShouldCallUnread(
      topics[selectedTopicId],
      this.props.user.uuid
    );
    if (shouldCallUnread) this.handleSetHasRead(participantId);

    this.setState({
      topics,
      selectedTopicId,
      isFetchingTopics: false
    });
  };

  handleFetchListFail = () => {
    this.props.invokeInfo({
      variant: "danger",
      message: "Messenger.fetchListFailAlert"
    });

    this.setState({ isFetchingTopics: false });
  };

  handleSetHasRead = id => {
    depracated_handleFetch(
      {
        url: config.url.api.participantResource + "/" + id + "/hasRead",
        token: this.props.token,
        method: "PUT"
      },
      {
        success: () => this.handleSetHasReadSuccess(id),
        error: () => this.handleSetHasReadSuccess(id)
      }
    );
  };

  handleSetHasReadSuccess = id => {
    const topics = this.state.topics.map(topic => {
      return {
        ...topic,
        messengerParticipants: topic.messengerParticipants.map(participant => {
          return {
            ...participant,
            is_unread: participant.id === id ? false : participant.is_unread
          };
        })
      };
    });

    const unreadMessagesAmount = calculateUnreadMessagesAmount(
      topics,
      this.props.user.uuid
    );
    this.props.setMessagesAmount(unreadMessagesAmount);
    this.setState({ topics });
  };

  handleFetchUser = userUuid => {
    depracated_handleFetch(
      {
        url: config.url.api.userResource + "/" + userUuid,
        token: this.props.token
      },
      {
        success: json => this.handleFetchUserSuccess(json, userUuid),
        error: () => this.handleFetchUserFail()
      }
    );
  };

  handleFetchUserSuccess = (json, userUuid) => {
    this.setState({
      topics: [
        ...this.state.topics,
        getEmptyTopicStructure(this.props.user, userUuid, json)
      ],
      selectedTopicId: this.state.topics.length
    });
  };

  handleFetchUserFail = () => {
    this.props.invokeInfo({
      variant: "danger",
      message: "Messenger.fetchNewTopicFailAlert"
    });
  };

  onTopicListClick = topicId => {
    const selectedTopicId = this.state.topics.findIndex(
      topic => topic.id === topicId
    );

    const { shouldCallUnread, participantId } = calculateShouldCallUnread(
      this.state.topics[selectedTopicId],
      this.props.user.uuid
    );
    if (shouldCallUnread) this.handleSetHasRead(participantId);

    const oldestMessageId =
      this.state.topics[selectedTopicId].messengerMessages.data.length > 0
        ? this.state.topics[selectedTopicId].messengerMessages.data[0].id
        : null;

    this.setState({
      selectedTopicId,
      scrollToBottom: true,
      oldestMessageId
    });
  };

  handleLoadMessages = (topicId, fetchOlder) => {
    if (!!topicId) {
      this.handleFetchMessages(topicId, fetchOlder);

      if (fetchOlder)
        this.setState({
          oldestMessageId: this.state.topics.find(topic => topic.id === topicId)
            .messengerMessages.data[0].id
        });
    }
  };

  handleFetchMessages = (topicId, fetchOlder) => {
    const topic = this.state.topics.find(topic => topic.id === topicId);

    const oldestMessageDate = unifyDate(
      topic.messengerMessages.data[0].updated_at
    ).format("X");

    depracated_handleFetch(
      {
        url:
          config.url.api.topicResource +
          "/" +
          topicId +
          "/messages/" +
          (fetchOlder ? oldestMessageDate : "0"),
        token: this.props.token
      },
      {
        success: json =>
          this.handleFetchMessagesSuccess(json, topicId, fetchOlder),
        error: () => this.handleFetchMessagesFail()
      }
    );
  };

  handleFetchMessagesSuccess = (json, topicId, fetchOlder) => {
    const newState = {
      topics: this.state.topics.map(topic => {
        if (topic.id !== topicId) return topic;

        const actualMessageIds = topic.messengerMessages.data.map(
          message => message.id
        );
        const messagesToAdd = json.data.filter(
          message => actualMessageIds.indexOf(message.id) < 0
        );

        if (messagesToAdd.length === 0) return topic;

        return {
          ...topic,
          messengerMessages: {
            data: [...topic.messengerMessages.data, ...messagesToAdd].sort(
              (a, b) =>
                unifyDate(b.updated_at).isBefore(unifyDate(a.updated_at))
                  ? 1
                  : -1
            ),
            more_messages: fetchOlder
              ? json.more_messages
              : topic.messengerMessages.more_messages
          }
        };
      })
    };

    if (fetchOlder) newState.fetchOlderMessagesTimestamp = new Date().getTime();

    this.setState(newState);
  };

  handleFetchMessagesFail = () => {
    this.setState({
      fetchOlderMessagesTimestamp: new Date().getTime()
    });

    this.props.invokeInfo({
      variant: "danger",
      message: "Messenger.fetchMessagesFailAlert"
    });
  };

  handleSendMessage = data => {
    const url =
      config.url.api.topicResource +
      (!!data.id_messenger_topic ? "/" + data.id_messenger_topic : "");
    const method = !!data.id_messenger_topic ? "PUT" : "POST";
    let body = {
      content: data.content
    };
    if (!data.id_messenger_topic)
      body.interlocutor_uuid = data.interlocutor_uuid;

    depracated_handleFetch(
      {
        url,
        token: this.props.token,
        method,
        body
      },
      {
        success: () => this.handleSendMessageSuccess(data.id_messenger_topic),
        error: () => this.handleSendMessageFail(data.id_messenger_topic)
      }
    );
  };

  handleSendMessageSuccess = topicId => {
    this.setState({
      newMessageSentTimestamp: new Date().getTime()
    });

    if (!!topicId) {
      this.handleFetchMessages(topicId, false);
    } else {
      this.handleFetchList();
    }
  };

  handleSendMessageFail = topicId => {
    this.props.invokeInfo({
      variant: "danger",
      message: "Messenger.sendMessageFailAlert"
    });

    this.setState({
      newMessageSentTimestamp: new Date().getTime()
    });

    if (!!topicId) {
      this.handleFetchMessages(topicId, false);
    } else {
      this.handleFetchList();
    }
  };

  handleScrollToBottomSwitchOn = () => {
    this.setState({
      scrollToBottom: true
    });
  };

  handleScrollToBottomSwitchOff = () => {
    this.setState({
      scrollToBottom: false
    });
  };

  handleInterlocutorClick = uuid => {
    this.props.showModalContent({
      type: "userProfile",
      conf: {
        userId: uuid
      }
    });
    // if (!!this.props.onInterlocutorClick) this.props.onInterlocutorClick(uuid);
  };

  render() {
    return (
      <div
        className="Messenger"
        // for tracking purposes
        id="Messenger"
        // for tracking purposes
        trackinglabel={this.componentTrackingLabel}
      >
        <MessengerList
          author={this.props.user}
          topics={this.state.topics}
          selectedTopicId={this.state.selectedTopicId}
          onTopicListClick={this.onTopicListClick}
          isFetchingTopics={this.state.isFetchingTopics}
        />
        <div className="Messenger__right-content">
          <MessengerTopic
            key={
              !!this.state.topics[this.state.selectedTopicId]
                ? this.state.topics[this.state.selectedTopicId].id
                : null
            }
            topic={this.state.topics[this.state.selectedTopicId]}
            author={this.props.user}
            handleLoadMessages={this.handleLoadMessages}
            oldestMessageId={this.state.oldestMessageId}
            scrollToBottom={this.state.scrollToBottom}
            switchOnScrollToBottom={this.handleScrollToBottomSwitchOn}
            switchOffScrollToBottom={this.handleScrollToBottomSwitchOff}
            handleInterlocutorClick={this.handleInterlocutorClick}
            fetchOlderMessagesTimestamp={this.state.fetchOlderMessagesTimestamp}
          />
          <MessengerNewMessage
            topic={this.state.topics[this.state.selectedTopicId]}
            author={this.props.user}
            onSendClick={this.handleSendMessage}
            newMessageSentTimestamp={this.state.newMessageSentTimestamp}
          />
        </div>
      </div>
    );
  }
}

export default Messenger;
