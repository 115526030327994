import config from "../../containers/App/config";

export const calculateValidationState = (fieldId, content) => {
  // Non empty values
  if (["name", "ownership"].indexOf(fieldId) > -1 && content.length === 0)
    return false;

  // Allowed signs
  if (
    ["name"].indexOf(fieldId) > -1 &&
    content.length > 0 &&
    !config.regex.allowedSigns.test(content)
  )
    return false;

  // Digits
  if (
    ["ownership"].indexOf(fieldId) > -1 &&
    content.length > 0 &&
    !config.regex.floats.test(content)
  )
    return false;

  // Between 0 and 100
  if (
    ["ownership"].indexOf(fieldId) > -1 &&
    content.length > 0 &&
    (parseFloat(content, 10) <= 0 || parseFloat(content, 10) > 100)
  )
    return false;

  // Only two signs after comma
  if (
    ["ownership"].indexOf(fieldId) > -1 &&
    content.length > 0 &&
    content.indexOf(".") > -1 &&
    content.length - content.indexOf(".") > 3
  )
    return false;

  return true;
};

export const calculateOwnershipFormValidation = (
  ownershipId,
  validations,
  fieldId
) => {
  const validation = validations["_" + ownershipId] || null;

  if (!!validation) {
    if (fieldId === "ownership" && !!validation.ownershipExceeded)
      return validation.ownershipExceeded;
    if (!!validation[fieldId]) return validation[fieldId];
  }

  return null;
};

export const sortOwnerships = ownerships => {
  return ownerships.sort((a, b) => {
    const aOwnership = parseFloat(a.ownership);
    const bOwnership = parseFloat(b.ownership);

    return aOwnership === bOwnership
      ? a.name > b.name
        ? 1
        : -1
      : aOwnership > bOwnership
        ? -1
        : 1;
  });
};
