export const getOverlayProps = (props, config, tooltipComponent) => {
  let overlayProps = {
    placement: !!props.placement
      ? props.placement
      : config.defaultTooltipPlacement,
    overlay: tooltipComponent
  };

  if (!!props.delay) overlayProps.delay = props.delay;

  return overlayProps;
};

export const getTooltipProps = (props, config) => {
  return {
    id: config.defaultTooltipId,
    className:
      config.defaultTooltipClassName +
      (!!props.descriptionTheme ? "--" + props.descriptionTheme : "")
  };
};
