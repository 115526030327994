import React from "react";

import "./style.scss";

const MapAttribution = () => {
  return (
    <section className="MapAttribution">
      <div>
        <a
          href="http://leafletjs.com"
          title="A JS library for interactive maps"
        >
          Leaflet
        </a>{" "}
        | © <a href="http://www.openstreetmap.org/copyright">OpenStreetMap</a>
      </div>
    </section>
  );
};

export default MapAttribution;
