/**
 * Returns props for image icon presented on Unit typ tree menu
 *
 * @param icons
 * @param iconConfig
 * @param mapType
 * @returns {{className: string, src: string, width: integer, height: integer}}
 */
export const getIconProps = (icons, iconConfig, mapType) => {
  const iconTypes = icons.split(":");
  const icon =
    mapType === "satellite" && !!iconTypes[1] ? iconTypes[1] : iconTypes[0];
  const iconData = icon.split("_");

  return {
    className: "UnitTypeLayer__map-icon",
    src: iconConfig.iconUrl.replace("_ICON_NAME_", icon),
    width: iconConfig.unitTypeTree.iconSize[iconData[0]][0],
    height: iconConfig.unitTypeTree.iconSize[iconData[0]][1]
  };
};
