import React from "react";

import "./style.scss";
import { Filter } from "phyzzi.com-frontend-filter-sorter";
import { Sorter } from "phyzzi.com-frontend-filter-sorter";
import { translate } from "react-i18next";
import { config } from "../InfoSectionBoard/config";

export const InfoSectionBoardFilterSorterComponent = props => {
  const { t } = props;

  return (
    <thead className="InfoSectionBoardFilterSorter">
      <tr>
        <th colSpan={8}>
          <div className="InfoSectionBoardFilterSorter__filter-wrapper">
            <Filter
              onChange={props.handleFilterChange}
              maxLength={config.filter.filterMaxLength}
              value={props.filterValue}
              transformedItemsNumber={props.transformedItemsNumber}
              originalItemsNumber={props.originalItemsNumber}
              className="InfoSectionBoardFilterSorter__filter"
              placeholder={!!props.placeholder && props.placeholder}
            />
          </div>
        </th>
      </tr>
      <tr>
        <th className="InfoSectionBoardFilterSorter__sort-cell" />
        {props.sorters.map(sorter => {
          return (
            <th
              key={sorter.field}
              className="InfoSectionBoardFilterSorter__sort-cell"
            >
              <Sorter
                key={sorter.field}
                sorter={sorter}
                onClick={props.handleSorterClick}
              />
            </th>
          );
        })}
        <th className="InfoSectionBoardFilterSorter__sort-cell">
          {t("InfoSectionBoard.columnGroup")}
        </th>
        <th className="InfoSectionBoardFilterSorter__sort-cell">
          {t("InfoSectionBoard.columnKeyword")}
        </th>
        <th className="InfoSectionBoardFilterSorter__sort-cell">
          {t("InfoSectionBoard.columnDescription")}
        </th>
        <th className="InfoSectionBoardFilterSorter__sort-cell" />
      </tr>
    </thead>
  );
};

export default translate("frontend")(InfoSectionBoardFilterSorterComponent);
