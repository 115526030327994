import { connect } from "react-redux";

import UnitTypeTreeButtons from "../../components/UnitTypeTreeButtons";
import { clearAllUnitTypeFilters } from "../UnitTypeTreeWrapper/actions";

const mapStateToProps = state => ({
  theme: state.theme
});

const mapDispatchToProps = {
  clearAllUnitTypeFilters
};

const UnitTypeTreeButtonsWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnitTypeTreeButtons);

export default UnitTypeTreeButtonsWrapper;
