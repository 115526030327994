import { connect } from "react-redux";

import InfoSectionBoardRow from "../../components/InfoSectionBoardRow";
import { showModalContent } from "../ModalGroupWrapper/actions";

const mapStateToProps = state => ({
  user: state.user,
  theme: state.theme
});

const mapDispatchToProps = {
  showModalContent
};

const InfoSectionBoardRowWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoSectionBoardRow);

export default InfoSectionBoardRowWrapper;
