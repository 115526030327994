export const shouldCaptchaAppear = (
  fieldId,
  lastChanges,
  isAdmin,
  captchaConfig
) => {
  if (isAdmin) return false;

  switch (fieldId) {
    case "name":
    case "unit_type_id":
    case "capacity":
    case "built_year":
    case "products_in":
    case "products_out":
    case "accesses":
    case "operator":
    case "owner":
    case "ownerships":
    case "status":
    case "maintenance":
    case "internet":
      let changesNumber = lastChanges.length;
      if (changesNumber < captchaConfig.freeChanges) {
        return false;
      } else {
        let firstChange =
          lastChanges[changesNumber - (captchaConfig.freeChanges + 1)];

        if (
          Math.floor(
            new Date(firstChange).getTime() / 1000 -
              new Date().getTimezoneOffset() * 60
          ) <
          Math.floor(
            new Date().getTime() / 1000 - captchaConfig.timeBuffer * 60 * 60
          )
        ) {
          return false;
        } else {
          return true;
        }
      }
    case "delete":
    case "lat":
    case "lon":
      return true;
    default:
      return true;
  }
};
