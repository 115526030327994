import React, { Component } from "react";

import { getRowLabel } from "./functions";
import FormData from "../FormData/index";
import { Config } from "../ModalSection/config";
import { shouldCaptchaAppear } from "../InfoSectionContent/functions";
import { translate } from "react-i18next";

class InfoSectionInfoRow extends Component {
  handleChange = (fieldId, content) => {
    this.props.handleReloadData({ [fieldId]: content });
  };

  handleSaveClick = (fieldId, content) => {
    shouldCaptchaAppear(
      fieldId,
      this.props.user.lastChanges,
      this.props.user.isAdmin,
      Config.captcha
    )
      ? this.handleShowCaptcha(() => this.handleSave(fieldId, content))
      : this.handleSave(fieldId, content);
  };

  handleSave = (fieldId, content) => {
    this.props.handleSaveData({
      [fieldId]: content
    });
  };

  getFormProps = () => {
    let formProps = {
      value: this.props.content[this.props.fieldId],
      validation: formValue =>
        this.props.handleValidation(this.props.fieldId, formValue),
      tooltipClassName: this.props.theme
    };

    if (this.props.isAddition) {
      formProps.nonSavable = true;
      formProps.nonCancelable = true;
      formProps.change = formValue =>
        this.handleChange(this.props.fieldId, formValue);
      formProps.isEditing = true;
    } else {
      formProps.save = formValue =>
        this.handleSaveClick(this.props.fieldId, formValue);
      formProps.nonEditable = !this.props.userUuid;
    }

    switch (this.props.fieldId) {
      case "capacity":
        formProps.maxLength = 12;
        formProps.presentation = "int";
        break;
      case "built_year":
        formProps.maxLength = 4;
        break;
      case "name":
        formProps.useTranslation = false;
        formProps.placement = "left";
        break;
      default:
        formProps.maxLength = 255;
        break;
    }

    return formProps;
  };

  getAfterFormContent = (fieldId, content) => {
    return fieldId === "capacity" && !!content ? (
      <span
        className="InfoSectionInfoData__capacity-unit"
        dangerouslySetInnerHTML={{ __html: "(" + content + ")" }}
      />
    ) : (
      ""
    );
  };

  handleShowCaptcha = action => {
    this.props.activateCaptcha(action, "Unit info - text");
  };

  render() {
    const { t } = this.props;

    let capacityUnit = null;

    if (this.props.fieldId === "capacity") {
      this.props.unitTypes.forEach(unitType => {
        if (unitType.id === this.props.content.unit_type_id)
          capacityUnit = unitType.capacityUnit;
      });
    }

    return (
      <tr className={!!this.props.rowClassName ? this.props.rowClassName : ""}>
        <td
          className={
            !!this.props.leftColumnClassName
              ? this.props.leftColumnClassName
              : ""
          }
        >
          {t(getRowLabel(this.props.fieldId))}:
        </td>
        <td
          className={
            !!this.props.rightColumnClassName
              ? this.props.rightColumnClassName
              : ""
          }
        >
          <FormData {...this.getFormProps()} />
          {this.getAfterFormContent(this.props.fieldId, capacityUnit)}
        </td>
      </tr>
    );
  }
}

export default translate("frontend")(InfoSectionInfoRow);
