import { connect } from "react-redux";

import InfoSectionInfo from "../../components/InfoSectionInfo";
import { closeModalSection, setModalConf } from "../ModalGroupWrapper/actions";
import { invokeInfo } from "../AlertBoxWrapper/actions";
import { stackUserLastChange } from "../App/actions";
import { changeViewport, selectUnit } from "../MapContainerWrapper/actions";

const mapStateToProps = (state, props) => ({
  id: state.modalSection[props.modalId].conf.id,
  isAdditionPath: state.modalSection[props.modalId].conf.isAdditionPath,
  reloadContent: state.modalSection[props.modalId].conf.reloadContent,
  user: state.user,
  unitTypes: state.unitTypes.data,
  token: state.initData.token,
  lat: state.modalSection[props.modalId].conf.lat || 0,
  lon: state.modalSection[props.modalId].conf.lon || 0,
  unitTypeId: state.modalSection[props.modalId].conf.unitTypeId || null,
  isSelectedUnit: !!state.map.selectedUnit,
  unitName: state.map.selectedUnit ? state.map.selectedUnit.name : null,
});

const mapDispatchToProps = {
  setModalConf,
  invokeInfo,
  stackUserLastChange,
  closeModalSection,
  selectUnit,
  changeViewport
};

const InfoSectionInfoWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoSectionInfo);

export default InfoSectionInfoWrapper;
