import React, { Component } from "react";

import "./style.scss";
import Config from "../../containers/App/config";
import MyPictureWrapper from "../../containers/MyPictureWrapper/index";
import { translate } from "react-i18next";
import { Button, Collapse } from "react-bootstrap";

export class UserBarComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isUserMenuShown: false
    };

    this.mouseClickInside = false;
  }

  componentDidMount() {
    window.addEventListener("mousedown", this.handlePageClick, false);
  }

  componentWillUnmount() {
    window.removeEventListener("mousedown", this.handlePageClick, false);
  }

  handlePageClick = () => {
    if (this.mouseClickInside) {
      return;
    }

    if (this.state.isUserMenuShown) this.handleUserMenuVisibility("hide");
  };

  handleUserMenuVisibility = (action = null) => {
    const calculatedState = ["show", "hide"].includes(action)
      ? action === "show"
        ? true
        : false
      : !this.state.isUserMenuShown;

    this.setState({ isUserMenuShown: calculatedState });
  };

  handleAccountClick = () => {
    this.props.showModalContent({
      type: "myProfile",
      conf: {
        userId: this.props.user.uuid
      }
    });
    this.handleUserMenuVisibility("hide");
  };

  handleSettingsClick = () => {
    this.props.showModalContent({
      type: "mySettings",
      conf: {}
    });
    this.handleUserMenuVisibility("hide");
  };

  handleAdminClick = () => {
    if (this.props.user.isAdmin) {
      this.handleUserMenuVisibility("hide");
    } else {
      this.props.adminPanel({
        variant: "error",
        message: "UserBar.adminClickAlert"
      });
    }
  };

  handleAboutClick = () => {
    this.props.showModalContent({
      type: "about",
      conf: {}
    });
    this.handleUserMenuVisibility("hide");
  };

  handlePolicyClick = () => {
    this.props.showModalContent({
      type: "privacyPolicy",
      conf: {}
    });
    this.handleUserMenuVisibility("hide");
  };

  handleTouClick = () => {
    this.props.showModalContent({
      type: "tou",
      conf: {}
    });
    this.handleUserMenuVisibility("hide");
  };

  handleHowToClick = () => {
    this.props.showModalContent({
      type: "howTo",
      conf: {}
    });
    this.handleUserMenuVisibility("hide");
  };

  handleFaqClick = () => {
    this.props.showModalContent({
      type: "faq",
      conf: {}
    });
    this.handleUserMenuVisibility("hide");
  };

  handleContactClick = () => {
    this.props.showModalContent({
      type: "contact",
      conf: {}
    });
    this.handleUserMenuVisibility("hide");
  };

  handleClickInside = () => {
    this.handleClick(true);
  };

  handleClickOutside = () => {
    this.handleClick(false);
  };

  handleClick = mouseClickInside => {
    this.mouseClickInside = mouseClickInside;
  };

  render() {
    const { t } = this.props;

    return (
      <div className="UserBar">
        <Button
          onClick={this.handleUserMenuVisibility}
          className="UserBar__user-section"
        >
          <MyPictureWrapper
            className="UserBar__thumbnail-wrapper"
            iconClassName="UserBar__thumbnail"
            imageClassName="UserBar__thumbnail"
          />
          <span className="UserBar__badge">
            {this.props.user.name} {this.props.user.surname}
          </span>
        </Button>
        <Collapse in={this.state.isUserMenuShown}>
          <div
            className="UserBar__menu"
            onMouseDown={this.handleClickInside}
            onMouseUp={this.handleClickOutside}
          >
            <div>
              <button
                className="UserBar__menu-link"
                id="UserBar__menu-profile"
                onClick={this.handleAccountClick}
              >
                {t("UserBar.profile")}
              </button>
            </div>
            <div>
              <button
                className="UserBar__menu-link"
                id="UserBar__menu-settings"
                onClick={this.handleSettingsClick}
              >
                {t("UserBar.settings")}
              </button>
            </div>
            {this.props.user.isAdmin && <hr className="Hr--dashed" />}
            {this.props.user.isAdmin && (
              <div>
                <a
                  href="/admin/"
                  target="_blank"
                  className="UserBar__menu-link"
                  id="UserBar__menu-adminPanel"
                  onClick={this.handleAdminClick}
                  rel="nofollow"
                >
                  {t("UserBar.adminPanel")}
                </a>
              </div>
            )}
            <hr className="Hr--dashed" />
            <div>
              <button
                className="UserBar__menu-link"
                id="UserBar__menu-about"
                onClick={this.handleAboutClick}
              >
                {t("UserBar.about")}
              </button>
            </div>
            {/*<div><a className="UserBar__menu-link" onClick={this.handleAdvertisingClick}>
                            {t('UserBar.advertising')}
                        </a></div>*/}
            <div>
              <button
                className="UserBar__menu-link"
                id="UserBar__menu-tou"
                onClick={this.handleTouClick}
              >
                {t("UserBar.tou")}
              </button>
            </div>
            <div>
              <button
                className="UserBar__menu-link"
                id="UserBar__menu-privacyPolicy"
                onClick={this.handlePolicyClick}
              >
                {t("UserBar.privacyPolicy")}
              </button>
            </div>
            <div>
              <button
                className="UserBar__menu-link"
                id="UserBar__menu-howTo"
                onClick={this.handleHowToClick}
              >
                {t("UserBar.howTo")}
              </button>
            </div>
            <div>
              <button
                className="UserBar__menu-link"
                id="UserBar__menu-faq"
                onClick={this.handleFaqClick}
              >
                {t("UserBar.faq")}
              </button>
            </div>
            <div>
              <button
                className="UserBar__menu-link"
                id="UserBar__menu-contact"
                onClick={this.handleContactClick}
              >
                {t("UserBar.contact")}
              </button>
            </div>
            <hr className="Hr--dashed" />
            <a
              className="UserBar__menu-link"
              href={Config.url.logout}
              rel="nofollow"
            >
              {t("UserBar.signOut")}
            </a>
          </div>
        </Collapse>
      </div>
    );
  }
}

export default translate("frontend")(UserBarComponent);
