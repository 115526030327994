import { connect } from "react-redux";

import LeftSection from "../../components/LeftSection";
import { toggleLeftSectionVisibility } from "./actions";

const mapStateToProps = state => ({
  collapsed: state.leftSection.collapsed,
  theme: state.theme
});

const mapDispatchToProps = {
  toggleLeftSectionVisibility
};

const LeftSectionWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(LeftSection);

export default LeftSectionWrapper;
