import React, { Component } from "react";

import "./style.scss";
import {
  getMarkerPositionInsideBounds,
  getPopupShiftedPosition,
  isDraggingOutsideBounds
} from "./functions";
import { Marker, Pane, Popup } from "react-leaflet";
import Config from "../MapContainer/config";
import { handleSaveUnitData } from "../InfoSectionInfo/fetchUtils";
import { translate } from "react-i18next";
import { ButtonGroup } from "react-bootstrap";
import { DescribedButton } from "react-bootstrap-tools";

export class EditPositionMarkerComponent extends Component {
  constructor(props) {
    super(props);

    const position = {
      lat: props.autoCenter ? props.viewport.lat : props.lat,
      lon: props.autoCenter ? props.viewport.lon : props.lon
    };

    this.state = {
      markerPosition: position,
      popupPosition: getPopupShiftedPosition(position, props.mapReference),
      isAddition: props.isAddition,
      isAdditionPath: props.isAdditionPath
    };
  }

  componentWillReceiveProps(props) {
    if (props.viewport.zoom !== this.props.viewport.zoom) {
      const position = this.refs.marker.leafletElement.getLatLng();
      this.setState({
        popupPosition: getPopupShiftedPosition(
          position,
          this.props.mapReference
        )
      });
    }
  }

  handleDragend = () => {
    const position = this.refs.marker.leafletElement.getLatLng();

    this.setState({
      markerPosition: {
        lat: position.lat,
        lon: position.lng
      },
      popupPosition: getPopupShiftedPosition(position, this.props.mapReference)
    });
  };

  handleDrag = conf => {
    if (isDraggingOutsideBounds(conf.latlng, Config.map.initial.maxBounds))
      this.setState({
        markerPosition: getMarkerPositionInsideBounds(
          conf.latlng,
          Config.map.initial.maxBounds
        )
      });
  };

  handleSaveClick = () => {
    !this.state.isAddition
      ? this.props.activateCaptcha(
          this.handleSaveEdition,
          "Unit info - edit position"
        )
      : this.handleSaveAddition();
  };

  handleSaveEdition = () => {
    const pointPosition = this.state.markerPosition;

    handleSaveUnitData({
      id: this.props.pointId,
      data: pointPosition,
      token: this.props.token,
      invokeInfo: this.props.invokeInfo,
      stackUserLastChange: this.props.stackUserLastChange,
      handleFetchUnitData: null
    });

    this.props.setModalConf(this.props.modalId, {
      hide: false,
      useRelativePosition: this.props.mapReference.leafletElement.latLngToLayerPoint(
        this.refs.marker.leafletElement.getLatLng()
      ),
      lat: this.state.markerPosition.lat,
      lon: this.state.markerPosition.lon
    });

    this.props.closePositionMarker();
  };

  handleSaveAddition = () => {
    const relativePosition = this.props.mapReference.leafletElement.latLngToLayerPoint(
      this.refs.marker.leafletElement.getLatLng()
    );
    this.props.setModalConf(this.props.modalId, {
      hide: false,
      useRelativePosition: relativePosition,
      unitTypeId: null,
      lat: this.state.markerPosition.lat,
      lon: this.state.markerPosition.lon
    });
    this.props.closePositionMarker();
    this.props.setModalType(
      this.props.modalId,
      this.state.isAdditionPath ? "unitTypes" : "unitInfo"
    );
  };

  handleCancelClick = () => {
    !this.state.isAddition
      ? this.handleCancelEdition()
      : this.handleCancelAddition();
  };

  handleCancelEdition = () => {
    this.props.closePositionMarker();
    this.props.setModalConf(this.props.modalId, { hide: false });
  };

  handleCancelAddition = () => {
    this.props.closePositionMarker();
    this.state.isAdditionPath
      ? this.props.closeModalSection(this.props.modalId)
      : this.props.setModalConf(this.props.modalId, { hide: false });
  };

  render() {
    const { t } = this.props;

    return (
      <Pane name="EditPosition" className="MapContainer__positionContainer">
        <Marker
          draggable={true}
          position={this.state.markerPosition}
          pane="EditPosition"
          ref="marker"
          onDragend={this.handleDragend}
          onDrag={this.handleDrag}
          alt={t("EditPositionMarker.iconAlt")}
        />
        <Popup
          position={this.state.popupPosition}
          closeButton={false}
          closeOnClick={false}
          className="EditPositionMarker__popup"
        >
          <ButtonGroup>
            <DescribedButton
              buttonClassName="EditPositionMarker__button"
              onClick={this.handleSaveClick}
              size="sm"
              icon="done"
            />
            <DescribedButton
              buttonClassName="EditPositionMarker__button"
              onClick={this.handleCancelClick}
              size="sm"
              icon="close"
            />
          </ButtonGroup>
        </Popup>
      </Pane>
    );
  }
}

export default translate("frontend")(EditPositionMarkerComponent);
