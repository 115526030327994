import Immutable from "seamless-immutable";

/**
 * Utility for mass update of array state. Only changed (added, edited) objects
 * will mutate. Other objects remain Immutable.
 *
 * @param Immutable(array) currentArray - array of objects representing current state
 * @param array newArray - array of changes to process. Action command under 'updateAction' key. Match by 'id' key
 * @param object config - optional configuration of method. Possible keys:
 * - array addOnly - array of keys possible to add. No other keys will be added
 * - object addDefault - object of default values used during adding elements - use only if given value is not in newArray's object
 * - array editOnly - array of keys possible to edit. No other keys will be edited
 * @returns Immutable(array)
 */
export const updateArrayState = (currentArray, newArray, config = {}) => {
  const arrayWithNoDeleted = deleteElements(currentArray, newArray);
  const arrayWithEdited = editElements(arrayWithNoDeleted, newArray, config);
  return addElements(arrayWithEdited, newArray, config);
};

/**
 * Returns Immutable(array) of objects without marked as to delete
 * @param Immutable(array) currentArray - array of objects representing modified current state
 * @param array newArray - array of changes to process. Objects to delete: updateAction: 'DELETE'
 * @returns Immutable(array)
 */
const deleteElements = (currentArray, newArray) => {
  const toDeletes = getElementsToDelete(currentArray, newArray);

  if (toDeletes.length > 0)
    return Immutable(
      currentArray.filter(
        element => !toDeletes.some(toDelete => toDelete.id === element.id)
      )
    );

  return currentArray;
};

/**
 * Returns array of objects to delete from current state
 * @param Immutable(array) currentArray - array of objects representing modified current state
 * @param array newArray - array of changes to process. Objects to delete: updateAction: 'DELETE'
 * @returns array
 */
const getElementsToDelete = (currentArray, newArray) => {
  return currentArray.filter(toModify =>
    newArray.every(element => element.id !== toModify.id)
  );
};

/**
 * Returns Immutable(array) of objects with edited ones
 * @param Immutable(array) currentArray - array of objects representing modified current state
 * @param array newArray - array of changes to process. Objects to eidt: updateAction: 'EDIT'
 * @param object config - optional configuration of method. Possible keys:
 * - array editOnly - array of keys possible to edit. No other keys will be edited
 * @returns Immutable(array)
 */
const editElements = (currentArray, newArray, config) => {
  const toEdits = getElementsToEdit(currentArray, newArray);

  if (toEdits.length > 0)
    return Immutable(
      currentArray.map(element => {
        const elementToEdit = getElementToEdit(element, toEdits);
        if (!!elementToEdit) {
          return editElement(element, elementToEdit, config);
        }
        return element;
      })
    );

  return currentArray;
};

/**
 * Returns array of objects to edit on current state
 * @param Immutable(array) currentArray - array of objects representing modified current state
 * @param array newArray - array of changes to process. Objects to edit: updateAction: 'EDIT'
 * @returns array
 */
const getElementsToEdit = (currentArray, newArray) => {
  return newArray.filter(
    toModify => {
      const currentVersion = currentArray.filter(
        element => element.id === toModify.id
      );

      if (currentVersion.length > 0) return currentVersion !== toModify;

      return false;
    }
    /*toModify.updateAction === "EDIT" &&
      currentArray.some(element => element.id === toModify.id)*/
  );
};

/**
 * Returns object to edit filtered from elementsToEdit array
 * @param Immutable(object) currentElement - object from modified current state which is to edit
 * @param array elementsToEdit - array of elements to filter from (match by 'id' key)
 * @returns object || null
 */
const getElementToEdit = (currentElement, elementsToEdit) => {
  const elementArray = elementsToEdit.filter(
    elementToEdit => elementToEdit.id === currentElement.id
  );

  if (elementArray.length > 0) return elementArray[0];

  return null;
};

/**
 * Returns Immutable(object) of edited object
 * @param Immutable(object) currentElement - object from modified current state which is to edit
 * @param object finalElement - object with final data
 * @param object config - optional configuration of method. Possible keys:
 * - array editOnly - array of keys possible to edit. No other keys will be edited
 * @returns Immutable(array)
 */
const editElement = (currentElement, finalElement, config) => {
  // Copy of original object. This one will be edited and return
  let currentElementEdited = Immutable.asMutable(currentElement);
  // If edited - edited object shall return, otherwise the currentElement one
  let edited = false;

  Object.keys(currentElement).forEach(key => {
    if (
      currentElement[key] !== finalElement[key] &&
      (!config.editOnly ||
        (!!config.editOnly && config.editOnly.indexOf(key) > -1))
    ) {
      edited = true;
      currentElementEdited[key] = finalElement[key];
    }
  });

  if (edited) return Immutable(currentElementEdited);

  return currentElement;
};

/**
 * Returns Immutable(array) of objects including those marked as to add
 * @param Immutable(array) currentArray - array of objects representing modified current state
 * @param array newArray - array of changes to process. Objects to add: updateAction: 'ADD'
 * @param object config - optional configuration of method. Possible keys:
 * - array addOnly - array of keys possible to add. No other keys will be added
 * - object addDefault - object of default values used during adding elements - use only if given value is not in newArray's object
 * @returns Immutable(array)
 */
const addElements = (currentArray, newArray, config) => {
  const toAdds = getElementsToAdd(currentArray, newArray, config);

  if (toAdds.length > 0) return Immutable([...currentArray, ...toAdds]);

  return currentArray;
};

/**
 * Returns array of objects to add to current state
 * @param Immutable(array) currentArray - array of objects representing modified current state
 * @param array newArray - array of changes to process. Objects to add: updateAction: 'ADD'
 * @param object config - optional configuration of method. Possible keys:
 * - array addOnly - array of keys possible to add. No other keys will be added
 * - object addDefault - object of default values used during adding elements - use only if given value is not in newArray's object
 * @returns array
 */
const getElementsToAdd = (currentArray, newArray, config) => {
  const elementsToAdd = newArray.filter(toModify =>
    // ["ADD", "EDIT"].indexOf(toModify.updateAction) > -1 &&
    currentArray.every(element => element.id !== toModify.id)
  );

  return elementsToAdd
    .map(element => addElementDefaults(element, config.addDefault))
    .map(element => narrowElementProperties(element, config.addOnly));
};

/**
 * Returns object with default values if the one has not been set on original object
 * @param object element - original object
 * @param object defaults - object of default values
 * @returns object
 */
const addElementDefaults = (element, defaults) => {
  // We must copy the element, otherwise it changes newArray on getElementsToAdd() and all json
  let elementCopy = { ...element };
  if (!!defaults) {
    Object.keys(defaults).forEach(property => {
      if (!elementCopy[property] && elementCopy[property] !== false)
        elementCopy[property] = defaults[property];
    });
  }

  return elementCopy;
};

/**
 * Returns object narrowed to given keys collection.
 * @param object element - original object
 * @param array only - array of keys to narrow
 * @returns object
 */
const narrowElementProperties = (element, only) => {
  // We must copy the element, otherwise it changes newArray on getElementsToAdd() and all json
  let elementCopy = { ...element };
  if (!!only)
    Object.keys(elementCopy).forEach(property => {
      if (only.indexOf(property) < 0) delete elementCopy[property];
    });
  return elementCopy;
};
