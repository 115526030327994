export const getIframeProps = props => {
  let iframeProps = {
    src: props.url,
    className: !!props.className
      ? props.className
      : "ModalSectionExternalContent"
  };

  if (!!props.allow) iframeProps.allow = props.allow;

  return iframeProps;
};
