const Config = {
  map: {
    initial: {
      position: [35, 0],
      zoomLevel: 3,
      minZoom: 3,
      maxZoom: 17,
      maxBounds: [[-90, -180], [90, 180]]
    }
  }
};

export default Config;
