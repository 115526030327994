/*
 * Action types
 */
export const SET_BOTTOM_SECTION_SIZE_MODE = "SET_BOTTOM_SECTION_SIZE_MODE";

/*
 * Action creators
 */
export function setBottomSectionVisibility(mode) {
  return {
    type: SET_BOTTOM_SECTION_SIZE_MODE,
    mode
  };
}
