/**
 * Returns className of a component
 * @param bool isLeftSectionCollapsed
 * @param string bottomSectionSizeMode - sizeMode of bottom component (from store)
 * @returns {string}
 */
export const getClassName = (isLeftSectionCollapsed, bottomSectionSizeMode) => {
  const verticalClassName = getVerticalClassName(isLeftSectionCollapsed);
  const horizontalClassName = getHorizontalClassName(bottomSectionSizeMode);
  return verticalClassName + " " + horizontalClassName;
};

/**
 * Returns className of a component for vertical behaviour
 * @param bool isLeftSectionCollapsed
 * @returns {string}
 */
const getVerticalClassName = isLeftSectionCollapsed => {
  return "AgentButton" + (!isLeftSectionCollapsed ? "--narrow" : "");
};

/**
 * Returns className of a component for horizontal behaviour
 * @param string sizeMode - sizeMode of bottom component (from store)
 * @returns {string}
 */
const getHorizontalClassName = sizeMode => {
  if (["partial30"].indexOf(sizeMode) < 0) return "";

  return "AgentButton--" + sizeMode;
};
