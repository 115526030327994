export const getProviderIconDescription = (providerId, isConnected) => {
  switch (providerId) {
    case "linkedin":
      return isConnected
        ? "UserSettings.buttonLinkedinDisconnectDescription"
        : "UserSettings.buttonLinkedinConnectDescription";
    case "google":
      return isConnected
        ? "UserSettings.buttonGoogleDisconnectDescription"
        : "UserSettings.buttonGoogleConnectDescription";
    default:
      return "";
  }
};

export const getProviderIconClassName = (providerId, isConnected) => {
  return (
    "UserSettings__provider-button--" +
    providerId +
    (isConnected ? "" : "-not-connected")
  );
};

export const getProviderButtonText = (providerId, isConnected) => {
  switch (providerId) {
    case "linkedin":
      return isConnected
        ? "UserSettings.buttonLinkedinDisconnectText"
        : "UserSettings.buttonLinkedinConnectText";
    case "google":
      return isConnected
        ? "UserSettings.buttonGoogleDisconnectText"
        : "UserSettings.buttonGoogleConnectText";
    default:
      return "";
  }
};
