import React from "react";

import "./style.scss";
import { Filter } from "phyzzi.com-frontend-filter-sorter";
import { Sorter } from "phyzzi.com-frontend-filter-sorter";

export const InfoSectionUsersFilterSorterComponent = props => {
  return (
    <thead className="InfoSectionUsersFilterSorter">
      <tr>
        <th colSpan={4}>
          <div className="InfoSectionUsersFilterSorter__filter-wrapper">
            <Filter
              onChange={props.handleFilterChange}
              maxLength={20}
              value={props.filterValue}
              transformedItemsNumber={props.transformedItemsNumber}
              originalItemsNumber={props.originalItemsNumber}
              className="InfoSectionUsersFilterSorter__filter"
              placeholder={!!props.placeholder && props.placeholder}
            />
          </div>
        </th>
      </tr>

      <tr>
        <th className="InfoSectionUsersFilterSorter__sort-cell" />
        {props.sorters.map(sorter => {
          return (
            <th
              key={sorter.field}
              className="InfoSectionUsersFilterSorter__sort-cell"
            >
              <Sorter
                key={sorter.field}
                sorter={sorter}
                onClick={props.handleSorterClick}
              />
            </th>
          );
        })}
        <th className="InfoSectionUsersFilterSorter__sort-cell" />
      </tr>
    </thead>
  );
};

export default InfoSectionUsersFilterSorterComponent;
