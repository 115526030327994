import React, { Component } from "react";

import "./style.scss";
import {
  getRowLabel,
  getUnitTypeProducts,
  prepareGroupData,
  prepareGroupTags,
  prepareSuggestions
} from "./functions";
import { WithContext as ReactTags } from "react-tag-input";
import ReactTagsRemove from "../ReactTagsRemove/index";
import {
  filterSuggestions,
  getGroupDataExclude
} from "../../containers/App/functions";
import { Config } from "../ModalSection/config";
import { shouldCaptchaAppear } from "../InfoSectionContent/functions";
import { DescribedButton } from "react-bootstrap-tools";
import { translate } from "react-i18next";

class InfoSectionInfoTag extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isEdit: props.isAddition
    };

    this.t = props.t;
    this.finishButtonDescription = this.t(
      "InfoSectionInfoTag.buttonFinishDescription"
    );
    this.editButtonDescription = this.t(
      "InfoSectionInfoTag.buttonEditDescription"
    );
    this.editButtonEmptyText = this.t("common:emptyInBrackets");

    this.handleReactTagsKeyChange();
  }

  handleAddClick = (groupData, tag) => {
    this.props.isAddition
      ? this.handleChange(groupData, tag.id, "add")
      : shouldCaptchaAppear(
          this.props.fieldId,
          this.props.user.lastChanges,
          this.props.user.isAdmin,
          Config.captcha
        )
        ? this.handleShowCaptcha(() => this.handleAdd(groupData, tag.id))
        : this.handleAdd(groupData, tag.id);
  };

  handleChange = (groupData, id, operation) => {
    let actualData = this.props.content[this.props.fieldId];

    if (operation === "add") actualData.push(parseInt(id, 10));

    if (operation === "delete")
      actualData = getGroupDataExclude(id, groupData, this.props.fieldId).map(
        tag => parseInt(tag.id, 10)
      );

    this.props.handleReloadData({ [this.props.fieldId]: actualData });
    this.handleReactTagsKeyChange();
  };

  handleReactTagsKeyChange = () => {
    this.reactTagsKey = new Date().getTime();
  };

  handleAdd = (groupData, id) => {
    if (id !== "") {
      let actualData = this.props.content[this.props.fieldId];
      actualData.push(parseInt(id, 10));

      this.props.handleSaveData({ [this.props.fieldId]: actualData });
      this.props.handleReloadData({ [this.props.fieldId]: actualData });
      this.handleReactTagsKeyChange();
    }
  };

  handleDeleteClick = (selectedTags, i) => {
    this.props.isAddition
      ? this.handleChange(selectedTags, i, "delete")
      : shouldCaptchaAppear(
          this.props.fieldId,
          this.props.user.lastChanges,
          this.props.user.isAdmin,
          Config.captcha
        )
        ? this.handleShowCaptcha(() => this.handleDelete(selectedTags, i))
        : this.handleDelete(selectedTags, i);
  };

  handleDelete = (selectedTags, i) => {
    const actualData = getGroupDataExclude(i, selectedTags).map(element =>
      parseInt(element.id, 10)
    );

    this.props.handleSaveData({ [this.props.fieldId]: actualData });
    this.props.handleReloadData({ [this.props.fieldId]: actualData });
    this.handleReactTagsKeyChange();
  };

  toggleEdit = () => {
    this.setState({
      isEdit: !this.state.isEdit
    });
  };

  handleSuggestions = (inputValue, suggestions) => {
    return filterSuggestions(inputValue, suggestions);
  };

  handleShowCaptcha = action => {
    this.props.activateCaptcha(action, "Unit info - tag");
  };

  render() {
    const fieldId = this.props.fieldId;
    const value = this.props.content[fieldId];
    const groupData =
      fieldId === "products_in" || fieldId === "products_out"
        ? getUnitTypeProducts(
            this.props.content.unit_type_id,
            this.props.products,
            this.props.unitTypeProducts,
            fieldId
          )
        : this.props[fieldId];
    const translatedSuggestions = prepareSuggestions(
      value,
      groupData,
      fieldId,
      this.t
    );

    const tags = this.state.isEdit
      ? prepareGroupTags(value, groupData, fieldId, this.t)
      : prepareGroupData(value, groupData, fieldId, this.t);

    return (
      <tr className={!!this.props.rowClassName ? this.props.rowClassName : ""}>
        <td
          className={
            !!this.props.leftColumnClassName
              ? this.props.leftColumnClassName
              : ""
          }
        >
          {this.t(getRowLabel(fieldId))}:
        </td>
        <td
          className={
            !!this.props.rightColumnClassName
              ? this.props.rightColumnClassName
              : ""
          }
        >
          {this.state.isEdit ? (
            <div>
              <ReactTags
                /*
                When user choose suggestion the suggestion list inside react-tag-input component does not refresh (even if a new one is provided).
                This is an ugly bug of a package and we have to hack to walk it around.
                So the idea is that certain actions change reactTagsKey and therefore forces component to unmount and mount again.
                 */
                key={this.reactTagsKey}
                tags={tags}
                labelField="name"
                suggestions={translatedSuggestions}
                removeComponent={ReactTagsRemove}
                handleAddition={tag => this.handleAddClick(groupData, tag)}
                handleDelete={i => this.handleDeleteClick(tags, i)}
                placeholder={this.t("InfoSectionInfoTag.formPlaceholder")}
                minQueryLength={0}
                maxLength={255}
                autocomplete={true}
                autofocus={false}
                handleFilterSuggestions={this.handleSuggestions}
              />
              <DescribedButton
                description={this.finishButtonDescription}
                size="sm"
                onClick={this.toggleEdit}
                buttonClassName="InfoSectionInfo__button"
                iconClassName="InfoSectionInfo__button-icon"
                icon="done"
              />
            </div>
          ) : (
            <DescribedButton
              description={
                !this.props.userUuid ? null : this.editButtonDescription
              }
              size="sm"
              onClick={this.toggleEdit}
              disabled={!this.props.userUuid}
              buttonClassName="InfoSectionInfoTag__tags"
              text={tags.length === 0 ? this.editButtonEmptyText : tags}
            />
          )}
        </td>
      </tr>
    );
  }
}

export default translate("frontend")(InfoSectionInfoTag);
