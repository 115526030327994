import React from "react";

import "./style.scss";
import TopRightSection from "../TopRightSection/index";
import UserBarWrapper from "../../containers/UserBarWrapper/index";
import LogoWrapper from "../../containers/LogoWrapper/index";
import MessageButtonWrapper from "../../containers/MessageButtonWrapper/index";
import ShareButtonWrapper from "../../containers/ShareButtonWrapper/index";
import SearchWrapper from "../../containers/SearchWrapper";
import LanguageButtonWrapper from "../../containers/LanguageButtonWrapper";

export const TopSection = props => {
  return (
    <section className="TopSection">
      <section className="TopSection__component-space--left">
        <LogoWrapper />
      </section>
      <section className="TopSection__component-space">
        <SearchWrapper />
      </section>
      <section className="TopSection__component-space--right">
        <ShareButtonWrapper />
        <MessageButtonWrapper />
        <LanguageButtonWrapper />
        {props.userUuid ? <UserBarWrapper /> : <TopRightSection />}
      </section>
    </section>
  );
};

export default TopSection;
