import { connect } from "react-redux";

import InfoSectionBoard from "../../components/InfoSectionBoard";
import { invokeInfo } from "../AlertBoxWrapper/actions";
import { receivePrivateData } from "../App/actions";
import { changeViewport, selectUnit } from "../MapContainerWrapper/actions";

const mapStateToProps = (state, props) => ({
  id: state.modalSection[props.modalId].conf.id,
  unitTypeId: state.modalSection[props.modalId].conf.unitTypeId || null,
  boardGroups: state.boardGroups.data,
  user: state.user,
  token: state.initData.token,
  unitTypes: state.unitTypes.data,
  isSelectedUnit: !!state.map.selectedUnit
});

const mapDispatchToProps = {
  invokeInfo,
  receivePrivateData,
  selectUnit,
  changeViewport
};

const InfoSectionBoardWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoSectionBoard);

export default InfoSectionBoardWrapper;
