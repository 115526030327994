import React, { Component } from "react";

import "./style.scss";
import { getStatusLabel } from "./functions";
import { Config } from "../ModalSection/config";
import { shouldCaptchaAppear } from "../InfoSectionContent/functions";
import { DescribedButton, DescribedComponent } from "react-bootstrap-tools";
import { translate } from "react-i18next";
import { ButtonGroup } from "react-bootstrap";

class InfoSectionInfoStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {
      status: props.status,
      isEdit: props.isAddition
    };

    this.t = props.t;
    this.saveButtonDescription = this.t(
      "InfoSectionInfoStatus.buttonSaveDescription"
    );
    this.cancelButtonDescription = this.t(
      "InfoSectionInfoStatus.buttonCancelDescription"
    );
    this.editButtonDescription = this.t(
      "InfoSectionInfoStatus.buttonEditDescription"
    );
  }

  handleChangeClick = status => {
    this.setState({
      status: status
    });
  };

  handleSaveClick = () => {
    const status = this.state.status;

    this.props.isAddition
      ? this.props.handleReloadData({ status })
      : shouldCaptchaAppear(
          "status",
          this.props.user.lastChanges,
          this.props.user.isAdmin,
          Config.captcha
        )
        ? this.handleShowCaptcha(() => this.handleSave(status))
        : this.handleSave(status);

    this.toggleEdit();
  };

  handleSave = status => {
    this.props.handleSaveData({ status });
    this.props.handleReloadData({ status });
  };

  toggleEdit = () => {
    this.setState({
      isEdit: !this.state.isEdit,
      status: this.props.status
    });
  };

  getDivProps = () => {
    return this.props.userUuid
      ? {
          className: "InfoSectionInfoStatus__edit",
          onClick: this.toggleEdit
        }
      : {};
  };

  handleShowCaptcha = action => {
    this.props.activateCaptcha(action, "Unit info - status");
  };

  getStatusLabel = (status, selectedStatus) => {
    return this.t(getStatusLabel(status, selectedStatus));
  };

  render() {
    return (
      <tr className={!!this.props.rowClassName ? this.props.rowClassName : ""}>
        <td
          className={
            !!this.props.leftColumnClassName
              ? this.props.leftColumnClassName
              : ""
          }
        >
          {this.t("InfoSectionInfoStatus.status")}:
        </td>
        <td
          className={
            !!this.props.rightColumnClassName
              ? this.props.rightColumnClassName
              : ""
          }
        >
          {this.state.isEdit ? (
            <ButtonGroup>
              <DescribedButton
                buttonClassName="InfoSectionInfo__button--positive"
                size="sm"
                onClick={this.handleChangeClick}
                onClickArg={true}
                active={this.state.status === true}
                text={this.getStatusLabel(true, this.state.status)}
              />
              <DescribedButton
                buttonClassName="InfoSectionInfo__button--negative"
                size="sm"
                onClick={this.handleChangeClick}
                onClickArg={false}
                active={this.state.status === false}
                text={this.getStatusLabel(false, this.state.status)}
              />
              <DescribedButton
                buttonClassName="InfoSectionInfo__button"
                size="sm"
                onClick={this.handleChangeClick}
                onClickArg={""}
                active={this.state.status === ""}
                text={this.getStatusLabel("", this.state.status)}
              />
              <DescribedButton
                description={this.saveButtonDescription}
                size="sm"
                onClick={this.handleSaveClick}
                buttonClassName="InfoSectionInfo__button--positive"
                iconClassName="InfoSectionInfo__button-icon"
                icon="done"
              />
              <DescribedButton
                description={this.cancelButtonDescription}
                descriptionTheme={this.props.theme}
                size="sm"
                onClick={this.toggleEdit}
                buttonClassName="InfoSectionInfo__button--negative"
                iconClassName="InfoSectionInfo__button-icon"
                icon="close"
              />
            </ButtonGroup>
          ) : (
            <DescribedComponent
              description={
                !this.props.userUuid ? null : this.editButtonDescription
              }
            >
              <div {...this.getDivProps()}>
                {this.getStatusLabel(this.props.status, this.props.status)}
              </div>
            </DescribedComponent>
          )}
        </td>
      </tr>
    );
  }
}

export default translate("frontend")(InfoSectionInfoStatus);
