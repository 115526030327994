import { connect } from "react-redux";

import CookieInfo from "../../components/CookieInfo";
import { showModalContent } from "../ModalGroupWrapper/actions";

const mapStateToProps = state => ({
  isLeftSectionCollapsed: state.leftSection.collapsed,
  bottomSectionSizeMode: state.bottomSection.sizeMode
});

const mapDispatchToProps = {
  showModalContent
};

const CookieInfoWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(CookieInfo);

export default CookieInfoWrapper;
