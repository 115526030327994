import { connect } from "react-redux";

import ModalGroup from "../../components/ModalGroup";

const mapStateToProps = state => ({
  modalSection: state.modalSection,
  isLeftSectionShown: !state.leftSection.collapsed
});

const mapDispatchToProps = {};

const ModalGroupWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalGroup);

export default ModalGroupWrapper;
