import { connect } from "react-redux";

import InfoSectionInfoRow from "../../components/InfoSectionInfoRow";
import { activateCaptcha } from "../CaptchaWrapper/actions";

const mapStateToProps = (state, props) => ({
  isAddition: state.modalSection[props.modalId].conf.isAddition,
  userUuid: state.user.uuid,
  user: state.user,
  theme: state.theme,
  unitTypes: state.unitTypes.data
});

const mapDispatchToProps = {
  activateCaptcha
};

const InfoSectionInfoRowWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoSectionInfoRow);

export default InfoSectionInfoRowWrapper;
