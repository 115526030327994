import React from "react";

import "./style.scss";
import InfoSectionBoardFirstRowWrapper from "../../containers/InfoSectionBoardFirstRowWrapper";
import InfoSectionBoardNoResults from "../InfoSectionBoardNoResults";
import InfoSectionBoardRowWrapper from "../../containers/InfoSectionBoardRowWrapper";

const InfoSectionBoardList = props => {
  return (
    <tbody className="InfoSectionBoardList">
      <InfoSectionBoardFirstRowWrapper
        toggleShowAddForm={props.toggleShowAddForm}
        canUserAddMessage={props.canUserAddMessage}
      />
      {props.content.length === 0 && <InfoSectionBoardNoResults />}
      {props.content.map(message => (
        <InfoSectionBoardRowWrapper
          key={message.id}
          message={message}
          canUserEditMessage={props.canUserEditMessage}
          handleRefresh={props.handleRefresh}
          toggleEditForm={props.toggleEditForm}
          handleDelete={props.handleDelete}
        />
      ))}
    </tbody>
  );
};

export default InfoSectionBoardList;
