import React, { PureComponent } from "react";

import { Marker } from "react-leaflet";

class MapIcon extends PureComponent {
  constructor(props) {
    super(props);
    this.isMarkerDraggable = false;
  }

  handlePointClick = event => {
    this.props.handlePointClick(event, {
      id: this.props.id,
      lat: this.props.lat,
      lon: this.props.lon,
      name: this.props.name,
      icon: this.props.pointIcon
    });
  };

  render() {
    return (
      <Marker
        position={[this.props.lat, this.props.lon]}
        draggable={this.isMarkerDraggable}
        icon={this.props.icon}
        onClick={this.handlePointClick}
        title={this.props.name}
      />
    );
  }
}

export default MapIcon;
