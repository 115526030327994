import { connect } from "react-redux";

import InfoSectionInfoStatus from "../../components/InfoSectionInfoStatus";
import { activateCaptcha } from "../CaptchaWrapper/actions";

const mapStateToProps = (state, props) => ({
  isAddition: state.modalSection[props.modalId].conf.isAddition,
  userUuid: state.user.uuid,
  user: state.user,
  theme: state.theme
});

const mapDispatchToProps = {
  activateCaptcha
};

const InfoSectionInfoStatusWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoSectionInfoStatus);

export default InfoSectionInfoStatusWrapper;
