import React from "react";

import "./style.scss";
import LeftSectionContent from "../LeftSectionContent/index";
import Slogan from "../Slogan/index";
import FooterMenuWrapper from "../../containers/FooterMenuWrapper/index";
import { Collapse } from "react-bootstrap";

export const LeftSectionStructure = props => {
  return (
    <Collapse in={!props.collapsed} dimension="width">
      <section
        className={
          "LeftSectionStructure--" +
          (props.collapsed ? "collapsed" : "expanded")
        }
      >
        <LeftSectionContent />
        <FooterMenuWrapper />
        <Slogan />
      </section>
    </Collapse>
  );
};

export default LeftSectionStructure;
