import { connect } from "react-redux";

import InfoSectionUnitTypeTree from "../../components/InfoSectionUnitTypeTree";

const mapStateToProps = state => ({
  unitTypes: state.unitTypes.data
});

const mapDispatchToProps = {};

const InfoSectionUnitTypeTreeWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoSectionUnitTypeTree);

export default InfoSectionUnitTypeTreeWrapper;
