import React, { Component } from "react";

import "./style.scss";
import { Config } from "../ModalSection/config";
import {
  getClassName,
  getContentComponentProps,
  getContentType,
  getExternalContentProps
} from "./functions";
import { Interpolate, translate } from "react-i18next";
import { ModalSectionExternalContent } from "../ModalSectionExternalContent/index";
import UserProfileWrapper from "../../containers/UserProfileWrapper/index";
import UserSettingsWrapper from "../../containers/UserSettingsWrapper/index";
import ContactWrapper from "../../containers/ContactWrapper/index";
import {
  Login,
  LoginError,
  Register,
  RegistrationSuccess,
  RegistrationError,
  Request,
  RequestSuccess,
  Reset,
  ResetError,
  ResetFormError,
  ResetSuccess,
  VerificationSuccess
} from "phyzzi.com-frontend-login-register";
import "phyzzi.com-frontend-login-register/build/static/css/index.css";
import MessengerWrapper from "../../containers/MessengerWrapper/index";
import InfoSectionContent from "../InfoSectionContent/index";
import FaqWrapper from "../../containers/FaqWrapper/index";
import AboutWrapper from "../../containers/AboutWrapper/index";
import PrivacyPolicyWrapper from "../../containers/PrivacyPolicyWrapper/index";
import TermsOfUseWrapper from "../../containers/TermsOfUseWrapper/index";
import HowTo from "../HowTo";

class ModalSectionContent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentType: getContentType(props.modalSection.type, Config)
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      contentType: getContentType(props.modalSection.type, Config)
    });
  }

  render() {
    const { t } = this.props;
    const i18nUtils = { Interpolate, t };
    const componentProps = getContentComponentProps(
      this.state.contentType,
      this.props,
      i18nUtils
    );

    return (
      <section className={getClassName(this.state.contentType)}>
        {this.state.contentType === "externalContent" && (
          <ModalSectionExternalContent
            {...getExternalContentProps(this.props)}
          />
        )}
        {this.state.contentType === "userSettings" && <UserSettingsWrapper />}
        {this.state.contentType === "about" && <AboutWrapper />}
        {this.state.contentType === "privacyPolicy" && <PrivacyPolicyWrapper />}
        {this.state.contentType === "tou" && <TermsOfUseWrapper />}
        {this.state.contentType === "howTo" && <HowTo />}
        {this.state.contentType === "faq" && <FaqWrapper />}
        {this.state.contentType === "contact" && <ContactWrapper {...componentProps} />}

        {["unitInfo", "unitUsers", "unitBoard", "unitTypes"].indexOf(
          this.state.contentType
        ) > -1 && (
          <InfoSectionContent
            type={this.state.contentType}
            modalId={this.props.id}
          />
        )}

        {this.state.contentType === "userProfile" && (
          <UserProfileWrapper {...componentProps} />
        )}

        {this.state.contentType === "register" && (
          <Register {...componentProps} />
        )}
        {this.state.contentType === "registrationSuccess" && (
          <RegistrationSuccess {...componentProps} />
        )}
        {this.state.contentType === "registrationError" && (
          <RegistrationError {...componentProps} />
        )}
        {this.state.contentType === "registrationVerificationSuccess" && (
          <VerificationSuccess {...componentProps} />
        )}

        {this.state.contentType === "login" && <Login {...componentProps} />}
        {this.state.contentType === "loginError" && (
          <LoginError {...componentProps} />
        )}
        {this.state.contentType === "resetPasswordRequest" && (
          <Request {...componentProps} />
        )}
        {this.state.contentType === "resetPasswordRequestSuccess" && (
          <RequestSuccess {...componentProps} />
        )}
        {this.state.contentType === "resetPassword" && (
          <Reset {...componentProps} />
        )}
        {this.state.contentType === "resetPasswordSuccess" && (
          <ResetSuccess {...componentProps} />
        )}
        {this.state.contentType === "resetPasswordError" && (
          <ResetError {...componentProps} />
        )}
        {this.state.contentType === "resetPasswordFormError" && (
          <ResetFormError {...componentProps} />
        )}

        {this.state.contentType === "messenger" && (
          <MessengerWrapper {...componentProps} />
        )}
      </section>
    );
  }
}

export default translate("frontend")(ModalSectionContent);
