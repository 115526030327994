import { config } from "./config";

export const getClassName = (isMouseOver, focused) => {
  const FOCUSED = "--focused";
  const UNFOCUSED = "--unfocused";

  return "Search__field" + (focused || isMouseOver ? FOCUSED : UNFOCUSED);
};

export const calculateHasFocus = (isMouseOver, query, isFocus) => {
  return isFocus || query !== "" || isMouseOver ? true : false;
};

export const getFinalQuery = query => {
  return encodeURIComponent(
    query.replace(config.queryCharsToRemove, "").replace(/ /g, "+")
  );
};
