import React, { Component } from "react";

import "./style.scss";
import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";
import DateRange from "../DateRange/index";
import moment from "moment";
import { convertTimestampToDate } from "../PhyzziFrontend/functions";
import { Config } from "../ModalSection/config";
import { shouldCaptchaAppear } from "../InfoSectionContent/functions";
import { DescribedButton, DescribedComponent } from "react-bootstrap-tools";
import { translate } from "react-i18next";
import { ButtonGroup } from "react-bootstrap";

class InfoSectionInfoMaintenance extends Component {
  constructor(props) {
    super(props);

    this.state = {
      maintenance_since: props.maintenance_since,
      maintenance_to: props.maintenance_to,
      startDate:
        props.maintenance_since !== "" ? moment(props.maintenance_since) : null,
      endDate:
        props.maintenance_to !== "" ? moment(props.maintenance_to) : null,
      isEdit: props.isAddition,
      canSave: false
    };

    this.t = props.t;
    this.saveButtonDescription = this.t(
      "InfoSectionInfoMaintenance.buttonSaveDescription"
    );
    this.cancelButtonDescription = this.t(
      "InfoSectionInfoMaintenance.buttonCancelDescription"
    );
    this.editButtonDescription = this.t(
      "InfoSectionInfoMaintenance.buttonEditDescription"
    );
  }

  handleChange = (startDate, endDate) => {
    let maintenance_since = "",
      maintenance_to = "";

    if (startDate) maintenance_since = convertTimestampToDate(startDate._d);

    if (endDate) maintenance_to = convertTimestampToDate(endDate._d);

    this.setState({
      maintenance_since,
      maintenance_to,
      canSave: true
    });
  };

  handleSaveClick = () => {
    const maintenance = {
      maintenance_since: this.state.maintenance_since,
      maintenance_to: this.state.maintenance_to
    };

    if (this.props.isAddition) {
      this.props.handleReloadData(maintenance);
    } else {
      shouldCaptchaAppear(
        "maintenance",
        this.props.user.lastChanges,
        this.props.user.isAdmin,
        Config.captcha
      )
        ? this.handleShowCaptcha(() => this.handleSave(maintenance))
        : this.handleSave(maintenance);
    }

    this.toggleEdit(maintenance);
  };

  handleSave = maintenance => {
    this.props.handleSaveData(maintenance);
    this.props.handleReloadData(maintenance);
  };

  toggleEdit = conf => {
    const maintenance_since =
      !!conf && !!conf.maintenance_since
        ? conf.maintenance_since
        : this.props.maintenance_since !== ""
          ? this.props.maintenance_since
          : null;
    const maintenance_to =
      !!conf && !!conf.maintenance_to
        ? conf.maintenance_to
        : this.props.maintenance_to !== ""
          ? this.props.maintenance_to
          : null;

    this.setState({
      isEdit: !this.state.isEdit,
      maintenance_since: maintenance_since,
      maintenance_to: maintenance_to,
      startDate: !!maintenance_since ? moment(maintenance_since) : null,
      endDate: !!maintenance_to ? moment(maintenance_to) : null
    });
  };

  getDivProps = () => {
    return this.props.userUuid
      ? {
          className: "InfoSectionInfoMaintenance__edit",
          onClick: this.toggleEdit
        }
      : {};
  };

  isOutsideRange = (date, focusedInput) => {
    if (focusedInput === "startDate") return false;

    return convertTimestampToDate(date._d) < convertTimestampToDate(new Date());
  };

  handleShowCaptcha = action => {
    this.props.activateCaptcha(action, "Unit info - maintenance");
  };

  render() {
    const { t } = this.props;

    return (
      <tr className={!!this.props.rowClassName ? this.props.rowClassName : ""}>
        <td
          className={
            !!this.props.leftColumnClassName
              ? this.props.leftColumnClassName
              : ""
          }
        >
          {t("InfoSectionInfoMaintenance.maintenance")}:
        </td>
        <td
          className={
            !!this.props.rightColumnClassName
              ? this.props.rightColumnClassName
              : ""
          }
        >
          {this.state.isEdit ? (
            <div className="InfoSectionInfoMaintenance">
              <DateRange
                initialStartDate={this.state.startDate}
                initialEndDate={this.state.endDate}
                onDatesChange={(startDate, endDate) =>
                  this.handleChange(startDate, endDate)
                }
                displayFormat="YYYY-MM-DD"
                isOutsideRange={(date, focusedInput) =>
                  this.isOutsideRange(date, focusedInput)
                }
              />
              <ButtonGroup>
                <DescribedButton
                  description={this.saveButtonDescription}
                  size="sm"
                  onClick={this.handleSaveClick}
                  disabled={!this.state.canSave}
                  buttonClassName="InfoSectionInfo__button--positive"
                  iconClassName="InfoSectionInfo__button-icon"
                  icon="done"
                />
                <DescribedButton
                  description={this.cancelButtonDescription}
                  size="sm"
                  onClick={this.toggleEdit}
                  buttonClassName="InfoSectionInfo__button--negative"
                  iconClassName="InfoSectionInfo__button-icon"
                  icon="close"
                />
              </ButtonGroup>
            </div>
          ) : (
            <DescribedComponent
              description={
                !this.props.userUuid ? null : this.editButtonDescription
              }
            >
              <div {...this.getDivProps()}>
                {this.state.maintenance_since} - {this.state.maintenance_to}
              </div>
            </DescribedComponent>
          )}
        </td>
      </tr>
    );
  }
}

export default translate("frontend")(InfoSectionInfoMaintenance);
