import { connect } from "react-redux";

import LoginRegisterButton from "../../components/LoginRegisterButton";
import { showModalContent } from "../ModalGroupWrapper/actions";

const mapStateToProps = state => ({
  theme: state.theme
});

const mapDispatchToProps = {
  showModalContent
};

const LoginRegisterButtonWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginRegisterButton);

export default LoginRegisterButtonWrapper;
