import { connect } from "react-redux";

import InfoSectionInfoData from "../../components/InfoSectionInfoData";

const mapStateToProps = state => ({
  unitTypes: state.unitTypes.data
});

const mapDispatchToProps = {};

const InfoSectionInfoDataWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoSectionInfoData);

export default InfoSectionInfoDataWrapper;
