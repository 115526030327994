export const validateInput = (fieldId, content, regex) => {
  // Non empty values
  if (content.length === 0) return false;

  // Email
  if (fieldId === "email" && !regex.email.test(content)) return false;

  return true;
};

export const getAllowSend = (
  isValid,
  content,
  isUserSignedin,
  isFormSending
) => {
  let allowSend = true && !isFormSending;

  Object.keys(isValid).forEach(validationKey => {
    allowSend =
      allowSend &&
      (isValid[validationKey] === true ||
        (validationKey === "email"
          ? isUserSignedin
            ? true
            : content[validationKey].length !== 0
          : content[validationKey].length !== 0));
  });

  return allowSend;
};
