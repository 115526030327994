import { connect } from "react-redux";

import Faq from "../../components/Faq";
import { invokeInfo } from "../AlertBoxWrapper/actions";

const mapStateToProps = state => ({
  token: state.initData.token
});

const mapDispatchToProps = {
  invokeInfo
};

const FaqWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(Faq);

export default FaqWrapper;
