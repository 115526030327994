import { connect } from "react-redux";

import TermsOfUse from "../../components/TermsOfUse";
import { invokeInfo } from "../AlertBoxWrapper/actions";

const mapStateToProps = state => ({
  token: state.initData.token
});

const mapDispatchToProps = {
  invokeInfo
};

const TermsOfUseWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(TermsOfUse);

export default TermsOfUseWrapper;
