import React from "react";

import "./style.scss";
import MapContainerWrapper from "../../containers/MapContainerWrapper/index";
// import MapToggleWrapper from "../../containers/MapToggleWrapper/index";
import AddButtonWrapper from "../../containers/AddButtonWrapper/index";
import HowToThumbnailWrapper from "../../containers/HowToThumbnailWrapper";

export default function MapSection() {
  return (
    <div className="MapSection">
      <MapContainerWrapper />
      {/*<MapToggleWrapper />*/}
      <AddButtonWrapper />
      <HowToThumbnailWrapper />
    </div>
  );
}
