import { depracated_handleFetch } from "phyzzi.com-frontend-utils";
import Config from "../../containers/App/config";

export const handleFetchUnitData = conf => {
  depracated_handleFetch(
    {
      url: Config.url.api.pointResource + "/" + conf.id,
      token: conf.token
    },
    {
      success: json => conf.handleFetchUnitDataSuccess(json),
      error: () => handleFetchUnitDataFail(conf.invokeInfo)
    }
  );
};

const handleFetchUnitDataFail = invokeInfo => {
  invokeInfo({
    variant: "danger",
    message: "InfoSectionInfo.fetchDataFailAlert"
  });
};

export const handleAddUnit = conf => {
  if (!!conf.data.lat) conf.data.lat = Math.floor(1000000 * conf.data.lat);
  if (!!conf.data.lon) conf.data.lon = Math.floor(1000000 * conf.data.lon);

  depracated_handleFetch(
    {
      url: Config.url.api.pointResource,
      method: "POST",
      token: conf.token,
      body: conf.data
    },
    {
      success: () => handleAddUnitSuccess(conf),
      error: () => handleAddUnitFail(conf.invokeInfo)
    }
  );
};

const handleAddUnitSuccess = conf => {
  conf.invokeInfo({
    variant: "success",
    message: "InfoSectionInfo.addDataSuccessAlert"
  });
  conf.closeModalSection(conf.modalId);
  conf.stackUserLastChange();
};

const handleAddUnitFail = invokeInfo => {
  invokeInfo({
    variant: "danger",
    message: "InfoSectionInfo.addDataFailAlert"
  });
};

export const handleSaveUnitData = conf => {
  if (!!conf.data.lat) conf.data.lat = Math.floor(1000000 * conf.data.lat);
  if (!!conf.data.lon) conf.data.lon = Math.floor(1000000 * conf.data.lon);

  depracated_handleFetch(
    {
      url: Config.url.api.pointResource + "/" + conf.id,
      method: "PUT",
      token: conf.token,
      body: conf.data
    },
    {
      success: () => handleSaveUnitDataSuccess(conf),
      error: () => handleSaveUnitDataFail(conf.invokeInfo)
    }
  );
};

const handleSaveUnitDataSuccess = conf => {
  conf.invokeInfo({
    variant: "success",
    message: "InfoSectionInfo.editDataSuccessAlert"
  });
  conf.stackUserLastChange();
  conf.handleFetchUnitData(conf.id);
};

const handleSaveUnitDataFail = invokeInfo => {
  invokeInfo({
    variant: "danger",
    message: "InfoSectionInfo.editDataFailAlert"
  });
};

export const handleDeleteUnit = conf => {
  depracated_handleFetch(
    {
      url: Config.url.api.pointResource + "/" + conf.id,
      method: "DELETE",
      token: conf.token
    },
    {
      success: () => handleDeleteUnitSuccess(conf),
      error: () => handleDeleteUnitFail(conf.invokeInfo)
    }
  );
};

const handleDeleteUnitSuccess = conf => {
  conf.invokeInfo({
    variant: "success",
    message: "InfoSectionInfo.deleteDataSuccessAlert"
  });
  conf.closeModalSection(conf.modalId);
  conf.stackUserLastChange();
};

const handleDeleteUnitFail = invokeInfo => {
  invokeInfo({
    variant: "danger",
    message: "InfoSectionInfo.deleteDataFailAlert"
  });
};
