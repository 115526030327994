import {
  CLOSE_MODAL_SECTION,
  SET_MODAL_CONF,
  SHOW_MODAL_CONTENT,
  SHOW_MODAL_NEXT_CONTENT,
  SHOW_MODAL_PREV_CONTENT,
  SET_MODAL_TYPE,
  TOGGLE_RELOAD_MODAL_CONTENT
} from "./actions";
import {
  getModalId,
  getModalToDeleteId,
  getPosition,
  isModalTypeAllowedWithLoggedUser,
  refreshHistory
} from "./functions";
import { INJECT_DATA, RECEIVE_PRIVATE_DATA } from "../App/actions";

const initialState = [];

const initialModalState = {
  // For identification and positioning (time of invoke, or 0 if it's unitInfo instance)
  position: 0,

  // Type of modal section (to calculate menu and content type)
  type: "",

  // Abstract bucket for content generation (all data we need to share across subcomponents)
  // This data should maintain clean, so erase it as soon as possible
  conf: {
    // Flag to inform if modal component should change it's position (i.e. opened by click on different unit icon)
    // This is short-time flag - reload to false after every component update
    reloadContent: false,

    // Flag to inform if modal component should be hidden (i.e. some other component should be visible now)
    hide: false
  },

  // Snapshots of properties (to reload, prev or next content)
  history: [],

  // Indicator of which snapshot should is presented
  historyPointer: -1
};

function modalSectionReducers(state = initialState, action) {
  switch (action.type) {
    case INJECT_DATA:
      if (action.injectedData.modalType) {
        let conf = initialModalState.conf;
        if (action.injectedData.modalType === "resetPassword")
          conf.url = action.injectedData.url;
        if (
          action.injectedData.modalType === "unitInfo" ||
          action.injectedData.modalType === "unitUsers" ||
          action.injectedData.modalType === "unitBoard"
        ) {
          conf.id = action.injectedData.unitId;
          conf.useRelativePosition = {
            x: window.innerWidth / 2,
            y: window.innerHeight / 2
          };
        }

        return [
          Object.assign({}, initialModalState, {
            position: getPosition(action.injectedData.modalType),
            type: action.injectedData.modalType,
            conf,
            history: refreshHistory(initialModalState, null),
            historyPointer: initialModalState.history.length
          })
        ];
      }
      return state;
    case SHOW_MODAL_CONTENT:
      const modalId = getModalId(action.conf.type, state);

      if (modalId < 0) {
        // Some modals are in conflict, i.e. mapMenu and unitInfo in the past
        const modalToDeleteId = getModalToDeleteId(action.conf.type, state);

        const newModalObject = Object.assign({}, initialModalState, {
          position: getPosition(action.conf.type),
          type: action.conf.type,
          conf: action.conf.conf || initialModalState.conf,
          history: refreshHistory(initialModalState, null),
          historyPointer: initialModalState.history.length
        });

        // New modal section added to the group
        // and conflicted one removed
        return modalToDeleteId > 0
          ? [
              ...state.slice(0, modalToDeleteId),
              ...state.slice(modalToDeleteId + 1),
              newModalObject
            ]
          : [...state.slice(modalToDeleteId + 1), newModalObject];
      }

      return state.map((modal, idx) => {
        if (idx !== modalId) return modal;

        return Object.assign({}, modal, {
          position: getPosition(action.conf.type),
          type: action.conf.type,
          conf: action.conf.conf || modal.conf,
          history: refreshHistory(modal, null),
          historyPointer: modal.history.length
        });
      });
    case SHOW_MODAL_PREV_CONTENT:
      return state.map((modal, idx) => {
        if (idx !== action.id) return modal;

        return Object.assign({}, modal, {
          ...modal.history[modal.historyPointer - 1],
          history: refreshHistory(modal, "prev"),
          historyPointer: modal.historyPointer - 1
        });
      });
    case SHOW_MODAL_NEXT_CONTENT:
      return state.map((modal, idx) => {
        if (idx !== action.id) return modal;

        return Object.assign({}, modal, {
          ...modal.history[modal.historyPointer + 1],
          history: refreshHistory(modal, "next"),
          historyPointer: modal.historyPointer + 1
        });
      });
    case CLOSE_MODAL_SECTION:
      return state.filter((modal, idx) => idx !== action.id);
    case TOGGLE_RELOAD_MODAL_CONTENT:
      return state.map((modal, idx) => {
        if (idx !== action.id) return modal;

        return Object.assign({}, modal, {
          conf: {
            ...modal.conf,
            reloadContent: false
          }
        });
      });
    case SET_MODAL_TYPE:
      return state.map((modal, idx) => {
        if (idx !== action.id) return modal;

        return Object.assign({}, modal, {
          type: action.modalType
        });
      });
    case SET_MODAL_CONF:
      return state.map((modal, idx) => {
        if (idx !== action.id) return modal;

        return Object.assign({}, modal, {
          conf: {
            ...modal.conf,
            ...action.conf
          }
        });
      });
    case RECEIVE_PRIVATE_DATA:
      return state.filter(modal =>
        isModalTypeAllowedWithLoggedUser(modal.type)
      );
    default:
      return state;
  }
}

export default modalSectionReducers;
