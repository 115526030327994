import { getModalInstance } from "../../containers/ModalGroupWrapper/functions";

export const getClassName = (modalType, conf) => {
  const modalInstance = getModalInstance(modalType);

  const modalStyleInstance = getModalStyleInstance(modalInstance, modalType);

  let modalStyleModifier = getModalStyleModifier(
    modalStyleInstance,
    modalType,
    conf
  );

  return (
    "ModalSection" +
    (modalStyleInstance !== "" ? "__" + modalStyleInstance : "") +
    (modalStyleModifier !== "" ? "--" + modalStyleModifier : "")
  );
};

const getModalStyleInstance = (modalInstance, modalType) => {
  switch (modalInstance) {
    case "loginRegister":
      return "loginRegister";
    case "unitInfo":
    case "unitTypes":
      return modalType;
    default:
      return "";
  }
};

const getModalStyleModifier = (modalStyleInstance, modalType, conf) => {
  if (
    ["unitTypes", "unitInfo", "unitUsers", "unitBoard"].indexOf(
      modalStyleInstance
    ) > -1
  )
    return conf.previousContentType;

  return "";
};

/**
 * Calculates left position of opening modal window. Basically if modal is to wide to fully open on the right side
 * of invoking relative object, it will open at the left side of it.
 *
 * @param conf = {
 *  relativeObject: {
 *      left: int, // left position of invoking object over window
 *      top: int, // top position of invoking object over window
 *  },
 *  defaults: {
 *      pointMargin: int, // minimal margin between invoking object and modal window
 *      otherSideMargin: int, // minimal margin between modal window and a border of browser window
 *      topPosition: int, // not relevant
 *  },
 *  modalWidth: int, // caluculated (taken from actual style) width of a modal window
 *  windowWidth: int, // window.innerWidth
 *  }
 * @returns int // calculated left position at which modal window should initiate
 */
export const calculateModalLeftPosition = conf => {
  const necessaryWidth =
    conf.defaults.pointMargin + conf.modalWidth + conf.defaults.otherSideMargin;

  if (conf.relativeObject.left + necessaryWidth > conf.windowWidth) {
    const relativeLeft =
      conf.relativeObject.left > conf.windowWidth
        ? conf.windowWidth
        : conf.relativeObject.left;
    return relativeLeft - (conf.modalWidth + conf.defaults.pointMargin);
  } else {
    return conf.relativeObject.left + conf.defaults.pointMargin;
  }
};

/**
 * Calculates top position of opening modal window. Basically if modal is to big to fully open on the bottom side
 * of invoking relative object, it will open at the bottom margin of window.
 *
 * @param conf = {
 *  relativeObject: {
 *      left: int, // left position of invoking object over window
 *      top: int, // top position of invoking object over window
 *  },
 *  defaults: {
 *      pointMargin: int, // minimal margin between invoking object and modal window
 *      otherSideMargin: int, // minimal margin between modal window and a border of browser window
 *      topPosition: int,
 *  },
 *  modalHeight: int, // caluculated (taken from actual style) height of a modal window
 *  windowHeight: int, // window.innerHeight
 *  }
 * @returns int // calculated top position at which modal window should initiate
 */
export const calculateModalTopPosition = conf => {
  const necessaryHeight =
    (conf.defaults.pointMargin +
      conf.modalHeight +
      conf.defaults.otherSideMargin) /
    2;

  if (conf.relativeObject.top + necessaryHeight > conf.windowHeight)
    return conf.windowHeight - 2 * necessaryHeight;

  if (conf.relativeObject.top - necessaryHeight < conf.defaults.otherSideMargin)
    return conf.defaults.otherSideMargin;

  return conf.relativeObject.top - necessaryHeight;
};
