import React, { useEffect } from "react";
import "./style.scss";
import { DescribedButton } from "react-bootstrap-tools";
import { translate } from "react-i18next";
import { handleFetchMessages } from "./fetchUtils";
import { isMessengerModalOpen } from "./functions";
import config from "../../containers/App/config";

export const MessageButtonComponent = props => {
  const {
    token,
    userUuid,
    messagesAmount,
    setMessagesAmount,
    modalSection
  } = props;

  useEffect(
    () => {
      let fetchMessagesInterval;
      if (userUuid) {
        fetchMessages();

        fetchMessagesInterval = setInterval(
          fetchMessages,
          config.messenger.fetchNewMessagesButtonTimeout
        );
      }
      return () => clearInterval(fetchMessagesInterval);
    },
    [userUuid, isMessengerModalOpen(modalSection), token]
  );

  const fetchMessages = () => {
    if (!isMessengerModalOpen(modalSection)) {
      handleFetchMessages({
        userUuid,
        token,
        setMessagesAmount
      });
    }
  };

  const { t } = props;

  const handleButtonClick = () => {
    if (!!props.userUuid) {
      props.showModalContent({
        type: "messenger"
      });
    } else {
      props.invokeInfo({
        variant: "warning",
        message: "MessageButton.alert"
      });
    }
  };

  const buttonDescription = t("MessageButton.description");

  return (
    <div className="MessageButton">
      <DescribedButton
        description={buttonDescription}
        placement="bottom"
        icon="email"
        buttonClassName="MessageButton__button"
        iconClassName="MessageButton__button-icon"
        onClick={handleButtonClick}
      />
      {messagesAmount > 0 && (
        <span className={"MessageButton__unreadAmount"}>{messagesAmount}</span>
      )}
    </div>
  );
};

export default translate("frontend")(MessageButtonComponent);
