import { connect } from "react-redux";

import Movable from "../../components/Movable";
import { startEmittingCallback } from "./actions";

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  startEmittingCallback
};

const MovableWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(Movable);

export default MovableWrapper;
