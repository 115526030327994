import { connect } from "react-redux";

import EditPositionMarker from "../../components/EditPositionMarker";
import { closePositionMarker } from "../MapContainerWrapper/actions";
import { getModalId } from "../ModalGroupWrapper/functions";
import {
  closeModalSection,
  setModalConf,
  setModalType
} from "../ModalGroupWrapper/actions";
import { invokeInfo } from "../AlertBoxWrapper/actions";
import { stackUserLastChange } from "../App/actions";
import { activateCaptcha } from "../CaptchaWrapper/actions";

const mapStateToProps = state => {
  const unitTypeId = getModalId("unitType", state.modalSection);
  const unitInfoId = getModalId("unitInfo", state.modalSection);
  const modalId = unitTypeId < 0 ? unitInfoId : unitTypeId;

  return {
    lat: state.map.editPositionMarker.lat,
    lon: state.map.editPositionMarker.lon,
    autoCenter: state.map.editPositionMarker.autoCenter,
    viewport: state.map.viewport,
    pointId: state.modalSection[modalId].conf.id || 0,
    isAddition: state.modalSection[modalId].conf.isAddition,
    isAdditionPath: state.modalSection[modalId].conf.isAdditionPath,
    modalId: modalId,
    token: state.initData.token
  };
};

const mapDispatchToProps = {
  closePositionMarker,
  setModalConf,
  setModalType,
  closeModalSection,
  invokeInfo,
  stackUserLastChange,
  activateCaptcha
};

const EditPositionMarkerWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPositionMarker);

export default EditPositionMarkerWrapper;
