import React, { Component } from "react";
import UnitTypeTreeWrapper from "../../containers/UnitTypeTreeWrapper/index";
import UnitTypeMenu from "../UnitTypeMenu/index";

class UnitTypeTreeItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: props.unitTypeBranch.collapsed,
      childrenHiding: false
    };
  }

  toggleUnitTypeSelection = id => {
    this.props.toggleUnitTypeSelection(id);
  };

  toggleChildrenCollapse = () => {
    if (this.state.collapsed) {
      this.setState({
        collapsed: false,
        childrenHiding: false
      });
    } else {
      this.setState({
        childrenHiding: true
      });
    }
  };

  handleSectionCollapsed = () => {
    this.setState({
      collapsed: true,
      childrenHiding: false
    });
  };

  render() {
    return (
      <div>
        <UnitTypeMenu
          key={this.props.unitType.id + "menu"}
          unitType={this.props.unitType}
          unitTypeBranch={this.props.unitTypeBranch}
          toggleUnitTypeSelection={this.toggleUnitTypeSelection}
          toggleChildrenCollapse={this.toggleChildrenCollapse}
          hasChildren={this.props.hasChildren}
          collapsed={this.state.collapsed}
          mapType={this.props.mapType}
          level={this.props.level}
        />
        {!this.state.collapsed && (
          <UnitTypeTreeWrapper
            key={this.props.unitType.id + "wrapper"}
            parentId={this.props.unitType.id}
            level={this.props.level + 1}
            hide={this.state.childrenHiding}
            handleSectionCollapsed={this.handleSectionCollapsed}
          />
        )}
      </div>
    );
  }
}

export default UnitTypeTreeItem;
