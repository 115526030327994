import React, { Component } from "react";

import "./style.scss";
import { DescribedButton } from "react-bootstrap-tools";
import InfoSectionUnitTypeTreeWrapper from "../../containers/InfoSectionUnitTypeTreeWrapper/index";
import { ButtonGroup } from "react-bootstrap";

class InfoSectionUnitTypes extends Component {
  constructor(props) {
    super(props);

    this.state = {
      canSave: false,
      selectedId: props.unitTypeId || null,
      isAddition: props.isAddition
    };
  }

  handleUnitTypeSelect = id => {
    this.setState({
      canSave: true,
      selectedId: id
    });
  };

  selectUnitType = (id, unitTypeTree) => {
    return unitTypeTree.map(unitType => {
      let checkedLayer = unitType;
      if (checkedLayer.id === id) {
        checkedLayer.selected = !checkedLayer.selected;
        this.toggleCanSave(checkedLayer.id, checkedLayer.selected);
      } else {
        checkedLayer.selected = false;
      }
      checkedLayer.children = this.selectUnitType(id, checkedLayer.children);

      return checkedLayer;
    });
  };

  toggleCanSave = (id, isSelected) => {
    this.setState({
      selectedId: isSelected ? id : null,
      canSave: isSelected
    });
  };

  handleSaveClick = () => {
    this.props.setModalConf(this.props.modalId, {
      unitTypeId: this.state.selectedId,
      isAdditionPath: false
    });
    this.props.setModalType(this.props.modalId, "unitInfo");
  };

  handleCancelClick = () => {
    !this.state.isAddition
      ? this.handleCancelEdition()
      : this.handleCancelAddition();
  };

  handleCancelEdition = () => {
    this.props.setModalType("unitInfo");
  };

  handleCancelAddition = () => {
    this.props.isAdditionPath
      ? this.props.closeModalSection(this.props.modalId)
      : this.props.setModalType("unitInfo");
  };

  render() {
    return (
      <div className="InfoSectionUnitTypes">
        <InfoSectionUnitTypeTreeWrapper
          level={0}
          parentId={null}
          handleUnitTypeSelect={this.handleUnitTypeSelect}
          selectedId={this.state.selectedId}
        />
        <div className="InfoSectionUnitTypes__buttons">
          <ButtonGroup>
            <DescribedButton
              buttonClassName="InfoSectionUnitTypes__button"
              onClick={this.handleSaveClick}
              size="sm"
              disabled={!this.state.canSave}
              icon="done"
            />
            <DescribedButton
              buttonClassName="InfoSectionUnitTypes__button"
              onClick={this.handleCancelClick}
              size="sm"
              icon="close"
            />
          </ButtonGroup>
        </div>
      </div>
    );
  }
}

export default InfoSectionUnitTypes;
