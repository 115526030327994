export const getFormGroupProps = (props, state) => {
  let componentProps = {
    className: "FormData"
  };

  if (!!props.className)
    componentProps.className = componentProps.className + " " + props.className;

  return componentProps;
};

export const getInputProps = (props, state) => {
  let inputProps = {
    type: "text",
    value: state.value,
    maxLength: state.maxLength,
    className: "FormData__input",
    isInvalid: state.isValid === false,
    size: "sm"
  };

  if (!!props.placeholder) inputProps.placeholder = props.placeholder;

  if (!!props.as) inputProps.as = props.as;

  return inputProps;
};

export const presentationValue = (value, flag) => {
  switch (flag) {
    case "int":
      return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    default:
      return value;
  }
};
