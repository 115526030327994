import { depracated_handleFetch } from "phyzzi.com-frontend-utils";
import Config from "../../containers/App/config";

export const handleFetchPingUpdates = conf => {
  depracated_handleFetch(
    {
      url: Config.url.api.pingUpdates,
      token: conf.token
    },
    {
      success: json =>
        handleFetchPingUpdatesSuccess(json, conf.handlePingUpdatesSuccess),
      error: () => handleFetchPingUpdatesFail(conf.invokeInfo),
      code_503: () => handleFetchPingUpdatesFailMaintenance(conf.invokeInfo)
    }
  );
};

const handleFetchPingUpdatesSuccess = (json, handlePingUpdatesSuccess) => {
  handlePingUpdatesSuccess(json);
};

const handleFetchPingUpdatesFail = invokeInfo => {
  invokeInfo({
    variant: "danger",
    message: "PhyzziFrontend.fetchFailAlert"
  });
};

const handleFetchPingUpdatesFailMaintenance = invokeInfo => {
  invokeInfo({
    variant: "danger",
    message: "PhyzziFrontend.maintenanceModeDetected"
  });
  window.location.assign(Config.url.mainPage);
};

export const handleFetchPoints = conf => {
  depracated_handleFetch(
    {
      url: Config.url.api.fetchPoints
        .replace("_POINT_TYPE_ID_", encodeURIComponent(conf.unitTypeStringId))
        .replace(
          "_LAST_UPDATE_",
          // encodeURIComponent(Math.floor((new Date(data.timestamp).getTime() / 1000) - (new Date().getTimezoneOffset() * 60)))
          encodeURIComponent(conf.timestamp)
        ),
      token: conf.token
    },
    {
      success: json => conf.receivePoints(json, conf.unitTypeStringId),
      error: () =>
        handleFetchPointsFail(
          conf.toggleUnitTypeSelection,
          conf.unitTypeId,
          conf.invokeInfo
        )
    }
  );
};

const handleFetchPointsFail = (
  toggleUnitTypeSelection,
  unitTypeId,
  invokeInfo
) => {
  toggleUnitTypeSelection(unitTypeId);
  invokeInfo({
    variant: "danger",
    message: "PhyzziFrontend.fetchPointsFailAlert"
  });
};

export const handleFetchPublicGroupData = conf => {
  depracated_handleFetch(
    {
      url: Config.url.api.publicDataResource + "/" + conf.category,
      token: conf.token
    },
    {
      success: json => conf.receivePublicGroupData(conf.category, json),
      error: () => handleFetchPublicGroupDataFail(conf.invokeInfo)
    }
  );
};

const handleFetchPublicGroupDataFail = invokeInfo => {
  invokeInfo({
    variant: "danger",
    message: "PhyzziFrontend.fetchGroupDataFailAlert"
  });
};
