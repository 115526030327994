import Immutable from "seamless-immutable";

/**
 * Utility for mass update of array state. Only changed (added, edited) objects
 * will mutate. Other objects remain Immutable.
 *
 * @param Immutable(array) currentArray - array of objects representing current state
 * @param array newArray - array of changes to process. Action command under 'updateAction' key. Match by 'id' key
 * @returns Immutable(array)
 */
export const updatePoints = (
  actualPoints,
  stringId,
  receivedPoints,
  lastUpdate
) => {
  const pointType = actualPoints.filter(type => type.stringId === stringId);
  let currentArray = pointType[0].points;

  const arrayWithNoDeleted = deleteElements(currentArray, receivedPoints);
  const arrayWithEdited = editElements(arrayWithNoDeleted, receivedPoints);
  const arrayWithAdded = addElements(arrayWithEdited, receivedPoints);

  if (arrayWithAdded !== currentArray) {
    return actualPoints.map(type => {
      if (type.stringId === stringId)
        return {
          ...type,
          points: arrayWithAdded,
          lastUpdate
        };
      return type;
    });
  } else {
    return actualPoints;
  }
};

/**
 * Returns Immutable(array) of objects without marked as to delete
 * @param Immutable(array) currentArray - array of objects representing modified current state
 * @param array newArray - array of changes to process. Objects to delete: updateAction: 'DELETE'
 * @returns Immutable(array)
 */
const deleteElements = (currentArray, newArray) => {
  const toDeletes = getElementsToDelete(currentArray, newArray);

  if (toDeletes.length > 0)
    return Immutable(
      currentArray.filter(
        element => !toDeletes.some(toDelete => toDelete.id === element.id)
      )
    );

  return currentArray;
};

/**
 * Returns array of objects to delete from current state
 * @param Immutable(array) currentArray - array of objects representing modified current state
 * @param array newArray - array of changes to process. Objects to delete: updateAction: 'DELETE'
 * @returns array
 */
const getElementsToDelete = (currentArray, newArray) => {
  return newArray.filter(
    toModify =>
      toModify.updateAction === "DELETE" &&
      currentArray.some(element => element.id === toModify.id)
  );
};

/**
 * Returns Immutable(array) of objects with edited ones
 * @param Immutable(array) currentArray - array of objects representing modified current state
 * @param array newArray - array of changes to process. Objects to eidt: updateAction: 'EDIT'
 * @returns Immutable(array)
 */
const editElements = (currentArray, newArray) => {
  const toEdits = getElementsToEdit(currentArray, newArray);

  if (toEdits.length > 0)
    return Immutable(
      currentArray.map(element => {
        const elementToEdit = getElementToEdit(element, toEdits);
        if (!!elementToEdit) {
          return editElement(element, elementToEdit);
        }
        return element;
      })
    );

  return currentArray;
};

/**
 * Returns array of objects to edit on current state
 * @param Immutable(array) currentArray - array of objects representing modified current state
 * @param array newArray - array of changes to process. Objects to edit: updateAction: 'EDIT'
 * @returns array
 */
const getElementsToEdit = (currentArray, newArray) => {
  return newArray.filter(
    toModify =>
      toModify.updateAction === "EDIT" &&
      currentArray.some(element => element.id === toModify.id)
  );
};

/**
 * Returns object to edit filtered from elementsToEdit array
 * @param Immutable(object) currentElement - object from modified current state which is to edit
 * @param array elementsToEdit - array of elements to filter from (match by 'id' key)
 * @returns object || null
 */
const getElementToEdit = (currentElement, elementsToEdit) => {
  const elementArray = elementsToEdit.filter(
    elementToEdit => elementToEdit.id === currentElement.id
  );

  if (elementArray.length > 0) return elementArray[0];

  return null;
};

/**
 * Returns Immutable(object) of edited object
 * @param Immutable(object) currentElement - object from modified current state which is to edit
 * @param object finalElement - object with final data
 * @returns Immutable(array)
 */
const editElement = (currentElement, finalElement) => {
  // Copy of original object. This one will be edited and return
  let currentElementEdited = Immutable.asMutable(currentElement);
  // If edited - edited object shall return, otherwise the currentElement one
  let edited = false;

  Object.keys(currentElement).forEach(key => {
    if (["lat", "lon"].indexOf(key) > -1) {
      if (currentElementEdited[key] !== finalElement[key] / 1000000) {
        edited = true;
        currentElementEdited[key] = finalElement[key] / 1000000;
      }
    } else {
      if (currentElement[key] !== finalElement[key]) {
        edited = true;
        currentElementEdited[key] = finalElement[key];
      }
    }
  });

  if (edited) return Immutable(currentElementEdited);

  return currentElement;
};

/**
 * Returns Immutable(array) of objects including those marked as to add
 * @param Immutable(array) currentArray - array of objects representing modified current state
 * @param array newArray - array of changes to process. Objects to add: updateAction: 'ADD'
 * @returns Immutable(array)
 */
const addElements = (currentArray, newArray) => {
  const toAdds = getElementsToAdd(currentArray, newArray);

  if (toAdds.length > 0) return Immutable([...currentArray, ...toAdds]);

  return currentArray;
};

/**
 * Returns array of objects to add to current state
 * @param Immutable(array) currentArray - array of objects representing modified current state
 * @param array newArray - array of changes to process. Objects to add: updateAction: 'ADD'
 * @returns array
 */
const getElementsToAdd = (currentArray, newArray) => {
  return newArray
    .filter(
      toModify =>
        ["ADD", "EDIT"].indexOf(toModify.updateAction) > -1 &&
        currentArray.every(element => element.id !== toModify.id)
    )
    .map(toModify => {
      return {
        ...toModify,
        lat: toModify.lat / 1000000,
        lon: toModify.lon / 1000000
      };
    });
};
