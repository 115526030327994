export const getClassName = level => {
  return "UnitTypeTree" + (level > 0 ? "__sublevel" : "");
};

export const getHasChildren = (unitTypes, unitId) => {
  return unitTypes.some(item => item.parentId === unitId);
};

export const getSelectedUnitTypes = unitTypeTree => {
  return unitTypeTree.data.filter(unitType => unitType.selected);
};
