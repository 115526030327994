import { connect } from "react-redux";

import ModalSectionMenu from "../../components/ModalSectionMenu";
import {
  closeModalSection,
  showModalContent,
  showModalNextContent,
  showModalPreviousContent
} from "../ModalGroupWrapper/actions";
import { unselectUnit } from "../MapContainerWrapper/actions";

const mapStateToProps = (state, props) => ({
  modalSection: state.modalSection[props.id],
  menuItemsConf: {
    userId: state.user.uuid
  }
});

const mapDispatchToProps = {
  showModalContent,
  showModalPreviousContent,
  showModalNextContent,
  closeModalSection,
  unselectUnit
};

const ModalSectionMenuWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalSectionMenu);

export default ModalSectionMenuWrapper;
