import moment from "moment-timezone";
import Config from "../containers/App/config";
import mapConfig from "../components/MapContainer/config";
import { unifyDate } from "phyzzi.com-frontend-utils";

/**
 * Helper for getting right language
 * When Phyzzi will get translations, languages array should be provided by backend. Right now it may be statically typed
 */
export const getLanguageId = language => {
  const languages = ["en", "ru"];
  const defaultLanguage = "en";
  const correctLanguage = language.substring(0, 2).toLowerCase();

  if (languages.indexOf(correctLanguage) > -1) return correctLanguage;

  return defaultLanguage;
};

/**
 * Returns if application should redirect itself to a lowResolutionPage
 * @returns {boolean}
 */
export const getShouldRedirectToSmallScreenPage = (
  windowWidth,
  userAgent,
  minScreenWidth,
  searchBots
) => {
  const isSearchBot = getIsSearchBot(userAgent, searchBots);
  return !isSearchBot && windowWidth < minScreenWidth;
};

/**
 * Returns if request been made by user bot
 * @param userAgent
 * @param searchBots
 * @returns {boolean}
 */
const getIsSearchBot = (userAgent, searchBots) => {
  return searchBots.test(userAgent);
};

/**
 * Returns if application should redirect itself to a lowIePage
 * @returns {boolean}
 */
export const getShouldRedirectToLowIePage = () => {
  const ieVersion = getIeVersion();
  return !!ieVersion && ieVersion < 12;
};

/**
 * @returns numeric version of ie (>= 12 means Edge) or false (if other than ie)
 */
const getIeVersion = () => {
  var ua = window.navigator.userAgent;

  var msie = ua.indexOf("MSIE ");
  if (msie > 0) {
    // IE 10 or older => return version number
    return parseInt(ua.substring(msie + 5, ua.indexOf(".", msie)), 10);
  }

  var trident = ua.indexOf("Trident/");
  if (trident > 0) {
    // IE 11 => return version number
    var rv = ua.indexOf("rv:");
    return parseInt(ua.substring(rv + 3, ua.indexOf(".", rv)), 10);
  }

  var edge = ua.indexOf("Edge/");
  if (edge > 0) {
    // Edge (IE 12+) => return version number
    return parseInt(ua.substring(edge + 5, ua.indexOf(".", edge)), 10);
  }

  // other browser
  return false;
};

/**
 * Returns unified date object within server's timezone
 *
 * @param dateObject
 * @returns {*}
 */
export const toServerTimezone = dateObject => {
  return dateObject.clone().tz(Config.dbTimezone);
};

/**
 * Returns unified date object within user's timezone
 *
 * @param dateObject
 * @returns {*}
 */
export const toUserTimezone = dateObject => {
  const userTimezone = moment.tz.guess();

  return dateObject.clone().tz(userTimezone);
};

/**
 * If given date is on the same day (of user's timezone) it returns hours:minutes, otherwise year-months-days
 * ToDo: this function is identical to one on messenger. Nevertheless messenger will be moved to separate project so it's acceptable
 *
 * @param dateString
 */
export const transformDate = dateString => {
  const topicDate = toUserTimezone(unifyDate(dateString));
  const topicDateString = topicDate.format("YYYY-MM-DD");
  const actualDate = moment();

  if (topicDateString === actualDate.format("YYYY-MM-DD"))
    return topicDate.format("HH:mm");

  return topicDateString;
};

export const shouldCookieInfoAppear = (cookies, cookieInfoConfig) => {
  const extractedCookies = extractCookies(cookies);

  return (
    !extractedCookies[cookieInfoConfig.cookieName] ||
    extractedCookies[cookieInfoConfig.cookieName] !==
      cookieInfoConfig.cookieDismissedValue
  );
};

export const shouldEnterVideoAppear = (cookies, cookieInfoConfig) => {
  const extractedCookies = extractCookies(cookies);

  return (
    !extractedCookies[cookieInfoConfig.cookieName] ||
    extractedCookies[cookieInfoConfig.cookieName] !==
      cookieInfoConfig.cookieDismissedValue
  );
};

/**
 * Returns cookie converted to an object
 *
 * @param cookies
 */
export const extractCookies = cookies => {
  let cookieObject = {};

  cookies.split(";").forEach(cookieArray => {
    const splittedArray = cookieArray.split("=");
    if (!!splittedArray[0] && !!splittedArray[1])
      cookieObject[splittedArray[0].trim()] = splittedArray[1].trim();
  });

  return cookieObject;
};

/**
 * Returns map zoom level inside it's config range
 *
 * @param int level
 */
export const getMapRangedZoomLevel = level => {
  return Math.min(
    Math.max(level, mapConfig.map.initial.minZoom),
    mapConfig.map.initial.maxZoom
  );
};

/**
 * Returns props for image icon presented on map
 *
 * @param string icons - i.e. circle_ff0000:square_0000ff
 * @param object pointConfig - i.e. {iconUrl: 'url_to_icon_folder', iconSize: {circle: [13, 13]}, iconAnchor: {circle: [7, 7]}}
 * @param string mapType - map | satellite
 * @returns {{iconUrl: 'url_to_icon', iconSize: [13, 13], iconAnchor: [7, 7], className: null}}
 */
export const getMapIconProps = (icons, iconConfig, mapType) => {
  const iconTypes = icons.split(":");
  const icon =
    mapType === "satellite" && !!iconTypes[1] ? iconTypes[1] : iconTypes[0];
  const iconData = icon.split("_");

  return {
    iconUrl: iconConfig.iconUrl.replace("_ICON_NAME_", icon),
    iconSize: iconConfig.map.iconSize[iconData[0]],
    iconAnchor: iconConfig.map.iconAnchor[iconData[0]],
    className: !!iconConfig.className ? iconConfig.className : null
  };
};
