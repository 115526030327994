import i18n from "i18next";
import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";

// Custom language detector. Tries to get locale from containers dataset
// <div id='App' data-locale=chosen-locale />
const datasetDetector = {
  name: "datasetDetector",

  lookup(options) {
    const dataset = document.getElementById("App").dataset;
    if (!!dataset.locale) return dataset.locale;

    return null;
  },

  cacheUserLanguage(lng, options) {
    // options -> are passed in options
    // lng -> current language, will be called after init and on changeLanguage
    // store it
  }
};

const lngDetector = new LanguageDetector();
lngDetector.addDetector(datasetDetector);

const shouldSaveMissing = () => {
  const dataset = document.getElementById("App").dataset;

  if (
    !!dataset.leaveTranslationLabels &&
    dataset.leaveTranslationLabels === "true"
  )
    return false;

  return true;
};

const shouldDebug = () => {
  return false;
};

const parseResults = (data, filename) => {
  let json = JSON.parse(data);

  const dataset = document.getElementById("App").dataset;

  if (
    !!dataset.leaveTranslationLabels &&
    dataset.leaveTranslationLabels === "true"
  ) {
    const labels = Object.keys(json);

    const filenameParts = filename.split("/");
    const ns = filenameParts[filenameParts.length - 1];

    labels.forEach(label => {
      json[label] = "[[" + ns + "." + label + "]]";
    });
  }

  return json;
};

// options for LanguageDetector
const languageDetectorOptions = {
  // order and from where user language should be detected
  order: ["datasetDetector", "path", "cookie", "htmlTag", "localStorage"],

  // keys or params to lookup language from
  lookupCookie: "Phyzzi_locale",
  lookupLocalStorage: "i18nextLng",
  lookupFromPathIndex: 0,

  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement
};

i18n
  .use(lngDetector)
  .use(XHR)
  .init(
    {
      backend: {
        // path where resources get loaded from, or a function
        // returning a path:
        // function(lngs, namespaces) { return customPath; }
        // the returned path will interpolate lng, ns if provided like giving a static path
        loadPath: "/api/locales/{{lng}}/{{ns}}",

        // path to post missing resources
        addPath: "/api/locales/{{lng}}/{{ns}}",

        // your backend server supports multiloading
        // /locales/resources.json?lng=de+en&ns=ns1+ns2
        allowMultiLoading: false, // set loadPath: '/locales/resources.json?lng={{lng}}&ns={{ns}}' to adapt to multiLoading

        // parse data after it has been fetched
        // in example use https://www.npmjs.com/package/json5
        // here it removes the letter a from the json (bad idea)
        // parse: function(data) { return data.replace(/a/g, ''); },
        parse: (data, filename) => parseResults(data, filename),

        // allow cross domain requests
        crossDomain: false,

        // allow credentials on cross domain requests
        withCredentials: false

        // define a custom xhr function
        // can be used to support XDomainRequest in IE 8 and 9
        // ajax: function (url, options, callback, data) {},

        // adds parameters to resource URL. 'example.com' -> 'example.com?v=1.3.5'
        // queryStringParams: { v: '1.3.5' }
      },
      fallbackLng: "en",
      debug: shouldDebug(),

      // have a common namespace used around the full app
      ns: ["frontend", "common"],
      defaultNS: "frontend",

      keySeparator: false, // we use content as keys

      interpolation: {
        escapeValue: false, // not needed for react!!
        formatSeparator: ","
      },

      react: {
        wait: true
      },

      saveMissing: shouldSaveMissing(),

      detection: languageDetectorOptions
    },
    (err, t) => {
      // ...
    }
  );

export default i18n;
