import { CHANGE_MAP_BACKGROUND } from "../MapContainerWrapper/actions";
import { INJECT_DATA } from "./actions";

const MAP_THEME = "map-theme";
const SATELLITE_THEME = "satellite-theme";
const INITIAL_THEME = MAP_THEME;

function themeReducers(state = INITIAL_THEME, action) {
  switch (action.type) {
    case INJECT_DATA:
      if (action.injectedData.defaultMapType) {
        return action.injectedData.defaultMapType;
      }
      return state;
    case CHANGE_MAP_BACKGROUND:
      switch (action.background) {
        case "map":
          return MAP_THEME;
        case "satellite":
          return SATELLITE_THEME;
        default:
          return state;
      }
    default:
      return state;
  }
}

export default themeReducers;
