import { depracated_handleFetch } from "phyzzi.com-frontend-utils";
import config from "../../containers/App/config";

export const handleFetchSendContact = conf => {
  depracated_handleFetch(
    {
      url: config.url.api.contact,
      method: "POST",
      token: conf.token,
      body: conf.body
    },
    {
      success: () =>
        handleFetchSendContactSuccess(
          conf.invokeInfo,
          conf.handleFetchSendContactSuccessCallback
        ),
      error: () =>
        handleFetchSendContactFail(
          conf.invokeInfo,
          conf.handleFetchSendContactFailCallback
        )
    }
  );
};

const handleFetchSendContactSuccess = (invokeInfo, callback) => {
  callback();

  invokeInfo({
    variant: "success",
    message: "Contact.sendSuccessAlert"
  });
};

const handleFetchSendContactFail = (invokeInfo, callback) => {
  callback();

  invokeInfo({
    variant: "danger",
    message: "Contact.sendFailAlert"
  });
};
