import React from "react";

import "./style.scss";
import { getInterlocutor, transformDate } from "../Messenger/functions";
import { Avatar } from "react-bootstrap-tools";

export default function MessengerMessage(props) {
  const interlocutor = getInterlocutor(
    { messengerParticipants: props.participants },
    props.author.uuid
  );
  const isAuthor = interlocutor.id !== props.message.messenger_participant_id;

  return (
    <div
      className={"MessengerMessage" + (isAuthor ? "--author" : "")}
      id={"message_" + props.message.id}
    >
      <div className="MessengerMessage__content">
        {!isAuthor && (
          <Avatar
            src={!!interlocutor.user ? interlocutor.user.picture : null}
            className="MessengerMessage__thumbnail-wrapper"
            iconClassName="MessengerMessage__thumbnail"
            imageClassName="MessengerMessage__thumbnail"
          />
        )}
        <div
          className="MessengerMessage__text"
          dangerouslySetInnerHTML={{
            __html: props.message.content.replace(/\n/g, "<br />")
          }}
        />
        {isAuthor && (
          <Avatar
            src={props.author.picture}
            className="MessengerMessage__thumbnail-wrapper--author"
            iconClassName="MessengerMessage__thumbnail"
            imageClassName="MessengerMessage__thumbnail"
          />
        )}
      </div>
      <div className="MessengerMessage__date">
        {transformDate(props.message.updated_at)}
      </div>
    </div>
  );
}
