import React from "react";

import "./style.scss";
import { DescribedButton } from "react-bootstrap-tools";
import { translate } from "react-i18next";
import { shouldRefreshButtonAppear } from "./functions";
import { Avatar } from "react-bootstrap-tools";
import { transformDate } from "../../utils/helpers";

export const InfoSectionBoardRowComponent = props => {
  const { t } = props;
  const refreshButtonDescription = t(
    props.canUserEditMessage
      ? "InfoSectionBoardRow.refreshButtonDescription"
      : "InfoSectionBoardRow.refreshButtonDescriptionLowAccountType"
  );
  const editButtonDescription = t(
    props.canUserEditMessage
      ? "InfoSectionBoardRow.editButtonDescription"
      : "InfoSectionBoardRow.editButtonDescriptionDisabled"
  );
  const deleteButtonDescription = t(
    "InfoSectionBoardRow.deleteButtonDescription"
  );

  const handleUserNameClick = uuid => {
    props.showModalContent({
      type: "userProfile",
      conf: {
        userId: uuid
      }
    });
  };

  const handleRefreshButtonClick = id => {
    props.handleRefresh(id);
  };

  const handleDeleteButtonClick = id => {
    props.handleDelete(id);
  };

  const handleMessageClick = uuid => {
    props.showModalContent({
      type: "messenger",
      conf: {
        userUuid: uuid
      }
    });
  };

  const handleEditButtonClick = id => {
    props.toggleEditForm(id);
  };

  return (
    <tr
      key={props.message.id}
      className={
        "InfoSectionBoardRow" +
        (props.message.uuid === props.user.uuid ? "--highlighted" : "")
      }
    >
      <td className="InfoSectionBoardRow__cell">
        <Avatar
          src={props.message.picture}
          className="InfoSectionBoardRow__image-wrapper"
          iconClassName="InfoSectionBoardRow__icon"
        />
      </td>
      <td className="InfoSectionBoardRow__cell--left">
        <div>
          <DescribedButton
            buttonClassName="InfoSectionBoardRow__name"
            onClick={handleUserNameClick}
            text={props.message.name + " " + props.message.surname}
            onClickArg={props.message.uuid}
          />
        </div>
        <div className="InfoSectionBoardRow__position">
          {props.message.position}
        </div>
        <div className="InfoSectionBoardRow__location">
          {props.message.location}
        </div>
      </td>
      <td className="InfoSectionBoardRow__cell">
        {transformDate(props.message.updated_at)}
        {shouldRefreshButtonAppear(props.message, props.user) && (
          <DescribedButton
            description={refreshButtonDescription}
            onClick={handleRefreshButtonClick}
            icon="refresh"
            disabled={!props.canUserEditMessage}
            buttonClassName="InfoSectionBoardRow__refresh-button"
            iconClassName="InfoSectionBoardRow__refresh-button-icon"
            onClickArg={props.message.id}
          />
        )}
      </td>
      <td className={"InfoSectionBoardRow__cell--type_" + props.message.type}>
        {t("common:boardType." + props.message.type)}
      </td>
      <td className="InfoSectionBoardRow__cell--group">
        {props.message.board_group_name}
      </td>
      <td className="InfoSectionBoardRow__cell">{props.message.keyword}</td>
      <td className="InfoSectionBoardRow__cell--description">
        {props.message.message}
      </td>
      <td className="InfoSectionBoardRow__cell">
        <div>
          {props.user.uuid === props.message.uuid && (
            <DescribedButton
              description={editButtonDescription}
              onClick={handleEditButtonClick}
              buttonClassName="InfoSectionBoardRow__icon-button"
              icon="edit"
              disabled={!props.canUserEditMessage}
              onClickArg={props.message.id}
            />
          )}
          {props.user.uuid !== props.message.uuid ? (
            <DescribedButton
              onClick={handleMessageClick}
              buttonClassName="InfoSectionBoardRow__icon-button"
              icon="email"
              disabled={!props.user.uuid}
              onClickArg={props.message.uuid}
            />
          ) : (
            <DescribedButton
              description={deleteButtonDescription}
              onClick={handleDeleteButtonClick}
              onClickArg={props.message.id}
              buttonClassName="InfoSectionBoardRow__icon-button"
              icon={"delete"}
            />
          )}
        </div>
      </td>
    </tr>
  );
};

export default translate("frontend")(InfoSectionBoardRowComponent);
