import {
  RECEIVE_PUBLIC_DATA,
  RECEIVE_PUBLIC_UNIT_TYPES_DATA
} from "../App/actions";
import Immutable from "seamless-immutable";
import {
  TOGGLE_SECTION_COLLAPSE,
  TOGGLE_UNIT_TYPE_SELECTION,
  CLEAR_ALL_UNIT_TYPE_FILTERS
} from "./actions";
import { updateArrayState } from "../../utils/updateArrayState";

const initialState = {
  data: Immutable([])
};

function unitTypeTreeReducers(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_PUBLIC_DATA:
      return Object.assign({}, state, {
        data: updateArrayState(state.data, action.json.unit_types.data, {
          addOnly: ["id", "collapsed", "selected"]
        })
      });
    case RECEIVE_PUBLIC_UNIT_TYPES_DATA:
      return Object.assign({}, state, {
        data: updateArrayState(state.data, action.json.data, {
          addOnly: ["id", "collapsed", "selected"]
        })
      });
    // @ToDo: data is Immutable, so change may not work that easy
    // also map() creates new object, so maybe different way to change state?
    case TOGGLE_SECTION_COLLAPSE:
      return Object.assign({}, state, {
        data: state.data.map(item => {
          if (item.id === action.id)
            return {
              ...item,
              collapsed: !item.collapsed
            };

          return item;
        })
      });
    // @ToDo: data is Immutable, so change may not work that easy
    // also map() creates new object, so maybe different way to change state?
    case TOGGLE_UNIT_TYPE_SELECTION:
      return Object.assign({}, state, {
        data: state.data.map(item => {
          if (item.id === action.id)
            return {
              ...item,
              selected: !item.selected
            };

          return item;
        })
      });
    // @ToDo: data is Immutable, so change may not work that easy
    // also map() creates new object, so maybe different way to change state?
    case CLEAR_ALL_UNIT_TYPE_FILTERS:
      return Object.assign({}, state, {
        data: state.data.map(unitType => {
          if (unitType.selected) {
            return {
              ...unitType,
              selected: false
            };
          }

          return unitType;
        })
      });
    default:
      return state;
  }
}

export default unitTypeTreeReducers;
