import React, { Component } from "react";

import "./style.scss";
import {
  getMenuClassName,
  getMenuItems,
  getMenuToSelect,
  getNavbarStatus,
  getNavClassName
} from "./functions";
import { DescribedButton } from "react-bootstrap-tools";
import { translate } from "react-i18next";
import { getModalInstance } from "../../containers/ModalGroupWrapper/functions";
import MovableWrapper from "../../containers/MovableWrapper/index";

class ModalSectionMenuComponent extends Component {
  constructor(props) {
    super(props);

    const modalInstance = getModalInstance(props.modalSection.type);

    this.state = {
      modalInstance,
      selectedMenu: getMenuToSelect(props.modalSection.type),
      navbarStatus: getNavbarStatus(
        modalInstance,
        props.modalSection.history,
        props.modalSection.historyPointer
      ),
      menuItems: getMenuItems(
        modalInstance,
        props.modalSection,
        props.menuItemsConf
      )
    };

    this.t = props.t;
  }

  componentWillReceiveProps(props) {
    const modalInstance = getModalInstance(props.modalSection.type);

    this.setState({
      modalInstance,
      selectedMenu: getMenuToSelect(props.modalSection.type),
      navbarStatus: getNavbarStatus(
        modalInstance,
        props.modalSection.history,
        props.modalSection.historyPointer
      ),
      menuItems: getMenuItems(
        modalInstance,
        props.modalSection,
        props.menuItemsConf
      )
    });
  }

  handleModalClose = () => {
    this.props.closeModalSection(this.props.id);
    if(this.props.modalSection.type !== 'contact') {
      this.props.unselectUnit();
    };
  };

  handleMenuClick = menuItem => {
    if (!menuItem.disabled && menuItem.id !== this.state.selectedMenu) {
      const showData = {
        type: menuItem.modalType
      };

      if (!!menuItem.conf) {
        showData.conf = menuItem.conf;
      }

      this.props.showModalContent(showData);
    }
  };

  handlePrevClick = () => {
    if (!this.state.navbarStatus.prevDisabled)
      this.props.showModalPreviousContent();
  };

  handleNextClick = () => {
    if (!this.state.navbarStatus.nextDisabled)
      this.props.showModalNextContent();
  };

  handleExternalContentClick = url => {
    window.open(url);
    this.setState({
      navbarStatus: {
        ...this.state.navbarStatus
      }
    });
  };

  render() {
    const menu = (
      <div className="ModalSectionMenu">
        {this.state.navbarStatus.shown && (
          <ul className="ModalSectionMenu__nav--left">
            <li className="ModalSectionMenu__item">
              <DescribedButton
                onClick={this.handlePrevClick}
                buttonClassName="ModalSectionMenu__button"
                disabled={this.state.navbarStatus.prevDisabled}
                icon="arrow_forward"
              />
            </li>
            <li className="ModalSectionMenu__item">
              <DescribedButton
                onClick={this.handleNextClick}
                buttonClassName="ModalSectionMenu__button"
                disabled={this.state.navbarStatus.nextDisabled}
                icon="arrow_forward"
              />
            </li>
          </ul>
        )}

        {!!this.state.modalInstance && (
          <ul className={getNavClassName(this.state.modalInstance)}>
            {this.state.menuItems.map(menuItem => {
              return (
                <li className="ModalSectionMenu__item" key={menuItem.id}>
                  <DescribedButton
                    buttonClassName={getMenuClassName(
                      menuItem,
                      this.state.selectedMenu
                    )}
                    onClick={this.handleMenuClick}
                    onClickArg={menuItem}
                    disabled={menuItem.disabled === true}
                    text={this.t(menuItem.label)}
                    useTranslation={menuItem.useTranslation}
                  />
                </li>
              );
            })}
          </ul>
        )}

        <ul className="ModalSectionMenu__nav--right">
          {this.state.navbarStatus.externalContentShown && (
            <li className="ModalSectionMenu__item">
              <DescribedButton
                onClick={this.handleExternalContentClick}
                onClickArg={this.props.modalSection.conf.url}
                buttonClassName="ModalSectionMenu__button"
                disabled={this.state.navbarStatus.externalContentDisabled}
                icon="open_in_new"
              />
            </li>
          )}
          <li className="ModalSectionMenu__item">
            <DescribedButton
              onClick={this.handleModalClose}
              buttonClassName="ModalSectionMenu__button"
              icon="close"
            />
          </li>
        </ul>
      </div>
    );

    return this.props.movable ? (
      <MovableWrapper onMove={this.props.handlePositionChange}>
        {menu}
      </MovableWrapper>
    ) : (
      menu
    );
  }
}

export default translate("frontend")(ModalSectionMenuComponent);
