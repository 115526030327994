import { connect } from "react-redux";

import UnitTypeTree from "../../components/UnitTypeTree";

const mapStateToProps = state => ({
  unitTypeTree: state.unitTypeTree.data,
  unitTypes: state.unitTypes.data
});

const mapDispatchToProps = {};

const UnitTypeTreeWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnitTypeTree);

export default UnitTypeTreeWrapper;
