/*
 * Action types
 */
export const START_EMITTING_CALLBACK = "START_EMITTING_CALLBACK";
export const STOP_EMITTING_CALLBACK = "STOP_EMITTING_CALLBACK";

/*
 * Action creators
 */
export function startEmittingCallback(callback, screenX, screenY) {
  return {
    type: START_EMITTING_CALLBACK,
    callback,
    screenX,
    screenY
  };
}

export function stopEmittingCallback() {
  return {
    type: STOP_EMITTING_CALLBACK
  };
}
