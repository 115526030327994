import { connect } from "react-redux";

import AlertBox from "../../components/AlertBox";
import { dismissInfo } from "./actions";

const mapStateToProps = state => ({
  alertBox: state.alertBox,
  isLeftSectionCollapsed: state.leftSection.collapsed
});

const mapDispatchToProps = { dismissInfo };

const AlertBoxWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(AlertBox);

export default AlertBoxWrapper;
