import React from "react";

import "./style.scss";
import { DescribedButton } from "react-bootstrap-tools";
import { translate } from "react-i18next";

const MessengerInterlocutor = props => {
  const { t } = props;

  return (
    <div className="MessengerInterlocutor">
      <div>
        {!!props.interlocutor.user ? (
          <DescribedButton
            onClick={props.handleInterlocutorClick}
            onClickArg={props.interlocutor.user.uuid}
            text={
              props.interlocutor.user.name +
              " " +
              props.interlocutor.user.surname
            }
            buttonClassName="MessengerInterlocutor__name"
          />
        ) : (
          <div className="MessengerInterlocutor__name">
            {t("MessengerInterlocutor.deletedUser")}
          </div>
        )}
      </div>
      <div className="MessengerInterlocutor__position">
        {!!props.interlocutor.user && props.interlocutor.user.position}
      </div>
    </div>
  );
};

export default translate("frontend")(MessengerInterlocutor);
