import { connect } from "react-redux";

import InfoSectionUnitTypes from "../../components/InfoSectionUnitTypes";
import {
  closeModalSection,
  setModalConf,
  setModalType
} from "../ModalGroupWrapper/actions";

const mapStateToProps = (state, props) => ({
  unitTypeId: state.modalSection[props.modalId].conf.unitTypeId || null,
  isAddition: state.modalSection[props.modalId].conf.isAddition,
  isAdditionPath: state.modalSection[props.modalId].conf.isAdditionPath
});

const mapDispatchToProps = {
  closeModalSection,
  setModalType,
  setModalConf
};

const InfoSectionUnitTypesWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoSectionUnitTypes);

export default InfoSectionUnitTypesWrapper;
