import React, { Component } from "react";

import "./style.scss";
import { DescribedButton } from "react-bootstrap-tools";
import { translate } from "react-i18next";
import { getInterlocutor } from "../Messenger/functions";
import { Form } from "react-bootstrap";

class MessengerNewMessage extends Component {
  defaultState = {
    isSending: false,
    content: "",
    newMessageSentTimestamp: 0
  };

  config = {
    textMaxLength: 7000
  };

  constructor(props) {
    super(props);

    this.state = this.defaultState;

    this.t = props.t;
    this.sendButtonText = this.t("common:send");
  }

  componentWillReceiveProps(props) {
    if (props.newMessageSentTimestamp !== this.state.newMessageSentTimestamp)
      this.setState({
        ...this.defaultState,
        newMessageSentTimestamp: props.newMessageSentTimestamp
      });
  }

  handleInputChange = content => {
    this.setState({
      content
    });
  };

  handleSend = () => {
    this.setState({
      isSending: true
    });

    const interlocutor = getInterlocutor(
      this.props.topic,
      this.props.author.uuid
    );

    this.props.onSendClick({
      id_messenger_topic: this.props.topic.id,
      content: this.state.content,
      interlocutor_uuid: interlocutor.user.uuid
    });
  };

  handleKeyPress = event => {
    if (event.key === "Enter" && !event.shiftKey) this.handleSend();
  };

  render() {
    if (!!this.props.topic) {
      const { t } = this.props;

      const interlocutor = getInterlocutor(
        this.props.topic,
        this.props.author.uuid
      );

      if (!interlocutor.user)
        return (
          <div className="MessengerNewMessage">
            {t("MessengerNewMessage.closedTopic")}
          </div>
        );

      return (
        <div className="MessengerNewMessage">
          <Form.Control
            readOnly={this.state.isSending}
            className="MessengerNewMessage__textarea"
            as="textarea"
            maxLength={this.config.textMaxLength}
            placeholder={t("MessengerNewMessage.formPlaceholder")}
            onChange={event => this.handleInputChange(event.target.value)}
            value={this.state.content}
            onKeyPress={this.handleKeyPress}
          />
          <div className="MessengerNewMessage__counter-wrapper">
            <p className="MessengerNewMessage__counter">
              {this.state.content.length} / {this.config.textMaxLength}
            </p>
          </div>
          <div className="MessengerNewMessage__button-wrapper">
            <DescribedButton
              text={this.sendButtonText}
              buttonClassName="MessengerNewMessage__button"
              disabled={this.state.content.length === 0 || this.state.isSending}
              onClick={this.handleSend}
            />
          </div>
        </div>
      );
    }

    return null;
  }
}

export default translate("frontend")(MessengerNewMessage);
