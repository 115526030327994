import { SET_BOTTOM_SECTION_SIZE_MODE } from "./actions";
import { getExpectedSizeMode } from "./functions";

export const BOTTOM_SECTION_SIZE_MODE_HIDDEN = "hidden";
export const BOTTOM_SECTION_SIZE_MODE_PARTIAL30 = "partial30";
export const BOTTOM_SECTION_SIZE_MODE_FULL = "full";
export const BOTTOM_SECTION_AVAILABLE_SIZE_MODES = [
  BOTTOM_SECTION_SIZE_MODE_HIDDEN,
  BOTTOM_SECTION_SIZE_MODE_PARTIAL30,
  BOTTOM_SECTION_SIZE_MODE_FULL
];

const sizeModeQueue = BOTTOM_SECTION_AVAILABLE_SIZE_MODES;
const initialState = {
  sizeMode: BOTTOM_SECTION_SIZE_MODE_HIDDEN
};

function bottomSectionReducers(state = initialState, action) {
  switch (action.type) {
    case SET_BOTTOM_SECTION_SIZE_MODE:
      const sizeMode = getExpectedSizeMode(
        sizeModeQueue,
        state.sizeMode,
        action.mode
      );
      return Object.assign({}, state, { sizeMode });
    default:
      return state;
  }
}

export default bottomSectionReducers;
