import React from "react";

import "./style.scss";
import MessengerListTopic from "../MessengerListTopic/index";

export default function MessengerListTopics(props) {
  return (
    <div className="MessengerListTopics">
      {props.content.map((topic, idx) => {
        return (
          <MessengerListTopic
            topic={topic}
            key={topic.id}
            onTopicListClick={props.onTopicListClick}
            isSelected={false}
            author={props.author}
          />
        );
      })}
    </div>
  );
}
