import React, { Component } from "react";

import { Provider } from "react-redux";
import thunkMiddleware from "redux-thunk";
import { createStore, applyMiddleware, compose } from "redux";

import reducers from "./reducers";
import { injectData, receivePrivateData, receivePublicData } from "./actions";

import "./style.scss";

import PhyzziFrontendWrapper from "../PhyzziFrontendWrapper/index";
import Config from "./config";
import { depracated_handleFetch } from "phyzzi.com-frontend-utils";
import { invokeInfo } from "../AlertBoxWrapper/actions";

const middleware = [thunkMiddleware];

const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(applyMiddleware(...middleware));

let store = createStore(reducers, enhancer);

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      /* The case is, that js executes differently on certain browsers. On Chrome it happened, that PhizzyFrontend
            component initialized, fetched and even analysed ping data before public data have fetched at the first place.
            It caused unfetching unit type points data even if given unit type has been selected.
            This way we don't want to initialise the application unless public and private data is fetched.
             */
      initialiseApplication: false
    };

    // Injecting data from data-* html attributes
    store.dispatch(injectData(props.dataset));

    // Fetching public and private data from backend
    store.dispatch(this.fetchPublicData()).then(() => {
      this.setState({
        initialiseApplication: true
      });

      store.dispatch(this.fetchPrivateData());
    });
  }

  fetchPublicData = () => {
    return (dispatch, getState) => {
      return depracated_handleFetch(
        {
          url: Config.url.api.publicDataResource,
          token: getState().initData.token
        },
        {
          success: json => this.fetchPublicDataSuccess(dispatch, json),
          error: () => this.fetchPublicDataFail(dispatch)
        }
      );
    };
  };

  fetchPublicDataSuccess = (dispatch, json) => {
    !!json && dispatch(receivePublicData(json));
  };

  fetchPublicDataFail = dispatch => {
    dispatch(
      invokeInfo({
        variant: "danger",
        message: "App.fetchDataFailAlert"
      })
    );
  };

  fetchPrivateData = () => {
    return (dispatch, getState) => {
      return depracated_handleFetch(
        {
          url: Config.url.api.privateDataResource,
          token: getState().initData.token
        },
        {
          success: json => this.fetchPrivateDataSuccess(dispatch, json),
          error: () => this.fetchPrivateDataFail(dispatch),
          code_4xx: () => null
        }
      );
    };
  };

  fetchPrivateDataSuccess = (dispatch, json) => {
    if (!!json) {
      dispatch(receivePrivateData(json));

      if (json.user && json.user.name && json.user.name !== "") {
        dispatch(
          invokeInfo({
            variant: "info",
            message: "App.loggedIn"
          })
        );
      }
    }
  };

  fetchPrivateDataFail = dispatch => {
    dispatch(
      invokeInfo({
        variant: "danger",
        message: "App.fetchPrivateDataFailAlert"
      })
    );
  };

  render() {
    if (!this.state.initialiseApplication) return null;

    return (
      <Provider store={store}>
        <PhyzziFrontendWrapper
          initialiseApplication={this.state.initialiseApplication}
        />
      </Provider>
    );
  }
}

export default App;
