import React, { Component } from "react";

import "./style.scss";
import { DescribedButton } from "react-bootstrap-tools";
import { calculateCanSend, validateInput } from "./functions";
import { config } from "../InfoSectionBoard/config";
import { translate } from "react-i18next";
import { Form } from "react-bootstrap";
import Immutable from "seamless-immutable";

export class InfoSectionBoardNewMessageComponent extends Component {
  constructor(props) {
    super(props);

    this.t = props.t;

    this.addButtonText = this.t("common:add");
    this.editButtonText = this.t("common:edit");
    this.cancelButtonText = this.t("common:cancel");

    this.boardGroups = Immutable.asMutable(props.boardGroups)
      .map(boardGroup => {
        return {
          id: boardGroup.id,
          name: this.t("common:boardGroupName." + boardGroup.string_id)
        };
      })
      .sort((a, b) => {
        return a.name < b.name ? 1 : -1;
      })
      .map(category => {
        return (
          <option key={category.id} value={category.id}>
            {category.name}
          </option>
        );
      });

    this.boardTypes = ["sell", "buy"].map(category => {
      return (
        <option key={category} value={category}>
          {this.t("common:boardType." + category)}
        </option>
      );
    });

    this.state = {
      values: props.mode === "edit" ? props.values : config.message.empty.data,
      validations:
        props.mode === "edit"
          ? config.message.edit.validation
          : config.message.empty.validation,
      canSend: false,
      isSending: false
    };
  }

  handleChange = ev => {
    const { name, value } = ev.target;
    const values = {
      ...this.state.values,
      [name]: value
    };
    const validations = {
      ...this.state.validations,
      [name]: validateInput(name, value)
    };

    this.setState({
      values,
      validations,
      canSend: calculateCanSend(validations)
    });
  };

  handleAddClick = () => {
    if (this.state.canSend) {
      this.props.handleAdd(this.state.values, this.removeIsSending);
      this.setState({
        isSending: true
      });
    }
  };

  handleEditClick = () => {
    if (this.state.canSend) {
      this.props.handleEdit(this.state.values, this.removeIsSending);
      this.setState({
        isSending: true
      });
    }
  };

  removeIsSending = () => {
    this.setState({
      isSending: false
    });
  };

  render() {
    return (
      <div className="InfoSectionBoardNewMessage">
        <div className="InfoSectionBoardNewMessage__row">
          <Form inline>
            <Form.Group controlId="board_group_id">
              <Form.Control
                as="select"
                bsSize="small"
                onChange={this.handleChange}
                className="InfoSectionBoardNewMessage__select"
                readOnly={this.state.isSending}
                value={this.state.values.board_group_id}
                name={"board_group_id"}
                isInvalid={this.state.validations.board_group_id === false}
              >
                <option value="">
                  {this.t("InfoSectionBoardNewMessage.formGroupPlaceholder")}
                </option>
                {this.boardGroups}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="type">
              <Form.Control
                as="select"
                bsSize="small"
                onChange={this.handleChange}
                className="InfoSectionBoardNewMessage__select"
                readOnly={this.state.isSending}
                value={this.state.values.type}
                name={"type"}
                isInvalid={this.state.validations.type === false}
              >
                <option value="">
                  {this.t("InfoSectionBoardNewMessage.formTypePlaceholder")}
                </option>
                {this.boardTypes}
              </Form.Control>
            </Form.Group>
            <Form.Group controlId="keyword">
              <Form.Control
                bsSize="small"
                onChange={this.handleChange}
                className="InfoSectionBoardNewMessage__keyword"
                readOnly={this.state.isSending}
                value={this.state.values.keyword}
                name={"keyword"}
                maxLength={config.maxLengths.keyword}
                placeholder={this.t(
                  "InfoSectionBoardNewMessage.formKeywordPlaceholder"
                )}
                isInvalid={this.state.validations.keyword === false}
              />
            </Form.Group>
          </Form>
        </div>
        <div className="InfoSectionBoardNewMessage__row">
          <Form.Group
            controlId="message"
            className="InfoSectionBoardNewMessage__textarea"
          >
            <Form.Control
              as="textarea"
              bsSize="small"
              onChange={this.handleChange}
              maxLength={config.maxLengths.message}
              placeholder={this.t(
                "InfoSectionBoardNewMessage.formMessagePlaceholder"
              )}
              value={this.state.values.message}
              readOnly={this.state.isSending}
              name={"message"}
              isInvalid={this.state.validations.message === false}
            />
          </Form.Group>
        </div>
        <div className="InfoSectionBoardNewMessage__row">
          <p className="InfoSectionBoardNewMessage__counter">
            {this.state.values.message.length} / {config.maxLengths.message}
          </p>
        </div>
        <div className="InfoSectionBoardNewMessage__row--right">
          {this.props.mode === "add" && (
            <DescribedButton
              onClick={this.handleAddClick}
              text={this.addButtonText}
              disabled={!this.state.canSend || this.state.isSending}
              buttonClassName="InfoSectionBoardNewMessage__button"
            />
          )}
          {this.props.mode === "edit" && (
            <DescribedButton
              onClick={this.handleEditClick}
              text={this.editButtonText}
              disabled={!this.state.canSend || this.state.isSending}
              buttonClassName="InfoSectionBoardNewMessage__button"
            />
          )}
          <DescribedButton
            onClick={this.props.handleCancel}
            text={this.cancelButtonText}
            disabled={this.state.isSending}
            buttonClassName="InfoSectionBoardNewMessage__button"
          />
        </div>
      </div>
    );
  }
}

export default translate("frontend")(InfoSectionBoardNewMessageComponent);
