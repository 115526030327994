import React from "react";

import "./style.scss";
import { translate } from "react-i18next";

export const FooterMenuComponent = props => {
  const handleAboutClick = () => {
    props.showModalContent({
      type: "about",
      conf: {}
    });
  };

  const handlePolicyClick = () => {
    props.showModalContent({
      type: "privacyPolicy",
      conf: {}
    });
  };

  const handleTouClick = () => {
    props.showModalContent({
      type: "tou",
      conf: {}
    });
  };

  const handleContactClick = () => {
    props.showModalContent({
      type: "contact",
      conf: {}
    });
  };

  const { t } = props;

  return (
    <div className="FooterMenu">
      <h2 className="FooterMenu__heading">
        <button className="FooterMenu__menu-link" onClick={handleAboutClick}>
          {t("FooterMenu.about")}
        </button>
      </h2>
      |{" "}
      <h2 className="FooterMenu__heading">
        <button className="FooterMenu__menu-link" onClick={handleTouClick}>
          {t("FooterMenu.tou")}
        </button>
      </h2>
      |{" "}
      <h2 className="FooterMenu__heading">
        <button className="FooterMenu__menu-link" onClick={handlePolicyClick}>
          {t("FooterMenu.privacyPolicy")}
        </button>
      </h2>
      |{" "}
      <h2 className="FooterMenu__heading">
        <button className="FooterMenu__menu-link" onClick={handleContactClick}>
          {t("FooterMenu.contact")}
        </button>
      </h2>
    </div>
  );
};

export default translate("frontend")(FooterMenuComponent);
