import React from "react";

import "./style.scss";
import { generateTriangleGlyph, generateButtonClassName } from "./functions";
import LeftSectionStructure from "../LeftSectionStructure/index";
import { DescribedButton } from "react-bootstrap-tools";
import { translate } from "react-i18next";

export const LeftSectionComponent = props => {
  const { t } = props;

  const showDescription = t("LeftSection.toggleShow");
  const hideDescription = t("LeftSection.toggleHide");

  const icon = generateTriangleGlyph(props.collapsed);
  const buttonClassName = generateButtonClassName(props.collapsed);

  const toggleSectionShow = () => {
    props.toggleLeftSectionVisibility(!props.collapsed);
  };

  return (
    <section className="LeftSection">
      <DescribedButton
        description={props.collapsed ? showDescription : hideDescription}
        icon={icon}
        onClick={toggleSectionShow}
        buttonClassName={buttonClassName}
        iconClassName="LeftSection__sectionTogglerIcon"
      />
      <LeftSectionStructure collapsed={props.collapsed} />
    </section>
  );
};

export default translate("frontend")(LeftSectionComponent);
