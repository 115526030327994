import React  from "react";
import "./style.scss";
import { translate } from "react-i18next";

export const UnitAgentButtonComponent = ({showModalContent, t, unitName}) => {

  const handleClick = () => {
    showModalContent({type: 'contact', conf: {unitName}})
  }

    return (
      <section>
        <button className={'UnitAgentButton__button'} onClick={handleClick}>{t("UnitAgentButton.text")}</button>
      </section>
    );
}

export default translate("frontend")(UnitAgentButtonComponent);
