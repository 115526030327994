import { connect } from "react-redux";

import PhyzziBrandLogo from "../../components/PhyzziBrandLogo";

const mapStateToProps = state => ({
  theme: state.theme,
  isLeftSectionShown: !state.leftSection.collapsed
});

const mapDispatchToProps = {};

const PhyzziBrandLogoWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(PhyzziBrandLogo);

export default PhyzziBrandLogoWrapper;
