import React, { Component } from "react";

import "./style.scss";
import { getClassName, getHasChildren } from "./functions";
import UnitTypeTreeItemWrapper from "../../containers/UnitTypeTreeItemWrapper/index";
import { Collapse } from "react-bootstrap";
import Immutable from "seamless-immutable";

class UnitTypeTree extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentHiding: false
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      contentHiding: !!props.hide && props.hide === true
    });
  }

  render() {
    const unitTypes = Immutable.asMutable(
      this.props.unitTypes.filter(item => item.parentId === this.props.parentId)
    ).sort((a, b) => (a.groupOrder > b.groupOrder ? 1 : -1));

    if (unitTypes.length === 0) return null;

    return (
      <Collapse
        in={!this.state.contentHiding}
        appear={true}
        onExited={() => this.props.handleSectionCollapsed(this.props.parentId)}
      >
        <div className={getClassName(this.props.level)}>
          {unitTypes.map(unitType => {
            const unitTypeBranch = this.props.unitTypeTree.filter(
              item => item.id === unitType.id
            );

            return (
              <UnitTypeTreeItemWrapper
                key={unitType.id}
                unitType={unitType}
                unitTypeBranch={unitTypeBranch[0]}
                hasChildren={getHasChildren(this.props.unitTypes, unitType.id)}
                level={this.props.level}
              />
            );
          })}
          {this.props.level === 0 && (
            <div className="UnitTypeTree__bottom-icons-spacer" />
          )}
        </div>
      </Collapse>
    );
  }
}

export default UnitTypeTree;
