import { RECEIVE_PUBLIC_ACCESSES_DATA, RECEIVE_PUBLIC_DATA } from "./actions";
import { updateArrayState } from "../../utils/updateArrayState";
import Immutable from "seamless-immutable";
import { unifyDate } from "phyzzi.com-frontend-utils";

const initialState = {
  lastUpdate: 0,
  data: Immutable([])
};

function accessReducers(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_PUBLIC_DATA:
      if (action.json.accesses) {
        return Object.assign({}, state, {
          lastUpdate: unifyDate(action.json.accesses.lastUpdate),
          data: updateArrayState(state.data, action.json.accesses.data)
        });
      }
      return state;
    case RECEIVE_PUBLIC_ACCESSES_DATA:
      return Object.assign({}, state, {
        lastUpdate: unifyDate(action.json.lastUpdate),
        data: updateArrayState(state.data, action.json.data)
      });
    default:
      return state;
  }
}

export default accessReducers;
