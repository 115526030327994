import React from "react";

import "./style.scss";
import Config from "../../containers/App/config";
import { translate } from "react-i18next";
import PhyzziBrandLogoWrapper from "../../containers/PhyzziBrandLogoWrapper/index";

export const LogoComponent = props => {
  const { t } = props;

  return (
    <section className="Logo">
      <a
        href={Config.url.mainPage}
        title={t("Logo.title")}
        className="Logo__link"
      >
        <PhyzziBrandLogoWrapper />
      </a>
    </section>
  );
};

export default translate("frontend")(LogoComponent);
