export const getMenuToSelect = modalSectionType => {
  switch (modalSectionType) {
    case "mySettings":
      return "settings";
    case "myProfile":
    case "userProfile":
      return "profile";
    case "about":
      return "about";
    case "advertising":
      return "advertising";
    case "privacyPolicy":
      return "privacyPolicy";
    case "tou":
      return "tou";
    case "howTo":
      return "howTo";
    case "faq":
      return "faq";
    case "contact":
      return "contact";
    case "unitWikipedia":
      return "unitWikipedia";
    case "unitYoutube":
      return "unitYoutube";
    case "login":
      return "loginRegisterLogin";
    case "register":
      return "loginRegisterRegister";
    case "resetPasswordRequest":
    case "resetPasswordRequestSuccess":
      return "loginRegisterResetPasswordRequest";
    case "resetPassword":
    case "resetPasswordSuccess":
    case "resetPasswordError":
    case "resetPasswordFormError":
      return "loginRegisterResetPassword";
    case "messenger":
      return "messenger";
    case "unitTypes":
      return "unitTypes";
    case "unitInfo":
      return "unitInfo";
    case "unitUsers":
      return "unitUsers";
    case "unitBoard":
      return "unitBoard";
    default:
      return "";
  }
};

export const getNavbarStatus = (modalInstance, history, historyPointer) => {
  let navbarStatus = {
    shown: false,
    prevDisabled: true,
    nextDisabled: true,
    refreshShown: false,
    externalContentShown: false,
    externalContentDisabled: false
  };

  switch (modalInstance) {
    case "externalContent":
    case "unitInternet":
      navbarStatus.externalContentShown = true;
      break;
    default:
  }

  switch (modalInstance) {
    case "unitInternet":
      navbarStatus.shown = true;
      break;
    default:
  }

  if (historyPointer > 0) navbarStatus.prevDisabled = false;

  if (historyPointer < history.length) navbarStatus.nextDisabled = false;

  return navbarStatus;
};

export const getMenuItems = (modalInstance, modalSectionProps, menuConf) => {
  switch (modalInstance) {
    case "myAccount":
      return [
        {
          id: "profile",
          label: "ModalSectionMenu.profile",
          modalType: "myProfile",
          conf: {
            userId: menuConf.userId
          }
        },
        {
          id: "settings",
          label: "ModalSectionMenu.settings",
          modalType: "mySettings"
        }
      ];
    case "userProfile":
      return [
        {
          id: "profile",
          label:
            menuConf.userId === modalSectionProps.conf.userId
              ? "ModalSectionMenu.profile"
              : "ModalSectionMenu.user"
        }
      ];
    case "phyzziInfo":
      return [
        {
          id: "about",
          label: "ModalSectionMenu.about",
          modalType: "about"
        },
        /*{
                    id: 'advertising',
                    label: 'ModalSectionMenu.advertising',
                    modalType: 'advertising',
                },*/
        {
          id: "tou",
          label: "ModalSectionMenu.tou",
          modalType: "tou"
        },
        {
          id: "privacyPolicy",
          label: "ModalSectionMenu.privacyPolicy",
          modalType: "privacyPolicy"
        },
        {
          id: "howTo",
          label: "ModalSectionMenu.howTo",
          modalType: "howTo"
        },
        {
          id: "faq",
          label: "ModalSectionMenu.faq",
          modalType: "faq"
        },
        {
          id: "contact",
          label: "ModalSectionMenu.contact",
          modalType: "contact"
        }
      ];
    case "unitInternet":
      let menuItemsInternet = [];

      modalSectionProps.conf.youtubeUrl !== "" &&
        menuItemsInternet.push({
          id: "unitYoutube",
          label: "ModalSectionMenu.unitYoutube",
          modalType: "unitYoutube",
          conf: {
            wikiUrl: modalSectionProps.conf.wikiUrl,
            youtubeUrl: modalSectionProps.conf.youtubeUrl,
            url: modalSectionProps.conf.youtubeUrl,
            unitName: modalSectionProps.conf.unitName
          }
        });

      return menuItemsInternet;
    case "messenger":
      return [
        {
          id: "messenger",
          label: "ModalSectionMenu.messenger",
          modalType: "messenger"
        }
      ];
    case "loginRegister":
      let menuItemsLoginRegister = [
        {
          id: "loginRegisterLogin",
          label: "ModalSectionMenu.loginRegisterLogin",
          modalType: "login"
        },
        {
          id: "loginRegisterRegister",
          label: "ModalSectionMenu.loginRegisterRegister",
          modalType: "register"
        }
      ];

      if (
        ["resetPasswordRequest", "resetPasswordRequestSuccess"].indexOf(
          modalSectionProps.type
        ) > -1
      )
        menuItemsLoginRegister.push({
          id: "loginRegisterResetPasswordRequest",
          label: "ModalSectionMenu.loginRegisterResetPasswordRequest",
          modalType: "resetPasswordRequest"
        });

      if (
        [
          "resetPassword",
          "resetPasswordFormError",
          "resetPasswordSuccess",
          "resetPasswordError"
          // 'resetPasswordRequest', 'resetPasswordRequestSuccess'
        ].indexOf(modalSectionProps.type) > -1
      )
        menuItemsLoginRegister.push({
          id: "loginRegisterResetPassword",
          label: "ModalSectionMenu.loginRegisterResetPassword",
          modalType: "resetPassword"
        });

      return menuItemsLoginRegister;
    case "unitInfo":
      let menuItemsUnitInfo = [];

      if (modalSectionProps.type === "unitTypes") {
        menuItemsUnitInfo.push({
          id: "unitTypes",
          label: "ModalSectionMenu.unitTypes",
          disabled: true,
          modalType: "unitTypes"
        });
      } else {
        menuItemsUnitInfo.push({
          id: "unitInfo",
          label: "ModalSectionMenu.unitInfo",
          modalType: "unitInfo",
          conf: {
            reloadContent: false,
            useRelativePosition: true,
            id: modalSectionProps.conf.id,
            isAddition: false,
            isAdditionPath: false
          }
        });

        if (!modalSectionProps.conf.isAddition)
          menuItemsUnitInfo.push({
            id: "unitUsers",
            label: "ModalSectionMenu.unitUsers",
            modalType: "unitUsers",
            conf: {
              reloadContent: false,
              useRelativePosition: true,
              id: modalSectionProps.conf.id
            }
          });

        /*if (!modalSectionProps.conf.isAddition)
          menuItemsUnitInfo.push({
            id: "unitBoard",
            label: "ModalSectionMenu.unitBoard",
            modalType: "unitBoard",
            conf: {
              reloadContent: false,
              useRelativePosition: true,
              id: modalSectionProps.conf.id
            }
          });*/
      }

      return menuItemsUnitInfo;
    default:
      return [];
  }
};

export const getNavClassName = modalInstance => {
  return (
    "ModalSectionMenu__nav" +
    (["unitInfo", "unitTypes"].indexOf(modalInstance) > -1
      ? "--" + modalInstance
      : "")
  );
};

export const getMenuClassName = (menuItem, selectedMenu) => {
  const className = "ModalSectionMenu__text-item";

  if (menuItem.id === selectedMenu) return className + "--active";

  return className;
};
