import React, { Component } from "react";

import "./style.scss";
import { getClassName, getHasChildren } from "./functions";
import InfoSectionUnitTypeTreeItem from "../InfoSectionUnitTypeTreeItem/index";
import { Collapse } from "react-bootstrap";
import Immutable from "seamless-immutable";

export class InfoSectionUnitTypeTreeComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      contentHiding: false
    };
  }

  componentWillReceiveProps(props) {
    this.setState({
      contentHiding: !!props.hide && props.hide === true
    });
  }

  render() {
    const unitTypes = Immutable.asMutable(this.props.unitTypes)
      .filter(item => item.parentId === this.props.parentId)
      .sort((a, b) => (a.groupOrder > b.groupOrder ? 1 : -1));

    if (unitTypes.length === 0) return null;

    return (
      <Collapse
        in={!this.state.contentHiding}
        appear={true}
        onExited={this.props.toggleSectionCollapse}
      >
        <div className={getClassName(this.props.level)}>
          {unitTypes.map(unitType => {
            return (
              <InfoSectionUnitTypeTreeItem
                key={unitType.id}
                unitType={unitType}
                handleUnitTypeSelect={this.props.handleUnitTypeSelect}
                hasChildren={getHasChildren(this.props.unitTypes, unitType.id)}
                level={this.props.level}
                selectedId={this.props.selectedId}
                unitTypes={this.props.unitTypes}
              />
            );
          })}
        </div>
      </Collapse>
    );
  }
}

export default InfoSectionUnitTypeTreeComponent;
