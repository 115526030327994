import React from "react";
import ReactDOM from "react-dom";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import App from "./containers/App/";
import {
  getShouldRedirectToLowIePage,
  getShouldRedirectToSmallScreenPage
} from "./utils/helpers";
import Config from "./containers/App/config";

const shouldRedirectToLowIePage = getShouldRedirectToLowIePage();
const shouldRedirectToSmallScreenPage = getShouldRedirectToSmallScreenPage(
  window.innerWidth,
  navigator.userAgent,
  Config.minScreenWidth,
  Config.regex.searchBots
);

// We don't support IE, redirect to info page
if (shouldRedirectToLowIePage) window.location.href = Config.url.lowIePage;

// We don't support low with devices, redirect to info page
if (shouldRedirectToSmallScreenPage)
  window.location.href = Config.url.smallScreenPage;

if (!shouldRedirectToLowIePage && !shouldRedirectToSmallScreenPage) {
  var applicationAnchor = document.getElementById("App");

  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <App dataset={applicationAnchor.dataset} />
    </I18nextProvider>,
    applicationAnchor
  );
}
