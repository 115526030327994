export const getPostedMessagesNumber = (content, userId) => {
  return content.filter(message => message.uuid === userId).length;
};

export const getCanUserAddMessage = (
  user,
  postedUsersMessages,
  isUserSubscribed
) => {
  const subscriptionsNumber = isUserSubscribed
    ? user.unitSubscriptionsNumber - 1
    : user.unitSubscriptionsNumber;
  return (
    !!user.uuid &&
    postedUsersMessages < user.accountType.max_per_board_messages &&
    subscriptionsNumber < user.accountType.max_subscriptions
  );
};

export const getCanUserEditMessage = (
  user,
  postedUsersMessages,
  isUserSubscribed
) => {
  const subscriptionsNumber = isUserSubscribed
    ? user.unitSubscriptionsNumber - 1
    : user.unitSubscriptionsNumber;
  return (
    !!user.uuid &&
    postedUsersMessages <= user.accountType.max_per_board_messages &&
    subscriptionsNumber <= user.accountType.max_subscriptions
  );
};
