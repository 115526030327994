import React from "react";
import { translate } from "react-i18next";
import "./style.scss";

export const SloganComponent = props => {
  const { t } = props;

  const year = new Date().getFullYear();

  return (
    <div className="Slogan">
      <span className="Slogan__copyright">&copy; {year} Phyzzi.com</span>,{" "}
      {t("Slogan.slogan")}
    </div>
  );
};

export default translate("frontend")(SloganComponent);
