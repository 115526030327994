export const getMapPointCollection = (unitTypeTree, points) => {
  let pointCollection = [];

  // Hack for a moment. There is some edge case on Chrome - while loading state points is undefined
  if (points !== undefined && unitTypeTree !== undefined) {
    const selectedUnitTypes = unitTypeTree
      .filter(unitType => unitType.selected)
      .map(unitType => unitType.id);

    points.forEach(mapUnitType => {
      // Only selected on unit type tree
      if (selectedUnitTypes.indexOf(mapUnitType.id) > -1) {
        // Hack for a moment. There is some edge case on Chrome - while loading state points is undefined
        if (mapUnitType.points !== undefined) {
          mapUnitType.points.forEach(point => {
            pointCollection.push({
              id: point.id,
              name: point.name,
              lat: point.lat,
              lon: point.lon,
              icon: mapUnitType.icon
            });
          });
        }
      }
    });
  }

  return pointCollection;
};
