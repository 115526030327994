import React from "react";

import "./style.scss";
import { getClassName } from "./functions";
import ModalSectionWrapper from "../../containers/ModalSectionWrapper/index";

export default function ModalGroup(props) {
  if (props.modalSection.length === 0) {
    return null;
  } else {
    return (
      <div className={getClassName(props.isLeftSectionShown)}>
        {props.modalSection
          .sort((a, b) => {
            return a.position > b.position ? 1 : -1;
          })
          .map((modalSection, idx) => {
            return !modalSection.conf.hide ||
              modalSection.conf.hide !== true ? (
              <ModalSectionWrapper key={modalSection.position} id={idx} />
            ) : null;
          })}
      </div>
    );
  }
}
