import React from "react";

import "./style.scss";
import { translate } from "react-i18next";
import { Button, ButtonGroup } from "react-bootstrap";

export const LoginRegisterButtonComponent = props => {
  const { t } = props;

  const handleLoginClick = () => {
    props.showModalContent({
      type: "login",
      conf: {}
    });
  };

  const handleRegisterClick = () => {
    props.showModalContent({
      type: "register",
      conf: {}
    });
  };

  return (
    <section className="LoginRegisterButton">
      <ButtonGroup>
        <Button
          className="LoginRegisterButton__button"
          onClick={handleLoginClick}
        >
          {t("LoginRegisterButton.login")}
        </Button>
        <Button
          className="LoginRegisterButton__button"
          onClick={handleRegisterClick}
        >
          {t("LoginRegisterButton.register")}
        </Button>
      </ButtonGroup>
    </section>
  );
};

export default translate("frontend")(LoginRegisterButtonComponent);
