export const getButtonDescription = (userUuid, canUserSubscribe) => {
  if (!userUuid) return "InfoSectionUsersList.buttonDescriptionUnsigned";

  if (!canUserSubscribe)
    return "InfoSectionUsersList.buttonDescriptionLowAccountType";

  return "InfoSectionUsersList.buttonDescription";
};

export const isButtonDisabled = (userUuid, canUserSubscribe) => {
  return !userUuid || !canUserSubscribe;
};
