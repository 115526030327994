import React, { Component } from "react";

import "./style.scss";
import { getBoardGroupNameLabel } from "phyzzi.com-frontend-utils";
import { getUnitTypeNameLabel } from "../../containers/App/functions";
import { DescribedButton } from "react-bootstrap-tools";
import { getToggledUnits, getUnitTypeTreeLevelIds } from "./functions";
import { translate } from "react-i18next";
import { getMapRangedZoomLevel } from "../../utils/helpers";
import { config } from "../Search/config";
import { Collapse } from "react-bootstrap";

export class UserProfileUnitsComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: []
    };

    this.t = props.t;
    this.toggleButtonExpandDescription = this.t(
      "UserProfileUnits.buttonExpandDescription"
    );
    this.toggleButtonCollapseDescription = this.t(
      "UserProfileUnits.buttonCollapseDescription"
    );
    this.removeUserButtonDescription = this.t(
      "UserProfileUnits.buttonUnsubscribeDescription"
    );
    this.removeBoardButtonDescription = this.t(
      "UserProfileUnits.buttonBoardDescription"
    );
  }

  toggleUnit = id => {
    this.setState({
      expanded: getToggledUnits(this.state.expanded, id)
    });
  };

  handleRemoveUserClick = id => {
    this.props.handleRemoveUserClick(id);
  };

  handleRemoveBoardClick = id => {
    this.props.handleRemoveBoardClick(id);
  };

  handleUnitNameClick = (unit, ev) => {
    this.props.changeViewport({
      lat: unit.lat,
      lon: unit.lon,
      zoom: getMapRangedZoomLevel(config.unitZoomLevel)
    });
    this.props.selectUnit({
      id: unit.id,
      lat: unit.lat,
      lon: unit.lon,
      name: unit.name,
      icon: unit.icon
    });
    this.props.showModalContent({
      type: "unitInfo",
      conf: {
        useRelativePosition: {
          x: window.innerWidth / 2,
          y: window.innerHeight / 2
        },
        reloadContent: true,
        id: unit.id,
        isAddition: false,
        isAdditionPath: false
      }
    });
    this.props.closeModalSection(this.props.modalId);
  };

  render() {
    const postsWithNoUnits = this.props.posts.filter(
      post => !post.unit_user_id
    );

    return (
      <ul className="UserProfileUnits">
        {this.props.unitUsers.map(unitUser => {
          const unitTypeTree = getUnitTypeTreeLevelIds(
            this.props.unitTypes,
            unitUser.unit.unit_type_id
          ).reverse();

          const unitPosts = this.props.posts.filter(
            post => post.unit_user_id === unitUser.id
          );

          return (
            <li key={unitUser.id}>
              <div>
                <span className="UserProfileUnits__expand-button-wrapper">
                  {unitPosts.length > 0 && (
                    <DescribedButton
                      description={
                        this.state.expanded.indexOf(unitUser.unit.id) < 0
                          ? this.toggleButtonExpandDescription
                          : this.toggleButtonCollapseDescription
                      }
                      icon={
                        this.state.expanded.indexOf(unitUser.unit.id) < 0
                          ? "add"
                          : "remove"
                      }
                      size="sm"
                      buttonClassName="UserProfileUnits__expand-button"
                      iconClassName="UserProfileUnits__expand-icon"
                      onClick={this.toggleUnit}
                      onClickArg={unitUser.unit.id}
                    />
                  )}
                </span>
                <DescribedButton
                  text={unitUser.unit.name}
                  buttonClassName="UserProfileUnits__unit-button"
                  onClick={this.handleUnitNameClick}
                  onClickArg={{
                    id: unitUser.unit.id,
                    lat: unitUser.unit.lat / 1000000,
                    lon: unitUser.unit.lon / 1000000,
                    name: unitUser.unit.name,
                    icon: unitUser.unit.unitType.icon
                  }}
                />
                <span className="UserProfileUnits__unit-type">
                  {unitTypeTree.map((unitTypeId, idx) => {
                    return (
                      <span key={unitTypeId}>
                        {idx > 0 ? " / " : ""}
                        {this.t(
                          getUnitTypeNameLabel(this.props.unitTypes, unitTypeId)
                        )}
                      </span>
                    );
                  })}
                </span>
                {!this.props.nonEditable && (
                  <DescribedButton
                    description={this.removeUserButtonDescription}
                    icon="delete"
                    buttonClassName="UserProfileUnits__remove-button"
                    onClick={this.handleRemoveUserClick}
                    onClickArg={unitUser.unit.id}
                  />
                )}
              </div>
              <Collapse in={this.state.expanded.indexOf(unitUser.unit.id) > -1}>
                <div className="UserProfileUnits__expanded-wrapper">
                  {unitPosts.length > 0 && (
                    <div className="UserProfileUnits__boards-wrapper">
                      <div className="UserProfileUnits__board-title">
                        {this.t("UserProfileUnits.board")}:
                      </div>
                      <table>
                        <tbody>
                          {unitPosts.map(board => {
                            return (
                              <tr key={board.id}>
                                <td className="UserProfileUnits__board-cell">
                                  {this.t("common:boardType." + board.type)}
                                </td>
                                <td className="UserProfileUnits__board-cell">
                                  {this.t(
                                    getBoardGroupNameLabel(
                                      this.props.boardGroups,
                                      board.board_group_id
                                    )
                                  )}
                                </td>
                                <td className="UserProfileUnits__board-cell">
                                  {board.message}
                                </td>
                                <td className="UserProfileUnits__board-cell">
                                  {!this.props.nonEditable && (
                                    <DescribedButton
                                      description={
                                        this.removeBoardButtonDescription
                                      }
                                      icon="delete"
                                      buttonClassName="UserProfileUnits__remove-button"
                                      onClick={this.handleRemoveBoardClick}
                                      onClickArg={board.id}
                                    />
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  )}
                </div>
              </Collapse>
            </li>
          );
        })}
        {postsWithNoUnits.length > 0 && (
          <li>
            <div>
              <span className="UserProfileUnits__expand-button-wrapper">
                <DescribedButton
                  description={
                    this.state.expanded.indexOf("non-correlated") < 0
                      ? this.toggleButtonExpandDescription
                      : this.toggleButtonCollapseDescription
                  }
                  icon={
                    this.state.expanded.indexOf("non-correlated") < 0
                      ? "add"
                      : "remove"
                  }
                  size="sm"
                  buttonClassName="UserProfileUnits__expand-button"
                  iconClassName="UserProfileUnits__expand-icon"
                  onClick={this.toggleUnit}
                  onClickArg={"non-correlated"}
                />
              </span>
              <span className="UserProfileUnits__other-posts">
                {this.t("UserProfileUnits.nonCorrelatedPosts")}
              </span>
            </div>
            <Collapse in={this.state.expanded.indexOf("non-correlated") > -1}>
              <div className="UserProfileUnits__expanded-wrapper">
                <div className="UserProfileUnits__boards-wrapper">
                  <div className="UserProfileUnits__board-title">
                    {this.t("UserProfileUnits.board")}:
                  </div>
                  <table>
                    <tbody>
                      {postsWithNoUnits.map(board => {
                        return (
                          <tr key={board.id}>
                            <td className="UserProfileUnits__board-cell">
                              {this.t("common:boardType." + board.type)}
                            </td>
                            <td className="UserProfileUnits__board-cell">
                              {this.t(
                                getBoardGroupNameLabel(
                                  this.props.boardGroups,
                                  board.board_group_id
                                )
                              )}
                            </td>
                            <td className="UserProfileUnits__board-cell">
                              {board.message}
                            </td>
                            <td className="UserProfileUnits__board-cell">
                              {!this.props.nonEditable && (
                                <DescribedButton
                                  description={
                                    this.removeBoardButtonDescription
                                  }
                                  icon="delete"
                                  buttonClassName="UserProfileUnits__remove-button"
                                  onClick={this.handleRemoveBoardClick}
                                  onClickArg={board.id}
                                />
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </Collapse>
          </li>
        )}
      </ul>
    );
  }
}

export default translate("frontend")(UserProfileUnitsComponent);
