import React from "react";

import "./style.scss";
import { DescribedButton } from "react-bootstrap-tools";
import { translate } from "react-i18next";
import { getTrClassName } from "./functions";

export const InfoSectionUsersAddSectionComponent = props => {
  const { t } = props;

  return (
    <tr className={getTrClassName(props.isButtonDisabled)} onClick={!props.isButtonDisabled && props.handleAddClick}>
      <td />
      <td colSpan={2}>
        <p className="InfoSectionUsersAddSection__add-yourself">
          {t("InfoSectionUsersAddSection.addYourself")}
        </p>
        <p className="InfoSectionUsersAddSection__add-yourself-info">
          {t("InfoSectionUsersAddSection.addYourselfInfo")}
        </p>
      </td>
      <td>
        <DescribedButton
          buttonClassName="InfoSectionUsersAddSection__add-button"
          icon="add"
          placement="left"
          disabled={props.isButtonDisabled}
        />
      </td>
    </tr>
  );
};

export default translate("frontend")(InfoSectionUsersAddSectionComponent);
