import React from "react";

import { getButtonProps, getComponentProps, getGlyphProps } from "./functions";
import DescribedComponent from "../DescribedComponent/index";
import { translate } from "react-i18next";
import { Button } from "react-bootstrap";
import Glyphicon from "../Glyphicon";

export const DescribedButtonComponent = props => {
  const { t } = props;

  const textLeft = !!props.textLeft
    ? props.useTranslation !== false
      ? t(props.textLeft)
      : props.textLeft
    : "";

  const text = !!props.text
    ? props.useTranslation !== false
      ? t(props.text)
      : props.text
    : "";

  const onClickHandler = ev => {
    if (!!props.onClickAdditionalArgument)
      props.onClick(props.onClickAdditionalArgument, ev);
    else props.onClick(ev);
  };

  const buttonComponent = (
    <Button {...getButtonProps(props, onClickHandler)}>
      {textLeft}
      {!!props.glyph && <Glyphicon {...getGlyphProps(props)} />}
      {text}
    </Button>
  );

  if (!!props.description) {
    return (
      <DescribedComponent {...getComponentProps(props)}>
        {buttonComponent}
      </DescribedComponent>
    );
  } else {
    return buttonComponent;
  }
};

export default translate("frontend")(DescribedButtonComponent);
