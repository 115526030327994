import React, { Component } from "react";

import { generateClassName, shouldFetchUpdate } from "./functions";

import "./style.scss";
import MapSection from "../MapSection/index";
import TopSectionWrapper from "../../containers/TopSectionWrapper/index";
import AlertBoxWrapper from "../../containers/AlertBoxWrapper/index";
import LeftSectionWrapper from "../../containers/LeftSectionWrapper/index";
import ModalGroupWrapper from "../../containers/ModalGroupWrapper/index";
import Config from "../../containers/App/config";
import { getUnitTypeStringId } from "../../containers/App/functions";
import { getSelectedUnitTypes } from "../UnitTypeTree/functions";
import CaptchaWrapper from "../../containers/CaptchaWrapper/index";
import CookieInfoWrapper from "../../containers/CookieInfoWrapper/index";
import { toServerTimezone } from "../../utils/helpers";
import { unifyDate } from "phyzzi.com-frontend-utils";
import {
  handleFetchPingUpdates,
  handleFetchPoints,
  handleFetchPublicGroupData
} from "./fetchUtils";
import MapAttribution from "../MapAttribution";
import AgentButtonWrapper from "../../containers/AgentButtonWrapper";

export class PhyzziFrontendComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      movable: props.movable
    };
  }

  componentWillReceiveProps(props) {
    this.setState(prevState => {
      return {
        ...prevState,
        movable: props.movable
      };
    });
  }

  componentDidMount() {
    // Mounting update pings
    this.handlePingUpdates();
    this.pingUpdates = setInterval(this.handlePingUpdates, Config.pingTimeout);
  }

  componentWillUnmount() {
    clearInterval(this.pingUpdates);
  }

  handlePingUpdates = () => {
    handleFetchPingUpdates({
      token: this.props.token,
      invokeInfo: this.props.invokeInfo,
      handlePingUpdatesSuccess: this.handlePingUpdatesSuccess
    });
  };

  handlePingUpdatesSuccess = json => {
    // Fetching group data (access, product etc.) if update is required
    [
      "accesses",
      "products",
      "unitTypes",
      "unitTypeProducts",
      "boardGroups"
    ].forEach(category => {
      if (
        shouldFetchUpdate(
          this.props[category + "LastUpdate"],
          unifyDate(json[category])
        )
      )
        this.handleGetPublicGroupData(category);
    });

    // Fetching group points data if update is require
    const selectedUnitTypes = getSelectedUnitTypes(this.props.unitTypeTree);

    selectedUnitTypes.forEach(unitType => {
      const lastUpdateString = json.points.filter(
        item => item.id === unitType.id
      )[0].lastUpdate;
      const lastUpdate = unifyDate(lastUpdateString);
      const mapUnitType = this.props.mapPoints.filter(
        item => item.id === unitType.id
      )[0];

      if (shouldFetchUpdate(unifyDate(mapUnitType.lastUpdate), lastUpdate)) {
        this.handleGetPoints({
          id: unitType.id,
          string_id: getUnitTypeStringId(
            this.props.unitTypes.data,
            unitType.id
          ),
          timestamp: toServerTimezone(unifyDate(mapUnitType.lastUpdate)).format(
            "X"
          )
        });
      }
    });
  };

  handleGetPublicGroupData = category => {
    handleFetchPublicGroupData({
      category,
      token: this.props.token,
      invokeInfo: this.props.invokeInfo,
      receivePublicGroupData: this.props.receivePublicGroupData
    });
  };

  handleGetPoints = data => {
    handleFetchPoints({
      unitTypeStringId: data.string_id,
      unitTypeId: data.id,
      timestamp: data.timestamp,
      token: this.props.token,
      invokeInfo: this.props.invokeInfo,
      toggleUnitTypeSelection: this.props.toggleUnitTypeSelection,
      receivePoints: this.props.receivePoints
    });
  };

  handleMouseMove = event => {
    if (!!this.state.movable.emitCallback && this.state.movable.emitCallback) {
      const { screenX, screenY } = event;

      this.props.movable.callback({
        x: screenX - this.state.movable.screenX,
        y: screenY - this.state.movable.screenY
      });

      this.setState(prevState => {
        return {
          ...prevState,
          movable: {
            ...prevState.movable,
            screenX,
            screenY
          }
        };
      });
    }
  };

  handleMouseUp = () => {
    this.props.stopEmittingCallback();
  };

  render() {
    if (!this.props.initialiseApplication) return <div />;

    return (
      <div
        className={generateClassName(this.props.theme)}
        onMouseUp={this.handleMouseUp}
        onMouseMove={event => this.handleMouseMove(event)}
      >
        <ModalGroupWrapper />
        <MapSection />
        <LeftSectionWrapper />
        {/* <BottomSectionWrapper /> */}
        <TopSectionWrapper />
        <CookieInfoWrapper />
        <AgentButtonWrapper />
        <MapAttribution />
        <AlertBoxWrapper />
        <CaptchaWrapper />
      </div>
    );
  }
}

export default PhyzziFrontendComponent;
