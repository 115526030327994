export const validateInput = (fieldId, content, regex) => {
  if (
    [
      "location",
      "position",
      "company",
      "previous_company",
      "industry",
      "country",
      "languages",
      "website"
    ].indexOf(fieldId) > -1 &&
    content.length > 255
  )
    return false;

  if (["phone_number"].indexOf(fieldId) > -1 && content.length > 15)
    return false;

  // Website
  if (fieldId === "website" && content.length > 0 && !regex.url.test(content))
    return false;

  return true;
};
