export const getRowLabel = fieldId => {
  switch (fieldId) {
    case "name":
      return "InfoSectionInfoRow.name";
    case "capacity":
      return "InfoSectionInfoRow.capacity";
    case "built_year":
      return "InfoSectionInfoRow.yearBuilt";
    case "operator":
      return "InfoSectionInfoRow.operator";
    case "owner":
      return "InfoSectionInfoRow.owner";
    default:
      return "";
  }
};
