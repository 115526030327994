import {
  CHANGE_MAP_BACKGROUND,
  CHANGE_VIEWPORT,
  CLOSE_POSITION_MARKER,
  OPEN_POSITION_MARKER,
  RECEIVE_POINTS,
  SELECT_UNIT,
  UNSELECT_UNIT
} from "./actions";
import {
  INJECT_DATA,
  RECEIVE_PUBLIC_DATA,
  RECEIVE_PUBLIC_UNIT_TYPES_DATA
} from "../App/actions";
import { updatePoints } from "./functions";
import { updateArrayState } from "../../utils/updateArrayState";
import Immutable from "seamless-immutable";
import { unifyDate } from "phyzzi.com-frontend-utils";

const initialState = {
  background: "satellite",
  points: Immutable([]),
  editPositionMarker: {
    shown: false,
    lat: null,
    lon: null,
    autoCenter: false
  },
  viewport: {
    zoom: null,
    lat: null,
    lon: null
  },
  selectedUnit: null
};

function mapReducers(state = initialState, action) {
  switch (action.type) {
    case INJECT_DATA:
      const background = !!action.injectedData.defaultMapType
        ? action.injectedData.defaultMapType
        : initialState.background;
      return Object.assign({}, initialState, {
        background: background
      });
    case RECEIVE_PUBLIC_DATA:
      return Object.assign({}, state, {
        points: updateArrayState(state.points, action.json.unit_types.data, {
          addOnly: ["id", "name", "icon", "stringId", "lastUpdate", "points"],
          addDefault: {
            lastUpdate: unifyDate("1970-01-01 00:00:00").format(
              "YYYY-MM-DD HH:mm:ss"
            ),
            points: Immutable([])
          },
          editOnly: ["id", "name", "icon", "stringId"]
        })
      });
    case RECEIVE_PUBLIC_UNIT_TYPES_DATA:
      return Object.assign({}, state, {
        points: updateArrayState(state.points, action.json.data, {
          addOnly: ["id", "name", "icon", "stringId"],
          addDefault: {
            lastUpdate: unifyDate("1970-01-01 00:00:00"),
            points: Immutable([])
          },
          editOnly: ["id", "name", "icon", "stringId"]
        })
      });
    case RECEIVE_POINTS:
      const points = updatePoints(
        state.points,
        action.stringId,
        action.json.units,
        action.json.lastUpdate
      );
      return Object.assign({}, state, {
        points
      });
    case SELECT_UNIT:
      return Object.assign({}, state, { selectedUnit: action.unit });
    case UNSELECT_UNIT:
      return Object.assign({}, state, { selectedUnit: null });
    case OPEN_POSITION_MARKER:
      return Object.assign({}, state, {
        editPositionMarker: {
          shown: true,
          lat: !!action.lat ? action.lat : 0,
          lon: !!action.lon ? action.lon : 0,
          autoCenter: !!action.autoCenter ? action.autoCenter : false
        }
      });
    case CLOSE_POSITION_MARKER:
      return Object.assign({}, state, {
        editPositionMarker: initialState.editPositionMarker
      });
    case CHANGE_MAP_BACKGROUND:
      if (action.background) {
        return Object.assign({}, state, {
          background: action.background
        });
      }
      return state;
    case CHANGE_VIEWPORT:
      return Object.assign({}, state, {
        viewport: {
          zoom: action.zoom,
          lat: action.lat,
          lon: action.lon
        }
      });
    default:
      return state;
  }
}

export default mapReducers;
