import React, { Component } from "react";
import { translate } from "react-i18next";

import "./style.scss";
import { Button, Card, Collapse } from "react-bootstrap";

export class FaqContentComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expanded: null
    };

    this.t = props.t;
  }

  togleSection = idx => {
    this.setState({
      expanded: this.state.expanded === idx ? null : idx
    });
  };

  render() {
    return (
      <div className="FaqContent" id="Faq">
        {this.props.content.length === 0 && (
          <div className="FaqContent__empty">{this.t("common.noResults")}</div>
        )}
        {this.props.content.map((message, idx) => {
          return (
            <Card key={idx} className="FaqContent__chapter-card">
              <Card.Header className="FaqContent__chapter-wrapper">
                <Card.Title className="FaqContent__chapter">
                  {!!message.title && (
                    <Button
                      className="FaqContent__chapter-button"
                      onClick={() => this.togleSection(idx)}
                    >
                      {message.title.map((title, idx) => (
                        <p className="FaqContent__title" key={idx}>
                          {title}
                        </p>
                      ))}
                    </Button>
                  )}
                </Card.Title>
              </Card.Header>
              <Collapse in={this.state.expanded === idx}>
                <Card.Body className="FaqContent__content-group">
                  {!!message.content &&
                    message.content.map((content, idx) => (
                      <p className="FaqContent__content" key={idx}>
                        {content}
                      </p>
                    ))}
                </Card.Body>
              </Collapse>
            </Card>
          );
        })}
      </div>
    );
  }
}

export default translate("frontend")(FaqContentComponent);
