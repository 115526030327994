import { RECEIVE_PUBLIC_DATA, RECEIVE_PUBLIC_PRODUCTS_DATA } from "./actions";
import { updateArrayState } from "../../utils/updateArrayState";
import Immutable from "seamless-immutable";
import { unifyDate } from "phyzzi.com-frontend-utils";

const initialState = {
  lastUpdate: 0,
  data: Immutable([])
};

function productReducers(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_PUBLIC_DATA:
      if (action.json.products) {
        return Object.assign({}, state, {
          lastUpdate: unifyDate(action.json.products.lastUpdate),
          data: updateArrayState(state.data, action.json.products.data)
        });
      }
      return state;
    case RECEIVE_PUBLIC_PRODUCTS_DATA:
      return Object.assign({}, state, {
        lastUpdate: unifyDate(action.json.lastUpdate),
        data: updateArrayState(state.data, action.json.data)
      });
    default:
      return state;
  }
}

export default productReducers;
