import { connect } from "react-redux";

import AddButton from "../../components/AddButton";
import { invokeInfo } from "../AlertBoxWrapper/actions";
import { openPositionMarker } from "../MapContainerWrapper/actions";
import { showModalContent } from "../ModalGroupWrapper/actions";

const mapStateToProps = state => ({
  userUuid: state.user.uuid,
  modalSection: state.modalSection,
  theme: state.theme,
  bottomSectionSizeMode: state.bottomSection.sizeMode
});

const mapDispatchToProps = {
  invokeInfo,
  openPositionMarker,
  showModalContent
};

const AddButtonWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(AddButton);

export default AddButtonWrapper;
