import { connect } from "react-redux";

import TopSection from "../../components/TopSection";

const mapStateToProps = state => ({
  userUuid: state.user.uuid
});

const mapDispatchToProps = {};

const TopSectionWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(TopSection);

export default TopSectionWrapper;
