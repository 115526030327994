export const getClassName = (icons, mapType) => {
  const defaultClassName = "SelectedPoint";

  const iconTypes = icons.split(":");
  const icon =
    mapType === "satellite" && !!iconTypes[1] ? iconTypes[1] : iconTypes[0];
  const iconData = icon.split("_");

  if (iconData[0] !== "") return defaultClassName + "--" + iconData[0];
  return defaultClassName;
};
