import React from "react";

import "./style.scss";
import { getIframeProps } from "./functions";

export function ModalSectionExternalContent(props) {
  return (
    <div className="ModalSectionContent__content_container">
      <iframe title="External content" {...getIframeProps(props)} />
    </div>
  );
}
