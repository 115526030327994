import { connect } from "react-redux";

import SelectedPoint from "../../components/SelectedPoint";

const mapStateToProps = state => ({
  selectedUnit: state.map.selectedUnit,
  mapType: state.map.background
});

const mapDispatchToProps = {};

const SelectedPointWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(SelectedPoint);

export default SelectedPointWrapper;
