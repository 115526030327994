import { connect } from "react-redux";

import LanguageButton from "../../components/LanguageButton";

const mapStateToProps = state => ({
  theme: state.theme
});

const mapDispatchToProps = {};

const LanguageButtonWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(LanguageButton);

export default LanguageButtonWrapper;
