import React, { Component } from "react";

import "./style.scss";
import { DescribedButton } from "react-bootstrap-tools";
import { translate } from "react-i18next";
import Config from "../../containers/App/config";
import { Collapse } from "react-bootstrap";
import {
  FacebookIcon,
  FacebookShareButton,
  FacebookShareCount,
  GooglePlusIcon,
  GooglePlusShareButton,
  GooglePlusShareCount,
  LinkedinIcon,
  LinkedinShareButton,
  LinkedinShareCount,
  PinterestIcon,
  PinterestShareButton,
  PinterestShareCount,
  RedditIcon,
  RedditShareButton,
  RedditShareCount,
  TumblrIcon,
  TumblrShareButton,
  TumblrShareCount,
  TwitterIcon,
  TwitterShareButton,
  VKIcon,
  VKShareButton,
  VKShareCount,
  WhatsappIcon,
  WhatsappShareButton
} from "react-share";

export class ShareButtonComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShareMenuShown: false
    };

    this.url = Config.url.mainPage;
    this.title = props.t("ShareButton.pageTitle");
    this.icon = Config.url.mainPage + "favicon.png";
    this.showCounter = false;

    this.iconProps = {
      size: 32,
      round: true
    };

    this.mouseClickInside = false;

    this.t = props.t;
    this.shareButtonDescription = this.t("ShareButton.description");
  }

  componentDidMount() {
    window.addEventListener("mousedown", this.handlePageClick, false);
  }

  componentWillUnmount() {
    window.removeEventListener("mousedown", this.handlePageClick, false);
  }

  handlePageClick = () => {
    if (this.mouseClickInside) {
      return;
    }

    if (this.state.isShareMenuShown) this.toggleMenu();
  };

  toggleMenu = () => {
    this.setState({
      isShareMenuShown: !this.state.isShareMenuShown
    });
  };

  handleClickInside = () => {
    this.handleClick(true);
  };

  handleClickOutside = () => {
    this.handleClick(false);
  };

  handleClick = mouseClickInside => {
    this.mouseClickInside = mouseClickInside;
  };

  render() {
    /*const { i18n } = this.props;

        const changeLanguage = (lng) => {
            i18n.changeLanguage(lng);
        };*/

    return (
      <div className="ShareButton">
        {/*<DescribedButton
                    text='pl'
                    placement='bottom'
                    buttonClassName='ShareButton__button'
                    onClick={() => changeLanguage('pl')}
                />
                <DescribedButton
                    text='en'
                    placement='bottom'
                    buttonClassName='ShareButton__button'
                    onClick={() => changeLanguage('en')}
                />*/}
        <DescribedButton
          description={this.shareButtonDescription}
          placement="bottom"
          icon={"share"}
          buttonClassName="ShareButton__button"
          iconClassName="ShareButton__button-icon"
          onClick={this.toggleMenu}
        />

        <Collapse in={this.state.isShareMenuShown}>
          <div
            className="ShareButton__share-menu-wrapper"
            onMouseDown={this.handleClickInside}
            onMouseUp={this.handleClickOutside}
          >
            <div className="ShareButton__share-menu">
              <section className="ShareButton__share-menu-provider">
                <FacebookShareButton url={this.url} quote={this.title}>
                  <FacebookIcon {...this.iconProps} />
                  {this.showCounter && (
                    <FacebookShareCount url={this.url}>
                      {count => count}
                    </FacebookShareCount>
                  )}
                </FacebookShareButton>
              </section>

              <section className="ShareButton__share-menu-provider">
                <LinkedinShareButton
                  url={this.url}
                  title={this.title}
                  windowWidth={750}
                  windowHeight={600}
                >
                  <LinkedinIcon {...this.iconProps} />
                  {this.showCounter && (
                    <LinkedinShareCount url={this.url}>
                      {count => count}
                    </LinkedinShareCount>
                  )}
                </LinkedinShareButton>
              </section>

              <section className="ShareButton__share-menu-provider">
                <TwitterShareButton url={this.url} title={this.title}>
                  <TwitterIcon {...this.iconProps} />
                </TwitterShareButton>
              </section>

              <section className="ShareButton__share-menu-provider">
                <WhatsappShareButton
                  url={this.url}
                  title={this.title}
                  separator=":: "
                >
                  <WhatsappIcon {...this.iconProps} />
                </WhatsappShareButton>
              </section>

              <section className="ShareButton__share-menu-provider">
                <GooglePlusShareButton url={this.url}>
                  <GooglePlusIcon {...this.iconProps} />
                  {this.showCounter && (
                    <GooglePlusShareCount url={this.url}>
                      {count => count}
                    </GooglePlusShareCount>
                  )}
                </GooglePlusShareButton>
              </section>

              <section className="ShareButton__share-menu-provider">
                <PinterestShareButton
                  url={this.url}
                  media={this.icon}
                  windowWidth={1000}
                  windowHeight={730}
                >
                  <PinterestIcon {...this.iconProps} />
                  {this.showCounter && (
                    <PinterestShareCount url={this.url}>
                      {count => count}
                    </PinterestShareCount>
                  )}
                </PinterestShareButton>
              </section>

              <section className="ShareButton__share-menu-provider">
                <VKShareButton
                  url={this.url}
                  image={this.icon}
                  windowWidth={660}
                  windowHeight={460}
                >
                  <VKIcon {...this.iconProps} />
                  {this.showCounter && (
                    <VKShareCount url={this.url}>{count => count}</VKShareCount>
                  )}
                </VKShareButton>
              </section>

              <section className="ShareButton__share-menu-provider">
                <RedditShareButton
                  url={this.url}
                  title={this.title}
                  windowWidth={660}
                  windowHeight={460}
                >
                  <RedditIcon {...this.iconProps} />
                  {this.showCounter && (
                    <RedditShareCount url={this.url}>
                      {count => count}
                    </RedditShareCount>
                  )}
                </RedditShareButton>
              </section>

              <section className="ShareButton__share-menu-provider">
                <TumblrShareButton
                  url={this.url}
                  title={this.title}
                  windowWidth={660}
                  windowHeight={460}
                >
                  <TumblrIcon {...this.iconProps} />
                  {this.showCounter && (
                    <TumblrShareCount url={this.url}>
                      {count => count}
                    </TumblrShareCount>
                  )}
                </TumblrShareButton>
              </section>
            </div>
          </div>
        </Collapse>
      </div>
    );
  }
}

export default translate("frontend")(ShareButtonComponent);
