import React, { Component } from "react";

import "./style.scss";
import {
  canShowAddButton,
  canShowDeleteButton,
  canShowEditUnitTypeButton
} from "./functions";
import { Config } from "../ModalSection/config";
import { shouldCaptchaAppear } from "../InfoSectionContent/functions";
import { DescribedButton } from "react-bootstrap-tools";
import { translate } from "react-i18next";
import { ButtonGroup } from "react-bootstrap";

class InfoSectionInfoButtonsComponent extends Component {
  constructor(props) {
    super(props);

    this.t = props.t;
    this.addButtonDescription = this.t(
      "InfoSectionInfoButtons.buttonAddDescription"
    );
    this.locationButtonDescription = this.t(
      "InfoSectionInfoButtons.buttonEditPositionDescription"
    );
    this.unitTypeButtonDescription = this.t(
      "InfoSectionInfoButtons.buttonEditTypeDescription"
    );
    this.deleteButtonDescription = this.t(
      "InfoSectionInfoButtons.buttonRemoveDescription"
    );
  }

  handleDeleteClick = () => {
    shouldCaptchaAppear(
      "delete",
      this.props.user.lastChanges,
      this.props.user.isAdmin,
      Config.captcha
    )
      ? this.handleShowCaptcha(this.deleteUnit)
      : this.deleteUnit();
  };

  deleteUnit = () => {
    this.props.handleDeleteUnit();
  };

  handleAddClick = () => {
    this.handleShowCaptcha(this.addUnit);
  };

  addUnit = () => {
    this.props.handleAddUnit({
      ...this.props.content,
      lat: Math.floor(1000000 * this.props.content.lat),
      lon: Math.floor(1000000 * this.props.content.lon)
    });
  };

  handleEditPositionClick = () => {
    this.props.setModalConf(this.props.modalId, { hide: true });
    this.props.openPositionMarker({
      lat: this.props.content.lat,
      lon: this.props.content.lon
    });
  };

  handleEditUnitTypeClick = () => {
    this.props.setModalConf(this.props.modalId, {
      unitTypeId: this.props.content.unit_type_id
    });
    this.props.setModalType(this.props.modalId, "unitTypes");
  };

  handleShowCaptcha = action => {
    this.props.activateCaptcha(action, "Unit info - buttons");
  };

  render() {
    return (
      <tfoot>
        <tr>
          <td />
          <td className="InfoSectionInfoButtons__right">
            <ButtonGroup>
              {canShowAddButton(this.props.unitId) && (
                <DescribedButton
                  description={this.addButtonDescription}
                  size="sm"
                  onClick={this.handleAddClick}
                  disabled={!this.props.canAdd}
                  buttonClassName="InfoSectionInfoButtons"
                  icon="done"
                />
              )}
              <DescribedButton
                description={this.locationButtonDescription}
                size="sm"
                onClick={this.handleEditPositionClick}
                buttonClassName="InfoSectionInfoButtons"
                icon="edit_location"
              />
              {canShowEditUnitTypeButton(this.props.unitId) && (
                <DescribedButton
                  description={this.unitTypeButtonDescription}
                  descriptionTheme={this.props.theme}
                  size="sm"
                  onClick={this.handleEditUnitTypeClick}
                  buttonClassName="InfoSectionInfoButtons"
                  icon="undo"
                />
              )}
              {canShowDeleteButton(this.props.unitId) && (
                <DescribedButton
                  description={this.deleteButtonDescription}
                  size="sm"
                  onClick={this.handleDeleteClick}
                  buttonClassName="InfoSectionInfoButtons"
                  icon="delete"
                />
              )}
            </ButtonGroup>
          </td>
        </tr>
      </tfoot>
    );
  }
}

export default translate("frontend")(InfoSectionInfoButtonsComponent);
