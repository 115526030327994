export const getTypeName = infoType => {
  switch (infoType) {
    case "warning":
    case "danger":
    case "success":
    case "info":
      return infoType;
    default:
      return null;
  }
};
