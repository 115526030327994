import { connect } from "react-redux";

import AgentButton from "../../components/AgentButton";
import { showModalContent } from "../ModalGroupWrapper/actions";

const mapStateToProps = state => ({
  isLeftSectionCollapsed: state.leftSection.collapsed,
  bottomSectionSizeMode: state.bottomSection.sizeMode
});

const mapDispatchToProps = {
  showModalContent
};

const AgentButtonWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(AgentButton);

export default AgentButtonWrapper;
