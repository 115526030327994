import { depracated_handleFetch } from "phyzzi.com-frontend-utils";
import Config from "../../containers/App/config";

export const handleFetchUnitUsers = conf => {
  depracated_handleFetch(
    {
      url: Config.url.api.pointUserResource.replace(
        "_POINT_ID_",
        encodeURIComponent(conf.id)
      ),
      token: conf.token
    },
    {
      success: json => conf.injectUnitUsersData(json),
      error: () => handleFetchUnitUsersFail(conf.invokeInfo)
    }
  );
};

const handleFetchUnitUsersFail = invokeInfo => {
  invokeInfo({
    variant: "danger",
    message: "InfoSectionUsers.fetchUsersFailAlert"
  });
};

export const handleFetchUnitData = conf => {
  depracated_handleFetch(
    {
      url: Config.url.api.pointResource + "/" + conf.id,
      token: conf.token
    },
    {
      success: json => conf.handleFetchUnitDataSuccess(json),
      error: () => handleFetchUnitDataFail(conf.invokeInfo)
    }
  );
};

const handleFetchUnitDataFail = invokeInfo => {
  invokeInfo({
    variant: "danger",
    message: "InfoSectionUsers.fetchDataFailAlert"
  });
};

export const handleFetchPrivateData = conf => {
  depracated_handleFetch(
    {
      url: Config.url.api.privateDataResource,
      token: conf.token
    },
    {
      success: json => conf.handleFetchPrivateDataSuccess(json),
      error: () => handleFetchPrivateDataFail(conf.invokeInfo)
    }
  );
};

const handleFetchPrivateDataFail = invokeInfo => {
  invokeInfo({
    variant: "danger",
    message: "InfoSectionUsers.fetchPrivateDataFailAlert"
  });
};

export const handleFetchUserAdd = conf => {
  depracated_handleFetch(
    {
      url: Config.url.api.pointUserResource.replace(
        "_POINT_ID_",
        encodeURIComponent(conf.id)
      ),
      method: "POST",
      token: conf.token,
      body: conf.body
    },
    {
      success: conf.handleReload,
      error: () => handleAddUnitUserFail(conf.invokeInfo)
    }
  );
};

const handleAddUnitUserFail = invokeInfo => {
  invokeInfo({
    variant: "danger",
    message: "InfoSectionUsers.addUserFailAlert"
  });
};

export const handleFetchUserDelete = conf => {
  depracated_handleFetch(
    {
      url:
        Config.url.api.pointUserResource.replace(
          "_POINT_ID_",
          encodeURIComponent(conf.id)
        ) +
        "/" +
        encodeURIComponent(conf.userUuid),
      method: "DELETE",
      token: conf.token
    },
    {
      success: conf.handleReload,
      error: () => handleDeleteUnitUserFail(conf.invokeInfo)
    }
  );
};

const handleDeleteUnitUserFail = invokeInfo => {
  invokeInfo({
    variant: "danger",
    message: "InfoSectionUsers.deleteUserFailAlert"
  });
};
