import React, { Component } from "react";

import "./style.scss";
import { DescribedButton } from "react-bootstrap-tools";
import { translate } from "react-i18next";
import UserProfileUnitsWrapper from "../../containers/UserProfileUnitsWrapper/index";
import {
  handleFetchUserData,
  handleRemoveBoard,
  handleRemoveUser,
  handleSaveUserValue
} from "./fetchUtils";
import UserProfileData from "../UserProfileData/index";
import { Avatar } from "react-bootstrap-tools";
import { PhyzziBrandLoader } from "phyzzi.com-frontend-utils";
import "phyzzi.com-frontend-utils/build/static/css/index.css";

export class UserProfileComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uuid: props.uuid,
      userData: {}
    };

    this.t = props.t;
    this.linkedinProfileButtonDescription = this.t(
      "UserProfile.buttonLinkedinDescription"
    );
    this.messageButtonDescription = this.t(
      "UserProfile.buttonMessageDescription"
    );
    this.messageButtonUnsignedDescription = this.t(
      "UserProfile.buttonMessageUnsignedDescription"
    );
  }

  componentDidMount() {
    Object.keys(this.state.userData).length === 0 && this.fetchUserData();
  }

  fetchUserData = () => {
    handleFetchUserData({
      uuid: this.state.uuid,
      token: this.props.token,
      injectUserData: this.injectUserData,
      invokeInfo: this.props.invokeInfo
    });
  };

  injectUserData = json => {
    if (Object.keys(json).length > 0) {
      this.setState({
        userData: json
      });
    }
  };

  handleMessageClick = () => {
    this.props.showModalContent({
      type: "messenger",
      conf: {
        userUuid: this.props.uuid
      }
    });
  };

  handleRemoveUserClick = unitId => {
    this.removeUser(unitId);
  };

  removeUser = unitId => {
    handleRemoveUser({
      unitId,
      uuid: this.state.uuid,
      token: this.props.token,
      fetchUserData: this.fetchUserData,
      invokeInfo: this.props.invokeInfo
    });
  };

  handleRemoveBoardClick = boardId => {
    this.removeBoard(boardId);
  };

  removeBoard = boardId => {
    handleRemoveBoard({
      boardId,
      uuid: this.state.uuid,
      token: this.props.token,
      fetchUserData: this.fetchUserData,
      invokeInfo: this.props.invokeInfo
    });
  };

  saveUserValue = (fieldId, content) => {
    handleSaveUserValue({
      fieldId,
      content,
      uuid: this.state.uuid,
      token: this.props.token,
      invokeInfo: this.props.invokeInfo,
      changeUserData: this.changeUserData
    });
  };

  changeUserData = (fieldId, content) => {
    let userData = this.state.userData;

    if (["location", "position"].indexOf(fieldId) > -1)
      userData[fieldId] = content;

    if (
      [
        "company",
        "company_description",
        "previous_company",
        "industry",
        "country",
        "languages",
        "phone_number",
        "website",
        "personal_summary"
      ].indexOf(fieldId) > -1
    )
      userData.userData[fieldId] = content;

    this.setState({
      userData
    });
  };

  handleLinkedinClick = () => {
    window.open(this.state.userData.userLinkedin.profile_url);
  };

  render() {
    const nonEditable = this.props.user.uuid !== this.props.uuid;

    return (
      <section className="UserProfile" key={this.props.user.uuid}>
        {Object.keys(this.state.userData).length === 0 ? (
          <PhyzziBrandLoader />
        ) : (
          <div
            className="UserProfile__content"
            // for tracking purposes
            id="UserProfile__content"
            // for tracking purposes
            trackinglabel={this.props.modalType}
          >
            <div className="UserProfile__title">
              {this.state.userData.name} {this.state.userData.surname}
              {this.state.userData.accountType.price > 0 && (
                <span
                  className={
                    "UserProfile__account-badge--" +
                    this.state.userData.accountType.string_id
                  }
                >
                  {this.t(
                    "common:accountTypeName." +
                      this.state.userData.accountType.string_id
                  )}
                </span>
              )}
            </div>
            <table className="UserProfile__profile-table">
              <tbody>
                <tr>
                  <td className="UserProfile__cell-title">
                    {this.t("UserProfile.position")}:
                  </td>
                  <td className="UserProfile__cell">
                    <UserProfileData
                      fieldId="position"
                      content={this.state.userData.position || ""}
                      save={formValue =>
                        this.saveUserValue("position", formValue)
                      }
                      nonEditable={nonEditable}
                      theme={this.props.theme}
                    />
                  </td>
                  <td className="UserProfile__cell-picture" rowSpan={8}>
                    <section className="UserProfile__side-wrapper">
                      <Avatar
                        src={this.state.userData.picture}
                        className="UserProfile__side-cell--picture-wrapper"
                        iconClassName="UserProfile__side-cell--picture"
                        imageClassName="UserProfile__side-cell--picture-image"
                      />
                      <div className="UserProfile__side-cell">
                        {!!this.state.userData.userLinkedin && (
                          <div className="UserProfile__side-button-wrapper">
                            <DescribedButton
                              description={
                                !!this.state.userData.userLinkedin.profile_url
                                  ? this.linkedinProfileButtonDescription
                                  : null
                              }
                              onClick={this.handleLinkedinClick}
                              buttonClassName="UserProfile__social-button--linkedin"
                              disabled={
                                !this.state.userData.userLinkedin.profile_url
                              }
                            />
                          </div>
                        )}
                        {!!this.state.userData.userGoogle && (
                          <div className="UserProfile__side-button-wrapper">
                            <div className="UserProfile__social-button--google" />
                          </div>
                        )}
                        {nonEditable && (
                          <div className="UserProfile__side-button-wrapper">
                            <DescribedButton
                              description={
                                !!this.props.user.uuid
                                  ? this.messageButtonDescription
                                  : this.messageButtonUnsignedDescription
                              }
                              disabled={!this.props.user.uuid}
                              onClick={this.handleMessageClick}
                              buttonClassName="UserProfile__message-button"
                              icon={"email"}
                            />
                          </div>
                        )}
                      </div>
                    </section>
                  </td>
                </tr>
                <tr>
                  <td className="UserProfile__cell-title">
                    {this.t("UserProfile.company")}:
                  </td>
                  <td className="UserProfile__cell">
                    <UserProfileData
                      fieldId="company"
                      content={this.state.userData.userData.company || ""}
                      save={formValue =>
                        this.saveUserValue("company", formValue)
                      }
                      nonEditable={nonEditable}
                      theme={this.props.theme}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="UserProfile__cell-title">
                    {this.t("UserProfile.previousCompany")}:
                  </td>
                  <td className="UserProfile__cell">
                    <UserProfileData
                      fieldId="previous_company"
                      content={
                        this.state.userData.userData.previous_company || ""
                      }
                      save={formValue =>
                        this.saveUserValue("previous_company", formValue)
                      }
                      nonEditable={nonEditable}
                      theme={this.props.theme}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="UserProfile__cell-title">
                    {this.t("UserProfile.languages")}:
                  </td>
                  <td className="UserProfile__cell">
                    <UserProfileData
                      fieldId="languages"
                      content={this.state.userData.userData.languages || ""}
                      save={formValue =>
                        this.saveUserValue("languages", formValue)
                      }
                      nonEditable={nonEditable}
                      theme={this.props.theme}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="UserProfile__cell-title">
                    {this.t("UserProfile.location")}:
                  </td>
                  <td className="UserProfile__cell">
                    <UserProfileData
                      fieldId="location"
                      content={this.state.userData.location || ""}
                      save={formValue =>
                        this.saveUserValue("location", formValue)
                      }
                      nonEditable={nonEditable}
                      theme={this.props.theme}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="UserProfile__cell-title">
                    {this.t("UserProfile.phone")}:
                  </td>
                  <td className="UserProfile__cell">
                    {this.state.userData.contact_visibility ? (
                      <UserProfileData
                        fieldId="phone_number"
                        content={
                          this.state.userData.userData.phone_number || ""
                        }
                        save={formValue =>
                          this.saveUserValue("phone_number", formValue)
                        }
                        nonEditable={nonEditable}
                        maxLength={15}
                        theme={this.props.theme}
                      />
                    ) : (
                      this.t("common:hidden")
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="UserProfile__cell-title">
                    {this.t("UserProfile.email")}:
                  </td>
                  <td className="UserProfile__cell">
                    {this.state.userData.contact_visibility
                      ? this.state.userData.email
                      : this.t("common:hidden")}
                  </td>
                </tr>
                <tr>
                  <td className="UserProfile__cell-title">
                    {this.t("UserProfile.web")}:
                  </td>
                  <td className="UserProfile__cell">
                    <UserProfileData
                      fieldId="website"
                      content={
                        nonEditable ? (
                          !!this.state.userData.userData.website ? (
                            <a
                              href={this.state.userData.userData.website}
                              target="_blank"
                              className="UserProfile__link"
                              rel="noopener noreferrer"
                            >
                              {this.state.userData.userData.website}
                            </a>
                          ) : (
                            ""
                          )
                        ) : (
                          this.state.userData.userData.website || ""
                        )
                      }
                      save={formValue =>
                        this.saveUserValue("website", formValue)
                      }
                      nonEditable={nonEditable}
                      theme={this.props.theme}
                      placeholder={this.t("UserProfile.webPlaceholder")}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="UserProfile__cell-title-wide">
                    {this.t("UserProfile.personalSummary")}:
                  </td>
                  <td className="UserProfile__cell-wide" colSpan={2}>
                    <UserProfileData
                      fieldId="personal_summary"
                      content={
                        this.state.userData.userData.personal_summary || ""
                      }
                      save={formValue =>
                        this.saveUserValue("personal_summary", formValue)
                      }
                      nonEditable={nonEditable}
                      as="textarea"
                      maxLength={1000}
                      theme={this.props.theme}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="UserProfile__cell-title-wide">
                    {this.t("UserProfile.companyDescription")}:
                  </td>
                  <td className="UserProfile__cell-wide" colSpan={2}>
                    <UserProfileData
                      fieldId="company_description"
                      content={
                        this.state.userData.userData.company_description || ""
                      }
                      save={formValue =>
                        this.saveUserValue("company_description", formValue)
                      }
                      nonEditable={nonEditable}
                      as="textarea"
                      maxLength={1000}
                      theme={this.props.theme}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="UserProfile__cell-title-wide">
                    {this.t("UserProfile.Units")}{" "}
                    {this.state.userData.unitUsers.length}/
                    {this.state.userData.accountType.max_subscriptions}:
                  </td>
                  <td className="UserProfile__cell-wide" colSpan={2}>
                    {(this.state.userData.unitUsers.length > 0 ||
                      this.state.userData.posts.length > 0) && (
                      <UserProfileUnitsWrapper
                        modalId={this.props.modalId}
                        unitUsers={this.state.userData.unitUsers}
                        posts={this.state.userData.posts}
                        nonEditable={nonEditable}
                        handleRemoveUserClick={this.handleRemoveUserClick}
                        handleRemoveBoardClick={this.handleRemoveBoardClick}
                      />
                    )}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}
      </section>
    );
  }
}

export default translate("frontend")(UserProfileComponent);
