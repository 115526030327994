import { INJECT_DATA } from "./actions";

const initialState = {
  token: "",
  captchaPublicKey: ""
};

function initDataReducers(state = initialState, action) {
  switch (action.type) {
    case INJECT_DATA:
      return Object.assign({}, state, action.injectedData);
    default:
      return state;
  }
}

export default initDataReducers;
