import { combineReducers } from "redux";

import initDataReducers from "./initDataReducers";
import productReducers from "./productReducers";
import accessReducers from "./accessReducers";
import themeReducers from "./themeReducers";
import mapReducers from "../MapContainerWrapper/mapReducers";
import userReducers from "./userReducers";
import leftSectionReducers from "../LeftSectionWrapper/leftSectionReducers";
import bottomSectionReducers from "../BottomSectionWrapper/bottomSectionReducers";
import unitTypeTreeReducers from "../UnitTypeTreeWrapper/unitTypeTreeReducers";
import alertBoxReducers from "../AlertBoxWrapper/alertBoxReducers";
import unitTypeReducers from "./unitTypeReducers";
import unitTypeProductReducers from "./unitTypeProductReducers";
import modalSectionReducers from "../ModalGroupWrapper/modalSectionReducers";
import boardGroupReducers from "./boardGroupReducers";
import movableReducers from "../MovableWrapper/movableReducers";
import captchaReducers from "../CaptchaWrapper/captchaReducers";
import messagesAmountReducers from "./messagesAmountReducers";

export const defaultReducersObject = {
  initData: initDataReducers,
  products: productReducers,
  accesses: accessReducers,
  unitTypes: unitTypeReducers,
  unitTypeProducts: unitTypeProductReducers,
  boardGroups: boardGroupReducers,
  theme: themeReducers,
  map: mapReducers,
  user: userReducers,
  leftSection: leftSectionReducers,
  bottomSection: bottomSectionReducers,
  unitTypeTree: unitTypeTreeReducers,
  alertBox: alertBoxReducers,
  modalSection: modalSectionReducers,
  movable: movableReducers,
  captcha: captchaReducers,
  messagesAmount: messagesAmountReducers
};

const reducers = combineReducers(defaultReducersObject);

export default reducers;
