import React from "react";

/**
 * Returns representation of search results with bold parts suited to search query
 * @param string result, i.e. Andy mocke-d Smith
 * @param array queryArray, i.e. ["mock"]
 * @returns {Array} sorted array of results
 * i.e. ["Andy ", <strong>mock</strong>, "e-d Smith"]
 */
export const transformString = (result, queryArray) => {
  // Array of part of strings not matched to search query
  let unMatched = [result.toLowerCase()];
  // Array of part of strings matched to search query
  let matched = [];

  queryArray.forEach(queryPart => {
    let localStrings = [];

    unMatched.forEach((stringPart, idx) => {
      const splitted = stringPart.split(queryPart);
      localStrings = localStrings.concat(splitted);
      if (splitted.length > 1) {
        for (let a = 1; a < splitted.length; a++) {
          matched.splice(idx + a - 1, 0, queryPart);
        }
      }
    });

    unMatched = localStrings;
  });

  // Now lowercase string is splited and sorted.
  // To piece it together use lengths of each item:
  // unMatched[0] + matched[0] + unMatched[1] + matched[1] ...
  // You need to use lengths as unMatched and matched holds only lowercases
  let transformedResult = [];
  let start = 0;

  unMatched.forEach((str, idx) => {
    transformedResult.push(result.substring(start, start + str.length));
    start += str.length;

    if (!!matched[idx]) {
      transformedResult.push(
        <strong key={idx}>
          {result.substring(start, start + matched[idx].length)}
        </strong>
      );
      start += matched[idx].length;
    }
  });

  return transformedResult;
};
