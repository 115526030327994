import { connect } from "react-redux";

import ModalSection from "../../components/ModalSection";
import { toggleReloadModalPosition } from "../ModalGroupWrapper/actions";

const mapStateToProps = (state, props) => ({
  modalSection: state.modalSection[props.id],
  isLeftSectionShown: !state.leftSection.collapsed
});

const mapDispatchToProps = {
  toggleReloadModalPosition
};

const ModalSectionWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalSection);

export default ModalSectionWrapper;
