/*
 * Action types
 */

export const DISMISS_INFO = "DISMISS_INFO";
export const INVOKE_INFO = "INVOKE_INFO";

/*
 * Action creators
 */

export function dismissInfo(id) {
  return {
    type: DISMISS_INFO,
    id
  };
}

export function invokeInfo(alertBox) {
  return {
    type: INVOKE_INFO,
    alertBox
  };
}
