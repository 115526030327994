import { connect } from "react-redux";

import InfoSectionBoardNewMessage from "../../components/InfoSectionBoardNewMessage";

const mapStateToProps = state => ({
  boardGroups: state.boardGroups.data
});

const mapDispatchToProps = {};

const InfoSectionBoardNewMessageWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoSectionBoardNewMessage);

export default InfoSectionBoardNewMessageWrapper;
