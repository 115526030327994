import React from "react";

import "./style.scss";
import Config from "../../containers/App/config";

const HowTo = () => {
  return (
    <section className="HowTo">
      <div
        className="HowTo__wrapper"
        // for tracking purposes
        id="HowTo"
      >
        <aside className="HowTo__video-wrapper">
          <iframe
            className="HowTo__iframe"
            title="External content"
            src={Config.url.howToVideo}
          />
        </aside>
      </div>
    </section>
  );
};

export default HowTo;
