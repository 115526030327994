import config from "../../containers/App/config";

export const calculateValidationState = (fieldId, content) => {
  // Non empty values
  if (["name"].indexOf(fieldId) > -1 && content.length === 0) return false;

  // Allowed signs
  if (
    [
      "name",
      "operator",
      "owner",
      "maintenance_since",
      "maintenance_to"
    ].indexOf(fieldId) > -1 &&
    content.length > 0 &&
    !config.regex.allowedSigns.test(content)
  )
    return false;

  // Digits
  if (
    ["capacity", "built_year"].indexOf(fieldId) > -1 &&
    content.length > 0 &&
    !config.regex.digits.test(content)
  )
    return false;

  switch (fieldId) {
    case "built_year":
      const year = new Date();
      if (content.length > 0) {
        if (content.length !== 4) return false;
        if (
          content < config.validation.minYear ||
          content > year.getFullYear() + config.validation.maxYearInterval
        )
          return false;
      }
      break;
    case "capacity":
      if (content.length > 12) return false;
      if (parseInt(content, 10) === 0) return false;
      break;
    default:
      return true;
  }

  return true;
};
