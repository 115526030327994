import React, { Component } from "react";
import "./style.scss";
import { config } from "./config";
import Recaptcha from "react-recaptcha";
import { DescribedButton } from "react-bootstrap-tools";
import { getCaptchaTheme } from "./functions";
import { Modal } from "react-bootstrap";
import { translate } from "react-i18next";

export class CaptchaComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      shouldAppear: !props.disableCaptcha || props.disableCaptcha !== "true",
      resolved: false
    };

    this.t = props.t;
    this.inactivateButtonId =
      "Captcha__cancel " +
      (!!props.captcha.invoker ? props.captcha.invoker : "");
    this.inactivateButtonText = this.t("common:cancel");
  }

  componentWillReceiveProps(props) {
    if (!this.state.shouldAppear)
      this.handleAfterCaptchaAction(props.captcha.afterCaptchaAction);
  }

  handleAfterCaptchaAction = action => {
    if (!!action) action();
  };

  handleResolveCaptcha = () => {
    this.setState({
      resolved: true
    });

    this.handleAfterCaptchaAction(this.props.captcha.afterCaptchaAction);
    this.inactivateCaptcha();
  };

  inactivateCaptcha = () => {
    // Delay is set for tracking purposes (to measure cancel clicking and resolving)
    setTimeout(() => {
      this.props.inactivateCaptcha();
    }, config.resolveTimeout);
  };

  render() {
    if (!this.props.captcha.active || !this.state.shouldAppear) return null;

    return (
      <Modal
        aria-labelledby={"Captcha modal"}
        show={true}
        className={"Captcha--" + this.props.theme}
        // for tracking purposes
        id="Captcha"
        // for tracking purposes
        trackinglabel={
          !!this.props.captcha.invoker ? this.props.captcha.invoker : ""
        }
      >
        <Modal.Body className="Captcha__body">
          <Recaptcha
            sitekey={this.props.captchaPublicKey}
            verifyCallback={this.handleResolveCaptcha}
            theme={getCaptchaTheme(this.props.theme)}
          />
        </Modal.Body>
        <Modal.Footer className="Captcha__footer">
          <DescribedButton
            onClick={this.inactivateCaptcha}
            text={this.inactivateButtonText}
            // for tracking purposes
            buttonClassName="Captcha__cancel"
            // for tracking purposes
            id={this.inactivateButtonId}
          />

          {/*
          // all div for tracking purposes
          */}
          {this.state.resolved && (
            <div
              className="Captcha__resolved"
              // for tracking purposes
              id="Captcha__resolved"
              // for tracking purposes
              trackinglabel={
                !!this.props.captcha.invoker ? this.props.captcha.invoker : ""
              }
            />
          )}
        </Modal.Footer>
      </Modal>
    );
  }
}

export default translate("frontend")(CaptchaComponent);
