import React from "react";
import "./style.scss";
import { getIconProps } from "./functions";
import conf from "../../containers/App/config";
import { translate } from "react-i18next";
import UnitTypeMenuItem from "../UnitTypeMenuItem";

const UnitTypeMenu = props => {
  const { t } = props;

  return (
    <div className="UnitTypeMenu">
      <div className="UnitTypeMenu__section--name">
        <UnitTypeMenuItem
          level={props.level}
          hasChildren={props.hasChildren}
          selected={props.unitTypeBranch.selected}
          collapsed={props.collapsed}
          unitType={props.unitType.stringId}
          toggleChildrenCollapse={() =>
            props.toggleChildrenCollapse(props.unitType.id)
          }
          toggleUnitTypeSelection={() =>
            props.toggleUnitTypeSelection(props.unitType.id)
          }
        />
      </div>
      {!props.hasChildren &&
        props.unitTypeBranch.selected && (
          <div className="UnitTypeMenu__section">
            {!!props.unitType.icon && (
              <img
                alt={t("UnitTypeMenu.mapIconAlt")}
                {...getIconProps(
                  props.unitType.icon,
                  conf.icons,
                  props.mapType
                )}
              />
            )}
          </div>
        )}
    </div>
  );
};

export default translate("frontend")(UnitTypeMenu);
