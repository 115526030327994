import {
  RECEIVE_PUBLIC_DATA,
  RECEIVE_PUBLIC_BOARD_GROUPS_DATA
} from "./actions";
import { updateArrayState } from "../../utils/updateArrayState";
import Immutable from "seamless-immutable";
import { unifyDate } from "phyzzi.com-frontend-utils";

const initialState = {
  lastUpdate: 0,
  data: Immutable([])
};

function boardGroupReducers(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_PUBLIC_DATA:
      if (action.json.board_groups) {
        return Object.assign({}, state, {
          lastUpdate: unifyDate(action.json.board_groups.lastUpdate),
          data: updateArrayState(state.data, action.json.board_groups.data)
        });
      }
      return state;
    case RECEIVE_PUBLIC_BOARD_GROUPS_DATA:
      return Object.assign({}, state, {
        lastUpdate: unifyDate(action.json.lastUpdate),
        data: updateArrayState(state.data, action.json.data)
      });
    default:
      return state;
  }
}

export default boardGroupReducers;
