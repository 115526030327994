import { handleFetch } from "phyzzi.com-frontend-utils";
import { Config as config } from "./../../containers/App/config";
import { calculateUnreadMessagesAmount } from "./functions";

export const handleFetchMessages = conf => {
  const { userUuid, token, setMessagesAmount } = conf;
  handleFetch(
    {
      url: config.url.api.topicResource + "/list/" + userUuid,
      token
    },
    {
      success: (params, json) =>
        fetchMessagesSuccess(json, setMessagesAmount, userUuid)
    }
  );
};

const fetchMessagesSuccess = (json, setMessagesAmount, userUuid) => {
  const unreadMessagesAmount = calculateUnreadMessagesAmount(json, userUuid);
  setMessagesAmount(unreadMessagesAmount);
};
