import { depracated_handleFetch } from "phyzzi.com-frontend-utils";
import Config from "../../containers/App/config";

export const handleFetchUnitBoardData = conf => {
  depracated_handleFetch(
    {
      url: Config.url.api.postResource + "/unit/" + conf.unitId,
      token: conf.token
    },
    {
      success: json => conf.injectUnitBoardData(json),
      error: () => handleFetchUnitBoardDataFail(conf.invokeInfo)
    }
  );
};

const handleFetchUnitBoardDataSuccess = (
  json,
  injectUserSubscribed,
  getUnitBoardData
) => {
  injectUserSubscribed(json);
  getUnitBoardData();
};

const handleFetchUnitBoardDataFail = invokeInfo => {
  invokeInfo({
    variant: "danger",
    message: "InfoSectionBoard.fetchBoardsFailAlert"
  });
};

export const handleFetchUnitUsers = conf => {
  depracated_handleFetch(
    {
      url: conf.url,
      token: conf.token
    },
    {
      success: json =>
        handleFetchUnitBoardDataSuccess(
          json,
          conf.injectUserSubscribed,
          conf.getUnitBoardData
        ),
      error: () => handleFetchUnitBoardDataFail(conf.invokeInfo)
    }
  );
};

export const handleFetchPrivateData = conf => {
  depracated_handleFetch(
    {
      url: conf.url,
      token: conf.token
    },
    {
      success: json => conf.receivePrivateData(json),
      error: () => handleFetchPrivateDataFail(conf.invokeInfo)
    }
  );
};

const handleFetchPrivateDataFail = invokeInfo => {
  invokeInfo({
    variant: "danger",
    message: "InfoSectionBoard.fetchPrivateDataFailAlert"
  });
};

export const handleFetchRefresh = conf => {
  depracated_handleFetch(
    {
      url: Config.url.api.postResource + "/" + conf.id + "/refresh",
      method: "PUT",
      token: conf.token,
      body: {}
    },
    {
      success: () =>
        handleFetchRefreshSuccess(conf.handleReload, conf.invokeInfo),
      error: () => handleFetchRefreshFail(conf.invokeInfo)
    }
  );
};

const handleFetchRefreshSuccess = (handleReload, invokeInfo) => {
  invokeInfo({
    variant: "success",
    message: "InfoSectionBoard.refreshBoardSuccessAlert"
  });
  handleReload();
};

const handleFetchRefreshFail = invokeInfo => {
  invokeInfo({
    variant: "danger",
    message: "InfoSectionBoard.refreshBoardFailAlert"
  });
};

export const handleFetchDelete = conf => {
  depracated_handleFetch(
    {
      url: Config.url.api.postResource + "/" + conf.id,
      method: "DELETE",
      token: conf.token
    },
    {
      success: () =>
        handleFetchDeleteSuccess(conf.handleReload, conf.invokeInfo),
      error: () => handleFetchDeleteFail(conf.invokeInfo)
    }
  );
};

const handleFetchDeleteSuccess = (handleReload, invokeInfo) => {
  invokeInfo({
    variant: "success",
    message: "InfoSectionBoard.deleteBoardSuccessAlert"
  });
  handleReload();
};

const handleFetchDeleteFail = invokeInfo => {
  invokeInfo({
    variant: "danger",
    message: "InfoSectionBoard.deleteBoardFailAlert"
  });
};

export const handleFetchAdd = conf => {
  depracated_handleFetch(
    {
      url: Config.url.api.postResource,
      method: "POST",
      token: conf.token,
      body: conf.body
    },
    {
      success: () =>
        handleFetchAddSuccess(
          conf.handleReload,
          conf.handleFetchAddSuccess,
          conf.invokeInfo
        ),
      error: () => handleFetchAddFail(conf.invokeInfo, conf.failCallback)
    }
  );
};

const handleFetchAddSuccess = (
  handleReload,
  handleFetchAddSuccess,
  invokeInfo
) => {
  invokeInfo({
    variant: "success",
    message: "InfoSectionBoard.addBoardSuccessAlert"
  });
  handleFetchAddSuccess();
  handleReload();
};

const handleFetchAddFail = (invokeInfo, failCallback) => {
  failCallback();
  invokeInfo({
    variant: "danger",
    message: "InfoSectionBoard.addBoardFailAlert"
  });
};

export const handleFetchEdit = conf => {
  depracated_handleFetch(
    {
      url: Config.url.api.postResource + "/" + conf.id,
      method: "PUT",
      token: conf.token,
      body: conf.body
    },
    {
      success: () =>
        handleFetchEditSuccess(
          conf.handleReload,
          conf.handleFetchEditSuccess,
          conf.invokeInfo
        ),
      error: () => handleFetchEditFail(conf.invokeInfo, conf.failCallback)
    }
  );
};

const handleFetchEditSuccess = (
  handleReload,
  handleFetchEditSuccess,
  invokeInfo
) => {
  invokeInfo({
    variant: "success",
    message: "InfoSectionBoard.editBoardSuccessAlert"
  });
  handleFetchEditSuccess();
  handleReload();
};

const handleFetchEditFail = (invokeInfo, failCallback) => {
  failCallback();
  invokeInfo({
    variant: "danger",
    message: "InfoSectionBoard.editBoardFailAlert"
  });
};

export const handleFetchUnitData = conf => {
  depracated_handleFetch(
    {
      url: Config.url.api.pointResource + "/" + conf.id,
      token: conf.token
    },
    {
      success: json => conf.handleFetchUnitDataSuccess(json),
      error: () => handleFetchUnitDataFail(conf.invokeInfo)
    }
  );
};

const handleFetchUnitDataFail = invokeInfo => {
  invokeInfo({
    variant: "danger",
    message: "InfoSectionBoard.fetchDataFailAlert"
  });
};
