import { connect } from "react-redux";

import PrivacyPolicy from "../../components/PrivacyPolicy";
import { invokeInfo } from "../AlertBoxWrapper/actions";

const mapStateToProps = state => ({
  token: state.initData.token
});

const mapDispatchToProps = {
  invokeInfo
};

const PrivacyPolicyWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(PrivacyPolicy);

export default PrivacyPolicyWrapper;
