import React from "react";

import "./style.scss";
import { getInterlocutor, transformDate } from "../Messenger/functions";
import { Avatar } from "react-bootstrap-tools";
import { translate } from "react-i18next";

const MessengerListTopic = props => {
  const { t } = props;

  const latest_message =
    props.topic.messengerMessages.data[
      props.topic.messengerMessages.data.length - 1
    ] || null;

  const interlocutor = getInterlocutor(props.topic, props.author.uuid);

  const handleItemClick = () => {
    props.onTopicListClick(props.topic.id);
  };

  return (
    <div
      onClick={handleItemClick}
      className={"MessengerListTopic" + (props.isSelected ? "--selected" : "")}
    >
      <Avatar
        src={!!interlocutor.user ? interlocutor.user.picture : null}
        className="MessengerListTopic__thumbnail-wrapper"
        iconClassName="MessengerListTopic__thumbnail"
        imageClassName="MessengerListTopic__thumbnail"
      />
      <div className="MessengerListTopic__right-content">
        <div className="MessengerListTopic__user-table">
          <div className="MessengerListTopic__user">
            {interlocutor.user
              ? interlocutor.user.name + " " + interlocutor.user.surname
              : t("MessengerListTopic.deletedUser")}
          </div>
          <div className="MessengerListTopic__date">
            {!!latest_message && transformDate(latest_message.updated_at)}
          </div>
        </div>
        <div className="MessengerListTopic__message">
          {!!latest_message
            ? [
                latest_message.messenger_participant_id !== interlocutor.id &&
                  t("MessengerListTopic.youPrefix") + ": ",
                latest_message.content
              ]
            : t("common:empty")}
        </div>
      </div>
    </div>
  );
};

export default translate("frontend")(MessengerListTopic);
