import { INJECT_DATA } from "../App/actions";
import { getTypeName } from "./functions";
import { DISMISS_INFO, INVOKE_INFO } from "./actions";
import { addAlert, dismissAlert } from "react-bootstrap-tools";
import Config from "../../components/AlertBox/config";

const initialState = [];

function alertBoxReducers(state = initialState, action) {
  switch (action.type) {
    case INJECT_DATA:
      if (
        action.injectedData.infoboxType ||
        action.injectedData.infoboxMessage
      ) {
        return addAlert(state, {
          variant: getTypeName(action.injectedData.infoboxType),
          message: action.injectedData.infoboxMessage,
          withIcon: true,
          withCollapse: true,
          hideAfter: Config.hideAfter,
          dismissible: true
        });
      } else {
        return state;
      }
    case INVOKE_INFO:
      return addAlert(state, {
        ...action.alertBox,
        withIcon: true,
        withCollapse: true,
        hideAfter: Config.hideAfter,
        dismissible: true
      });
    case DISMISS_INFO:
      return dismissAlert(state, action.id);
    default:
      return state;
  }
}

export default alertBoxReducers;
