import { RECEIVE_PRIVATE_DATA, STACK_USER_LAST_CHANGE } from "./actions";
import { convertTimestampToDateTime } from "../../components/PhyzziFrontend/functions";

const initialState = {
  id: null,
  name: null,
  surname: null,
  picture: null,
  isAdmin: false,
  lastChanges: []
};

function userReducers(state = initialState, action) {
  switch (action.type) {
    case RECEIVE_PRIVATE_DATA:
      return Object.assign({}, state, action.json.user);
    case STACK_USER_LAST_CHANGE:
      return Object.assign({}, state, {
        lastChanges: [
          ...state.lastChanges,
          convertTimestampToDateTime(
            new Date().getTime() + new Date().getTimezoneOffset() * 60 * 1000
          )
        ]
      });
    default:
      return state;
  }
}

export default userReducers;
