import { SET_MESSAGES_AMOUNT } from "./actions";

const initialState = {
  amount: 0
};

function messagesAmountReducers(state = initialState, action) {
  switch (action.type) {
    case SET_MESSAGES_AMOUNT:
      return Object.assign({}, state, {
        amount: action.amount
      });
    default:
      return state;
  }
}

export default messagesAmountReducers;
