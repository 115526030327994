/*
 * Action types
 */
export const ACTIVATE_CAPTCHA = "ACTIVATE_CAPTCHA";
export const INACTIVATE_CAPTCHA = "INACTIVATE_CAPTCHA";

/*
 * Action creators
 */
export function activateCaptcha(afterCaptchaAction, invoker) {
  return {
    type: ACTIVATE_CAPTCHA,
    afterCaptchaAction,
    invoker
  };
}

export function inactivateCaptcha() {
  return {
    type: INACTIVATE_CAPTCHA
  };
}
