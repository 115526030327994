export const calculateUnreadMessagesAmount = (messages, uuid) => {
  return messages.filter(message => {
    return (
      message.messengerParticipants.filter(
        participant =>
          !!participant.user &&
          participant.user.uuid === uuid &&
          participant.is_unread
      ).length > 0
    );
  }).length;
};

export const isMessengerModalOpen = modals => {
  return modals.some(modal => modal.type === "messenger");
};
