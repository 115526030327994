import React, { PureComponent } from "react";

import "./style.scss";
import { translate } from "react-i18next";

class MessengerMoreMessages extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      shouldTrigger: true
    };
  }

  componentDidMount() {
    const observerOptions = {
      root: document.querySelector("." + this.props.listWrapper),
      rootMargin: "0px",
      threshold: [1.0]
    };

    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      observerOptions
    );

    this.observer.observe(
      document.querySelector("#" + this.props.loadingTrigger)
    );
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.fetchOlderMessagesTimestamp !==
      this.props.fetchOlderMessagesTimestamp
    ) {
      this.setState({
        shouldTrigger: true
      });
    }
  }

  componentWillUnmount() {
    this.observer.unobserve(
      document.querySelector("#" + this.props.loadingTrigger)
    );
  }

  handleObserver(entities, observer) {
    if (this.state.shouldTrigger) {
      entities.forEach(entry => {
        if (entry.isIntersecting && entry.intersectionRatio > 0)
          this.onMoreMessagesTriggerAppear();
      });
    }
  }

  onMoreMessagesTriggerAppear = () => {
    this.setState({
      shouldTrigger: false
    });
    this.props.handleLoadMoreMessages();
  };

  render() {
    const { t } = this.props;

    return (
      <div id={this.props.loadingTrigger} className={"MessengerMoreMessages"}>
        {t("MessengerMoreMessages.loading")}
      </div>
    );
  }
}

export default translate("frontend")(MessengerMoreMessages);
