import { connect } from "react-redux";

import PointCluster from "../../components/PointCluster";
import { showModalContent } from "../ModalGroupWrapper/actions";
import { selectUnit } from "../MapContainerWrapper/actions";

const mapStateToProps = state => ({
  points: state.map.points,
  unitTypeTree: state.unitTypeTree.data,
  mapType: state.map.background
});

const mapDispatchToProps = {
  showModalContent,
  selectUnit
};

const PointClusterWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(PointCluster);

export default PointClusterWrapper;
