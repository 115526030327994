import React from "react";

import "./style.scss";
import LoginRegisterButtonWrapper from "../../containers/LoginRegisterButtonWrapper/index";

export const TopRightSection = () => {
  return (
    <section className="TopRightSection">
      <LoginRegisterButtonWrapper />
    </section>
  );
};

export default TopRightSection;
