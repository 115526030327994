import { depracated_handleFetch } from "phyzzi.com-frontend-utils";
import Config from "../../containers/App/config";

export const handleFetchUserSettings = conf => {
  depracated_handleFetch(
    {
      url: Config.url.api.privateDataResource,
      token: conf.token
    },
    {
      success: conf.injectUserSettings,
      error: () => handleFetchUserSettingsFail(conf.invokeInfo)
    }
  );
};

const handleFetchUserSettingsFail = invokeInfo => {
  invokeInfo({
    variant: "danger",
    message: "UserSettings.fetchDataFailAlert"
  });
};

export const handleFetchAccountTypes = conf => {
  depracated_handleFetch(
    {
      url: Config.url.api.accountTypeResource,
      token: conf.token
    },
    {
      success: conf.injectAccountTypes,
      error: () => handleFetchAccountTypesFail(conf.invokeInfo)
    }
  );
};

const handleFetchAccountTypesFail = invokeInfo => {
  invokeInfo({
    variant: "danger",
    message: "UserSettings.fetchAccountTypesFailAlert"
  });
};

export const handleFetchChangeContactVisibility = conf => {
  depracated_handleFetch(
    {
      url: Config.url.api.userResource + "/" + conf.uuid,
      method: "PUT",
      token: conf.token,
      body: conf.body
    },
    {
      success: conf.handleReload,
      error: () => handleFetchChangeContactVisibilityFail(conf.invokeInfo)
    }
  );
};

const handleFetchChangeContactVisibilityFail = invokeInfo => {
  invokeInfo({
    variant: "danger",
    message: "UserSettings.changeContactVisibilityFailAlert"
  });
};

export const handleFetchUserDelete = conf => {
  depracated_handleFetch(
    {
      url: Config.url.api.userResource + "/" + conf.uuid,
      method: "DELETE",
      token: conf.token
    },
    {
      success: handleFetchUserDeleteSuccess,
      error: () => handleFetchUserDeleteFail(conf.invokeInfo)
    }
  );
};

const handleFetchUserDeleteSuccess = () => {
  window.location.assign(Config.url.deletedAccountPage);
};

const handleFetchUserDeleteFail = invokeInfo => {
  invokeInfo({
    variant: "danger",
    message: "UserSettings.deleteAccountFailAlert"
  });
};

export const handleFetchDisconnectProvider = conf => {
  depracated_handleFetch(
    {
      url:
        Config.url.api.userResource +
        "/" +
        conf.uuid +
        "/provider/" +
        conf.providerId,
      token: conf.token,
      method: "DELETE"
    },
    {
      success: () =>
        handleFetchDisconnectProviderSuccess(
          conf.invokeInfo,
          conf.getUserSettings
        ),
      error: () => handleFetchDisconnectProviderFail(conf.invokeInfo)
    }
  );
};

const handleFetchDisconnectProviderSuccess = (invokeInfo, getUserSettings) => {
  invokeInfo({
    variant: "success",
    message: "UserSettings.disconnectProviderSuccessAlert"
  });

  getUserSettings();
};

const handleFetchDisconnectProviderFail = invokeInfo => {
  invokeInfo({
    variant: "danger",
    message: "UserSettings.disconnectProviderFailAlert"
  });
};
