export const getRowLabel = fieldId => {
  switch (fieldId) {
    case "accesses":
      return "InfoSectionInfoTag.access";
    case "products_in":
      return "InfoSectionInfoTag.productsIn";
    case "products_out":
      return "InfoSectionInfoTag.productsOut";
    default:
      return "";
  }
};

export const prepareGroupTags = (chosenData, allData, fieldId, t) => {
  if (!!chosenData && !!allData)
    return allData
      .filter(data => chosenData.indexOf(data.id) > -1)
      .map(data => {
        const elementName = translateItem(data, fieldId, t);
        return {
          id: data.id.toString(),
          text: elementName,
          name: elementName
        };
      });

  return [];
};

export const prepareGroupData = (chosenData, allData, fieldId, t) => {
  return prepareGroupTags(chosenData, allData, fieldId, t)
    .map(data => data.text)
    .join(", ");
};

const translateItem = (item, fieldId, t) => {
  const domain = fieldId === "accesses" ? "accessName" : "productName";

  return t("common:" + domain + "." + item.string_id);
};

export const prepareSuggestions = (chosenData, allData, fieldId, t) => {
  return allData
    .filter(data => chosenData.indexOf(data.id) < 0)
    .map(data => {
      const text = translateItem(data, fieldId, t);
      return { id: data.id.toString(), text, name: text };
    })
    .sort((a, b) => (a.text > b.text ? 1 : -1));
};

export const getUnitTypeProducts = (
  unitTypeId,
  allProducts,
  unitTypeProducts,
  fieldId
) => {
  let productIds = [];
  let products = [];
  const relation = fieldId === "products_in" ? "in" : "out";

  unitTypeProducts.forEach(unitTypeProduct => {
    if (
      unitTypeProduct.unit_type_id === unitTypeId &&
      unitTypeProduct.relation === relation &&
      productIds.indexOf(unitTypeProduct.product_id) < 0
    )
      productIds.push(unitTypeProduct.product_id);
  });

  allProducts.forEach(product => {
    if (productIds.indexOf(product.id) > -1) products.push(product);
  });

  return products;
};
