import React, { Component } from "react";

import "./style.scss";
import {
  calculateModalLeftPosition,
  calculateModalTopPosition,
  getClassName
} from "./functions";
import ModalSectionMenuWrapper from "../../containers/ModalSectionMenuWrapper/index";
import ModalSectionContentWrapper from "../../containers/ModalSectionContentWrapper/index";
import { Config } from "./config";

class ModalSection extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: props.modalSection.position,

      // Flag if component should open in relative position to invoking object. If true, component is also movable
      useRelativePosition: !!this.props.modalSection.conf.useRelativePosition,

      // Relevant if useRelativePosition === true
      leftPosition: 0,
      topPosition: Config.defaultSizes.topPosition,

      contentType: props.modalSection.type,
      previousContentType: ""
    };

    this.componentRef = "modalSection_" + this.props.modalSection.position;
  }

  componentWillReceiveProps(newProps) {
    const contentType = newProps.modalSection.type;
    const previousContentType = this.state.contentType;

    this.setState({
      contentType,
      previousContentType:
        contentType !== previousContentType
          ? previousContentType
          : this.state.previousContentType,
      useRelativePosition: !!newProps.modalSection.conf.useRelativePosition
    });

    if (
      !!newProps.modalSection.conf.reloadContent &&
      newProps.modalSection.position === this.state.id &&
      (!!newProps.modalSection.conf.useRelativePosition ||
        !!newProps.useRelativePosition)
    )
      this.setModalPosition(newProps.modalSection.conf.useRelativePosition);
  }

  componentDidMount() {
    if (this.state.useRelativePosition)
      this.setModalPosition(this.props.modalSection.conf.useRelativePosition);
  }

  componentDidUpdate(oldProps) {
    if (!!oldProps.modalSection.conf.reloadContent)
      this.props.toggleReloadModalPosition(this.props.id);
  }

  setModalPosition = relativeObject => {
    this.setState({
      leftPosition: this.setModalLeftPosition(relativeObject),
      topPosition: this.setModalTopPosition(relativeObject)
    });
  };

  setModalLeftPosition = relativeObject => {
    const renderedWidth = window
      .getComputedStyle(this.refs[this.componentRef])
      .getPropertyValue("width");
    const numericWidth = parseInt(
      renderedWidth.substring(0, renderedWidth.indexOf("px")),
      10
    );

    let leftPosition = calculateModalLeftPosition({
      relativeObject: {
        left: relativeObject.x || this.state.leftPosition,
        top: relativeObject.y || this.state.topPosition
      },
      defaults: Config.defaultSizes,
      modalWidth: numericWidth,
      windowWidth: window.innerWidth
    });

    return leftPosition;
  };

  setModalTopPosition = relativeObject => {
    const renderedHeight = window
      .getComputedStyle(this.refs[this.componentRef])
      .getPropertyValue("height");
    const numericHeight = parseInt(
      renderedHeight.substring(0, renderedHeight.indexOf("px")),
      10
    );

    let topPosition = calculateModalTopPosition({
      relativeObject: {
        left: relativeObject.x || this.state.leftPosition,
        top: relativeObject.y || this.state.topPosition
      },
      defaults: Config.defaultSizes,
      modalHeight: numericHeight,
      windowHeight: window.innerHeight
    });

    return topPosition;
  };

  handlePositionChange = data => {
    const renderedWidth = window
      .getComputedStyle(this.refs[this.componentRef])
      .getPropertyValue("width");
    const numericWidth = parseInt(
      renderedWidth.substring(0, renderedWidth.indexOf("px")),
      10
    );

    // Calculating boundaries
    const verticalMargin = 30;
    const horizontalMargin = 10;
    const topMargin = horizontalMargin;
    const rightMargin = window.innerWidth - verticalMargin;
    const bottomMargin = window.innerHeight - horizontalMargin;
    const leftMargin = verticalMargin - numericWidth;

    // Calculating required position
    const calculatedLeft = this.state.leftPosition + data.x;
    const calculatedTop = this.state.topPosition + data.y;

    // Setting required position inside boundaries
    this.setState({
      leftPosition:
        calculatedLeft > leftMargin
          ? calculatedLeft < rightMargin
            ? calculatedLeft
            : rightMargin
          : leftMargin,
      topPosition:
        calculatedTop > topMargin
          ? calculatedTop < bottomMargin
            ? calculatedTop
            : bottomMargin
          : topMargin
    });
  };

  render() {
    const additionalMenuProps = this.state.useRelativePosition
      ? {
          handlePositionChange: this.handlePositionChange,
          movable: true
        }
      : {
          movable: false
        };

    return (
      <div className="ModalSection__extender">
        <section
          className={getClassName(this.props.modalSection.type, {
            isLeftSectionShown: this.props.isLeftSectionShown,
            previousContentType: this.state.previousContentType
          })}
          ref={this.componentRef}
          style={
            this.state.useRelativePosition
              ? {
                  top: this.state.topPosition,
                  left: this.state.leftPosition
                }
              : {}
          }
        >
          <ModalSectionMenuWrapper
            id={this.props.id}
            {...additionalMenuProps}
          />
          <ModalSectionContentWrapper id={this.props.id} />
        </section>
      </div>
    );
  }
}

export default ModalSection;
