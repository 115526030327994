import React, { Component } from "react";

import "./style.scss";
import {
  getUnitTypeNameLabel,
  getUnitTypeParent
} from "../../containers/App/functions";
import { calculateValidationState } from "./functions";
import InfoSectionInfoOwnershipWrapper from "../../containers/InfoSectionInfoOwnershipWrapper/index";
import InfoSectionInfoStatusWrapper from "../../containers/InfoSectionInfoStatusWrapper/index";
import InfoSectionInfoMaintenanceWrapper from "../../containers/InfoSectionInfoMaintenanceWrapper/index";
import InfoSectionInfoRowWrapper from "../../containers/InfoSectionInfoRowWrapper/index";
import InfoSectionInfoTagWrapper from "../../containers/InfoSectionInfoTagWrapper/index";
import InfoSectionInfoInternetWrapper from "../../containers/InfoSectionInternetWrapper/index";
import { translate } from "react-i18next";

class InfoSectionInfoData extends Component {
  constructor(props) {
    super(props);

    this.state = {
      validations: {}
    };
  }

  componentWillUpdate(nextProps, nextState) {
    // In edge case of adding new unit empty 'name' form was not validated, but changeValidationStates() set
    // canAdd to true. This is because of overwriting setState(). So we must use this solution to pass
    // up to date canAdd info

    const validationKeys = Object.keys(nextState.validations);

    const canAdd = validationKeys.every(
      validationKey => nextState.validations[validationKey]
    );

    this.props.handleCanAdd(canAdd);
  }

  handleValidation = (fieldId, content) => {
    const validationState = calculateValidationState(fieldId, content);

    this.changeValidationStates(fieldId, validationState);

    return validationState;
  };

  changeValidationStates = (fieldId, validationState) => {
    // In edge case of adding new unit, sudden validation of several open forms will overwrite state.validations
    // This procedure instead of normal setState(object) protect with this overwriting

    this.setState(prevState => {
      return {
        validations: {
          ...prevState.validations,
          ...{ [fieldId]: validationState }
        }
      };
    });
  };

  getRow = fieldId => {
    switch (fieldId) {
      case "accesses":
      case "products_in":
      case "products_out":
        if (!!this.props.content[fieldId] || this.props.content[fieldId] === [])
          return (
            <InfoSectionInfoTagWrapper
              content={this.props.content}
              modalId={this.props.modalId}
              leftColumnClassName="InfoSectionInfoData__table-left"
              rightColumnClassName="InfoSectionInfoData__table-right"
              fieldId={fieldId}
              handleSaveData={this.props.handleSaveData}
              handleReloadData={this.props.handleReloadData}
            />
          );
        break;
      case "ownerships":
        if (!!this.props.content[fieldId] || this.props.content[fieldId] === [])
          return (
            <InfoSectionInfoOwnershipWrapper
              // content is not use explicite, but changes on ownerships state does not reload the component
              content={this.props.content}
              ownerships={this.props.content[fieldId]}
              modalId={this.props.modalId}
              leftColumnClassName="InfoSectionInfoData__table-left"
              rightColumnClassName="InfoSectionInfoData__table-right"
              handleSaveData={this.props.handleSaveData}
              handleReloadData={this.props.handleReloadData}
            />
          );
        break;
      case "status":
        if (
          !!this.props.content[fieldId] ||
          this.props.content[fieldId] === "" ||
          this.props.content[fieldId] === false
        )
          return (
            <InfoSectionInfoStatusWrapper
              status={this.props.content[fieldId]}
              modalId={this.props.modalId}
              leftColumnClassName="InfoSectionInfoData__table-left"
              rightColumnClassName="InfoSectionInfoData__table-right"
              handleSaveData={this.props.handleSaveData}
              handleReloadData={this.props.handleReloadData}
            />
          );
        break;
      case "maintenance":
        if (
          !!this.props.content.maintenance_to ||
          this.props.content.maintenance_to === ""
        )
          return (
            <InfoSectionInfoMaintenanceWrapper
              maintenance_since={this.props.content.maintenance_since}
              maintenance_to={this.props.content.maintenance_to}
              modalId={this.props.modalId}
              leftColumnClassName="InfoSectionInfoData__table-left"
              rightColumnClassName="InfoSectionInfoData__table-right"
              handleSaveData={this.props.handleSaveData}
              handleReloadData={this.props.handleReloadData}
            />
          );
        break;
      case "internet":
        if (
          !!this.props.content.website ||
          this.props.content.website === "" ||
          !!this.props.content.wikipedia ||
          this.props.content.wikipedia === "" ||
          !!this.props.content.youtube ||
          this.props.content.youtube === ""
        )
          return (
            <InfoSectionInfoInternetWrapper
              content={this.props.content}
              modalId={this.props.modalId}
              leftColumnClassName="InfoSectionInfoData__table-left"
              rightColumnClassName="InfoSectionInfoData__table-right"
              handleSaveData={this.props.handleSaveData}
              handleReloadData={this.props.handleReloadData}
            />
          );
        break;
      case "name":
        if (!!this.props.content.name || this.props.content.name === "")
          return (
            <InfoSectionInfoRowWrapper
              content={this.props.content}
              modalId={this.props.modalId}
              leftColumnClassName="InfoSectionInfoData__table-left"
              rightColumnClassName="InfoSectionInfoData__table-right"
              rowClassName="InfoSectionInfoData__table-header"
              handleValidation={this.handleValidation}
              fieldId={fieldId}
              handleSaveData={this.props.handleSaveData}
              handleReloadData={this.props.handleReloadData}
            />
          );
        break;
      default:
        if (
          !!this.props.content[fieldId] ||
          this.props.content[fieldId] === "" ||
          this.props.content[fieldId] === 0
        )
          return (
            <InfoSectionInfoRowWrapper
              content={this.props.content}
              modalId={this.props.modalId}
              leftColumnClassName="InfoSectionInfoData__table-left"
              rightColumnClassName="InfoSectionInfoData__table-right"
              handleValidation={this.handleValidation}
              fieldId={fieldId}
              handleSaveData={this.props.handleSaveData}
              handleReloadData={this.props.handleReloadData}
            />
          );
        break;
    }

    return null;
  };

  render() {
    const subcategory = getUnitTypeParent(
      this.props.unitTypes,
      this.props.content.unit_type_id
    );
    const category = getUnitTypeParent(this.props.unitTypes, subcategory.id);

    const { t } = this.props;

    return (
      <tbody className="InfoSectionInfoData">
        {this.getRow("name")}

        {!!this.props.content.unit_type_id && (
          <tr>
            <td className="InfoSectionInfoData__table-row">
              {t("InfoSectionInfoData.category")}:
            </td>
            <td>
              {!!category &&
                t(getUnitTypeNameLabel(this.props.unitTypes, category.id))}
            </td>
          </tr>
        )}
        {!!this.props.content.unit_type_id && (
          <tr>
            <td className="InfoSectionInfoData__table-row">
              {t("InfoSectionInfoData.subcategory")}:
            </td>
            <td>
              {!!subcategory &&
                t(getUnitTypeNameLabel(this.props.unitTypes, subcategory.id))}
            </td>
          </tr>
        )}
        {!!this.props.content.unit_type_id && (
          <tr>
            <td className="InfoSectionInfoData__table-row">
              {t("InfoSectionInfoData.unitType")}:
            </td>
            <td>
              {t(
                getUnitTypeNameLabel(
                  this.props.unitTypes,
                  this.props.content.unit_type_id
                )
              )}
            </td>
          </tr>
        )}

        {this.getRow("capacity")}
        {this.getRow("built_year")}
        {this.getRow("products_in")}
        {this.getRow("products_out")}
        {this.getRow("accesses")}
        {this.getRow("operator")}
        {this.getRow("owner")}
        {this.getRow("ownerships")}
        {this.getRow("status")}
        {this.getRow("maintenance")}
        {this.getRow("internet")}
      </tbody>
    );
  }
}

export default translate("frontend")(InfoSectionInfoData);
