import React from "react";

import "./style.scss";
import UnitTypeTreeContainer from "../UnitTypeTreeContainer/index";

export const LeftSectionContent = () => {
  return (
    <div className="LeftSectionContent">
      <UnitTypeTreeContainer />
    </div>
  );
};

export default LeftSectionContent;
