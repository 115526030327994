export const getContentType = (modalSectionType, config) => {
  switch (modalSectionType) {
    case "externalContent":
    case "about":
    case "advertising":
    case "privacyPolicy":
    case "tou":
    case "howTo":
    case "faq":
    case "contact":
    case "login":
    case "loginError":
    case "register":
    case "registrationSuccess":
    case "registrationError":
    case "registrationVerificationSuccess":
    case "resetPasswordRequest":
    case "resetPasswordRequestSuccess":
    case "resetPassword":
    case "resetPasswordSuccess":
    case "resetPasswordError":
    case "resetPasswordFormError":
    case "messenger":
    case "unitTypes":
    case "unitInfo":
    case "unitUsers":
    case "unitBoard":
      return modalSectionType;
    case "myProfile":
    case "userProfile":
      return "userProfile";
    case "mySettings":
      return "userSettings";
    case "unitWikipedia":
    case "unitYoutube":
      return "externalContent";
    default:
      return config.defaultContent;
  }
};

export const getExternalContentProps = props => {
  let externalContentProps = {
    url: props.modalSection.conf.url
  };

  if (props.modalSection.type === "unitYoutube")
    externalContentProps.url = props.modalSection.conf.youtubeUrl.replace(
      "watch?v=",
      "embed/"
    );

  if (!!props.modalSection.conf.className)
    externalContentProps.className = props.modalSection.conf.className;

  if (!!props.modalSection.conf.allow)
    externalContentProps.allow = props.modalSection.conf.allow;

  return externalContentProps;
};

export const getContentComponentProps = (contentType, props, i18nUtils) => {
  const { t, Interpolate } = i18nUtils;
  const { token, showModalContent, invokeInfo, activateCaptcha } = props;

  switch (contentType) {
    case "register":
      return {
        token,
        t,
        Interpolate,
        showModalContent,
        invokeInfo,
        activateCaptcha
      };
    case "login":
      return { token, t, Interpolate, showModalContent };
    case "resetPasswordRequest":
      return { token, t, activateCaptcha };
    case "resetPassword":
      return { token, t, activateCaptcha, url: props.modalSection.conf.url };
    case "loginError":
    case "registrationVerificationSuccess":
    case "registrationError":
    case "registrationSuccess":
    case "resetPasswordRequestSuccess":
    case "resetPasswordSuccess":
    case "resetPasswordError":
    case "resetPasswordFormError":
      return { t };
    case "userProfile":
      return {
        uuid: props.modalSection.conf.userId,
        modalType: props.modalSection.type,
        modalId: props.id
      };
    case "messenger":
      const defaultProps = {};
      /*onInterlocutorClick: uuid =>
          props.showModalContent({
            type: "userProfile",
            conf: {
              userId: uuid
            }
          })
      };*/

      if (!!props.modalSection.conf.userUuid)
        return {
          ...defaultProps,
          userUuid: props.modalSection.conf.userUuid
        };
      if (!!props.modalSection.conf.topicId)
        return {
          ...defaultProps,
          topicId: props.modalSection.conf.topicId
        };
      return defaultProps;
    case "contact":
      return { unitName: props.modalSection.conf.unitName };
    default:
      return {};
  }
};

export const getClassName = contentType => {
  return (
    "ModalSectionContent" +
    (["unitInfo", "unitTypes", "unitUsers", "unitBoard"].indexOf(contentType) <
    0
      ? ""
      : "--" + contentType)
  );
};
