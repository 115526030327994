/*
 * Action types
 */
export const TOGGLE_LEFT_SECTION_VISIBILITY = "TOGGLE_LEFT_SECTION_VISIBILITY";

/*
 * Action creators
 */
export function toggleLeftSectionVisibility(collapsed) {
  return {
    type: TOGGLE_LEFT_SECTION_VISIBILITY,
    collapsed
  };
}
