import React from "react";
import { Filter } from "phyzzi.com-frontend-filter-sorter";
import { config } from "../InfoSectionBoard/config";

export const FaqFilterComponent = props => {
  return (
    <Filter
      onChange={props.handleFilterChange}
      maxLength={config.filter.filterMaxLength}
      value={props.filterValue}
      transformedItemsNumber={props.transformedItemsNumber}
      originalItemsNumber={props.originalItemsNumber}
      className="InfoSectionBoardFilterSorter__filter"
      placeholder={!!props.placeholder && props.placeholder}
    />
  );
};

export default FaqFilterComponent;
