import React, { PureComponent } from "react";

import "./style.scss";

class MessengerListBottom extends PureComponent {
  componentDidMount() {
    const observerOptions = {
      root: document.querySelector("." + this.props.listWrapper),
      rootMargin: "0px",
      threshold: [1.0]
    };

    this.observer = new IntersectionObserver(
      this.handleObserver.bind(this),
      observerOptions
    );

    this.observer.observe(document.querySelector("#" + this.props.listBottom));
  }

  componentWillUnmount() {
    this.observer.unobserve(
      document.querySelector("#" + this.props.listBottom)
    );
  }

  handleObserver(entities, observer) {
    entities.forEach(entry => {
      if (entry.isIntersecting) {
        this.onBottomListTriggerAppear();
      } else {
        this.onBottomListTriggerDisappear();
      }
    });
  }

  onBottomListTriggerAppear = () => {
    this.props.switchOnScrollToBottom();
  };

  onBottomListTriggerDisappear = () => {
    this.props.switchOffScrollToBottom();
  };

  render() {
    return <div id={this.props.listBottom} className={"MessengerListBottom"} />;
  }
}

export default MessengerListBottom;
