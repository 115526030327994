import { connect } from "react-redux";

import UnitTypeTreeItem from "../../components/UnitTypeTreeItem";
import { toggleUnitTypeSelection } from "../UnitTypeTreeWrapper/actions";

const mapStateToProps = state => ({
  mapType: state.map.background
});

const mapDispatchToProps = {
  toggleUnitTypeSelection
};

const UnitTypeTreeItemWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(UnitTypeTreeItem);

export default UnitTypeTreeItemWrapper;
