import { connect } from "react-redux";

import InfoSectionUsersList from "../../components/InfoSectionUsersList";
import { showModalContent } from "../ModalGroupWrapper/actions";

const mapStateToProps = state => ({
  userUuid: state.user.uuid,
  theme: state.theme
});

const mapDispatchToProps = {
  showModalContent: showModalContent
};

const InfoSectionUsersListWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(InfoSectionUsersList);

export default InfoSectionUsersListWrapper;
