import { getUnitTypeParent } from "../../containers/App/functions";

/**
 * Returns an array of integers without given value if it previously was in it, or with given value otherwise
 *
 * @param array units
 * @param int id
 * @returns array
 */
export const getToggledUnits = (units, id) => {
  return units.some(unit => unit === id)
    ? units.filter(unit => unit !== id)
    : [...units, id];
};

/**
 * Returns an array of ids of a unit type tree of a unit. The array is from lowest level to core.
 *
 * @param array unitTypes
 * @param int unitTypeId
 * @returns array
 */
export const getUnitTypeTreeLevelIds = (unitTypes, unitTypeId) => {
  const parent = getUnitTypeParent(unitTypes, unitTypeId);

  return !!parent
    ? [unitTypeId].concat(getUnitTypeTreeLevelIds(unitTypes, parent.id))
    : [unitTypeId];
};
