import { connect } from "react-redux";

import ModalSectionContent from "../../components/ModalSectionContent";
import {
  closeModalSection,
  showModalContent
} from "../ModalGroupWrapper/actions";
import { invokeInfo } from "../AlertBoxWrapper/actions";
import { activateCaptcha } from "../CaptchaWrapper/actions";

const mapStateToProps = (state, props) => ({
  modalSection: state.modalSection[props.id],
  token: state.initData.token
});

const mapDispatchToProps = {
  closeModalSection,
  showModalContent,
  invokeInfo,
  activateCaptcha
};

const ModalSectionContentWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModalSectionContent);

export default ModalSectionContentWrapper;
