export const getNameGlyph = (hasChildren, selected, collapsed) => {
  return hasChildren
    ? collapsed
      ? "add"
      : "remove"
    : selected
      ? "check"
      : "check_box_outline_blank";
};

/**
 * Returns id for Unit type name button. Basically id is for tracking purpose. Return format:
 * show|hide|unselect|select_stringId
 *
 * @param hasChildren
 * @param selected
 * @param collapsed
 * @param stringId
 * @returns {string}
 */
export const getNameId = (hasChildren, selected, collapsed, stringId) => {
  return (
    (hasChildren
      ? collapsed
        ? "expand"
        : "fold"
      : selected
        ? "uncheck"
        : "check") +
    "_" +
    stringId
  );
};
