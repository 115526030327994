import React from "react";
import "./style.scss";
import { DescribedButton } from "react-bootstrap-tools";
import { getNameGlyph, getNameId } from "./functions";
import { translate } from "react-i18next";

const UnitTypeMenuItemComponent = props => {
  const { t } = props;
  const buttonText = t("common:unitTypeName." + props.unitType);
  const buttonId = getNameId(
    props.hasChildren,
    props.selected,
    props.collapsed,
    props.unitType
  );
  const buttonIcon = getNameGlyph(
    props.hasChildren,
    props.selected,
    props.collapsed
  );

  const handleButtonClick = () => {
    props.hasChildren
      ? props.toggleChildrenCollapse()
      : props.toggleUnitTypeSelection();
  };

  const MenuItem = (
    <DescribedButton
      icon={buttonIcon}
      iconClassName="UnitTypeMenuItem__name-icon"
      // for tracking purposes
      buttonClassName="UnitTypeMenu__name"
      // for tracking purposes
      id={buttonId}
      text={buttonText}
      onClick={handleButtonClick}
    />
  );

  if (props.level === 0)
    return <h2 className="UnitTypeMenuItem">{MenuItem}</h2>;
  if (props.level === 1)
    return <h3 className="UnitTypeMenuItem">{MenuItem}</h3>;
  if (props.level === 2)
    return <h4 className="UnitTypeMenuItem">{MenuItem}</h4>;
  if (props.level === 3)
    return <h5 className="UnitTypeMenuItem">{MenuItem}</h5>;

  return null;
};

export default translate("frontend")(UnitTypeMenuItemComponent);
