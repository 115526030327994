import { connect } from "react-redux";

import Captcha from "../../components/Captcha";
import { inactivateCaptcha } from "./actions";

const mapStateToProps = state => ({
  captcha: state.captcha,
  captchaPublicKey: state.initData.captchaPublicKey,
  disableCaptcha: state.initData.disableCaptcha || null,
  theme: state.theme
});

const mapDispatchToProps = {
  inactivateCaptcha
};

const CaptchaWrapper = connect(
  mapStateToProps,
  mapDispatchToProps
)(Captcha);

export default CaptchaWrapper;
